import { Box } from '@mui/material';
import React, { useState } from 'react'
import { createRoot } from 'react-dom/client';
import { Document, Page } from 'react-pdf';
import Swal from 'sweetalert2';

const useViewDocBox = () => {
    const [pageNumber, setPageNumber] = useState();
    const [aspectClass, setAspectClass] = useState('');

    // Verifica se é um PDF
    const handleDoc = (doc) => {
      return doc.complete.type === "application/pdf";
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPageNumber(numPages);
  };

  console.log(aspectClass);
    
    const viewDoc = (data) => {

        return Swal.fire({
          html: '<div id="swal-react-container"></div>',
          didOpen: () => {
            const container = document.getElementById('swal-react-container');
            const root = createRoot(container);
            root.render(
              handleDoc(data) ? (
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '0.7rem', alignItems: 'center' }}>
                  <Document file={data.file} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={1} width={500} />
                  </Document>
                  <b>{pageNumber} Páginas</b>
                </Box>
              ) : (
                <Box className={`doc-container ${aspectClass}`}>
                    <img 
                      src={data.file} 
                      alt="Document" 
                      className='swal2-image' 
                      style={{ width: '100%', maxWidth: '600px', height: 'auto', maxHeight: "calc(100vh - 264px)" }} 
                      onLoad={(e) => {
                        const img = e.target;
                        // Determinar a classe com base no aspecto da imagem
                        const aspectRatio = img.naturalWidth / img.naturalHeight;
                        if (aspectRatio > 1) {
                            setAspectClass('landscape');
                        } else if (aspectRatio < 1) {
                            setAspectClass('portrait');
                        } else {
                            setAspectClass('square');
                        }
                      }}
                    />
                </Box>
              ),
            );
          },
          imageHeight: 500,
          width: '90%',
          confirmButtonText: 'Fechar',
          confirmButtonColor: '#000',
          showCloseButton: true
        })
      }
  return { viewDoc }
}

export default useViewDocBox;