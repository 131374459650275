
import moment from 'moment';
export const getNextBusinessDayIfAfter17 = () => {
    const now = moment(); // Hora atual
    const endOfBusinessDay = moment().set({ hour: 17, minute: 0, second: 0 });
  
    // Verifica se é depois das 17:00
    if (now.isAfter(endOfBusinessDay)) {
      let prevBusinessDay = now.add(1, 'day')
      let nextBusinessDay = now.add(2, 'day');
  
      // Ajusta para o próximo dia útil, caso caia em um fim de semana
      while (prevBusinessDay.isoWeekday() > 5) {
        prevBusinessDay = prevBusinessDay.add(1, 'day');
        nextBusinessDay = nextBusinessDay.add(2, 'day');
      }

      return { prevBusinessDay: prevBusinessDay.toDate(), nextBusinessDay: nextBusinessDay.toDate() }
    }
  
    return { prevBusinessDay: now.toDate(), nextBusinessDay: now.add(1, 'day').toDate() }
  }