import { toast } from '../components/Toast';

export const copyText = (text, type = 'texto') => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast({ icon: 'success', text: `${type === 'Pix' ? 'Chave Pix copiada com sucesso!' : 'Texto copiado para a área de transferência'}` })
      })
      .catch((err) => {
        toast({ icon: 'error', text: 'Falha ao copiar texto:', error: err })
      });
}