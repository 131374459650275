import React, { useCallback, useEffect, useState } from 'react'
import { useFirebaseData } from '../../../contexts/QueueFirebaseContext';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { Alert, AlertTitle, Avatar, Box, Checkbox, Chip, FormControlLabel, FormGroup, Grid, IconButton, styled, SvgIcon, Tab, Tabs, TextField, ThemeProvider, Typography } from '@mui/material';

import { MdOutlineEdit, MdOutlinePaid } from "react-icons/md"; //Análista de Facilitação Financeira
import { GrDocumentText } from "react-icons/gr"; // Análista de Contratos e Recebíveis
import { MdOutlineFactCheck } from "react-icons/md"; // Análista de Orçamentos
import { TbRulerMeasure } from "react-icons/tb"; // Analista de Projetos
import { LiaMoneyCheckAltSolid } from "react-icons/lia"; // Analista de Faturamento
import { TbHeartRateMonitor } from "react-icons/tb"; // Analista de Pós-venda
import { AiFillInfoCircle } from "react-icons/ai";
import { IoIosAttach, IoLogoWhatsapp } from "react-icons/io";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'; // Endereço
import { FaRegMap } from "react-icons/fa6"; // CEP
import { MdAssignmentReturn } from "react-icons/md";
import { MdAssignmentTurnedIn } from "react-icons/md";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DeleteRounded from "@mui/icons-material/DeleteRounded";

import InfoForm from '../../Customers/Components/InfoForm';
import { CustomTabPanel } from '../../../components/CustomTabPanel';

import { theme } from '../../../data/theme';
import NewTooltip from "../../../components/Tooltip";
import formatCurrency from '../../../functions/formatCurrency';
import CurrencyInput from 'react-currency-input-field';

import { Controller, FormProvider, useForm } from 'react-hook-form';
import { toast } from '../../../components/Toast';
import Swal from 'sweetalert2';
import { BsPersonFillAdd } from 'react-icons/bs';
import removeNullProperties from '../../../functions/removeNullProperties';
import { useRegisterInfo } from '../../../contexts/RegisterInfoContext';
import { getFirstName } from '../../../functions/getFirstName';
import ButtonCB from '../../../components/Button';
import ReturnActivity from '../Components/ReturnActivity';
import { colorsBanks } from '../../../data/ColorsBanks';
import { banksList } from '../../System/data/Banks';
import ViewImprovements from '../../../components/ViewImprovements';
import useGoPage from '../../../hooks/useGoPage';
import { useActivity } from '../../../contexts/ActivityContext';
import Loading from '../../../components/Loading';

import ChatBot from '../../../assets/image/Chatbot.png';
import useViewDocBox from '../../../hooks/useViewDocBox';
import { VisuallyHiddenInput } from '../../../components/InputFile';
import { formatPhoneBR } from '../../../functions/FormatPhoneBR';
import moment from 'moment';
import { deleteFilesOnFirebase } from '../../../functions/deleteFilesOnFirebase';
import { doc, updateDoc } from 'firebase/firestore';
import { dataBase } from '../../../firebase/database';
import useUploadDocuments from '../../../hooks/useUploadDocuments';

const nextStep = {
    "Orçamento": "Visita Técnica",
    "Visita Técnica": "Projetos",
    "Projetos": "Contrato",
    "Contrato": "Recebíveis",
    "Recebíveis": "Faturamento",
    "Faturamento": "Pós-venda"
}

const ActivityReport = ({ userRef, members, type }) => {
    const goPage = useGoPage();
    const { viewDoc } = useViewDocBox();
    const [activityInfo, setActivityInfo] = useState(null);
    const [responsible, setResponsible] = useState(null);
    const { dataCustomers } = useFirebaseData();
    const { activityData, newActivitys, updateActivityAtIndex, updateActivity } = useActivity();
    const [indexActivity, setIndexActivity] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [estimate, setEstimate] = useState(null);
    const [valueProject, setValueProject] = useState(0);
    const [tabsValue, setTabsValue] = useState(0);
    const [valueTotal, setValueTotal] = useState(0);
    const { setAddress } = useRegisterInfo();
    const [payments, setPayments] = useState(null)
    const [open, setOpen] = useState({
        return: false,
        improvements: false
    })
    const { uploadDocuments } = useUploadDocuments();
    // const { setLoading } = useLoading(); Usar no futuro
    const [loading, setLoading] = useState(true);

    const methods = useForm({
        defaultValues: {
            documentos: [],
            infoExtra: [{ documentos: [] }],
        },
    });
    const [infoExtra, setInfoExtra] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [files] = useState([]);
    const [additionalElectric, setAdditionalElectric] = useState(false);
    const [searchParams] = useSearchParams(); // Captura os parâmetros da URL
    const [activityType, setActivityType] = useState('');
    const { idActivity } = useParams();
    const { control } = useForm({
        defaultValues: {}
    });

    const a11yProps = (index) => ({
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    });

    const StyledTab = styled(Tab)(() => ({
        textTransform: 'capitalize',
        borderRadius: '5px 5px 0 0',
        fontSize: '15px',
        '&.Mui-selected': {
            backgroundColor: "#fff",
            color: "#000",
        },
    }));
    const isResumeActivity = activityType === 'Atual';
    const filesPage = isResumeActivity ? files : newActivitys[indexActivity]?.documentos;

    console.log('Nova Atividade', newActivitys, indexActivity);

    const mode = searchParams.get('mode');

    useEffect(() => {
        if (mode) {
            const modeIndex = parseInt(mode.match(/\d+/)[0], 10); // Garante que o valor é um número
            const onlyTextAfterMode = mode.replace(/-\d+$/, '');
            console.log(onlyTextAfterMode);
            setActivityType(onlyTextAfterMode); // Define o tipo de atividade com base no valor do parâmetro
            setIndexActivity(modeIndex || 0);
        }
    }, [mode]);

    useEffect(() => {
        if (activityData) {
            // const activity = dataActivitys.find(activity => activity.id === idActivity);
            setActivityInfo(activityData);
        } else {
            return goPage("/atividades", "top");
        }
    }, [idActivity, activityData, goPage])

    useEffect(() => {
        if (members && !responsible && activityInfo) {
            const member = members.find(member => member.id === activityInfo.responsavel);
            setResponsible(member);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [members, activityInfo])

    useEffect(() => {
        if (estimate && estimate?.pagamento) {
            const improvements = estimate?.pagamento?.melhorias;
            const paymentType = estimate?.pagamento?.tipo.split(" ")[0];
            const paymentInfo = estimate?.pagamento?.info.split(" ")[0];
            const checkPaymentType = payments.find(payment => payment.metodo.includes(paymentType) || payment.metodo.includes(paymentInfo));
            handleElectricChange(Boolean(improvements));
            handleChange(checkPaymentType)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estimate, payments])


    const getEstimate = async (activityInfo, dataCustomers, setCustomer, setEstimate) => {
        const [idEstimate, index] = activityInfo?.orcamento_id?.split("-") || [];
        if (!idEstimate || index == null) return;

        const findCustomer = dataCustomers.find(data => data.id === idEstimate) || null;
        const findEstimate = findCustomer?.planilha[index] || null;

        setCustomer(findCustomer);
        setEstimate(findEstimate);

        const paymentsRef = [
            { tipo: 'À Vista', metodo: 'Condição Especial no Pix', valor: findEstimate?.orcamento?.valor },
            { tipo: 'À Vista', metodo: 'Transferência Bancária', valor: findEstimate?.orcamento?.valor },
            { tipo: 'À Vista', metodo: '80% de entrada + 20% na instalação', valor: findEstimate?.orcamento?.valor },
            { tipo: 'À Vista', metodo: '70% de entrada + 2x boletos', valor: findEstimate?.orcamento?.valor },
            { tipo: 'Parcelado', metodo: 'Cartão de Crédito Sem Juros', valor: findEstimate?.orcamento?.valor_parcelado },
            { tipo: 'Parcelado', metodo: 'Financiamento Bancário', valor: findEstimate?.orcamento?.valor_parcelado },
            { tipo: 'Outros', metodo: 'Incluir Melhoria Elétrica', valor: findEstimate?.visita_tecnica?.rede_eletrica?.melhorias?.valor_total }
        ];

        setPayments(paymentsRef);
        setLoading(false);
    };

    useEffect(() => {
        if (activityInfo && !estimate) {
            getEstimate(activityInfo, dataCustomers, setCustomer, setEstimate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activityInfo, dataCustomers]);

    useEffect(() => {
        if (customer) {
            setAddress(customer?.endereco ? customer.endereco : {});
            const newExtraInfo = customer?.dados_secundario?.length > 0 ? customer?.dados_secundario : [];
            const newCustomerRef = [{
                aba: getFirstName(customer?.nome),
                nome: customer?.nome,
                cidade: customer?.cidade,
                doc: customer?.doc,
                empresa: customer?.empresa,
                data_nascimento: customer?.data_nascimento,
                telefone: typeof customer?.telefone === 'number' ? customer?.telefone : customer?.telefone?.replace(/\D/g, ''),
                email: customer?.email,
                cep: customer?.cep,
                endereco: customer?.endereco,
                numero: customer?.numero,
                bairro: customer?.bairro,
                complemento: customer?.complemento,
                documentos: customer?.documentos
            }]

            const newDocuments = customer?.documentos ? [customer?.documentos] : []
            const newDocumentsExtra = newExtraInfo?.length > 0
                ? newExtraInfo.map((data) => data.documentos || [])
                : [];

            newDocuments.push(...newDocumentsExtra);
            newCustomerRef.push(...newExtraInfo);

            setDocuments(newDocuments);
            setInfoExtra(removeNullProperties(newCustomerRef));
            setLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer]);

    const handleChange = (data, extraValue = 0) => {
        const { tipo, metodo, valor } = data;
        let baseValue = parseFloat(valor);
        let finalValue = baseValue;

        if (metodo === '80% de entrada + 20% na instalação') {
            finalValue += baseValue * 0.05;
        } else if (metodo === '70% de entrada + 2x boletos') {
            finalValue += baseValue * 0.10;
        }

        setValueProject({
            tipo,
            metodo,
            valor,
            valor_final: finalValue + extraValue
        });

        setValueTotal(finalValue);
    };


    const handleElectricChange = useCallback((checked) => {
        setAdditionalElectric(checked);
        handleChange(valueProject, checked ? payments[6]?.valor || 0 : 0);
    }, [valueProject, payments]);

    const onSaveActivity = async () => {
        console.log(newActivitys);
        const result = await Swal.fire({
            title: 'Atenção',
            html: `Você deseja salvar os dados das novas atividades?`,
            icon: "warning",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0eb05f",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
          })
        
        if(result.isConfirmed) {
            try {
                
                await updateActivity(activityData.id, {
                    novas_atividades: newActivitys
                })
            } catch (error) {
                toast({ icon: 'error', message: 'Ocorreu um erro ao salvar os dados.' ,error: error})
            }
        }
    }

    const onSubmitForm = useCallback(async (values, ind) => {
        try {
  
        Swal.fire({
          title: 'Atenção',
          html: `Você deseja confirmar a alteração dos dados?`,
          icon: "warning",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: "#0eb05f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        }).then(async (result) => {
          if (result.isConfirmed) {
            setLoading(true);
  
            const documentMain = await Promise.all(documents.map((data) => uploadDocuments(customer.id, data, '', 'Clientes')));
  
            // Atualiza o cliente com propriedades filtradas e removendo valores nulos
            const valuesUpdate = ind === 0 ? values : infoExtra[0];
            const updatedCustomer = removeNullProperties({
              ...valuesUpdate,
              documentos: documentMain[0],
            });
  
            // Atualiza as informações extras com a lista de documentos atualizada
            const updatedInfoExtra = infoExtra
              .filter((_, index) => index !== 0) // Remove o item no índice 0
              .map((item, index) => {
                const updatedItem = index === (ind - 1)
                  ? removeNullProperties({ ...values, documentos: documentMain[index + 1] })
                  : removeNullProperties({ ...item, documentos: documentMain[index + 1] });
                return updatedItem;
              })
  
            // Combina os dados do cliente atualizado com as informações secundárias
            const newUpdatedCustomer = { ...updatedCustomer, dados_secundario: updatedInfoExtra };
  
            const documentsMainOnFirebase = customer?.documentos || [];
            const documentsSecondaryOnFirebase = customer?.dados_secundario?.length > 0 ? customer?.dados_secundario.map((data) => data?.documentos) : [];
            const allDocumentsOnFirebase = [documentsMainOnFirebase, ...documentsSecondaryOnFirebase ];
      
            await deleteFilesOnFirebase(allDocumentsOnFirebase.flat(), documents);
  
            await updateDoc(doc(dataBase, 'Clientes', customer.id), {
              ...newUpdatedCustomer,
              updateAt: new Date(),
              historico: [...customer.historico, {
                data: moment().format('DD/MM/YYYY - HH:mm'),
                evento: '[Atividade] Cliente Alterado',
                text: `O cliente recebeu novas informações na aba ${infoExtra[ind]}.`,
                usuario_id: userRef?.id,
                usuario_nome: userRef?.nome
              }]
  
            })
  
            setLoading(false);
            await Swal.fire({
              title: 'Cliente',
              html: `Os dados foram alterados com sucesso.`,
              icon: "success",
              showCloseButton: true,
              confirmButtonColor: "#111",
              confirmButtonText: "Ok",
            })
          }
        })
      } catch (error) {
        console.log('Erro: ' + error);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [infoExtra, documents])

    const handleInfo = async (type, nameRef, ind) => {
        const isAdd = type === 'Add';
        const { value: name } = await Swal.fire({
            title: 'Nomear Aba',
            html: `Informe o <b>nome</b> da aba para identificar os dados a serem preenchidos.`,
            showCancelButton: true,
            showCloseButton: true,
            inputValue: nameRef,
            confirmButtonColor: "#0eb05f",
            confirmButtonText: isAdd ? "Adicionar" : "Alterar",
            cancelButtonText: "Fechar",
            input: "text",
            inputAutoFocus: true,
            didOpen: () => {
                const swalContainer = document.querySelector('.swal2-container');
                const modalBody = document.querySelector('.MuiDialog-container');
                if (swalContainer && modalBody) {
                    modalBody.appendChild(swalContainer);
                }
            },
            inputValidator: (value) => {
                if (!value) {
                    return 'Informe o nome da aba'
                }
            },
        })

        if (name) {
            let infoList = [...infoExtra];
            if (isAdd) {
                const addNewInfo = {
                    aba: name,
                    criadoPor: {
                        nome: userRef?.nome,
                        id: userRef?.id,
                        ref: 'Simulação'
                    }
                }
                infoList.push(addNewInfo);
            } else {
                infoList = infoList.map((info, idx) =>
                    idx === ind ? { ...info, aba: name } : info
                );
            }
            setInfoExtra(infoList);
            toast({ icon: 'success', text: `Aba ${isAdd ? 'criada' : 'alterada'} com sucesso` })
        }
    }


    const sectorTitle = {
        "Pré-Venda": {
            color: "#57d9f7",
            text: "Pré-Venda",
            icon: <MdOutlineFactCheck size='70px' color='#57d9f7' />,
            colorText: "#000",
            previous: "Pré-Venda",
            form: false
        },
        "Orçamento": {
            color: "#FF8800",
            text: "Analista de Orçamento",
            icon: <MdOutlineFactCheck size='70px' color='#FF8800' />,
            colorText: "#000",
            previous: "Pré-Venda",
            form: false
        },
        "Simulação": {
            color: "#00A3EE",
            text: "Analista de Facilitação Financeira",
            icon: <MdOutlinePaid size='70px' color='#00A3EE' />,
            colorText: "#FFF",
            previous: "Orçamento",
            form: true
        },
        "Projetos": {
            color: "#AE00FF",
            text: "Analista de Projetos",
            icon: <TbRulerMeasure size='70px' color='#AE00FF' />,
            colorText: "#FFF",
            previous: "Visita Técnica",
            form: false
        },
        "Visita Técnica": {
            color: "#AE00FF",
            text: "Analista de Projetos",
            icon: <TbRulerMeasure size='70px' color='#AE00FF' />,
            colorText: "#FFF",
            previous: "Orçamento",
            form: false
        },
        "Contrato": {
            color: "#00B01D",
            text: "Analista de Contratos e Recebiveis",
            icon: <GrDocumentText size='70px' color='#00B01D' />,
            colorText: "#FFF",
            previous: "Projetos",
            form: true
        },
        "Recebíveis": {
            color: "#00B01D",
            text: "Analista de Contratos e Recebiveis",
            icon: <GrDocumentText size='70px' color='#00B01D' />,
            colorText: "#FFF",
            previous: "Contrato",
            form: true
        },
        "Faturamento": {
            color: "#014277",
            text: "Analista de Faturamento",
            icon: <LiaMoneyCheckAltSolid size='70px' color='#014277' />,
            colorText: "#FFF",
            previous: "Recebíveis",
            form: false
        },
        "Pós-Vendas": {
            color: "#FFBF00",
            text: "Analista de Pós-venda",
            icon: <TbHeartRateMonitor size='70px' color='#FFBF00' />,
            colorText: "#000",
            previous: "Faturamento",
            form: false
        }
    }

    const getMember = useCallback((id) => {
        const memberRef = members.find((data) => data.id === id)
        return memberRef;
    }, [members])

    const sendFile = async (e) => {
        const files = Array.from(e.target.files).map(image => ({
            file: URL.createObjectURL(image),
            complete: image,
        }));
        updateActivityAtIndex(indexActivity, {...newActivitys[indexActivity], documentos: [...newActivitys[indexActivity].documentos, ...files]});
        // setNewActivitys(prevNewActivitys =>
        //     prevNewActivitys.map((act, idx) =>
        //         idx === indexActivity
        //             ? {
        //                 ...act,
        //                 documentos: act.documentos ? [...act.documentos, ...files] : files,
        //             }
        //             : act
        //     )
        // );
    };

    const DocsItems = styled(Box)(({ color }) => ({
        padding: "0.5rem 1rem",
        borderRadius: "5px",
        backgroundColor: color || "#fff",
        display: "flex",
        justifyContent: "space-between",
        border: "1px solid rgba(0, 0, 0, 0.12)",
    }));

    const ResumeActivity = () => {
        return (
            <Box sx={{ display: 'flex', gap: '0.5rem', color: "#676767", flexDirection: 'column' }}>
                {isResumeActivity ?
                    <Box sx={{ display: 'flex', gap: '0.5rem', color: "#676767" }}>
                        <Avatar sx={{ margin: '0.4rem', width: 59, height: 59 }} alt={'Analista'} src={getMember(activityInfo?.orientacao?.responsavel)?.photo?.url || ChatBot} />
                        <Typography variant='p' marginTop="0.5rem">
                            <b>{getMember(activityInfo?.orientacao?.responsavel)?.nome_completo || 'Mensagem Automática'}: </b>
                            {activityInfo?.orientacao?.texto}
                        </Typography>
                    </Box> :
                    <Box>
                        <Controller
                            name={`comentario-${activityType}`}
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    rows={3}
                                    sx={{ marginTop: '0.3rem' }}
                                    label={`Demanda`}
                                    multiline
                                    inputProps={{
                                        maxlength: '1000'
                                    }}
                                    onBlur={(e) => {
                                        field.onBlur(); // Aciona o evento padrão de onBlur do Controller
                                        updateActivityAtIndex(indexActivity, {...newActivitys[indexActivity], comentario: field.value});
                                    }} />
                            )}
                        />
                        {/* <Typography textAlign={'end'} color={"#777777"}>{commentary.length}/1000</Typography> */}
                    </Box>
                }
                <Box sx={{
                    textAlign: 'center',
                    padding: '1rem',
                    display: 'flex',
                    gap: '0.5rem',
                    flexDirection: 'column',
                    backgroundColor: "#faf8f4",
                    marginTop: '1rem',
                    borderRadius: '5px',
                    borderLeft: "5px solid #7b705b"
                }}>
                    <h3>Documentos</h3>
                    {filesPage?.length > 0 ?
                        filesPage?.map((file, index) => (
                            <Box key={index}>
                                <DocsItems>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: "0.5rem",
                                            alignItems: "center",
                                        }}
                                        onClick={() => viewDoc(file)}
                                    >
                                        <InsertDriveFileOutlinedIcon sx={{ fill: "#484848" }} />
                                        <Link to="" style={{ color: "#000" }}>
                                            {file?.complete?.name}
                                        </Link>
                                    </Box>
                                    {!isResumeActivity && (
                                        <NewTooltip
                                            id="Clickable"
                                            title="Excluir"
                                            placement="top"
                                        >
                                            <IconButton
                                                onClick={() => updateActivityAtIndex(indexActivity, {...newActivitys[indexActivity], documentos: newActivitys[indexActivity]?.documentos?.filter((_, i) => i !== index)})}
                                                color="error"
                                            >
                                                <DeleteRounded />
                                            </IconButton>
                                        </NewTooltip>
                                    )}
                                </DocsItems>
                            </Box>
                        )) : <Typography variant=''>Nenhum Documento Anexado</Typography>}
                </Box>
                {!isResumeActivity && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <ButtonCB
                            sx={{ textTransform: "capitalize", marginTop: '1rem' }}
                            variant="contained"
                            component="label"
                            background="#000000"
                            disabled={files.length > 4}
                            onChange={sendFile}
                            startIcon={<IoIosAttach />}
                            disableElevation
                        >
                            Anexar Documento
                            <VisuallyHiddenInput
                                type="file"
                                accept="image/png,image/jpeg, application/pdf"
                            />
                        </ButtonCB>
                    </Box>
                )}
            </Box>
        )
    }

    const ChangePayments = useCallback(() => {

        const message = {
            "2": {
                text: <Box><p>5% de acréscimo do valor</p></Box>
            },
            "3": {
                text: <Box><b>Parcelamento no boleto bancário depende de aprovação de crédito.</b><br /><br /><p>10% acréscimo no valor (1° boleto no ato da instalação e 2° boleto depois de 30 dias).</p></Box>
            }
        }

        return (
            <ThemeProvider theme={theme}>
                <FormGroup
                    sx={{
                        display: "flex",
                        maxWidth: '500px',
                        gap: "0.5rem"
                    }}
                >
                    {payments?.map((data, index) => (
                        <FormControlLabel
                            key={index}
                            control={
                                <Checkbox
                                    disabled
                                    checked={data.metodo === valueProject.metodo || (data.metodo === 'Incluir Melhoria Elétrica' && additionalElectric)}
                                    name="condicoes_especiais_sim"
                                    color="success"
                                />
                            }
                            onChange={() => data.metodo === 'Incluir Melhoria Elétrica'
                                ? handleElectricChange(!additionalElectric)
                                : handleChange(data, additionalElectric ? payments[6]?.valor : 0)}
                            sx={{ '.MuiFormControlLabel-label': { width: '100%' } }}
                            label={
                                <Box sx={{ position: 'relative', width: '100%' }}>
                                    <Box sx={{
                                        display: 'flex',
                                        gap: '0.3rem',
                                        alignItems: 'center',
                                        boxShadow: '0px 4px 24px 0px #0000001A',
                                        padding: '0.4rem 0.7rem',
                                        borderRadius: '10px',
                                        width: '100%',
                                        maxWidth: '400px',
                                        justifyContent: 'space-between'
                                    }}>
                                        <Typography variant="b">{data?.metodo}</Typography>
                                        {message[index] && (
                                            <NewTooltip title={message[index]?.text} placement='top'>
                                                <span>
                                                    <SvgIcon>
                                                        <AiFillInfoCircle color='#9F9E9E' />
                                                    </SvgIcon>
                                                </span>
                                            </NewTooltip>
                                        )}
                                    </Box>
                                    {valueProject.metodo === "Financiamento Bancário" && index === 5 && (
                                        <Box sx={{ position: 'absolute', right: '-230px', top: 0 }}>
                                            <CurrencyInput
                                                customInput={TextField}
                                                label="Valor"
                                                color='info'
                                                size='small'
                                                InputProps={{
                                                    sx: {
                                                        '& .MuiInputBase-input': {
                                                            backgroundColor: '#E2FFD4', // Aplica o fundo branco ao input,http://localhost:3000/instalacao
                                                            maxWidth: '160px',
                                                            padding: '0.4rem',
                                                            textAlign: 'center'
                                                        },
                                                    },
                                                }}
                                                placeholder="R$ 00"
                                                intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                                                onValueChange={(formatted) => setValueTotal(formatted)}
                                                decimalsLimit={2}
                                                value={valueTotal}
                                                fullWidth
                                            />
                                        </Box>
                                    )}
                                    {additionalElectric && index === 6 && (
                                        <Box sx={{ position: 'absolute', right: '-230px', top: 0 }}>
                                            <CurrencyInput
                                                customInput={TextField}
                                                label="Valor"
                                                color='info'
                                                size='small'
                                                InputProps={{
                                                    sx: {
                                                        '& .MuiInputBase-input': {
                                                            backgroundColor: '#E2FFD4', // Aplica o fundo branco ao input,
                                                            maxWidth: '160px',
                                                            padding: '0.4rem',
                                                            textAlign: 'center'
                                                        },
                                                    },
                                                }}
                                                placeholder="R$ 00"
                                                intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                                                value={payments[6]?.valor}
                                                decimalsLimit={2}
                                                fullWidth
                                            />
                                        </Box>
                                    )}
                                </Box>
                            }
                        />

                    ))}
                </FormGroup>

            </ThemeProvider>
        )
    }, [valueProject, valueTotal, payments, additionalElectric, handleElectricChange])

    const TitleStep = styled(Box)(({ color, align, sx, colorText }) => ({
        borderRadius: '5px',
        backgroundColor: color,
        padding: '10px 20px',
        boxSizing: 'border-box',
        fontSize: '20px',
        fontWeight: 'bold',
        color: colorText,
        textAlign: align,
        maxWidth: '400px',
        width: '100%',
        ...sx
    }))

    const BoxAction = ({ title, children }) => {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: '10px',
                backgroundColor: "#fff",
                overflow: 'hidden'
            }}>
                <Box sx={{ backgroundColor: "#000", color: "#fff", width: "100%", padding: '1rem', textAlign: 'center' }}>
                    <Typography variant='h5'>{title}</Typography>
                </Box>
                <Box sx={{ width: '100%', padding: '1rem', boxSizing: 'border-box' }}>
                    {children}
                </Box>
            </Box>
        )
    }

    const InfoCustomer = React.memo(() => {
        return (
            <Grid container margin={'2rem 0 0.4rem 0'} padding={'0 0.3rem'}>
                <Grid item xs={10} sx={{ 'li': { display: 'flex', alignItems: 'flex-end', gap: '0.5rem' }, display: 'flex', gap: '1.3rem' }}>
                    <li>
                        <Typography variant='p' sx={{ 'a': { color: "#000" } }}>
                            <b>Cliente: </b>
                            <NewTooltip title="Visualizar o Perfil do Cliente" placement='top'>
                                <Link to={`/clientes/${activityInfo?.cliente_id}`}>{customer?.nome}</Link>
                            </NewTooltip>
                        </Typography>
                    </li>
                    <li>
                        <IoLogoWhatsapp size='24px' color='#60D669' />
                        <Typography variant='p' sx={{ 'a': { color: "#000" } }}>
                            <NewTooltip title="Entrar em Contato via WhatsApp" placement='top'>
                                <a href={`https://wa.me/55${customer?.telefone}`} target="_blank" rel="noopener noreferrer">
                                    {formatPhoneBR(customer?.telefone)}
                                </a>
                            </NewTooltip>
                        </Typography>
                    </li>
                    <li>
                        <LocationOnOutlinedIcon />
                        <Typography variant='p'>{customer?.endereco?.endereco}</Typography>
                    </li>
                    <li>
                        <FaRegMap size='24px' />
                        <Typography variant='p'>{customer?.cep}</Typography>
                    </li>
                </Grid>
                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0.3rem', width: '100px' }}>
                        <Typography variant='p' color="#676767">Nº Proposta</Typography>
                        <Typography variant='b' sx={{ padding: '0.3rem 0.7rem', border: '2px solid #000' }}>#1865-2</Typography>
                    </Box>
                </Grid>
            </Grid>
        )
    }, [])

    const saveTabData = useCallback((data, type, send, ind) => {

        setInfoExtra(prevInfo =>
            prevInfo.map((info, idx) =>
                idx === ind ? {
                    ...info,
                    ...data
                } : info
            )
        );

        if (send) {
            onSubmitForm(data, ind);
        }

    }, [onSubmitForm]);

    const checkPermissionInfoForm = useCallback((data) => {
        console.log(data)
        return activityType !== 'Atual' && data?.criadoPor?.ref === 'Simulação';
    }, [activityType]);

    const handleTabChange = useCallback(
        (event, newValue) => {
            if (tabsValue !== newValue) {
                const form = document.querySelector(`#form-Extra-${tabsValue}`);
                form?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
                setTabsValue(newValue);
            }
        },
        [tabsValue]
    );

    const PaymentsInfo = ({ data }) => {
        const paymentChoice = data?.pagamento;

        const str = paymentChoice?.tipo || '';
        const match = str?.match(/\(([^)]+)\)/);
        const result = match ? match[1] : null;
        const firstWord = str.split(" ")[0];
        const projectValue = payments?.find((item) => item?.metodo?.includes(firstWord)) || { valor: 0 }

        return (
            <Grid container spacing={2} sx={{ marginTop: '0.4rem' }} justifyContent={'center'}>
                {result ?
                    <>
                        <Grid item xs={4}>
                            <Box sx={{
                                backgroundColor: colorsBanks[result || 'Padrão'][0], borderRadius: '15px', padding: '1rem',
                                display: 'flex', flexDirection: 'column', gap: '0.5rem'
                            }}>
                                <Box
                                    sx={{ 'img': { width: '40px' }, display: 'flex', maxWidth: '440px', alignItems: 'center', gap: '1rem', width: '100%' }}
                                    marginTop={"0.5rem"}
                                >
                                    <img src={banksList[result || 'Padrão']} alt="" />
                                    <Box color="#fff">
                                        <h3>Condição Financiamento</h3>
                                        <p>{result}</p>
                                    </Box>
                                </Box>
                                <Box sx={{
                                    padding: '1.4rem 1rem', backgroundColor: "#fff", borderRadius: '10px', margin: '0.5rem 0',
                                    color: colorsBanks[result || 'Padrão'][2], lineHeight: '23px'
                                }}>
                                    <p><b>Carência: </b>{paymentChoice?.carencia} dias</p>
                                    <p><b>Condição: </b>{paymentChoice?.info}</p>
                                    <p><b>Entrada: </b>{paymentChoice?.entrada}</p>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{
                                background: "radial-gradient(ellipse at top, #5840b9 0%, #5a3bb1 25%, #6449BA 50%, #490A89 75%, #6D08A0 100%)", borderRadius: '15px', padding: '1rem', display: 'flex', flexDirection: 'column', gap: '0.5rem'
                            }}>
                                <Box
                                    sx={{ 'img': { width: '40px' }, display: 'flex', maxWidth: '440px', alignItems: 'center', gap: '1rem', width: '100%' }}
                                    marginTop={"0.5rem"}
                                >
                                    <img src={banksList[result || 'Padrão']} alt="" />
                                    <Box color="#fff">
                                        <h3>Programa de Pontos</h3>
                                        <p>Seu Crédito, Seu Prêmio</p>
                                    </Box>
                                </Box>
                                <Box sx={{
                                    padding: '0.6rem 1rem 0.6rem 1rem', backgroundColor: "#fff", borderRadius: '10px', margin: '0.5rem 0',
                                    color: "#3934BC", lineHeight: '21px'
                                }}>
                                    <Typography fontSize={'18px'}>Saldo de Pontos</Typography>
                                    <Typography fontSize={'25px'} lineHeight={'1.3rem'} paddingBottom={'0.3rem'} fontWeight={'bold'}>{paymentChoice?.pontos}</Typography>
                                    <p><b>CPF: </b>{paymentChoice?.cpfCNPJ}</p>
                                    <p><b>Telefone: </b>{customer?.telefone}</p>
                                </Box>
                            </Box>
                        </Grid>
                    </> :
                    <Grid item xs={8}>
                        <Alert sx={{ ".MuiAlert-message": { width: '100%' } }}>
                            <AlertTitle>Pagamento Escolhido pelo Cliente</AlertTitle>
                            <Grid container spacing={0.5}>
                                <Grid item xs={6} sx={{ lineHeight: '25px' }}>
                                    <p><b>Pagamento Escolhido: </b>{paymentChoice?.tipo}</p>
                                    <p><b>Entrada: </b>{formatCurrency(paymentChoice?.entrada)}</p>
                                    <p><b>Valor do Projeto: </b>{formatCurrency(projectValue?.valor)}</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p><b>Melhorias Elétricas: </b>{formatCurrency(estimate?.visita_tecnica?.rede_eletrica?.melhorias?.valor_total || 0)}</p>
                                    <ViewImprovements items={estimate?.visita_tecnica?.rede_eletrica?.melhorias} open={open.improvements} close={() => setOpen({ ...open, improvements: false })} />
                                    <ButtonCB sx={{ padding: '0 0.5rem', fontSize: '14px', fontWeight: '400', margin: '0.2rem 0 0.5rem 0' }} onClick={() => setOpen({ ...open, improvements: true })}>Detalhes</ButtonCB>
                                    <p><b>{estimate?.visita_tecnica?.rede_eletrica?.melhorias?.custos === 'Sim' ? "Com Custos" : "Sem Custos"}: </b>
                                        {paymentChoice?.melhorias ? "Incluido ✅" : "Não Incluido ❌"}</p>
                                </Grid>
                            </Grid>
                            <Box sx={{ width: '100%', backgroundColor: "#d7efd7", padding: '0.5rem 0', borderRadius: '3px', fontSize: '1.2rem', marginTop: '0.4rem', textAlign: 'center' }}>
                                <p><b>Valor Final:</b> {formatCurrency(paymentChoice?.valor_total)}</p>
                            </Box>
                        </Alert>
                    </Grid>
                }
            </Grid>
        )
    }

    return (
        <Box sx={{ maxWidth: '1300px', width: '100%' }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', margin: '2rem 0 1rem 0', gap: '0.5rem' }}>
                        {sectorTitle[isResumeActivity ? activityInfo?.etapa : activityType]?.icon}
                        <Typography variant='h5' fontSize='30px' margin='0 0 0.2rem 1rem' fontWeight='bold'>{sectorTitle[isResumeActivity ? activityInfo?.etapa : activityType]?.text}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography margin={'0 0 0.2rem 1rem'}>Recebido de</Typography>
                    <TitleStep color={sectorTitle[activityInfo?.orientacao?.etapa]?.color || '#7A7A7A'} colorText={sectorTitle[activityInfo?.orientacao?.etapa]?.colorText || '#FFF'} align="center">{activityInfo?.orientacao?.etapa || 'Robô'}</TitleStep>
                </Grid>
                <Grid item xs={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography margin={'0 0 0.2rem 1rem'}>Etapa Atual</Typography>
                    <TitleStep color={sectorTitle[activityInfo?.etapa]?.color || '#7A7A7A'} colorText={sectorTitle[activityInfo?.etapa]?.colorText || '#FFF'} align="center">{activityInfo?.etapa}</TitleStep>
                </Grid>
                <Grid item xs={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography margin={'0 1rem 0.2rem 0'}>Atividade Atual</Typography>
                    <TitleStep color={"#7A7A7A"} colorText={'#FFF'} align="center"><p>{activityInfo?.titulo}</p></TitleStep>
                </Grid>
                <Grid item xs={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography margin={'0 1rem 0.2rem 0'}>{isResumeActivity ? 'Objetivo' : 'Emcaminhar para'}</Typography>
                    <TitleStep color={sectorTitle[isResumeActivity ? nextStep[activityInfo?.etapa] : activityType]?.color || '#7A7A7A'} colorText={sectorTitle[nextStep[activityInfo?.etapa]]?.colorText || '#FFF'} align="center"><p>{isResumeActivity ? nextStep[activityInfo?.etapa] : activityType}</p></TitleStep>
                </Grid>
                <Grid item xs={12}>
                    <InfoCustomer />
                </Grid>
                <Grid item xs={12} sx={{ margin: "0.2rem 0 1rem 0" }}>
                    <BoxAction title={"Relatar Atividade"}>
                        <ResumeActivity />
                    </BoxAction>
                </Grid>
                {sectorTitle[isResumeActivity ? activityInfo.etapa : activityType]?.form && (
                    <>
                        <Grid item xs={12} sx={{ margin: "1rem 0" }}>
                            <BoxAction title={"Formas de Pagamento"}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', margin: '1rem 0', flexDirection: 'column', alignItems: 'center' }}>
                                    <ChangePayments />
                                    <Grid container justifyContent={'center'}>
                                        <Grid item xs={8}>
                                            <Box sx={{ backgroundColor: '#000', padding: '1rem 2rem', borderRadius: '5px', color: "#fff", textAlign: 'center', marginTop: '2rem' }}>
                                                <p>Total do Projeto:</p>
                                                <Typography variant='h5' fontSize='25px' fontWeight='bold'>{formatCurrency(valueProject?.valor_final || 0)}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <PaymentsInfo data={estimate} />
                                </Box>
                            </BoxAction>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: "1rem 0 5rem 0" }}>
                            <BoxAction title={isResumeActivity ? activityInfo?.etapa === 'Simulação' ? "Ficha de Simulação" : "Ficha de Contrato" : activityType === 'Simulação' ? "Ficha de Simulação" : "Ficha de Contrato"} sx={{ padding: '1rem' }}>
                                <Box sx={{ width: '100%', marginBottom: '1rem' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs
                                            value={tabsValue}
                                            onChange={handleTabChange}
                                            aria-label="Informações do Cliente"
                                        >
                                            {infoExtra.map((data, index) => (
                                                <StyledTab
                                                    key={index}
                                                    label={<Box sx={{ display: "flex", alignItems: "center", gap: '0.4rem' }}>
                                                        <span>{data?.aba}</span>
                                                        {data?.criadoPor?.nome && (
                                                            <Chip label={data?.criadoPor?.nome} size='small' />
                                                        )}
                                                        {index !== 0 && data?.criadoPor?.ref === 'Simulação' && (
                                                            <IconButton
                                                                onClick={(e) => {
                                                                    e.stopPropagation(); // Impede a mudança de aba ao clicar no ícone
                                                                    handleInfo('Edit', data.aba, index)
                                                                }}
                                                                size="small"
                                                                sx={{ ml: 1 }}
                                                            >
                                                                <MdOutlineEdit size="1rem" />
                                                            </IconButton>
                                                        )
                                                        }
                                                    </Box>}
                                                    {...a11yProps(index)} />
                                            ))}
                                            <IconButton disabled={isResumeActivity} sx={{ padding: '0.2rem', borderRadius: '5px' }} onClick={() => handleInfo('Add', '')}>
                                                <BsPersonFillAdd size='1.3rem' />
                                            </IconButton>
                                        </Tabs>
                                    </Box>
                                </Box>
                                {infoExtra.map((data, index) => (
                                    <CustomTabPanel key={index} style={{ position: 'relative', backgroundColor: '#fff', boxShadow: '0px 2px 10px 0px rgba(25, 93, 194, 0.07)', paddingBottom: '1rem' }} value={tabsValue} index={index}>
                                        <Loading fixed={false} view={loading} />
                                        <FormProvider {...methods}>
                                            <InfoForm
                                                initialValues={data}
                                                // tabsValue={tabsValue} 
                                                saveTabData={saveTabData}
                                                customerId={customer.id}
                                                // address={address}
                                                // setAddress={setAddress} 
                                                type="Extra"
                                                formId={`form-Extra-${index}`}
                                                ind={index}
                                                documents={documents}
                                                setDocuments={setDocuments}
                                                // deleteFile={deleteFile} 
                                                // close={onClose}
                                                viewClose={false}
                                                location={'activity'}
                                                changeData={checkPermissionInfoForm(data)}
                                                checkUser={true} />
                                        </FormProvider>
                                    </CustomTabPanel>
                                ))}
                            </BoxAction>
                        </Grid>
                    </>
                )
                }
            </Grid>
            <Box sx={{
                display: 'flex',
                zIndex: '1',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '0.5rem',
                position: 'fixed',
                bottom: 0,
                right: 0,
                left: '-80px',
                backgroundColor: '#fff',
                width: '100%',
                height: '70px'
            }}>
                <Box sx={{ maxWidth: '800px', width: '100%', display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                    <ReturnActivity open={open.return} close={() => setOpen({ ...open, return: false })} />
                    {isResumeActivity ? 
                        <ButtonCB background="#000000" startIcon={<MdAssignmentReturn />} onClick={() => setOpen({ ...open, return: true })}>Retornar Atividade</ButtonCB> : 
                        <ButtonCB background="#14AE5C" startIcon={<MdAssignmentTurnedIn />} onClick={onSaveActivity}>Salvar Dados</ButtonCB>
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default ActivityReport