import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { memo } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import formatCurrency from '../../../functions/formatCurrency';

const InstallmentCheckbox = memo(({ bank, data, installment, handleInstallments, disabled = false, color = "#000" }) => (
    <FormControlLabel 
    disabled={disabled}
    key={bank}
    sx={{ 
        display: 'flex',
        gap: '1.5rem',
        margin: 0,
        '& .MuiFormControlLabel-label': {
            color: disabled ? 'red' : 'inherit',
        },
    }}
        control={
            <Checkbox
                color="success"
                value={data.quantidade}
                sx={{
                    '& .MuiSvgIcon-root': {
                        display: disabled ? 'none' : 'flex',
                    },
                    '&.Mui-checked': { color: color }
                }} 
                checked={data.quantidade === installment?.quantidade && installment?.banco === bank}
                icon={<RadioButtonUncheckedIcon sx={{ fill: color }} />}
                checkedIcon={<CheckCircleIcon />}
                onChange={() => handleInstallments(data.quantidade, bank)}
            />
        }
        label={
            <Box sx={{ color: color }}>
                <h3>{data.quantidade}x parcelas</h3>
                {bank}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <p>{formatCurrency(data.valor)} por mês</p>
                </Box>
            </Box>
        }
    />
), (prevProps, nextProps) => {
    // Comparar todas as propriedades que realmente importam para o render
    return prevProps.data.quantidade === nextProps.data.quantidade &&
           prevProps.data.valor === nextProps.data.valor &&
           prevProps.bank === nextProps.bank && // Certifique-se de comparar o banco corretamente
           prevProps.installment?.quantidade === nextProps.installment?.quantidade &&
           prevProps.installment?.banco === nextProps.installment?.banco &&
           prevProps.color === nextProps.color &&
           prevProps.disabled === nextProps.disabled;
});

export default InstallmentCheckbox;
