export const updateObj = (obj, propertyPath, value) => {
    const keys = propertyPath.split('.');
    let current = obj;
  
    keys.forEach((key, index) => {
      if (index === keys.length - 1) {
        // Se for a última chave, atribui o valor
        current[key] = value;
      } else {
        // Se a chave não existir, cria um objeto vazio
        if (!current[key]) current[key] = {};
        current = current[key];
      }
    });
  
    return { ...obj };
  };