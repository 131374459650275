import { Box, Typography } from '@mui/material'
import LockIcon from '@mui/icons-material/Lock';

const LockContent = ({ text, row = false }) => {
    return (
        <Box 
            sx={{ 
                display: 'flex', 
                justifyContent: 'center',
                flexDirection: row ? 'row' : 'column', 
                gap: '0.5rem', 
                alignItems: 'center', 
                position: 'absolute', 
                backgroundColor: "#1c1c1cbf", 
                width: '100%', 
                height: '100%', 
                zIndex: '111', 
                top: '0', 
                right: '0',
                color: "#fff" 
            }}>
            <LockIcon sx={{ fill: '#fff', fontSize: '40px' }} />
            <Typography variant="h6" padding={'0 1rem'} textAlign={'center'}>{text}</Typography>
        </Box>
    )
}

export default LockContent