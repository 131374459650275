import { Link, useNavigate } from "react-router-dom"; // Cria rotas de páginas
import { signOut } from "firebase/auth";
import { auth, dataBase } from "../../firebase/database";
import Badge from '@mui/material/Badge';
import { Users } from "../../data/Data";
import { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import { collection, onSnapshot, query, orderBy, deleteDoc, doc } from "firebase/firestore";
import moment from "moment";
import parse from 'html-react-parser';

//CSS
import 'cooltipz-css';
import "./_style.scss";

import { ReactComponent as Leads } from '../../images/icons/Leads.svg';
import { ReactComponent as Admin } from '../../images/icons/Admin.svg';
import { ReactComponent as Exit } from '../../images/icons/Logoff.svg';
import { ReactComponent as Report } from '../../images/icons/Report.svg';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalculateIcon from '@mui/icons-material/Calculate';
import MenuIcon from '@mui/icons-material/Menu';
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { useFirebaseData } from "../../contexts/QueueFirebaseContext";
import { checkPermission } from "../../functions/checkPermission";

// Imagem
// import Logo from '../../images/LogoCORPBRASIL.png'

const Header = ({ user, alerts }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [open] = useState(false);
  const { dataQueue } = useFirebaseData();
  const [notification, setNotification] = useState();
  // const [viewPopover, setviewPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const id = open ? 'simple-popover' : undefined;
  const openNotification = Boolean(anchorEl);

  useEffect(
    () => {
      const fetchData = async () => {
        await onSnapshot(query(collection(dataBase, "Membros/" + user?.id + "/Notificacao"), orderBy("createAt", 'desc')), (data) => {
          // Atualiza os dados em tempo real
          setNotification(
            data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
          );
        });
      };
      fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  const logoff = () => {
    signOut(auth)
      .then(() => {
        document.location.replace("/");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteNotification = async (type, data) => {
    if (type === '1') {
      await deleteDoc(doc(dataBase, "Membros", user.id, 'Notificacao', data.id))
    } else if (notification) {
      notification.map(async (data) => {
        return await deleteDoc(doc(dataBase, "Membros", user.id, 'Notificacao', data.id))
      })
    }
  }

  console.log(user)

  return (
    <div className="container-header print" >
      <div className="container-header__nav">
        <div>
          <Badge badgeContent={notification?.length} color="error">
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} aria-label="Notificaçôes" data-cooltipz-dir="left">
              <NotificationsIcon className="icon-notification" />
            </IconButton>
          </Badge>
          {/* Notificação */}
          <Popover
            id={id}
            open={openNotification}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}>
            <div className="notification-content">
              <p className="notification-title">Notificações</p>
              <ul>
                {(notification && notification) && notification.length > 0 ? notification.map((data, index) => (
                  <li key={index}>
                    <div className="notification-icon">
                      {data.type === 'Relátorio' && <Report className="icon-black" />}
                      {data.type === 'Visita' && <CalendarMonthIcon />}
                    </div>
                    <div className="notification-text">
                      <div className="notification-type">
                        <h4>{data.type}</h4>
                        <span>
                          {data && moment(data.data, 'YYYY-MM-DD').add(1, 'days').isSame(moment().format('YYYY-MM-DD')) &&
                            `Ontem às ${data && moment(data.createAt.seconds * 1000).format('HH:mm')}`}
                          {data && moment(data.data).isSame(moment().format('YYYY-MM-DD')) &&
                            `Hoje às ${data && moment(data.createAt.seconds * 1000).format('HH:mm')}`}
                          {data && moment(data.data).add(1, 'days').isBefore(moment().format('YYYY-MM-DD')) &&
                            `${data && moment(data.createAt.seconds * 1000).format('DD/MM - HH:mm').replace('-', 'às')}`}
                        </span>
                      </div>
                      <div>
                        {parse(data.text)}
                      </div>
                    </div>
                    <IconButton aria-label="Fechar" onClick={() => deleteNotification('1', data)}>
                      <CloseIcon />
                    </IconButton>
                  </li>
                )) :
                  <li><h3 className="notification-alert">Nenhuma Notificação</h3></li>}
                {(notification && notification) && notification.length > 0 && <Button sx={{ textTransform: 'none' }} onClick={() => deleteNotification('All', notification)}>Limpar Tudo</Button>}
              </ul>
            </div>
          </Popover>
        </div>
        <div>
          {checkPermission(["Administrador", "Vendedor(a)", "Financeiro"], user) ? 
            <Badge color="error" badgeContent={dataQueue?.length || 0}>
                <IconButton color="primary" aria-label="Orçamentos em Fila" data-cooltipz-dir="left" onClick={() => navigate('/fila-orcamentos')}>
                  <FaMoneyBillTransfer />
                </IconButton>
            </Badge> : null
          }
        </div>
        <div>
          {(user?.email === Users[0].email || user?.cargo === 'Administrador' || (user?.cargo === 'Vendedor(a)' && user?.nome !== 'Pós-Venda')) &&
            <><Badge badgeContent={alerts?.length} color="error">
              <Link to="/leads" aria-label="Confirmar Leads" data-cooltipz-dir="left">
                <Leads />
              </Link>
            </Badge>
              <Badge badgeContent={0} color="error">
                <Link to="/relatorio" aria-label="Relatório" data-cooltipz-dir="left">
                  <Report className="icon-black" />
                </Link>
              </Badge></>}
          {(user?.cargo !== 'Instalador' || user?.cargo !== 'Limpeza') &&
            <a href="https://calculadora.app.deyeinversores.com.br/pt-BR" target="_blank" rel="noreferrer" aria-label="Calculadora Híbrida" data-cooltipz-dir="left">
              <CalculateIcon />
            </a>
          }
          {user?.email === Users[0].email || user?.cargo === 'Administrador' ?
            (<Link to="/admin" aria-label="Painel Administrativo" data-cooltipz-dir="left">
              <Admin />
            </Link>)
            :
            <></>
          }
          <Link to="" onClick={logoff} aria-label="Sair" data-cooltipz-dir="left">
            <Exit />
          </Link>
        </div>
        <nav className='nav'>
          <button onClick={() => setIsOpen(!isOpen)} className={'menu-icon'}>
            <MenuIcon />
          </button>
          <ul className={isOpen ? 'nav-links open' : 'nav-links'}>
            <li><Link to="/" data-cooltipz-dir="left">Inicio</Link></li>
            {(user?.email === Users[0].email || user?.cargo === 'Administrador' || user?.cargo === 'Vendedor(a)') &&
              <><li><Link to="/leads" data-cooltipz-dir="left">Leads</Link></li><li><Link to="/relatorio" data-cooltipz-dir="left">Relatório</Link></li></>}
            <li><a href="https://calculadora.app.deyeinversores.com.br/pt-BR" target="_blank" rel="noreferrer">Calculadora Híbrida</a></li>
            {(user?.email === Users[0].email || user?.cargo === 'Administrador') ?
              <li><Link to="/admin" data-cooltipz-dir="left">Painel Administrativo</Link></li> : <></>}
            <li><Link to="" onClick={logoff} data-cooltipz-dir="left">Sair</Link></li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Header;
