import React from 'react';
import ReactDOM from 'react-dom/client';
import './_index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AuthContextProvider from './contexts/AuthContext';
import { CadastroProvider } from './contexts/CadastroContext';
import { FirebaseProvider } from './contexts/QueueFirebaseContext';
import { LoadingProvider } from './contexts/LoadingContext';
import { ActivityProvider } from './contexts/ActivityContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <LoadingProvider>
        <ActivityProvider>
          <CadastroProvider>
            <FirebaseProvider>
              <App />
            </FirebaseProvider>,
          </CadastroProvider>
        </ActivityProvider>
      </LoadingProvider>
    </AuthContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
