import { Box, Typography } from '@mui/material'
import React from 'react'

// import { ReactComponent as GrupoCorpBrasil } from '../../../images/Logo_Grupo.svg';

const Footer = ({marginTop = '0.5rem'}) => {
  return (
    <Box sx={{ 
        backgroundColor: '#dbdbdb', 
        color: '#000', 
        padding: '1rem 0.5rem', 
        width: '100%', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        flexDirection: 'column', 
        gap: '0.7rem', 
        position: 'relative',
        top: '22px',
        boxSizing: 'border-box', 
        marginTop: marginTop }}>
        {/* <Box>
          <GrupoCorpBrasil />
        </Box> */}
        {/* <Box>
          <Typography textAlign={'center'} fontWeight={'bold'}>CorpBrasil Energia Renovável LTDA</Typography>
          <Typography textAlign={'center'} fontWeight={'bold'}>34.691.677/0001-35</Typography>
          </Box> */}
        <Box sx={{ marginTop: '0.9rem' }}>
          <Typography textAlign={'center'} fontWeight={'bold'}>© 2019 - 2024 Todos os direitos reservados</Typography>
          <Typography textAlign={'center'}>CorpBrasil Energia Renovável LTDA</Typography>
          <Typography textAlign={'center'}>34.691.677/0001-35</Typography>
          {/* <Typography textAlign={'center'}>Copyright © 2019 - 2024 CorpBrasil Energia Renovável.</Typography>
          <Typography textAlign={'center'}>Todos os direitos reservados.</Typography> */}
        </Box>
    </Box>
  )
}

export default Footer