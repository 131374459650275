import { getStorage, ref, deleteObject } from "firebase/storage";

const storage = getStorage();

export const deleteFilesOnFirebase = async (oldFiles, newFiles) => {
    if(!oldFiles) return [];
    const flatNewArray = newFiles.flat();
    const newUrls = new Set(flatNewArray.map(item => item.url));
    const removedItems = oldFiles.filter(item => item && item.url).filter(item => !newUrls.has(item.url));

    if (removedItems.length > 0) {
        await Promise.all(
          removedItems.map(async (data) => {
            try {
              const fileRef = data.ref ? ref(storage, data.ref) : null;
    
              if (fileRef) {
                await deleteObject(fileRef);
                console.log(`File ${data.ref} deleted successfully`);
              } else {
                console.error(`Invalid reference for file: ${data.ref}`);
              }
            } catch (error) {
              console.error(`Error deleting file ${data.ref}:`, error);
            }
          })
        );
      }

    console.log("REMOVED ITEMS", removedItems)
  }