import { styled } from "@mui/material";
import { darkenColor } from "../functions/darkenColor";
import LoadingButton from '@mui/lab/LoadingButton';

const ButtonCB = styled(LoadingButton)(({ colorText = "#fff", background, loading = false, isClickable = true, size = 'normal' }) => ({
    borderRadius: '5px',
    fontSize: size === 'small' ? '14px' : '15px',
    boxShadow: 'none',
    fontWeight: '600',
    padding: '0.5rem 1rem',
    textTransform: 'capitalize',
    pointerEvents: isClickable ? 'auto' : 'none', // Desativa o clique
    borderColor: colorText,
    color: colorText || "#fff",
    backgroundColor: background || '#FFAB00',
    '&:hover': {
        boxShadow: 'none',
        backgroundColor: darkenColor(background || "#FFAB00", 0.4),
        cursor: isClickable ? 'pointer' : 'default', // Cursor padrão quando não clicável
    },
    '&:disabled': {
        backgroundColor: '#d3d3d3', // cor de fundo quando desativado
        color: '#a9a9a9', // cor do texto quando desativado
        cursor: 'not-allowed', // cursor indicando que está desabilitado
        boxShadow: 'none',
    },
    '.MuiLoadingButton-loadingIndicator': {
    display: loading ? 'flex' : 'none',
    padding: '0.34rem 1rem', // Mostra ou oculta o ícone de carregamento
    position: 'relative',
    margin: 0,
    width: '100%',
    justifyContent: 'center',
    left: '50%',
}
  })
)

export default ButtonCB;