import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import ButtonCB from './Button'

import CloseIcon from '@mui/icons-material/Close';
import formatCurrency from '../functions/formatCurrency';

const ViewImprovements = ({ open, close, items }) => {

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <IconButton
        aria-label="close"
        onClick={close}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon sx={{ fill: '#000' }} />
      </IconButton>
      <DialogTitle align='center'>Melhorias Elétricas</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead sx={{ backgroundColor: "#EDEDED", borderRadius: '5px' }}>
            <TableCell sx={{ padding: '0.2rem 1rem', border: 'none' }}>Descrição</TableCell>
            <TableCell sx={{ padding: '0.2rem', border: 'none' }}>Qntd</TableCell>
            <TableCell sx={{ width: '90px', padding: '0.2rem 1rem', border: 'none' }}>Preço</TableCell>
          </TableHead>
          <TableBody sx={{ 'td': { padding: '0.4rem 0.8rem' } }}>
            {items?.material?.map((data, index) => (
              <TableRow key={index}>
                <TableCell>{data.nome}</TableCell>
                <TableCell>{data.categoria === 'Mão de Obra' ? '1' : data.quantidade}</TableCell>
                <TableCell>{formatCurrency(data.valor_total)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box sx={{ display: 'flex', margin: '1rem 0 0.5rem 0', justifyContent: 'flex-end', width: '100%' }}>
          <Typography variant='b' component='b' sx={{ padding: '0.65rem 1rem', backgroundColor: "#FFAB00", borderRadius: '15px' }}>Valor total: {formatCurrency(items?.valor_total)}</Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ marginBottom: '0.5rem', display: 'flex', justifyContent: 'center' }}>
        <ButtonCB background="#000000" onClick={close}>Fechar</ButtonCB>
      </DialogActions>
    </Dialog>
  )
}

export default ViewImprovements