import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, styled, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'

import CloseIcon from "@mui/icons-material/Close";
import { IoIosAttach } from "react-icons/io";

import ButtonCB from '../../../components/Button';
import { VisuallyHiddenInput } from '../../../components/InputFile';
import { Link } from 'react-router-dom';
import NewTooltip from '../../../components/Tooltip';
import useViewDocBox from '../../../hooks/useViewDocBox';

import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DeleteRounded from "@mui/icons-material/DeleteRounded";
import Swal from 'sweetalert2';

const ReturnActivity = ({ open, close, onSubmit }) => {
    const [files, setFiles] = useState([]);
    const [reason, setReason] = useState('');
    const { viewDoc } = useViewDocBox();

    const sendFile = async (e) => {
        const ref = e.target.files;
        const filesRef = [];
        Array.from(ref, (image) =>
            filesRef.push({ file: URL.createObjectURL(image), complete: image })
        );
        if (files) {
            setFiles([...files, ...filesRef]);
        } else {
            setFiles(filesRef);
        }
    }

    const DocsItems = styled(Box)(({ color }) => ({
        padding: "0.5rem 1rem",
        borderRadius: "5px",
        backgroundColor: color || "#fff",
        display: "flex",
        justifyContent: "space-between",
        border: "1px solid rgba(0, 0, 0, 0.12)",
    }));

    const sendReturn = async () => {

        const result = await Swal.fire({
            title: 'Atenção',
            html: `Você deseja realizar essa ação?`,
            icon: "warning",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0eb05f",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
        });

        if (result.isConfirmed) {
            onSubmit(reason, files);
        }

    }

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="md"
        >
            <IconButton
                aria-label="close"
                onClick={close}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon sx={{ fill: '#000' }} />
            </IconButton>
            <DialogTitle align='center'>Descreva o motivo do retorno da atividade:</DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    rows={5}
                    sx={{ marginTop: '0.3rem' }}
                    label={`Insira pelo menos 10 caracteres`}
                    multiline
                    inputProps={{
                        maxlength: '1000'
                    }}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)} />
                <Typography textAlign={'end'} color={"#777777"}>{reason.length}/1000</Typography>
                <Box sx={{
                    textAlign: 'center',
                    padding: '1rem',
                    display: 'flex',
                    gap: '0.5rem',
                    flexDirection: 'column',
                    backgroundColor: "#faf8f4",
                    marginTop: '1rem',
                    borderRadius: '5px',
                    borderLeft: "5px solid #7b705b"
                }}>
                    <h3>Documentos</h3>
                    {files.length > 0 ?
                        files.map((file, index) => (
                            <Box key={index}>
                                <DocsItems>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: "0.5rem",
                                            alignItems: "center",
                                        }}
                                        onClick={() => viewDoc(file)}
                                    >
                                        <InsertDriveFileOutlinedIcon sx={{ fill: "#484848" }} />
                                        <Link to="" style={{ color: "#000" }}>
                                            {file?.complete?.name}
                                        </Link>
                                    </Box>
                                    <NewTooltip
                                        id="Clickable"
                                        title="Excluir"
                                        placement="top"
                                    >
                                        <IconButton
                                            onClick={() => setFiles(files.filter((_, i) => i !== index))}
                                            color="error"
                                        >
                                            <DeleteRounded />
                                        </IconButton>
                                    </NewTooltip>
                                </DocsItems>
                            </Box>
                        )) : <Typography variant=''>Nenhum Documento Anexado</Typography>}
                </Box>
            </DialogContent>
            <DialogActions sx={{ marginBottom: '0.5rem' }}>
                <ButtonCB
                    sx={{ textTransform: "capitalize" }}
                    variant="contained"
                    component="label"
                    background="#000000"
                    disabled={files.length > 4}
                    onChange={(e) => sendFile(e)}
                    startIcon={<IoIosAttach />}
                    disableElevation
                >
                    Anexar Documento
                    <VisuallyHiddenInput
                        type="file"
                        accept="image/png,image/jpeg, application/pdf"
                    />
                </ButtonCB>
                <ButtonCB background="#00A3EE" onClick={sendReturn} disabled={reason.length < 10}>Enviar Retorno</ButtonCB>
            </DialogActions>
        </Dialog>
    )
}

export default ReturnActivity