import { BrowserRouter, Routes, Route } from "react-router-dom"; // Cria rotas de páginas
import { useEffect, useState } from 'react'
import Login from './pages/Login/Index';
import Schedules from './pages/Schedules/Index';
import PanelAdmin from './pages/PanelAdmin/Index';
import Alert from './pages/Alert/Index';
import useAuth from './hooks/useAuth';
import PrivateRoute from './components/PrivateRoute';
import Schedule from './pages/Schedule/Index';
import Finance from './pages/Finance/Index';
import { collection, onSnapshot, query, orderBy } from "firebase/firestore";
import { dataBase } from "./firebase/database";
import { Users } from "./data/Data";
import { useNavigatorOnline } from '@oieduardorabelo/use-navigator-online';
import { KeyMaps } from "./data/Data";
import { useJsApiLoader } from '@react-google-maps/api';

import Prospecction from "./pages/Prospection/Index";
import Commercial from "./pages/Management_commercial/Index";
import Report from "./pages/Report/Index";
import Visit from "./pages/Visit/Index";
import PrintVisit from "./pages/Visit/Print";
import CleanerFull from "./pages/Cleaner/Pages/Full";
import Cleaner from "./pages/Cleaner/Index";
import Credit from "./pages/Credit/Index";
import Sheet from "./pages/Credit/Pages/Sheet";
import Financing from "./pages/Financing/Index";
import Create from "./pages/Report/Pages/Create";
import ViewReport from "./pages/Report/Pages/View";
import CreateWork from "./pages/Cleaner/Pages/CreateWork";
import Comission from "./pages/Comission/Index";
import ComissionFull from "./pages/Comission/Pages/Index";
import CreateVisit from "./pages/Schedule/Pages/CreateVisit";
import EditVisit from "./pages/Schedule/Pages/EditVisit";
import InfoVisit from "./pages/Schedule/Pages/InfoVisit";
import EstimateCleaner from "./pages/Cleaner/Pages/Estimate";
import Install from "./pages/Install/Index";
// import Customers from "./pages/Customers/Index";
import CustomerProfile from "./pages/Customers/Pages/Profile";
import Customers from "./pages/Customers/Index";
import Profile from "./pages/Profile/index";
import ConfirmVisit from "./pages/Confirm/Visit";
// import System from "./pages/System/Index";
// import Thanks from "./pages/System/pages/Thanks";
// import Negotiation from "./pages/Negotiation/Index";
import Testimony from "./pages/Testimony";
import LayoutMain from "./components/LayoutMain";
import Estimate from "./pages/System/pages/Estimate";
// import Thanks from "./pages/System/pages/Thanks";
import Materials from "./pages/System/pages/Materials";
import History from "./pages/History";
import Projects from "./pages/System/pages/Projects";
import VisitTec from "./pages/System/pages/Visit";
import EstimateQueue from "./pages/Estimate_queue/Index";
import Payments from "./pages/System/pages/Payments";
// import Estimate from "./pages/System/pages/Estimate";
import { AcceptProvider } from "./contexts/AcceptImprovementsContext";
import { FirebaseProvider } from "./contexts/QueueFirebaseContext";
import Activitys from "./pages/Activity/Index";
import { RegisterInfoProvider } from "./contexts/RegisterInfoContext";
import Activity from "./pages/Activity/Pages/Activity";

function App() {
  const { user } = useAuth();
  const [members, setMembers] = useState([]);
  const [userRef, setUserRef] = useState();
  const [userAlerts, setUserAlerts] = useState();
  const [leads, setLeads] = useState([]);
  const [activity, setActivity] = useState();
  const [tecs, setTecs] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [representative, setRepresentative] = useState([]);
  const [listLeads, setListLeads] = useState();
  const [reports, setReports] = useState();
  const [reportsRef, setReportsRef] = useState();
  const [check, setCheck] = useState(false);
  const [visits, setVisits] = useState();
  const membersCollectionRef = collection(dataBase, "Membros");
  const leadsCollectionRef = collection(dataBase, "Clientes");
  const activityCollectionRef = collection(dataBase, "Atividades_Total");
  const listCollectionRef = collection(dataBase, "Lista_Leads");
  const relatorioCollectionRef = collection(dataBase, "Relatorio");
  const VisitasCollectionRef = collection(dataBase,"Visitas");
  let { status } = useNavigatorOnline();
  const [libraries] = useState(["places"]);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script2',
    googleMapsApiKey: KeyMaps,
    libraries
  });
  const isAdmin = user && userRef && (user.email === Users[0].email || userRef.time);
  const isTechnicalOrAdmin = userRef?.cargo === "Técnico" || userRef?.cargo === "Administrador" || userRef?.cargo === "Financeiro" || userRef?.nome_ref
  const isNotInstaller = userRef && userRef.cargo !== 'Instalador';

  useEffect(() => {
    if(status === 'online') {
      setCheck(false);
    } else {
      setCheck(true);
    }
  }, [status]);

  useEffect(
    () => {
      const fetchData = async () => {
        onSnapshot(membersCollectionRef, (member) => {
          // Atualiza os dados em tempo real
          setMembers(member.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        });
        onSnapshot(query(collection(dataBase, "Membros/" + user?.id + "/Avisos"), orderBy("data")), (member) => {
          // Atualiza os dados em tempo real
          setUserAlerts(member.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        });
          onSnapshot(query(leadsCollectionRef, orderBy("createAt", 'desc')), (lead) => {
            // Atualiza os dados em tempo real
          setLeads(lead.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
          });
          onSnapshot(query(activityCollectionRef, orderBy("createAt", 'desc')), (activity) => {
            // Atualiza os dados em tempo real
          setActivity(activity.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
          });
          onSnapshot(query(listCollectionRef, orderBy("createAt", 'desc')), (list) => {
            // Atualiza os dados em tempo real
          setListLeads(list.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
          });
          onSnapshot(query(relatorioCollectionRef, orderBy("createAt", 'desc')), (list) => {
            // Atualiza os dados em tempo real
          setReportsRef(list.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
          });
          onSnapshot(query(VisitasCollectionRef, orderBy("dataRef", 'asc')), (list) => {
            // Atualiza os dados em tempo real
          setVisits(list.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
          });
      };
      fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      const fetchData = async () => {
        if (members) {
          setUserRef(members.find((doc) => doc.uid === user.id));
          setTecs(members.filter((member) => member.cargo === "Técnico"));
          setSellers(members.filter((member) => member.cargo === "Vendedor(a)"));
          setRepresentative(members.filter((member) => member.cargo === "Representante"));
        }
      };
      fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [members]
  );

  useEffect(() => {
        if (reportsRef) {
          if(user?.cargo === 'Vendedor(a)' && !user?.nome_ref) {
            setReports(reportsRef.filter((act) => act.consultora_uid === user.id))
          } else {
            setReports(reportsRef);
          }
        }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportsRef, userRef]
  );

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PrivateRoute element={LayoutMain} />}>
            {isAdmin && (
              <>
                <Route exact path="/admin" element={<PanelAdmin user={user} userRef={userRef} alerts={userAlerts} check={check} reports={reports} />} />
                <Route exact path="/financiamento" element={<Financing user={user} userRef={userRef} alerts={userAlerts} check={check} reports={reports} />} />
              </>
            )}
            {isTechnicalOrAdmin && (
              <Route exact path="/financeiro" element={<Finance visits={visits} userRef={userRef} alerts={userAlerts} sellers={sellers} reports={reports} />} />
            )}
            <Route exact path="/gestao-comercial" element={<Commercial visits={visits} user={user} userRef={userRef} leads={leads} activity={activity} listLeads={listLeads} members={members} sellers={sellers} check={check} reports={reports}/>} />
            {isNotInstaller && (
              <>
                <Route exact path="/fila-orcamentos" element={
                  <FirebaseProvider>
                    <EstimateQueue userRef={user} members={members} />
                  </FirebaseProvider>
                } />
                <Route exact path="/atividades" element={
                  <FirebaseProvider>
                    <Activitys userRef={user} members={members} />
                  </FirebaseProvider>
                } />
                <Route exact path="/atividade/:idActivity" element={
                  <FirebaseProvider>
                    <RegisterInfoProvider>
                      <Activity userRef={user} members={members} />
                    </RegisterInfoProvider>
                  </FirebaseProvider>
                } />
                <Route exact path="/leads" element={<Alert user={user} userRef={userRef} alerts={userAlerts} check={check} reports={reports} />} />
                <Route exact path="/credito" element={<Credit user={user} userRef={userRef} alerts={userAlerts} check={check} reports={reports} />} />
                <Route exact path="/relatorio" element={<Report user={user} userRef={userRef} alerts={userAlerts} check={check} reports={reports} />} />
                <Route exact path="/relatorio/criar" element={<Create user={user} userRef={userRef} visits={visits}/>} />
                <Route exact path="/prospeccao" element={<Prospecction isLoaded={isLoaded} user={user} userRef={userRef} leads={leads} activity={activity} listLeads={listLeads} members={members} sellers={sellers} check={check} reports={reports} alerts={userAlerts} />} />
                <Route path="/instalacao" element={<Install visits={visits} userRef={userRef} />} />
                <Route path="/visitas" element={<Schedule isLoaded={isLoaded} visits={visits} userRef={userRef} members={members} sellers={sellers} check={check}/>} />
                <Route path="/visitas/criar/:type" element={<CreateVisit isLoaded={isLoaded} visitsAll={visits} userRef={userRef} members={members} tecs={tecs} sellers={sellers} representativesRef={representative} />} />
                <Route path="/visitas/editar/:idVisit" element={<EditVisit isLoaded={isLoaded} userRef={userRef} visitsAll={visits} members={members} tecs={tecs} sellers={sellers} representativesRef={representative} />} />
                <Route path="/visitas/:idVisit" element={<InfoVisit isLoaded={isLoaded} visitsAll={visits} userRef={userRef} members={members} tecs={tecs} sellers={sellers} alerts={userAlerts} reports={reports} representatives={representative} />} />
                <Route path="/clientes/:idCustomers" element={
                  <RegisterInfoProvider>
                    <CustomerProfile isLoaded={isLoaded} leads={leads} visitsAll={visits} userRef={userRef} members={members} tecs={tecs} sellers={sellers} alerts={userAlerts} reports={reports} representatives={representative} />
                  </RegisterInfoProvider>
                  } />
                <Route path="/clientes" element={<Customers isLoaded={isLoaded} user={user} userRef={userRef} />} />
              </>
            )}
            <Route exact path="/meu_perfil" element={<Profile userRef={userRef} />} />
            <Route exact path="/comissao" element={<Comission userRef={userRef} />} />
            <Route exact path="/comissao/:idComission" element={<ComissionFull userRef={userRef} />} />
            {user && userRef && (userRef.cargo === "Administrador" || userRef.nome_ref) && (
              <>
                <Route exact path="/limpeza" element={<Cleaner userRef={userRef} alerts={userAlerts} check={check} reports={reports} />} />
                <Route exact path="/limpeza/criar" element={<CreateWork isLoaded={isLoaded} userRef={userRef} visits={visits} sellers={sellers} />} />
              </>
            )}
            <Route exact path="/" element={<Schedules userRef={userRef} alerts={userAlerts} check={check} reports={reports} />} />
            <Route path="*" element={<Schedules userRef={userRef} alerts={userAlerts} check={check} reports={reports} />} />
          </Route>
          <Route exact path="/visita/:idVisit" element={<Visit isLoaded={isLoaded} userRef={userRef} />} />
          <Route exact path="/limpeza/:idCleaner" element={<CleanerFull userRef={userRef} />} />
          <Route exact path="/limpeza/:idCleaner/orcamento" element={<EstimateCleaner />} />
          <Route exact path="/relatorio/:idReport/pdf" element={<ViewReport userRef={userRef} />} />
          <Route exact path="/credito/:idSheet/:index" element={<Sheet userRef={userRef} />} />
          <Route exact path="/visita/:idVisit/orcamento" element={<PrintVisit />} />
          <Route exact path="/visita/:idVisit/relatorio" element={<PrintVisit />} />
          <Route exact path="/confirmar-visita/:idVisit" element={<ConfirmVisit />} />
          {/* <Route exact path="/orcamento/:combinedParam" element={<Negotiation />} /> */}
            <Route exact path="/orcamento/:combinedParam" element={<AcceptProvider>
              <Estimate />
            </AcceptProvider>} />
          {/* <Route exact path="/sistema/:combinedParam" element={<System />} />
          <Route exact path="/sistema/:combinedParam/obrigado" element={<Thanks />} /> */}
          <Route exact path="/pagamento/:combinedParam" element={<Payments />} />

          <Route exact path="/materiais/:combinedParam" element={<Materials />} />
          <Route exact path="/visita-tecnica/:combinedParam" element={<VisitTec />} />
          <Route exact path="/projetos/:combinedParam" element={<Projects />} />
          <Route exact path="/saiba-mais" element={<Testimony />} />
          <Route exact path="/nossa-historia" element={<History />} />
          <Route exact path="/login" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
