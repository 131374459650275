import React, { memo, useEffect, useState } from 'react'
import { Box, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, InputAdornment, TextField, ThemeProvider } from '@mui/material'
import styles from '../styles.module.scss';

import { theme } from '../../../data/theme';
import CancelIcon from '@mui/icons-material/Cancel';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
// import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
// import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
// import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { FaBarcode } from "react-icons/fa";

import Swal from 'sweetalert2';
import { dataBase } from '../../../firebase/database';
import { doc, updateDoc } from 'firebase/firestore';
import moment from 'moment';
import axios from 'axios';
import { PatternFormat } from 'react-number-format';
import ButtonSection from './ButtonSection';
import ButtonCB from '../../../components/Button';
import Simulation from './Simulation';

// const questions = ['Planejo instalar no próximo mês', 'Planejo instalar em 3 meses', 'Planejo instalar em 6 meses', 'Planejo instalar em 12 meses', 'Não tenho mais intenção de instalar']

const Visit = ({ open, close, customer, index, assistant, seller, type, system, setRefresh, sendPayment }) => {
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState({
    schedule: false,
    generation: false,
    response: false
  });
  const [feedback, setFeedback] = useState(false);
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');
  const [generation, setGeneration] = useState('');
  const [question, setQuestion] = useState('');
  const [info, setInfo] = useState({
    bairro: '',
    cidade: '',
    numero: '',
    endereco: '',
    horario: '',
    data: ''
  });

  // console.log(sendPayment);

  useEffect(() => {
    if(sendPayment || type === 'Gostou') {
      setView({ ...view, schedule: true });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sendPayment, type, open])

  useEffect(() => {
    if (system && open) {
      const hasFeedback = "feedback" in system;
      const hasVisita = "visita" in system;
      const hasGeracaoFeedback = system?.feedback?.geracao !== undefined;

      const response = hasVisita
        ? 'Visita'
        : hasGeracaoFeedback
          ? 'Geração'
          : 'Resposta';

      setFeedback({
        view: hasFeedback,
        response: response
      });
    }
  }, [open, system]);

  useEffect(() => {
    // Get today's date in YYYY-MM-DD format
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0];

    // Calculate the date 5 days in the future
    const futureDate = new Date();
    futureDate.setDate(today.getDate() + 5);
    const formattedFutureDate = futureDate.toISOString().split('T')[0];

    // Set the min and max dates
    setMinDate(formattedToday);
    setMaxDate(formattedFutureDate);
  }, []);

  const onClose = () => {
    close();
    setTimeout(() => {
      setView({
        schedule: false,
        generation: false,
        response: false
      });
      setGeneration('');
      setQuestion('');
      setInfo({
        bairro: '',
        cidade: '',
        numero: '',
        endereco: '',
        horario: '',
        data: ''
      });
    }, 500);
  }

  const onSubmit = async (e) => {
    try {
      e.preventDefault();

      // console.log({ // Tudo Certo
      //   nome: customer.nome,
      //   cidade: customer.cidade,
      //   kit: system.kit,
      //   vendedor: seller.nome,
      //   assistente: assistant.nome,
      //   telefone: customer.telefone,
      //   telefone_assistente: assistant.telefone,
      //   feedback: type === 'Gostou' ? '✅ Gostou' : '❌ Não Gostou',
      //   id: customer.id,
      //   idFull: `${customer.id}-${index}`,
      //   // info: infoResponse,
      //   pagamento: sendPayment
      // })

      const result = await Swal.fire({
        title: 'Atenção',
        html: `Os dados estão corretos?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#00B707',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      });

      if (result.isConfirmed) {
        setLoading(true);

        let infoResponse;
        let response = '';
        if (type === 'Gostou') {
          if (view.schedule) {

            infoResponse = {
              escolheu: 'Solicitação de Visita Técnica',
              ...info,
              data: moment(info.data).format('DD/MM/YYYY')
            };
            response = 'Visita';
            await requestVisit();
          } else {
            infoResponse = {
              escolheu: 'Solicitação de Outra Geração',
              texto: `${generation} kWp`
            };
            response = 'Geração';
            console.log(generation)
            await requestGeneration();
          }
        } else {
          if (view.generation) {
            infoResponse = {
              escolheu: 'Solicitação de Outra Geração',
              texto: `${generation} kWp`
            };
            console.log(generation)
            response = 'Geração';
            await requestGeneration();
          } else {
            infoResponse = {
              escolheu: 'Recusou o Orçamento',
              texto: question
            };
            response = 'Resposta';
            await requestQuestion();
          }
        }

        await axios.post('https://n8n.corpbrasil.cloud/webhook/4c278e91-e925-4846-b85c-7181a92936eb', {
          nome: customer.nome,
          cidade: customer.cidade,
          kit: system.kit,
          vendedor: seller.nome,
          assistente: assistant.nome,
          telefone: customer.telefone,
          telefone_assistente: assistant.telefone,
          feedback: type === 'Gostou' ? '✅ Gostou' : '❌ Não Gostou',
          id: customer.id,
          idFull: `${customer.id}-${index}`,
          info: infoResponse,
          pagamento: sendPayment
        })

        setLoading(false);

        await Swal.fire({
          title: 'Obrigado!',
          html: `Os dados foram enviados com sucesso.`,
          icon: "success",
          showConfirmButton: true,
          showCloseButton: true,
          confirmButtonColor: "#111",
        })
        setFeedback({
          view: true,
          response: response,
        });
        setRefresh(true);
      }
    } catch (error) {
      setLoading(false);
      console.log('Erro: ', error);
    }
  }

  const requestVisit = async () => {
    try {
      const visit = {
        ...info,
        createAt: new Date(),
      };
      let newSheets = customer.planilha;
      const currentSheet = customer.planilha[index];
      newSheets.splice(index, 1, {
        ...currentSheet,
        visita: visit,
        pagamento_ref: sendPayment || null,
        feedback: {
          kit: type
        },
        historico: [
          ...currentSheet.historico,
          {
            data: moment().format('DD/MM/YYYY HH:mm'),
            text: `Visita: O cliente solicitou uma Visita Técnica.`,
          }
        ]
      })

      await updateDoc(doc(dataBase, 'Clientes', customer.id), {
        planilha: newSheets,
        visita: visit,
        historico: [...customer.historico, {
          data: moment().format('DD/MM/YYYY - HH:mm'),
          evento: 'Orçamento',
          text: `O Cliente ✅ solicitou uma Visita Técnica.`,
          usuario_id: '0',
          usuario_nome: 'robo'
        }]
      })

    } catch (error) {
      console.log(error)
    }
  }

  const requestGeneration = async () => {
    try {
      const generationRef = {
        geracao: generation,
        kit: type,
        createAt: new Date(),
      };
      let newSheets = customer.planilha;
      const currentSheet = customer.planilha[index];
      newSheets.splice(index, 1, {
        ...currentSheet,
        feedback: generationRef,
        pagamento_ref: sendPayment || null,
        historico: [
          ...currentSheet.historico,
          {
            data: moment().format('DD/MM/YYYY HH:mm'),
            text: `Orçamento: O cliente <b>${type}</b> e solicitou uma nova geração de <b>${generation}</b> kWp através do orçamento <a href=https://corpbrasil.app/credito/${customer.id}/${index} rel="noreferrer" target="_blank">${system.kit} kWp</a>.`,
          }
        ]
      })

      await updateDoc(doc(dataBase, 'Clientes', customer.id), {
        planilha: newSheets,
        historico: [...customer.historico, {
          data: moment().format('DD/MM/YYYY - HH:mm'),
          evento: 'Orçamento',
          text: `O cliente <b>${type}</b> e solicitou uma nova geração de <b>${generation}</b> kWp através do orçamento <a href=https://corpbrasil.app/credito/${customer.id}/${index} rel="noreferrer" target="_blank">${system.kit} kWp</a>.`,
          usuario_id: '0',
          usuario_nome: 'robo'
        }]
      })

    } catch (error) {
      console.log(error)
    }
  }

  const requestQuestion = async () => {
    try {
      const generationRef = {
        resposta: question,
        kit: type,
        createAt: new Date(),
      };
      let newSheets = customer.planilha;
      const currentSheet = customer.planilha[index];
      newSheets.splice(index, 1, {
        ...currentSheet,
        feedback: generationRef,
        historico: [
          ...currentSheet.historico,
          {
            data: moment().format('DD/MM/YYYY HH:mm'),
            text: `Orçamento: O cliente <b>${type}</b> e selecionou: ${question}.`,
          }
        ]
      })

      await updateDoc(doc(dataBase, 'Clientes', customer.id), {
        planilha: newSheets,
        historico: [...customer.historico, {
          data: moment().format('DD/MM/YYYY - HH:mm'),
          evento: 'Orçamento',
          text: `O Cliente <b>${type}</b> e selecionou: ${question}.`,
          usuario_id: '0',
          usuario_nome: 'robo'
        }]
      })

    } catch (error) {
      console.log(error)
    }
  }

  const handleInfo = (e) => {
    const { name, value } = e.target;
    if(name === 'data') {
      setInfo({ ...info, [name]: handleDateChange(value) });
    } else {
      setInfo({ ...info, [name]: value });

    }
  }

  const handleDateChange = (value) => {
    const date = new Date(value);
    const day = date.getUTCDay();

    // Check if the selected day is Sunday (0)
    if (day === 0) {
      // alert("Domingos não são permitidos. Por favor, escolha outro dia.");
      Swal.fire({
        title: 'Atenção',
        html: `Domingos não são permitidos. Por favor, escolha outro dia.`,
        icon: "warning",
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: "#111",
      })
      return ''; // Clear the selected date
    } else {
      return value;
    }
  };

  const renderFeedbackMessage = (feedback, system) => {
    if (feedback.response === 'Visita') {
      return (
        <>
          <h3>Suas informações foram enviadas com sucesso!</h3>
          <p>Em breve, um de nossos analistas entrará em contato para confirmar todos os detalhes com você.</p>
        </>
      );
    }

    if (feedback.response === 'Geração') {
      return system?.feedback.kit === 'Gostou' ? (
        <>
          <h3>Sua nova geração foi enviada com sucesso!</h3>
          <p>Em breve, seu orçamento atualizado estará pronto com a geração sugerida por você.</p>
        </>
      ) : (
        <>
          <h3>Suas informações foram enviadas com sucesso!</h3>
          <p>Em breve, um de nossos analistas entrará em contato para confirmar todos os detalhes com você.</p>
        </>
      );
    }

    return <h3>Suas informações foram enviadas com sucesso!</h3>;
  }

  return (
    <Dialog
      className={styles.dialog}
      open={open}
      fullWidth
      // fullScreen={fullScreen}
      maxWidth="sm"
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CancelIcon sx={{ fill: '#fff', fontSize: '30px' }} />
      </IconButton>
      <DialogTitle
        align='center'
        sx={{ backgroundColor: type === 'Gostou' ? '#209CFF' : '#FFAB00', color: "#fff", display: 'flex', alignItems: 'center', gap: '0.7rem' }}>
        {type === 'Gostou' ? 
        <p>Próxima Etapa</p> 
        : 
        'Ajustar Orçamento'}
      </DialogTitle>
      {/* {paymentData ? 
        <Alert sx={{ margin: '0 1.5rem 1rem 1.5rem' }}>
          <AlertTitle>Pagamento escolhido:</AlertTitle>
          <p><b>Tipo:</b> {paymentData?.tipo}</p>
          <p><b>Método:</b> {paymentData?.info}</p>
          <p><b>Valor total:</b> {formatCurrency(paymentData?.valor_total)}</p>
        </Alert> : null
      } */}
      {feedback.view ?
        <Box sx={{ padding: '1rem 1rem 2rem 1rem', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem', color: "#808080", 'h3': { fontSize: '20px' } }}>
          <VerifiedRoundedIcon sx={{ fill: "#0FA958", fontSize: '100px' }} />
          {renderFeedbackMessage(feedback, system)}
        </Box> :
        <Box padding={'1rem'}>
          <ThemeProvider theme={theme}>
            {type === 'Gostou' ?
              <>
                <Box sx={{ marginBottom: '1rem' }}>
                  <ButtonSection icon={<CalendarMonthIcon />} text="Agendar Visita Técnica" open={view.schedule} onClick={() => setView({ ...view, schedule: !view.schedule, generation: false })}>
                  </ButtonSection>
                  <Collapse sx={{ width: '100%', backgroundColor: "#F2F2F2", borderRadius: '0 0 10px 10px' }} in={view.schedule} timeout="auto" unmountOnExit>
                    <form onSubmit={onSubmit}>
                      <ThemeProvider theme={theme}>
                        <DialogContent className={styles.dialog_content} sx={{ paddingTop: 0 }}>
                          <DialogContentText align='center' paddingTop={'0.8rem'}>Preencha os campos abaixo para agendar sua <b>Visita Técnica</b>.</DialogContentText>                      
                          <Grid container columnSpacing={1} rowSpacing={1.5} sx={{ marginTop: '0.5rem' }}>
                            <Grid item xs={12} sm={10}>
                              <TextField
                                fullWidth
                                name='endereco'
                                label="Endereço"
                                
                                sx={{ backgroundColor: "#fff" }}
                                value={info.endereco}
                                onChange={handleInfo}
                                required
                              />
                            </Grid>
                            <Grid item xs={4} sm={2}>
                              <TextField
                                fullWidth
                                name='numero'
                                label="Número"
                                type='number'
                                sx={{ backgroundColor: "#fff" }}
                                
                                value={info.numero}
                                required
                                onChange={handleInfo}
                              />
                            </Grid>
                            <Grid item xs={8} sm={4}>
                              <TextField
                                fullWidth
                                name='bairro'
                                label="Bairro"
                                value={info.bairro}
                                sx={{ backgroundColor: "#fff" }}
                                required
                                onChange={handleInfo}
                              />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                              <TextField
                                fullWidth
                                name='cidade'
                                label="Cidade"
                                value={info.cidade}
                                sx={{ backgroundColor: "#fff" }}
                                required
                                onChange={handleInfo}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <PatternFormat
                                value={info.cep}
                                onChange={handleInfo}
                                format="##.###-###"
                                mask="_"
                                name="cep"
                                fullWidth
                                required
                                customInput={TextField}
                                sx={{ backgroundColor: "#fff" }}
                                placeholder="00.000-000"
                                label="CEP"
                                minLength={10}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                name='data'
                                label="Data"
                                type='date'
                                sx={{ backgroundColor: "#fff" }}
                                value={info.data}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  min: minDate,
                                  max: maxDate,
                                }}
                                required
                                onChange={handleInfo}
                              />
                            </Grid>
                            {/* <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                name='horario'
                                label="Horário"
                                type='time'
                                sx={{ backgroundColor: "#fff" }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={info.horario}
                                required
                                onChange={handleInfo}
                              />
                            </Grid> */}
                          </Grid>
                        </DialogContent>
                        <DialogActions sx={{ padding: '0 20px 2rem 20px', display: 'flex', justifyContent: 'center' }}>
                          <ButtonCB
                            fullWidth
                            size='large'
                            variant='contained'
                            loading={loading}
                            background="#209CFF"
                            disableElevation
                            type='submit'
                            sx={{ textTransform: 'capitalize', padding: '0.7rem 0', fontWeight: 'bold', borderRadius: '5px', fontSize: '17px' }}>
                              {!loading && 'Enviar'}</ButtonCB>
                        </DialogActions>
                      </ThemeProvider>
                    </form>
                  </Collapse>
                </Box>
                {/* {!sendPayment ? 
                  <Box>
                    <ButtonSection icon={<OfflineBoltOutlinedIcon />} text="Quero outra geração" open={view.generation} onClick={() => setView({ ...view, generation: !view.generation, schedule: false })}>
                    </ButtonSection>
                    <Collapse sx={{ width: '100%', backgroundColor: "#F2F2F2", borderRadius: '0 0 10px 10px' }} in={view.generation} timeout="auto" unmountOnExit>
                      <form onSubmit={onSubmit}>
                        <DialogContent className={styles.dialog_content} sx={{ paddingTop: 0 }}>
                          <Grid container columnSpacing={1} rowSpacing={1.5} sx={{ marginTop: '0.5rem' }}>
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                name='geracao'
                                type='Number'
                                label="Nova Geração"
                                sx={{ backgroundColor: "#fff" }}
                                value={generation}
                                onChange={(e) => setGeneration(e.target.value)}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end"><div className={styles.line}></div> kWh</InputAdornment>,
                                }}
                                required
                              />
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions sx={{ padding: '0 20px 2rem 20px', display: 'flex', justifyContent: 'center' }}>
                          <ButtonCB
                            fullWidth
                            size='large'
                            variant='contained'
                            loading={loading}
                            background="#209CFF"
                            type='submit'
                            disableElevation
                            sx={{ textTransform: 'capitalize', padding: '0.7rem 0', fontWeight: 'bold', borderRadius: '5px', fontSize: '17px' }}>
                              {!loading && 'Enviar'}
                            </ButtonCB>
                        </DialogActions>
                      </form>
                    </Collapse>
                  </Box> : null
                } */}
                
              </> :
              <Box>
                <Box marginBottom={'1rem'}>
                  <ButtonSection icon={<OfflineBoltOutlinedIcon />} text="Quero outra geração" open={view.generation} onClick={() => setView({ ...view, generation: !view.generation, response: false })}>
                  </ButtonSection>
                  <Collapse sx={{ width: '100%', backgroundColor: "#F2F2F2", borderRadius: '0 0 10px 10px' }} in={view.generation} timeout="auto" unmountOnExit>
                    <form onSubmit={onSubmit}>
                      <DialogContent className={styles.dialog_content} sx={{ paddingTop: 0 }}>
                        <Grid container columnSpacing={1} rowSpacing={1.5} sx={{ marginTop: '0.5rem' }}>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              name='geracao'
                              type='Number'
                              sx={{ backgroundColor: "#fff" }}
                              label="Nova Geração"
                              value={generation}
                              onChange={(e) => setGeneration(e.target.value)}
                              InputProps={{
                                endAdornment: <InputAdornment position="end"><div className={styles.line}></div> kWh</InputAdornment>,
                              }}
                              required
                            />
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions sx={{ padding: '0 20px 2rem 20px', display: 'flex', justifyContent: 'center' }}>
                        <ButtonCB
                          fullWidth
                          size='large'
                          variant='contained'
                          background="#209CFF"
                          disableElevation
                          loading={loading}
                          type='submit'
                          sx={{ textTransform: 'capitalize', padding: '0.7rem 0', fontWeight: 'bold', borderRadius: '5px', fontSize: '17px' }}>
                            {!loading && 'Enviar'}
                          </ButtonCB>
                      </DialogActions>
                    </form>
                  </Collapse>
                </Box>
                <ButtonSection icon={<FaBarcode />} text="Parcelamento bancário" open={view.response} onClick={() => setView({ ...view, response: !view.response, generation: false })}>
                </ButtonSection>
                <Collapse sx={{ width: '100%', backgroundColor: "#F2F2F2", borderRadius: '0 0 10px 10px' }} in={view.response} timeout="auto" unmountOnExit>
                <Simulation ref={null} customer={customer} system={system} assistant={assistant} setRefresh={setRefresh} />
                  {/* <form onSubmit={onSubmit}>
                    <DialogContent className={styles.dialog_content} sx={{ padding: '0.7rem 0 1.5rem 0' }}>
                      <FormGroup>
                        {questions.map((data, index) => (
                          <FormControlLabel
                            sx={{ margin: 0 }}
                            key={index}
                            control={
                              <Checkbox
                                checked={question === data}
                                color='success'
                                label='123'
                                onChange={() => setQuestion(data)}
                                name={'1'}
                                icon={<RadioButtonUncheckedIcon sx={{ backgroundColor: "#fff", borderRadius: '50%' }} />}
                                checkedIcon={<RadioButtonCheckedIcon sx={{ fill: "#747474", backgroundColor: "#fff", borderRadius: '50%' }} />} />
                            }
                            label={
                              <Typography sx={{ fontWeight: 'bold', width: 'calc(100% - 65px)', backgroundColor: "#fff", color: "#4A4A4A", padding: '0.2rem 0.4rem', borderRadius: '5px' }}>
                                {data}
                              </Typography>
                            }
                          />
                        ))}
                      </FormGroup>
                    </DialogContent>
                    <DialogActions sx={{ padding: '0 20px 2rem 20px', display: 'flex', justifyContent: 'center' }}>
                      <ButtonCB
                        fullWidth
                        size='large'
                        variant='contained'
                        background="#209CFF"
                        loading={loading}
                        disableElevation
                        type='submit'
                        sx={{ textTransform: 'capitalize', padding: '0.7rem 0', fontWeight: 'bold', borderRadius: '5px', fontSize: '17px' }}>
                          {!loading && 'Enviar'}
                        </ButtonCB>
                    </DialogActions>
                  </form> */}
                </Collapse>
              </Box>}
          </ThemeProvider>
        </Box>
      }
    </Dialog>
  )
}

export default memo(Visit);