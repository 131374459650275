import { createContext, useCallback, useContext, useState } from 'react';
import { dataBase } from '../firebase/database';
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import removeNullProperties from '../functions/removeNullProperties';
import removePrefixPhone from '../functions/removePrefixPhone';
import { getNextBusinessDayIfAfter17 } from '../functions/getNextBusinessDayIfAfter17';

// Criando o contexto
const ActivityContext = createContext();

// Componente provedor que envolve toda a aplicação e fornece os dados do cadastro
export const ActivityProvider = ({ children }) => {
  const [activityData, setActivityData] = useState(null);
  const [newActivitys, setNewActivitys] = useState([]);

  const addNewActivity = useCallback((activity) => {
    setNewActivitys((prevActivities) => [...prevActivities, activity]);
  }, []);

  const deleteNewActivity = useCallback((index) => {
    setNewActivitys((prevActivities) => prevActivities.filter((_, i) => i !== index));
  }, [setNewActivitys]);

  const updateActivityAtIndex = useCallback((index, updatedActivity) => {
    setNewActivitys((prevActivities) =>
      prevActivities.map((activity, idx) => (idx === index ? updatedActivity : activity))
    );
  }, []);

  const addActivity = async ({
    cliente_id,
    responsavel_id,
    etapa,
    orcamento_id,
    titulo,
    telefone,
    etapa_anterior,
    orientacao
  }) => {
    try {
      const data = {
        etapa: etapa,
        telefone: removePrefixPhone(telefone),
        cliente_id: cliente_id,
        concluido: false,
        orcamento_id: orcamento_id,
        titulo: titulo,
        iniciado: false,
        iniciar_em: getNextBusinessDayIfAfter17().prevBusinessDay,
        orientacao: {
          etapa: etapa_anterior,
          texto: orientacao
        },
        createAt: new Date(),
        prazo: getNextBusinessDayIfAfter17().nextBusinessDay,
        responsavel: responsavel_id,
      };
      const newData = removeNullProperties(data);
      console.log('RESULTADO', newData, data);
      const response = await addDoc(collection(dataBase, 'Atividades'), newData);
      setActivityData((prev) => [...(prev || []), { id: response.id, ...newData }]);
    } catch (error) {
      console.log('ERRO:', error);
    }
  };

  const updateActivity = async (id, activityData) => {
    try {

      const newData = removeNullProperties(Object.fromEntries(
        Object.entries(activityData).filter(([_, value]) => value !== undefined && value !== null)
      ));

      await updateDoc(doc(dataBase, 'Atividades', id), newData);
      console.log('Activity atualizada com sucesso', id);
      setActivityData((prev) => ({ ...prev, ...newData }));      
    } catch (error) {
      console.log('ERRO:', error);
    }
  };

  return (
    <ActivityContext.Provider value={{ activityData, setActivityData, addActivity, updateActivity, setNewActivitys, newActivitys, addNewActivity, updateActivityAtIndex, deleteNewActivity }}>
      {children}
    </ActivityContext.Provider>
  );
};

// Hook personalizado para acessar o contexto
export const useActivity = () => {
  const context = useContext(ActivityContext);
  if (!context) {
    throw new Error('useActivity deve ser usado dentro de um ActivityProvider');
  }
  return context;
};
