import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

import { FaFileUpload } from "react-icons/fa";
import { Link } from "react-router-dom";

import CurrencyInput from "react-currency-input-field";
import NewTooltip from "../../../components/Tooltip";
import useViewDocBox from "../../../hooks/useViewDocBox";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DeleteRounded from "@mui/icons-material/DeleteRounded";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import { MdOutlineSendToMobile } from "react-icons/md";
import { BiSolidOffer } from "react-icons/bi";
import { GiTakeMyMoney } from "react-icons/gi";
import { TiDocumentText } from "react-icons/ti";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { IoDocumentSharp } from "react-icons/io5";
import { GrMoney } from "react-icons/gr";
import { FaMoneyCheckDollar, FaRegAddressCard } from "react-icons/fa6";
import { MdAccountBalanceWallet } from "react-icons/md";
import { GiPayMoney } from "react-icons/gi";
import { GiReceiveMoney } from "react-icons/gi";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { BsBuildingAdd } from "react-icons/bs";
import { BsBank2 } from "react-icons/bs";
import { IoIosClose } from "react-icons/io";
import { GiCrownCoin } from "react-icons/gi";

import { theme } from "../../../data/theme";
import { VisuallyHiddenInput } from "../../../components/InputFile";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { dataBase } from "../../../firebase/database";
import { toast } from "../../../components/Toast";
import Swal from "sweetalert2";
import useUploadPhotos from "../../../hooks/useUploadPhotos";
import moment from "moment";
import Loading from "../../../components/Loading";
import { capitalizeString } from "../../../functions/capitalizeString";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AudioRecorder from "../../../components/AudioRecorder/Index";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { generateId } from "../../../functions/generateId";
import CustomAudioPlayerFixed from "../../../components/AudioPlayerFixed/Index";
import axios from "axios";
import removeNullProperties from "../../../functions/removeNullProperties";
import ButtonCB from "../../../components/Button";
import formatCurrency from "../../../functions/formatCurrency";
import LockContent from "../../../components/LockContent";
import { banksList } from "../../System/data/Banks";
import { deleteFilesOnFirebase } from "../../../functions/deleteFilesOnFirebase";

const optionsSimulations = [
  { nome: "Banco Do Brasil", disabled: false },
  { nome: "Banco CorSolar", disabled: false },
  { nome: "Banco BV", disabled: false },
  { nome: "BTG Pactual", disabled: false },
  { nome: "Caixa Econômica Federal", disabled: false },
  // { nome: "Cartão de Crédito", disabled: false },
  { nome: "EOS", disabled: false },
  { nome: "Losango", disabled: false },
  { nome: "Santander", disabled: false },
  { nome: "Sicredi", disabled: false },
  { nome: "Sicoob", disabled: false },
  { nome: "Sol Agora", disabled: false },
  { nome: "Sol Facil", disabled: false },
  { nome: "SolPlace", disabled: false },
];
const optionsSituation = [
  { nome: "Aprovado" },
  { nome: "Negado" },
  { nome: "Aguardando" },
];

const SendDoc = ({
  userRef,
  customer,
  view,
  close,
  indexProposal,
  type,
  simulationRef,
  setSimulationRef,
  sheetRef,
}) => {
  const { uploadImages } = useUploadPhotos();
  const [nameDocument, setNameDocument] = useState(null);
  const [name, setName] = useState('');
  const [selectedBanks, setSelectedBanks] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [points, setPoints] = useState(null);
  const [activePoints, setActivePoints] = useState(false);
  const [includeImprovements, setIncludeImprovements] = useState(false);
  const [values, setValues] = useState({
    entrada: '0',
    valor_financiamento: '0'
  });
  const [optionsSimulation, setOptionsSimulation] = useState(optionsSimulations);
  const notMobile = useMediaQuery('(min-width:600px)');
  const [cpfCNPJ, setCpfCNPJ] = useState('');
  const [situation, setSituation] = useState("");
  const [audio, setAudio] = useState(null);
  const [banks, setBanks] = useState([]);
  const [conditionSpecial, setConditionSpecial] = useState({
    visualizar: false,
    banco: "Santander",
    carencia: "",
  });
  const [fgts, setFgts] = useState({
    visualizar: false,
    banco: "FGTS",
    resgate_saldo: undefined,
    resgate_valor: undefined
  });
  const [fgtsResult, setFgtsResult] = useState(0);
  const [document, setDocument] = useState([]);
  const [documentCondition, setDocumentCondition] = useState([]);
  const [documentFgts, setDocumentFgts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [simulation, setSimulation] = useState(null);
  const [activeFinancing, setActiveFinancing] = useState(false);
  const [carencia, setCarencia] = useState("");
  const inputRef = useRef(null);
  const [installments, setInstallments] = useState([
    {
      quantidade: "",
      valor: "",
    },
  ]);
  const [installmentsCondition, setInstallmentsCondition] = useState([
    {
      quantidade: "",
      valor: "",
    },
  ]);

  const { viewDoc } = useViewDocBox();
  const DocsItems = styled(Box)(({ color }) => ({
    padding: "0.5rem 1rem",
    borderRadius: "5px",
    backgroundColor: color || "#fff",
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid rgba(0, 0, 0, 0.12)",
  }));

  const closeBox = () => {
    close();
    setTimeout(() => {
      setNameDocument("");
      setDocument([]);
      setDocumentCondition([]);
      setDocumentFgts([]);
      setSimulationRef(null);
      setActiveFinancing(false);
      setAudio(null);
      setSimulation(null);
      setName("");
      setCpfCNPJ("");
      setBanks([]);
      setSituation("");
      setPoints(null);
      setActivePoints(false);
      setInstallments([
        {
          quantidade: "",
          valor: "",
        },
      ]);
      setInstallmentsCondition([
        {
          quantidade: "",
          valor: "",
        },
      ]);
      setFgts({
        visualizar: false,
        banco: "FGTS",
        resgate_saldo: undefined,
        resgate_valor: undefined
      })
    }, 500);
  };

  useEffect(() => {
    const loadSimulationData = async () => {
      if (simulationRef) {
        // Caso o cliente já tenha uma simulação vinculada ao documento
        const simulationsRef = customer.proposta[indexProposal].simulacoes;
        if (simulationsRef) {
          const newSimulationRef = simulationsRef.find(
            (data) => data.id === simulationRef.simulation_id
          );
          setSimulation(newSimulationRef);
          setNameDocument(newSimulationRef.banco);
          setName(newSimulationRef.nome);
          setCpfCNPJ(newSimulationRef.cpfCNPJ);
          setSituation(newSimulationRef.situacao);
          if (newSimulationRef.situacao !== 'Aguardando') {
            setActiveFinancing(newSimulationRef?.visualizar ?? true);
          }
          setDocument(newSimulationRef.documentos);
          setCarencia(newSimulationRef.carencia);
          setInstallments(newSimulationRef.parcelas);
          setConditionSpecial(newSimulationRef.condicao_especial || {
            visualizar: false,
            banco: "Santander",
            carencia: ""
          });
          setAudio(newSimulationRef.mensagemVoz);
          setDocumentCondition(newSimulationRef.condicao_especial?.documentos || []);
          setInstallmentsCondition(newSimulationRef.condicao_especial?.parcelas || [
            {
              quantidade: "",
              valor: "",
            },
          ]);
          if (newSimulationRef.bancos && newSimulationRef.bancos.length > 0) {
            newSimulationRef.bancos.forEach((data) => {
              changeBankSelected(null, data.banco);
            });
          }
          changeBankSelected(null, newSimulationRef.banco);
          setBanks(newSimulationRef.bancos || []);
          setFgts(newSimulationRef.fgts || {
            visualizar: false,
            banco: "FGTS",
            resgate_saldo: undefined,
            resgate_valor: undefined
          })
          setActivePoints(Boolean(newSimulationRef.pontos));
          setPoints(newSimulationRef.pontos);
          setValues({
            entrada: newSimulationRef.entrada || '0',
            valor_financiamento: newSimulationRef.valor_financiamento || '0'
          })
          setSelectedValue(checkValues(parseFloat(newSimulationRef?.valor_financiamento)))
        }
      }
    }

    loadSimulationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulationRef, indexProposal]);

  const checkValues = (value) => {

    const melhorias = parseFloat(sheetRef?.visita_tecnica?.rede_eletrica?.melhorias?.valor_total);
    const value1 = parseFloat(sheetRef?.orcamento?.valor);
    const value2 = parseFloat(sheetRef?.orcamento?.valor_parcelado);

    console.log('CHECKVALUES', value, value1, value2)

    let refSelected = null;
    let refImprovements = false;

    if (value === value1) {
      refSelected = 'vista'
      if (value === value1 + melhorias) {
        refImprovements = true;
      }
    } else if (value === value2) {
      refSelected = 'parcelado'
      if (value === value2 + melhorias) {
        refImprovements = true;
      }
    } else {
      refSelected = ''
    }
    setIncludeImprovements(refImprovements);
    return refSelected;
  }

  // console.log(customer?.proposta[indexProposal]?.simulacoes)
  console.log(simulation)

  const addBank = () => {
    const newBank = {
      banco: '',
      carencia: '',
      entrada: '0',
      creatAt: new Date(),
      id: generateId('Banco'),
      parcelas: [
        {
          quantidade: "",
          valor: "",
        }
      ],
      documentos: []
    }

    setBanks([...banks, newBank]);
  }

  const handleUpload = async () => {
    const storage = getStorage();
    if (!audio) {
      return;
    }

    try {
      // Upload do áudio
      let filesRef = {};

      if (audio) {
        const audioRef = ref(
          storage,
          `Clientes/${customer.id}/Mensagem-${Date.now()}.webm`
        );
        const audioSnapshot = await uploadBytes(audioRef, audio.complete);
        const audioURL = await getDownloadURL(audioSnapshot.ref);
        console.log("Áudio enviado com sucesso! URL:", audioURL);
        filesRef = {
          url: audioURL,
          ref: audioSnapshot.ref.fullPath,
        };
      }

      return filesRef;
    } catch (error) {
      console.error("Erro ao enviar arquivos:", error);
    }
  };

  const saveDoc = async () => {
    try {

      const result = await Swal.fire({
        title: "Atenção",
        text: `Você deseja salvar o Documento?`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#05a937",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });

      if (result.isConfirmed) {
        setLoading(true);

        // Função para realizar upload de documentos
        const proposalData = await handleSimulation(type);
        console.log(proposalData);

        await updateDoc(doc(dataBase, "Clientes", customer.id), proposalData);

        // Exiba uma mensagem de sucesso
        await Swal.fire({
          title: "Documentos",
          html: `O Documento foi salvo com sucesso.`,
          icon: "success",
          showConfirmButton: true,
          showCloseButton: true,
          confirmButtonColor: "#111",
        });
        closeBox();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast({
        icon: "error",
        text: `Erro ao salvar o documento. Erro: ${error}`,
      });
    }
  };

  const handleSimulation = async (type) => {
    const resultDocuments = await uploadDocuments(document, nameDocument);
    const resultCondition = conditionSpecial.visualizar
      ? await uploadDocuments(documentCondition, conditionSpecial.banco)
      : null;
    const resultFgts = fgts.visualizar
      ? await uploadDocuments(documentFgts, "FGTS")
      : null;

    // Verifica e Exclui arquivos já enviados que foram removidos pelo usuario
    const documentsMainOnFirebase = simulation?.documentos || [];
    const documentsSecondaryOnFirebase = simulation?.bancos?.length > 0 ? simulation?.bancos.map((data) => data?.documentos) : [];
    const allDocumentsOnFirebase = [documentsMainOnFirebase, ...documentsSecondaryOnFirebase];

    const documentsMain = document || [];
    const documentsSecondary = banks?.length > 0 ? banks.map((data) => data?.documentos) : [];
    const allDocuments = [documentsMain, ...documentsSecondary];

    console.log(allDocumentsOnFirebase.flat(), allDocuments.flat())

    await deleteFilesOnFirebase(allDocumentsOnFirebase.flat(), allDocuments.flat());


    const file = resultDocuments ? resultDocuments : [];
    const fileCondition = resultCondition ? resultCondition : [];
    const fileFgts = resultFgts ? resultFgts : [];
    const proposals = customer.proposta;
    const fatura = proposals[indexProposal].documentos;
    const proposalRef = proposals[indexProposal];

    // Inicializa o array de documentos se necessário
    if (!fatura[type]) {
      fatura[type] = [];
    }

    if (type === 'enviar simulação') {
      proposalRef.simulacao_enviada = true;
    }

    if (!proposalRef.id) {
      proposalRef.id = generateId(proposalRef.nome);
    }

    const payments = customer.pagamentos ? [...customer.pagamentos] : [];
    const simulations = proposalRef.simulacoes ? [...proposalRef.simulacoes] : [];
    const simulationId = simulation?.id ? simulation.id : generateId(nameDocument);

    const generateSimulationData = async () => ({
      ...simulation,
      nome: name,
      createAt: new Date(),
      cpfCNPJ: cpfCNPJ,
      banco: nameDocument,
      situacao: situation,
      id: simulationId,
      pontos: points,
      visualizar: activeFinancing,
      status: type === 'enviar simulação' ? 'Enviado' : simulation?.status || null,
      entrada: activeFinancing && situation === "Aprovado" ? values.entrada : null,
      valor_financiamento: activeFinancing && situation === "Aprovado" ? values.valor_financiamento : null,
      carencia: activeFinancing && situation === "Aprovado" ? carencia : null,
      parcelas: activeFinancing && situation === "Aprovado" ? installments : null,
      documentos: file,
      mensagemVoz: audio?.url ? audio : await handleUpload(),
      bancos: banks?.length > 0 ? await uploadAllDocuments(banks, customer) : null,
      condicao_especial: simulation?.condicao_especial || conditionSpecial.visualizar
        ? { ...conditionSpecial, documentos: fileCondition, parcelas: installmentsCondition }
        : null,
      fgts: (simulation?.fgts || fgts.visualizar) ? { ...fgts, documentos: fileFgts } : null,
      responsavel: simulation?.responsavel || { id: userRef?.id, nome: userRef?.nome }
    });

    const cleanSimulationData = removeNullProperties(await generateSimulationData());
    const simulationIndex = simulations.findIndex(sim => sim.id === simulation?.id);

    let historyRef = []

    if (simulationIndex > -1) {
      // Se já existe, atualiza a simulação existente
      historyRef = type === 'enviar simulação' && simulation?.status !== 'Enviado' ? [
        {
          data: moment().format("DD/MM/YYYY - HH:mm"),
          evento: "Simulação Enviada",
          text: `A <b>simulação</b> do <b>${simulation.banco}</b> foi enviada para o cliente.`,
          usuario_id: userRef?.id,
          usuario_nome: userRef?.nome,
        }
      ] : [
        {
          data: moment().format("DD/MM/YYYY - HH:mm"),
          evento: "Orçamento Alterado",
          text: `O documento <b>${nameDocument}</b> foi ALTERADO na aba <b>${capitalizeString(type === 'enviar simulação' ? 'simulações' : type)}</b> do orçamento <b>${proposalRef.nome}</b>.`,
          usuario_id: userRef?.id,
          usuario_nome: userRef?.nome,
        }
      ]
      simulations[simulationIndex] = cleanSimulationData;

    } else {
      // Caso não exista, adiciona uma nova simulação ao array
      historyRef = [
        {
          data: moment().format("DD/MM/YYYY - HH:mm"),
          evento: "Orçamento Alterado",
          text: `O documento <b>${nameDocument}</b> foi ADICIONADO na aba <b>${capitalizeString(type === 'enviar simulação' ? 'simulações' : type)}</b> do orçamento <b>${proposalRef.nome}</b>.`,
          usuario_id: userRef?.id,
          usuario_nome: userRef?.nome,
        },
        {
          data: moment().format("DD/MM/YYYY - HH:mm"),
          evento: "Pagamento Alterado",
          text: `<b>[Adicionado]:</b> ${nameDocument} - ${situation}.`,
          usuario_id: userRef?.id,
          usuario_nome: userRef?.nome,
        },
      ]
      simulations.push(cleanSimulationData);
      fatura[type].push({ ...file[0], simulation_id: simulationId });
      payments.push({
        // Envia dados para Pagamentos
        metodo: nameDocument,
        situacao: situation,
        orcamento_id: proposalRef.id,
        simulacao_id: simulationId,
      });
    }
    proposalRef.simulacoes = simulations;

    const proposalData = {
      proposta: proposals,
      pagamentos: payments,
      historico: [
        ...customer.historico,
        ...historyRef
      ],
    };

    return proposalData;
  }

  const uploadDocuments = async (documents, folder) => {
    if (!documents || documents.length === 0) return [];

    // Separar os objetos que possuem 'file' e os que já possuem 'url'
    const filesToUpload = documents.filter((doc) => doc.file);
    const existingFiles = documents.filter((doc) => doc.url);

    if (filesToUpload.length === 0 || !filesToUpload) {
      return existingFiles;
    }

    const uploadedFiles = await uploadImages(filesToUpload, folder, 0, customer.id, "Clientes");

    const mappedUploadedFiles = uploadedFiles.map(mapDocument); // Mapeia arquivos enviados

    // Combinar os arquivos que já possuem 'url' com os que foram carregados
    return [...existingFiles, ...mappedUploadedFiles];
  };

  const uploadAllDocuments = async (banks, customer) => {

    // Percorre o array de bancos e faz upload dos documentos de cada um
    const results = await Promise.all(
      banks.map(async (bank) => {
        if (bank.documentos && bank.documentos.length > 0) {
          const result = await uploadDocuments(bank.documentos, bank.banco);
          return {
            ...bank,
            documentos: result,
          };
        }
        return {
          ...bank,
          documentos: [],
        };
      })
    );

    return results;
  };

  const mapDocument = (object) => ({
    url: object.downloadURL,
    ref: object.fullPath,
  });

  const sendSimulation = async () => {

    try {
      console.log(type)
      const result = await Swal.fire({
        title: "Atenção",
        html: `Você deseja enviar a <b>Simulação</b> ao Cliente?`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#05a937",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });

      if (result.isConfirmed) {
        setLoading(true);

        const { proposta: proposals, planilha: sheets } = customer;
        const proposalRef = proposals[indexProposal] || {};

        // Encontrar a planilha correspondente
        const sheetIndex = sheets.findIndex(
          (data) =>
            data.orcamento_solicitado === sheetRef.orcamento_solicitado ||
            data?.orcamento_id === proposalRef?.id
        );
        const sheet = sheets[sheetIndex];

        // Marcar a planilha como simulação enviada, caso encontrada
        if (sheet) {
          sheet.simulacao_enviada = true;
        }

        // console.log('PROPOSTAAAA', proposals);

        const proposalData = await handleSimulation(type);

        const getEstimateQueue = await getDoc(
          doc(dataBase, "Fila", `${customer.id}-${sheetIndex}`)
        );
        const estimateQueueRef = getEstimateQueue.exists()
          ? getEstimateQueue.data()
          : null;

        await updateDoc(doc(dataBase, "Clientes", customer.id), {
          ...proposalData,
          planilha: sheets
        });

        if (!estimateQueueRef) {
          await Swal.fire({
            title: "Simulação",
            html: `A Simulação foi enviada com sucesso ao cliente.`,
            icon: "success",
            showConfirmButton: true,
            showCloseButton: true,
            confirmButtonColor: "#111",
          });
        } else {
          await Swal.fire({
            title: "Simulação",
            html: `O Cliente contém um orçamento na fila e por esse motivo, a simulação irá no aviso do orçamento.`,
            icon: "success",
            showConfirmButton: true,
            showCloseButton: true,
            confirmButtonColor: "#111",
          });
        }

        await axios.post(
          "https://n8n.corpbrasil.cloud/webhook/a213344f-021f-4220-ae1d-f710cb332151",
          {
            nome: customer.nome,
            telefone: customer.telefone,
            id: `${customer.id}-${sheetIndex}`,
            chave: situation === "Aprovado" ? "Simulação" : "Simulação Negado",
            cidade: customer.cidade,
            analista: userRef?.nome,
            consultora: customer.consultora,
            id_agenda: customer.id,
            representante: customer?.assistente_nome || userRef?.nome,
            orcamento: sheet.orcamento,
            orcamento_status: estimateQueueRef ? "Em Fila" : "Enviado",
            status: situation === "Aprovado" ? "A" : "N",
          }
        );

        closeBox();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast({
        icon: "error",
        text: `Erro ao salvar o documento. Erro: ${error}`,
      });
    }
  };

  const sendFile = async (e, type, bankIndex = null) => {
    const ref = e.target.files;
    const files = [];

    Array.from(ref, (image) =>
      files.push({ file: URL.createObjectURL(image), complete: image })
    );

    if (type === 'Condição') {
      setDocumentCondition((prev) => (prev ? [...prev, ...files] : files));
    } else if (type === 'Fgts') {
      setDocumentFgts((prev) => (prev ? [...prev, ...files] : files));
    } else if (type === 'Banks' && bankIndex !== null) {
      setBanks((prevBanks) => {
        return prevBanks.map((bank, bIndex) => {
          if (bIndex === bankIndex) {
            return {
              ...bank,
              documentos: bank.documentos ? [...bank.documentos, ...files] : files, // Atualiza os documentos dentro do banco específico
            };
          }
          return bank; // Mantém os outros bancos inalterados
        });
      });
    } else {
      setDocument((prev) => (prev ? [...prev, ...files] : files));
    }
  };

  const deleteBank = async (bIndex) => {
    try {
      const result = await Swal.fire({
        title: "Atenção",
        text: `Você deseja excluir o Banco?`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#05a937",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });

      if (result.isConfirmed) {
        setBanks((prevBanks) => prevBanks.filter((_, indexRef) => indexRef !== bIndex));
        const previousBank = banks[bIndex].banco;
        changeBankSelected(previousBank);
      }
    } catch (error) {
      console.error("Erro ao excluir banco:", error);
      toast({
        icon: "error",
        text: "Ocorreu um erro ao tentar excluir o banco.",
      });
    }
  };

  const deleteFile = (index, file, type, bankIndex = null) => {
    if (index >= 0 && index < file.length) {
      const newPhotos = [...file];
      newPhotos.splice(index, 1); // Remove o arquivo do array

      if (type === 'Condição') {
        setDocumentCondition(newPhotos);
      } else if (type === 'Fgts') {
        setDocumentFgts(newPhotos);
      } else if (type === 'Banks' && bankIndex !== null) {
        setBanks((prevBanks) => {
          return prevBanks.map((bank, bIndex) => {
            if (bIndex === bankIndex) {
              return {
                ...bank,
                documentos: bank.documentos ? [...newPhotos] : [], // Atualiza os documentos do banco específico
              };
            }
            return bank; // Mantém os outros bancos inalterados
          });
        });
      } else {
        setDocument(newPhotos);
      }
    }
  };


  const hasEmptyOrUndefinedProperty = (objects) => {
    if (!objects) return false;

    objects.some((obj) =>
      Object.values(obj).some(
        (value) => value === "" || value === undefined || value === null
      )
    );
  }

  const checkBanksProperties = (banks) => {
    // Verifica se há algum banco com propriedades nulas
    for (let bank of banks) {
      // Verifica se o próprio objeto 'bank' tem propriedades nulas
      for (let prop in bank) {
        if (bank[prop] === null || bank[prop] === undefined || bank[prop].length === 0) {
          return false; // Propriedade nula ou indefinida encontrada
        }
      }

      // Verifica se o array 'parcelas' existe e contém os objetos corretos
      if (Array.isArray(bank.parcelas)) {
        for (let parcela of bank.parcelas) {
          // Verifica se 'valor' ou 'quantidade' estão nulos ou indefinidos em cada 'parcela'
          if (parcela.valor === null || parcela.valor === undefined || parcela.quantidade === null ||
            parcela.quantidade === undefined || parcela.valor.length === 0 || parcela.quantidade.length === 0) {
            return false; // Propriedade nula ou indefinida encontrada
          }
        }
      } else {
        return false; // 'parcelas' não é um array válido
      }
    }

    return true; // Nenhuma propriedade nula encontrada
  };

  console.log(checkBanksProperties(banks))

  const checkButton = () => {

    const checkSimulationEstimate =
      (!simulationRef && document?.length === 0) ||
      (!simulationRef && !document) ||
      (!simulationRef && !nameDocument) ||
      situation?.length === 0 ||
      !values.entrada ||
      !values.valor_financiamento ||
      carencia?.length === 0 ||
      hasEmptyOrUndefinedProperty(installments) ||
      !checkBanksProperties(banks) ||
      name?.length < 3 ||
      cpfCNPJ?.length < 14 ||
      (!simulationRef && !audio)

    const checkSimulation =
      (!simulationRef && document?.length === 0) ||
      nameDocument?.length === 0 ||
      situation?.length === 0 ||
      name?.length < 3 ||
      values?.entrada?.length === 0 ||
      values.valor_financiamento?.length === 0 ||
      cpfCNPJ?.length < 14;

    const checkSimulationEstimateCondition =
      (!simulationRef && document?.length === 0) ||
      (!simulationRef && !document) ||
      (!simulationRef && !documentCondition) ||
      (!simulationRef && documentCondition?.length === 0) ||
      nameDocument?.length === 0 ||
      situation?.length === 0 ||
      carencia?.length === 0 ||
      !values.entrada ||
      !values.valor_financiamento ||
      name?.length < 3 ||
      cpfCNPJ?.length < 14 ||
      hasEmptyOrUndefinedProperty(installments) ||
      !checkBanksProperties(banks) ||
      (!simulationRef && !audio) ||
      hasEmptyOrUndefinedProperty(installmentsCondition) ||
      conditionSpecial.carencia?.length === 0;

    const checkMainSimulation =
      conditionSpecial.visualizar === true
        ? checkSimulationEstimateCondition
        : checkSimulationEstimate;

    console.log('CHECAGEM', checkMainSimulation, checkSimulationEstimateCondition, checkSimulation, checkSimulationEstimate, document)

    if (situation === "Aprovado" && activeFinancing) {
      if (checkMainSimulation) return false;
    } else {
      if (checkSimulation) return false;
    }

    return (true);
  };

  const checkFinancing = useCallback((nameRef) => {
    if (nameRef !== "Cartão de Crédito" && nameRef !== "FGTS") {
      return true;
    }
    return false;
  }, []);

  const handleInstallmentsCondition = (value, index, field) => {
    const currentInstallments = installmentsCondition;
    const newInstallments = [...currentInstallments];
    const newInstallment = { ...newInstallments[index], [field]: value };
    newInstallments[index] = newInstallment;
    return setInstallmentsCondition(newInstallments);
  };

  const handleInstallments = (value, index, field) => {
    const currentInstallments = installments;
    const newInstallments = [...currentInstallments];
    const newInstallment = { ...newInstallments[index], [field]: value };
    newInstallments[index] = newInstallment;
    return setInstallments(newInstallments);
  };

  const addNewInstallments = () => {
    const newInstallment = {
      quantidade: "",
      valor: "",
    };

    setInstallments((currentInstallment) => [
      ...(currentInstallment ?? []),
      newInstallment,
    ]);
  };

  const addNewInstallmentsBanks = (index) => {
    const newInstallment = {
      quantidade: "",
      valor: "",
    };

    setBanks((prevBanks) => {
      return prevBanks.map((bank, bIndex) => {
        if (bIndex === index) {
          return {
            ...bank,
            parcelas: [...bank.parcelas, newInstallment], // Adiciona nova parcela ao banco específico
          };
        }
        return bank;
      });
    });
  };


  const removeInstallment = async (index, arrayType, bankIndex = null) => {
    try {
      const result = await Swal.fire({
        title: "Atenção",
        html: `Você deseja excluir a <b>Parcela?</b>`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });

      if (result.isConfirmed) {
        if (arrayType === "installments") {
          // Remove a parcela do array `installments`
          const newInstallment = installments.filter((_, i) => i !== index);
          setInstallments(newInstallment);
        } else if (arrayType === "banks") {
          // Remove a parcela do array `banks[bankIndex].parcelas`
          setBanks((prevBanks) => {
            return prevBanks.map((bank, bIndex) => {
              if (bIndex === bankIndex) {
                return {
                  ...bank,
                  parcelas: bank.parcelas.filter((_, i) => i !== index), // Remove a parcela específica
                };
              }
              return bank; // Mantém os outros bancos inalterados
            });
          });
        }
      }
    } catch (error) {
      toast({
        icon: "error",
        text: "Ocorreu um erro ao excluir o pagamento.",
        error: error,
      });
    }
  };


  const sendAudio = (e) => {
    const audio = e.target.files[0];
    setAudio({ file: URL.createObjectURL(audio), complete: audio });
    console.log(audio);
  };

  const mask = (v) => {
    v = v.replace(/\D/g, "");

    if (v.length <= 11) {
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else {
      v = v.substring(0, 14); // limita em 14 números
      v = v.replace(/^(\d{2})(\d)/, "$1.$2");
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
      v = v.replace(/(\d{4})(\d)/, "$1-$2");
    }

    return v;
  };

  const handleFgts = (value, name) => {

    const valueRef = name === 'resgate_valor'
      ? parseFloat(sheetRef?.orcamento?.valor || 0) - parseFloat(value || 0)
      : fgtsResult;

    setFgts(prevFgts => ({
      ...prevFgts,
      [name]: value
    }));

    setFgtsResult(valueRef);

  }

  const ListFGTS = () => {

    const listObj = {
      'Nome do Projeto': sheetRef?.nome,
      'Valor do Projeto': parseFloat(sheetRef?.orcamento?.valor_parcelado || '0'),
      'Saldo Disponível p/ Resgate': parseFloat(fgts?.resgate_saldo || '0'),
      'Valor do Resgate': parseFloat(fgts?.resgate_valor || '0'),
      'Saldo Pendente do Projeto': fgtsResult > 0 ? fgtsResult : 0,
      'Saldo de Reembolso ao Cliente': fgtsResult < 0 ? -fgtsResult : 0
    }

    const icons = {
      'Nome do Projeto': <IoDocumentSharp color="#adadad" />,
      'Valor do Projeto': <GrMoney color="#F0BE5F" />,
      'Saldo Disponível p/ Resgate': <MdAccountBalanceWallet color="#8A4D30" />,
      'Valor do Resgate': <FaMoneyCheckDollar color="#1976d2" />,
      'Saldo Pendente do Projeto': <GiPayMoney color="red" />,
      'Saldo de Reembolso ao Cliente': <GiReceiveMoney color="#388e3c" />
    };

    return (
      <Box sx={{ backgroundColor: "#f3f9ff", padding: '1rem', borderRadius: '10px', margin: '0.5rem 0', display: 'flex', justifyContent: 'center', boxShadow: '0px 10px 24px 0px #0000001A' }}>
        <Grid container spacing={2} sx={{ maxWidth: '740px' }}>
          {Object.entries(listObj).map(([key, value], index) => (
            <Grid key={index} item xs={12} sm={6} sx={{ 'b': { paddingLeft: '0.3rem' } }}>
              <p>{icons[key]} <b>{key}: </b>{typeof value === 'number' ? formatCurrency(value) : value}</p>
            </Grid>
          ))}
        </Grid>
      </Box>

    )
  }

  const handleValueChange = (option) => {
    console.log(option);
    if (selectedValue === option) {
      setSelectedValue('');
    } else {
      setSelectedValue(option);
    }
  };

  const handleImprovementsChange = () => {
    setIncludeImprovements(!includeImprovements); // Toggle improvements state
  };

  useEffect(() => {
    if (selectedValue) {
      setValues({ ...values, valor_financiamento: calculateTotal() })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue, includeImprovements])

  const calculateTotal = () => {
    let total = 0;
    const cashPrice = parseFloat(sheetRef?.orcamento?.valor || 0);
    const installmentPrice = parseFloat(sheetRef?.orcamento?.valor_parcelado || 0);
    const improvementsPrice = parseFloat(sheetRef?.visita_tecnica?.rede_eletrica?.melhorias?.valor_total || 0);

    if (selectedValue === 'vista') {
      total += cashPrice;
    } else if (selectedValue === 'parcelado') {
      total += installmentPrice;
    } else if (selectedValue === '') {
      total = 0;
    }

    if (includeImprovements) {
      total += improvementsPrice;
    }

    return total.toFixed(2);
  };

  const handleBanks = useCallback((value, index, prop) => {
    const newBanks = [...banks];

    // Se o usuário estiver alterando o banco, remove o banco antigo da lista de selecionados
    if (prop === 'banco') {
      const previousBank = newBanks[index].banco;
      changeBankSelected(previousBank, value);
    }

    // Atualiza o valor do banco no array de bancos
    newBanks[index][prop] = value;
    setBanks(newBanks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banks, selectedBanks]);

  const handleBanksInstallments = (value, bankIndex, prop, installmentIndex) => {
    setBanks((prevBanks) => {
      return prevBanks.map((bank, bIndex) => {
        if (bIndex === bankIndex) {
          const updatedInstallments = bank.parcelas.map((installment, iIndex) => {
            if (iIndex === installmentIndex) {
              return { ...installment, [prop]: value }; // Atualiza a parcela específica
            }
            return installment; // Mantém as demais parcelas inalteradas
          });
          return { ...bank, parcelas: updatedInstallments }; // Atualiza o banco com as parcelas modificadas
        }
        return bank; // Mantém os outros bancos inalterados
      });
    });
  };

  const changeBankSelected = (previousBank, value) => {
    if (previousBank) {
      setSelectedBanks((prevSelected) =>
        prevSelected.filter((bank) => bank !== previousBank)
      );

      // Reabilita a opção anterior, definindo 'disabled: false'
      setOptionsSimulation((prevOptions) =>
        prevOptions.map((option) =>
          option.nome === previousBank ? { ...option, disabled: false } : option
        )
      );
    }

    // Adicionar o novo banco selecionado se ele ainda não estiver na lista
    if (!selectedBanks.includes(value)) {
      setSelectedBanks((prevSelected) => [...prevSelected, value]);

      // Desabilita a nova opção selecionada, definindo 'disabled: true'
      setOptionsSimulation((prevOptions) =>
        prevOptions.map((option) =>
          option.nome === value ? { ...option, disabled: true } : option
        )
      );
    }
  }

  const handleNameDocument = useCallback((value, type = null) => {
    // Determina qual estado usar com base no 'type'
    const previousBank = type === 'Condição' ? conditionSpecial.banco : nameDocument;

    // Atualiza o banco selecionado
    changeBankSelected(previousBank, value);

    // Atualiza o estado correto com o novo valor
    if (type === 'Condição') {
      setConditionSpecial((prevState) => ({
        ...prevState,
        banco: value,
      }));
    } else {
      setNameDocument(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditionSpecial.banco, nameDocument]);

  const SubTitle = useCallback(({ data }) => {
    const status = data?.status;
    const checkStatus = status ? status : data ? 'Pronto para Enviar' : null;
    const color = checkStatus === 'Enviado' ? "#1fcb4b" : "#838383"
    return (
      checkStatus && simulation?.mensagemVoz ?
        <Typography sx={{ backgroundColor: color, color: "#fff", borderRadius: '5px', padding: '0.1rem 0.5rem' }}>
          {checkStatus}
        </Typography> : null
    )
  }, [simulation?.mensagemVoz])

  const calcPoints = useCallback((value1, value2) => {

    const points = ((((parseFloat(value2) - parseFloat(value1)) / 100) * 30) / 0.7);
    return Math.floor(points)

  }, [])

  const handlePoints = (check) => {

    let points = null;
    if (check) {
      points = calcPoints(sheetRef?.orcamento?.valor, sheetRef?.orcamento?.valor_parcelado);
    }
    setActivePoints(check);
    setPoints(points);
  }

  console.log(sheetRef)

  return (
    <Dialog sx={{ overflow: "hidden" }} open={view} fullWidth maxWidth="md">
      <IconButton
        aria-label="close"
        onClick={closeBox}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem', justifyContent: 'center' }}>
        {capitalizeString(
          type === "simulacoes" || type === "enviar simulação"
            ? "Simulação"
            : type
        )} <SubTitle data={simulation} />
      </DialogTitle>
      <DialogContent
        sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
      >
        <Loading view={loading} />
        {simulationRef ? (
          <DialogContentText align="center">
            {simulation?.simulacao_enviada ? 'A Simulação já foi enviada para o cliente' : simulation?.mensagemVoz ? '⚠️ Revise as informações antes de enviar ⚠️' : null}
          </DialogContentText>
        ) : (
          <DialogContentText align="center">
            Preencha os dados abaixo para <b>salvar</b> a simulação.
          </DialogContentText>
        )}
        <Grid container spacing={1}>
          {type === "simulacoes" || type === "enviar simulação" ? (
            <>
              {customer?.pre_venda && (
                <Grid item xs={12}>
                  <Alert severity="success" icon={<FaRegAddressCard />} sx={{ marginBottom: "0.5rem" }}>
                    <AlertTitle>Pré-Venda (ManyChat)</AlertTitle>
                    <p>Campanha: <b>{customer?.pre_venda?.campanha || 'Não Informado'}</b></p>
                    <p>Inversor: <b>{customer?.pre_venda?.inversor || 'Não Informado'}</b></p>
                    <p>Kit: <b>{customer?.pre_venda?.kit || 'Não Informado'}</b></p>
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Nome Completo"
                  sx={{ backgroundColor: "#fff" }}
                  value={name || ""}
                  required
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="CPF / CNPJ"
                  sx={{ backgroundColor: "#fff" }}
                  value={cpfCNPJ || ""}
                  required
                  onChange={(e) => setCpfCNPJ(mask(e.target.value))}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Autocomplete
                  value={nameDocument}
                  fullWidth
                  sx={{ alignItems: "center", backgroundColor: "#fff" }}
                  onChange={(event, newValue) => {
                    if (typeof newValue === "string") {
                      handleNameDocument(newValue)
                      // setNameDocument(newValue);
                    } else if (newValue && newValue.inputValue) {
                      handleNameDocument(newValue.inputValue)
                      // setNameDocument(newValue.inputValue);
                    } else {
                      handleNameDocument(newValue ? newValue.nome : "")
                      // setNameDocument(newValue ? newValue.nome : "");
                    }
                  }}
                  selectOnFocus
                  noOptionsText="Forma de Pagamento não encontrado"
                  clearOnBlur
                  handleHomeEndKeys
                  // disabled={Boolean(simulationRef)}
                  disableClearable={true}
                  id="free-solo-with-text-demo"
                  options={optionsSimulation}
                  getOptionLabel={(option) => {
                    // Add "inputValue" to handle the newly created option
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.nome;
                  }}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      ariaDisabled={option.disabled}
                      style={{
                        opacity: option.disabled ? 0.5 : 1, // Estilo visual para opções desabilitadas
                        pointerEvents: option.disabled ? "none" : "auto", // Evita cliques nas opções desabilitadas
                      }}
                    >
                      {option.nome}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Banco"
                      required
                      type="search"
                    />
                  )}
                  isOptionEqualToValue={(option, value) => {
                    // This function ensures that the selected option matches the value
                    return option.nome === value || option.nome === value.nome;
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <FormControl fullWidth required>
                  <InputLabel id="Situação">Situação</InputLabel>
                  <Select
                    labelId="Situação"
                    label="Situação"
                    value={situation}
                    disabled={Boolean(simulationRef)}
                    onChange={(e) => setSituation(e.target.value)}
                    required
                  >
                    {optionsSituation.map((option) => (
                      <MenuItem key={option.nome} value={option.nome}>
                        {option.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {checkFinancing(nameDocument) && (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <CurrencyInput
                      customInput={TextField}
                      label="Valor do Financiamento"
                      placeholder="R$ 00"
                      intlConfig={{
                        locale: "pt-BR",
                        currency: "BRL",
                      }}
                      onValueChange={(value) =>
                        setValues({ ...values, valor_financiamento: value })

                      }
                      value={values.valor_financiamento}
                      decimalsLimit={2}
                      required
                      fullWidth
                    />
                  </Grid>
                </>
              )}

              {checkFinancing(nameDocument) && sheetRef ? (
                <>
                  {selectedValue === 'parcelado' && (
                    <Grid item xs={12}>
                      <Alert severity="warning" sx={{ position: 'relative', backgroundColor: "#fff8d6" }} icon={<GiCrownCoin size='1.5rem' color="#ed8e00" />}>
                        {situation !== 'Aprovado' &&
                          <LockContent text="Não é possível ativar a premiação para financiamento negado" />
                        }
                        <Typography sx={{ backgroundColor: "#ffca7a", position: 'absolute', top: '5px', right: '5px', borderRadius: '5px', padding: '0.1rem 0.5rem' }} fontWeight={'800'} variant="b">Premiação</Typography>
                        <AlertTitle>Seu Crédito Seu Premio</AlertTitle>
                        <Box>
                          <FormControlLabel
                            sx={{ margin: 0 }}
                            control={<Checkbox
                              checked={activePoints}
                              color="success"
                              label="123"
                              name="Pontos"
                              onChange={() => handlePoints(!activePoints)}
                              icon={
                                <RadioButtonUncheckedIcon sx={{
                                  fill: "#0FA958",
                                  borderRadius: "50%",
                                }} />
                              }
                              checkedIcon={<CheckCircleIcon sx={{
                                fill: "#0FA958",
                                borderRadius: "50%",
                              }} />
                              }
                            />
                            }
                            label={
                              <Box>
                                <b>{calcPoints(sheetRef?.orcamento?.valor, sheetRef?.orcamento?.valor_parcelado)} Pontos </b>
                                <p>Habilitar Premiação</p>
                              </Box>
                            }
                          />
                        </Box>
                      </Alert>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Alert severity="info" sx={{ '.MuiAlert-message': { width: '100%' } }}>
                      <AlertTitle>Informações sobre o Projeto{" "}
                        <Typography sx={{ backgroundColor: "#c4edff", borderRadius: '5px', padding: '0.1rem 0.5rem' }} fontWeight={'800'} variant="b">{sheetRef?.nome}</Typography>
                      </AlertTitle>
                      <Grid container spacing={2} sx={{ '.MuiGrid-item': { paddingTop: '0!important' }, paddingTop: '1rem' }}>
                        <Grid item xs={12} sm={6} md={3}>
                          <FormControlLabel
                            sx={{ margin: 0 }}
                            control={<Checkbox
                              checked={selectedValue === 'vista'}
                              color="success"
                              label="123"
                              name="vista"
                              value={sheetRef?.orcamento?.valor || 0}
                              onChange={() => handleValueChange('vista')}
                              icon={
                                <RadioButtonUncheckedIcon sx={{
                                  fill: "#0FA958",
                                  borderRadius: "50%",
                                }} />
                              }
                              checkedIcon={<CheckCircleIcon sx={{
                                fill: "#0FA958",
                                borderRadius: "50%",
                              }} />
                              }
                            />
                            }
                            label={
                              <Box>
                                <b>Valor Pix / TED: </b>
                                <p>{formatCurrency(sheetRef?.orcamento?.valor || 0)}</p>
                              </Box>
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={5}>
                          <FormControlLabel
                            sx={{ margin: 0 }}
                            control={<Checkbox checked={selectedValue === 'parcelado'}
                              color="success"
                              label="123"
                              name="parcelado"
                              value={sheetRef?.orcamento?.valor_parcelado || 0}
                              onChange={() => handleValueChange('parcelado')}
                              icon={
                                <RadioButtonUncheckedIcon sx={{
                                  fill: "#0FA958",
                                  borderRadius: "50%",
                                }} />
                              }
                              checkedIcon={<CheckCircleIcon sx={{
                                fill: "#0FA958",
                                borderRadius: "50%",
                              }} />
                              }
                            />
                            }
                            label={
                              <Box>
                                <b>Valor Cartão / Financiamento: </b>
                                <p>{formatCurrency(sheetRef?.orcamento?.valor_parcelado || 0)}</p>
                              </Box>
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormControlLabel
                            sx={{ margin: 0 }}
                            control={<Checkbox checked={includeImprovements}
                              color="success"
                              onChange={handleImprovementsChange}
                              icon={
                                <RadioButtonUncheckedIcon sx={{
                                  fill: "#0FA958",
                                  backgroundColor: "#fff",
                                  borderRadius: "50%",
                                }} />
                              }
                              checkedIcon={<CheckCircleIcon sx={{
                                fill: "#0FA958",
                                backgroundColor: "#fff",
                                borderRadius: "50%",
                              }} />
                              }
                            />
                            }
                            label={
                              <Box>
                                <b>Valor de Melhorias: </b>
                                <p>{formatCurrency(sheetRef?.visita_tecnica?.rede_eletrica?.melhorias?.valor_total || 0)}</p>
                              </Box>
                            }
                          />
                        </Grid>
                      </Grid>
                    </Alert>
                  </Grid>
                </>
              ) : null}

              {checkFinancing(nameDocument) ? (
                <Grid
                  container
                  spacing={1}
                  sx={{
                    backgroundColor: "#fff4e5",
                    borderLeft: "5px solid #663c00!important",
                    padding: "0.2rem 0.5rem 1rem 0.5rem",
                    margin: "1rem 0 0 0",
                    borderRadius: "5px",
                    position: 'relative',
                    overflow: 'hidden'
                  }}
                >
                  {!sheetRef &&
                    <LockContent text="Não é possível ativar o financiamento sem ter uma planilha vinculada ao orçamento" />
                  }
                  <Box sx={{
                    padding: '0.3rem 0.5rem',
                    backgroundColor: "#663c00",
                    borderRadius: '5px',
                    color: "#fff",
                    position: 'absolute',
                    left: '5px',
                    top: '8px',
                    fontSize: '15px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.4rem'
                  }}>
                    <BsBank2 /><p>Principal</p>
                  </Box>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Checkbox
                      checked={activeFinancing}
                      color="success"
                      disabled={Boolean(simulationRef)}
                      onChange={() => setActiveFinancing(!activeFinancing)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <Typography variant="h6">Ativar Financiamento</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', paddingTop: '0!important', paddingBottom: '0.3rem' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={simulation?.melhorias === true}
                          onChange={() =>
                            setSimulation({ ...simulation, melhorias: !simulation.melhorias })
                          }
                          name="melhorias"
                          color="primary"
                        />
                      }
                      sx={{ margin: '0' }}
                      disabled={!activeFinancing || !sheetRef?.visita_tecnica?.rede_eletrica?.melhorias}
                      label={<Typography variant="b">Melhorias Elétricas Inclusas</Typography>}
                    />
                  </Grid>
                  {situation === "Aprovado" && (
                    <>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          padding: "1rem",
                          justifyContent: "center",
                          alignItems: 'center',
                          gap: '0.5rem',
                          'img': { width: '50px', borderRadius: '10px' }
                        }}
                      >
                        <img src={banksList[nameDocument || 'Padrão']} alt="" />
                        <h2>{`${nameDocument}` || 'Banco Não Informado'}</h2>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <CurrencyInput
                          customInput={TextField}
                          label="Entrada"
                          placeholder="R$ 00"
                          sx={{ backgroundColor: "#fff" }}
                          intlConfig={{
                            locale: "pt-BR",
                            currency: "BRL",
                          }}
                          onValueChange={(value) =>
                            setValues({ ...values, entrada: value })

                          }
                          value={values.entrada}
                          decimalsLimit={2}
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <FormControl
                          fullWidth
                          required
                          disabled={!activeFinancing}
                        >
                          <InputLabel id="Carência">Carência</InputLabel>
                          <Select
                            labelId="Carência"
                            label="Carência"
                            value={carencia}
                            sx={{ backgroundColor: "#fff" }}
                            onChange={(e) => setCarencia(e.target.value)}
                            required
                          >
                            {[90, 120, 360, 720].map((option) => (
                              <MenuItem key={option} value={option}>
                                {option} Dias
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <TextField
                          fullWidth
                          label="Número de Parcelas"
                          inputProps={{ min: 0 }}
                          disabled={!activeFinancing}
                          sx={{ backgroundColor: "#fff" }}
                          value={installments[0].quantidade || ""}
                          onChange={(e) =>
                            handleInstallments(e.target.value, 0, "quantidade")
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <CurrencyInput
                          customInput={TextField}
                          label="Valor das Parcelas"
                          placeholder="R$ 00"
                          intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                          onValueChange={(value) =>
                            handleInstallments(value, 0, "valor")
                          }
                          decimalsLimit={2}
                          disabled={!activeFinancing}
                          sx={{ backgroundColor: "#fff" }}
                          value={installments[0].valor || ""}
                          required
                          fullWidth
                        />
                      </Grid>
                      {installments?.length > 1 &&
                        installments.map((data, index) =>
                          index === 0 ? null : (
                            <Grid item key={index} xs={12}>
                              <Grid
                                container
                                spacing={1}
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    fullWidth
                                    label="Número de Parcelas"
                                    inputProps={{ min: 0 }}
                                    sx={{ backgroundColor: "#fff" }}
                                    disabled={!activeFinancing}
                                    value={data.quantidade || ""}
                                    onChange={(e) =>
                                      handleInstallments(
                                        e.target.value,
                                        index,
                                        "quantidade"
                                      )
                                    }
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "0.3rem",
                                  }}
                                >
                                  <CurrencyInput
                                    customInput={TextField}
                                    label="Valor das Parcelas"
                                    placeholder="R$ 00"
                                    sx={{ backgroundColor: "#fff" }}
                                    intlConfig={{
                                      locale: "pt-BR",
                                      currency: "BRL",
                                    }}
                                    onValueChange={(value) =>
                                      handleInstallments(value, index, "valor")
                                    }
                                    decimalsLimit={2}
                                    disabled={!activeFinancing}
                                    value={data.valor || ""}
                                    required
                                    fullWidth
                                  />
                                  <NewTooltip
                                    title="Excluir Parcela"
                                    placement="top"
                                  >
                                    <IconButton
                                      disabled={!activeFinancing}
                                      onClick={() => removeInstallment(index, 'installments')}
                                      color="error"
                                    >
                                      <DeleteForeverIcon />
                                    </IconButton>
                                  </NewTooltip>
                                </Grid>
                              </Grid>
                            </Grid>
                          )
                        )}
                      <Grid item sm={12} sx={{ margin: "1rem 0" }}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "0.5rem",
                            flexWrap: "wrap",
                          }}
                        >
                          <ButtonCB
                            disabled={!activeFinancing || installments.length === 4}
                            size="small"
                            background="#025ca5"
                            onClick={addNewInstallments}
                            sx={{ textTransform: "capitalize" }}
                            startIcon={<AddCircleRoundedIcon />}
                          >
                            Adicionar Parcelas
                          </ButtonCB>
                        </Box>
                      </Grid>
                    </>
                  )}
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      gap: "0.3rem",
                    }}
                  >
                    <h3>Mensagem de Voz</h3>
                    {!audio?.url && (
                      <p>
                        Envie ou grave um áudio explicando sobre o financiamento
                        para o cliente.
                      </p>
                    )}
                    {audio?.url ? (
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          gap: "0.5rem",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "1rem",
                        }}
                      >
                        <CustomAudioPlayerFixed
                          url={audio?.url}
                        />
                        <NewTooltip title='Excluir Áudio' placement='top'>
                          <IconButton onClick={() => setAudio(null)} color='error'>
                            <DeleteRounded />
                          </IconButton>
                        </NewTooltip>
                      </Box>
                    ) : (
                      <div>
                        <AudioRecorder
                          setAudio={setAudio}
                          audio={audio}
                          disabled={!activeFinancing}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "1rem",
                          }}
                        >
                          <Button
                            disableElevation
                            sx={{ textTransform: "capitalize" }}
                            component="label"
                            variant="contained"
                            onChange={(e) => sendAudio(e)}
                            disabled={!activeFinancing}
                            startIcon={<AudioFileIcon />}
                          >
                            Enviar Áudio
                            <VisuallyHiddenInput
                              ref={inputRef}
                              multiple={false}
                              type="file"
                              accept="audio/*"
                            />
                          </Button>
                        </Box>
                      </div>
                    )}
                  </Grid>
                </Grid>
              ) : null}
            </>
          ) : (
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                label="Nome"
                fullWidth
                variant="outlined"
                required
                value={nameDocument || ""}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
          )}

          <Grid item xs={12} sx={{
            marginTop: "0.7rem",
            borderRadius: "5px",
            padding: "0.5rem 0 1rem 0",
            backgroundColor: "#fff4e5",
            borderLeft: "5px solid #663c00",
          }}>
            <Grid
              container
              xs={12}
              spacing={1}
            >
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Typography variant="h6">Arquivo ({nameDocument || 'Financiamento'})</Typography>
              </Grid>
              {document?.length > 0 ? (
                document?.map((file, index) => (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <DocsItems>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "0.5rem",
                          alignItems: "center",
                        }}
                        onClick={() => file.url ? window.open(file.url, '_blank') : viewDoc(file)}
                      >
                        <InsertDriveFileOutlinedIcon sx={{ fill: "#484848" }} />
                        <Link to="" style={{ color: "#000" }}>
                          {file.url ? `${file?.ref?.split(`Clientes/${customer.id}/`)[1]} [Enviado]` : file?.complete?.name}
                        </Link>
                      </Box>
                      <NewTooltip
                        id="Clickable"
                        title="Excluir"
                        placement="top"
                      >
                        <IconButton
                          onClick={() => deleteFile(index, document, '')}
                          color="error"
                        >
                          <DeleteRounded />
                        </IconButton>
                      </NewTooltip>
                    </DocsItems>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12} justifyContent={"center"}>
                  <Typography textAlign={"center"} color={"#838383"}>
                    Nenhum arquivo encontrado
                  </Typography>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  sx={{ textTransform: "capitalize" }}
                  variant="contained"
                  component="label"
                  color="primary"
                  onChange={(e) => sendFile(e)}
                  startIcon={<FaFileUpload />}
                  disableElevation
                >
                  Adicionar Arquivo
                  <VisuallyHiddenInput
                    multiple={true}
                    type="file"
                    accept="image/png,image/jpeg, application/pdf"
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {situation === 'Aprovado' && checkFinancing(nameDocument) && banks.length > 0 ?
            banks.map((data, index) => (
              <Grid key={index} item xs={12}
                sx={{
                  marginTop: "1rem",
                  backgroundColor: "#fff4e5",
                  borderRadius: "5px",
                  padding: "0.3rem 0 1.5rem 0",
                  borderLeft: "5px solid #663c00",
                  position: 'relative',
                  overflow: 'hidden'
                }}>
                {!activeFinancing &&
                  <LockContent text="Clique em Ativar Financiamento para desbloquear" />
                }
                <NewTooltip title="Excluir Simulação" placement='top'>
                  <Button disableElevation sx={{ position: 'absolute', right: '0', top: '0px', width: '30px', padding: '0', height: '30px', minWidth: '0', zIndex: '111' }}
                    variant="contained" color="error" onClick={() => deleteBank(index)}>
                    <IoIosClose size='25px' />
                  </Button>
                </NewTooltip>
                <Box sx={{
                  padding: '0.3rem 0.5rem',
                  backgroundColor: "#663c00",
                  borderRadius: '5px',
                  color: "#fff",
                  position: 'absolute',
                  left: '5px',
                  top: '8px',
                  fontSize: '15px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.4rem'
                }}>
                  <BsBank2 /><p>{index === 0 ? 'Secundário' : 'Terciário'}</p>
                </Box>
                <Box
                  sx={{
                    width: "100%", justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center',
                    position: "relative",
                    top: "2px",
                  }}
                  icon={false}
                >
                  <Grid container>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', margin: '0.5rem 0 0.5rem 0', gap: '0.4rem', 'img': { width: '50px', borderRadius: '10px' } }}>
                      <img src={banksList[data?.banco]} alt="" />
                      <FormControl
                        fullWidth
                        variant="standard"
                        sx={{ maxWidth: '350px' }}
                        required
                      >
                        <Select
                          placeholder="Escolha um Banco"
                          value={data.banco || ''}
                          sx={{
                            fontSize: '24px',
                            fontWeight: 'bold',
                            textAlign: 'center'
                          }}
                          onChange={(e) =>
                            handleBanks(e.target.value, index, 'banco')
                          }
                          required
                        >
                          <MenuItem value="" disabled>
                            Escolha um Banco
                          </MenuItem>
                          {optionsSimulation.map((option) => (
                            <MenuItem key={option.nome} value={option.nome} disabled={option.disabled}>
                              {option.nome}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <FormGroup
                    sx={{
                      display: "flex",
                      gap: "0.5rem",
                      flexDirection: "row",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.visualizar === true}
                          onChange={() =>
                            handleBanks(!data.visualizar, index, 'visualizar')
                          }
                          name="condicoes_especiais_sim"
                          color="primary"
                        />
                      }
                      disabled={!activeFinancing}
                      label={<Typography variant="b">Mostrar essa simulação ao cliente</Typography>}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.melhorias === true}
                          onChange={() =>
                            handleBanks(!data.melhorias, index, 'melhorias')
                          }
                          name="condicoes_especiais_sim"
                          color="primary"
                        />
                      }
                      disabled={!activeFinancing}
                      label={<Typography variant="b">Melhorias Elétricas Inclusas</Typography>}
                    />
                  </FormGroup>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    padding: '0.7rem 1rem 0.5rem 0.5rem',
                    boxSizing: 'border-box'
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={3}>
                        <CurrencyInput
                          customInput={TextField}
                          label="Entrada"
                          placeholder="R$ 00"
                          intlConfig={{
                            locale: "pt-BR",
                            currency: "BRL",
                          }}
                          onValueChange={(value) =>
                            handleBanks(value, index, 'entrada')
                          }
                          decimalsLimit={2}
                          disabled={
                            !activeFinancing
                          }
                          sx={{
                            backgroundColor: "#fff"
                          }}
                          value={data.entrada || ""}
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl
                          fullWidth
                          required
                          disabled={
                            !activeFinancing
                          }
                        >
                          <InputLabel id="Carência">
                            Carência
                          </InputLabel>
                          <Select
                            labelId="Carência"
                            label="Carência"
                            value={data.carencia}
                            sx={{
                              backgroundColor: "#fff"
                            }}
                            onChange={(e) =>
                              handleBanks(e.target.value, index, 'carencia')
                            }
                            required
                          >
                            {[90, 120, 360, 720].map((option) => (
                              <MenuItem key={option} value={option}>
                                {option} Dias
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          fullWidth
                          label="Número de Parcelas"
                          required
                          inputProps={{ min: 0 }}
                          sx={{
                            backgroundColor: "#fff"
                          }}
                          disabled={
                            !activeFinancing
                          }
                          value={data.parcelas[0].quantidade || ""}
                          onChange={(e) =>
                            handleBanksInstallments(e.target.value, index, 'quantidade', 0)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <CurrencyInput
                          customInput={TextField}
                          label="Valor das Parcelas"
                          placeholder="R$ 00"
                          intlConfig={{
                            locale: "pt-BR",
                            currency: "BRL",
                          }}
                          onValueChange={(value) =>
                            handleBanksInstallments(value, index, 'valor', 0)
                          }
                          decimalsLimit={2}
                          disabled={
                            !activeFinancing
                          }
                          sx={{
                            backgroundColor: "#fff"
                          }}
                          value={data.parcelas[0].valor || ""}
                          required
                          fullWidth
                        />
                      </Grid>
                      {data.parcelas?.length > 1 &&
                        data.parcelas.map((dataRef, ind) =>
                          ind === 0 ? null : (
                            <Grid item key={ind} xs={12}>
                              <Grid
                                container
                                spacing={1}
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    fullWidth
                                    label="Número de Parcelas"
                                    inputProps={{ min: 0 }}
                                    sx={{ backgroundColor: "#fff" }}
                                    disabled={!activeFinancing}
                                    value={dataRef.quantidade || ""}
                                    onChange={(e) =>
                                      handleBanksInstallments(e.target.value, index, 'quantidade', ind)
                                    }
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "0.3rem",
                                  }}
                                >
                                  <CurrencyInput
                                    customInput={TextField}
                                    label="Valor das Parcelas"
                                    placeholder="R$ 00"
                                    sx={{ backgroundColor: "#fff" }}
                                    intlConfig={{
                                      locale: "pt-BR",
                                      currency: "BRL",
                                    }}
                                    onValueChange={(value) =>
                                      handleBanksInstallments(value, index, 'valor', ind)
                                    }
                                    decimalsLimit={2}
                                    disabled={!activeFinancing}
                                    value={dataRef.valor || ""}
                                    required
                                    fullWidth
                                  />
                                  <NewTooltip
                                    title="Excluir Parcela"
                                    placement="top"
                                  >
                                    <IconButton
                                      disabled={!activeFinancing}
                                      onClick={() => removeInstallment(ind, 'banks', index)}
                                      color="error"
                                    >
                                      <DeleteForeverIcon />
                                    </IconButton>
                                  </NewTooltip>
                                </Grid>
                              </Grid>
                            </Grid>
                          )
                        )}
                    </Grid>
                    <Grid item sm={12} sx={{ margin: "1rem 0" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "0.5rem",
                          flexWrap: "wrap",
                        }}
                      >
                        <ButtonCB
                          disabled={!activeFinancing || data.parcelas.length === 4}
                          size="small"
                          background="#025ca5"
                          onClick={() => addNewInstallmentsBanks(index)}
                          sx={{ textTransform: "capitalize" }}
                          startIcon={<AddCircleRoundedIcon />}
                        >
                          Adicionar Parcelas
                        </ButtonCB>
                      </Box>
                    </Grid>
                  </ThemeProvider>
                </Box>
                <Box sx={{ paddingTop: '0.7rem' }}>
                  <Grid
                    container
                    spacing={1}
                    xs={12}
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Typography variant="h6">Arquivo {data.banco || 'Banco'}</Typography>
                    </Grid>
                    {data.documentos.length > 0 ? (
                      data.documentos?.map((file, ind) => (
                        <Grid
                          key={ind}
                          item
                          xs={12}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <DocsItems>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "0.5rem",
                                alignItems: "center",
                              }}
                              onClick={() => file.url ? window.open(file.url, '_blank') : viewDoc(file)}
                            >
                              <InsertDriveFileOutlinedIcon sx={{ fill: "#484848" }} />
                              <Link to="" style={{ color: "#000" }}>
                                {file.url ? `${file?.ref?.split(`Clientes/${customer.id}/`)[1]} [Enviado]` : file?.complete?.name}
                              </Link>
                            </Box>
                            <NewTooltip
                              id="Clickable"
                              title="Excluir"
                              placement="top"
                            >
                              <IconButton
                                onClick={() => deleteFile(ind, data.documentos, 'Banks', index)}
                                color="error"
                              >
                                <DeleteRounded />
                              </IconButton>
                            </NewTooltip>
                          </DocsItems>
                        </Grid>
                      ))
                    ) : (
                      <Grid item xs={12} justifyContent={"center"}>
                        <Typography textAlign={"center"} color={"#838383"}>
                          Nenhum arquivo encontrado
                        </Typography>
                      </Grid>
                    )}

                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        sx={{ textTransform: "capitalize" }}
                        variant="contained"
                        component="label"
                        disabled={!activeFinancing}
                        onChange={(e) => sendFile(e, 'Banks', index)}
                        startIcon={<FaFileUpload />}
                        disableElevation
                      >
                        Adicionar Arquivo
                        <VisuallyHiddenInput
                          multiple={true}
                          type="file"
                          accept="image/png,image/jpeg, application/pdf"
                        />
                      </Button>
                    </Grid>

                  </Grid>
                </Box>
              </Grid>
            ))
            : null
          }

          {checkFinancing(nameDocument) && situation === 'Aprovado' && (
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', position: 'relative', paddingBottom: '0.6rem', margin: '0.5rem 0', overflow: 'hidden' }}>
              {!activeFinancing &&
                <LockContent text="Clique em Ativar Financiamento para desbloquear" row />
              }
              <ButtonCB onClick={addBank} background="#663C00" startIcon={<BsBuildingAdd />}>Adicionar banco</ButtonCB>
            </Grid>
          )}

          {checkFinancing(nameDocument) && simulation?.mensagemVoz &&  // Ativar FGTS
            (<Grid item xs={12}
              sx={{
                marginTop: "0.2rem",
                backgroundColor: "#e8f4ff",
                borderRadius: "5px",
                padding: "0.3rem 1rem 1rem 1rem",
                borderLeft: "5px solid #003F74",
                position: 'relative',
                overflow: 'hidden'
              }}>
              {!activeFinancing &&
                <LockContent text="Clique em Ativar Financiamento para desbloquear" />
              }
              <Box
                sx={{
                  width: "100%", justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center',
                  position: "relative", paddingTop: '0.6rem', boxSizing: 'border-box'
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    checked={fgts.visualizar}
                    color="success"
                    onChange={() => handleFgts(!fgts.visualizar, 'visualizar')}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.7rem' }}>
                    <Typography variant="h6">Ativar Resgate FGTS</Typography>
                    {notMobile &&
                      <GiTakeMyMoney size='30px' />
                    }
                  </Box>
                </Box>
              </Box>
              <Box
                icon={false}
                sx={{
                  width: "100%",
                  padding: '1rem 0'
                }}
              >
                <ThemeProvider theme={theme}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.3rem', justifyContent: 'center' }}>
                        <TiDocumentText size='25px' />
                        <Typography variant="h6">
                          Resumo
                        </Typography>
                      </Box>
                      <ListFGTS data={fgts} />
                    </Grid>
                    <Grid item xs={6}>
                      <CurrencyInput
                        customInput={TextField}
                        label="Valor Disponivel p/ Resgate"
                        placeholder="R$ 00"
                        intlConfig={{
                          locale: "pt-BR",
                          currency: "BRL",
                        }}
                        onValueChange={(value) =>
                          handleFgts(value, 'resgate_saldo')
                        }
                        decimalsLimit={2}
                        disabled={
                          !fgts.visualizar
                        }
                        InputLabelProps={{
                          sx: {
                            color: "#003F74", // Cor normal do texto da label
                          },
                        }}
                        sx={{
                          backgroundColor: "#fff",
                          ".MuiSvgIcon-root": {
                            color: "#003F74",
                          },
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "#003F74", // Cor da borda normal
                          },
                        }}
                        value={fgts.resgate_saldo}
                        required
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CurrencyInput
                        customInput={TextField}
                        label="Valor do Resgate"
                        placeholder="R$ 00"
                        intlConfig={{
                          locale: "pt-BR",
                          currency: "BRL",
                        }}
                        onValueChange={(value) =>
                          handleFgts(value, 'resgate_valor')

                        }
                        decimalsLimit={2}
                        disabled={
                          !fgts.visualizar
                        }
                        InputLabelProps={{
                          sx: {
                            color: "#003F74", // Cor normal do texto da label
                          },
                        }}
                        sx={{
                          backgroundColor: "#fff",
                          ".MuiSvgIcon-root": {
                            color: "#003F74",
                          },
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "#003F74", // Cor da borda normal
                          },
                        }}
                        value={fgts.resgate_valor}
                        required
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </ThemeProvider>
              </Box>
              <Box>
                <Grid
                  container
                  spacing={1}
                  xs={12}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography variant="h6">Arquivo FGTS</Typography>
                  </Grid>
                  {documentFgts.length > 0 ? (
                    documentFgts?.map((file, index) => (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <DocsItems>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "0.5rem",
                              alignItems: "center",
                            }}
                            onClick={() => file.url ? window.open(file.url, '_blank') : viewDoc(file)}
                          >
                            <InsertDriveFileOutlinedIcon sx={{ fill: "#484848" }} />
                            <Link to="" style={{ color: "#000" }}>
                              {file.url ? `${file?.ref?.split(`Clientes/${customer.id}/`)[1]} [Enviado]` : file?.complete?.name}
                            </Link>
                          </Box>
                          <NewTooltip
                            id="Clickable"
                            title="Excluir"
                            placement="top"
                          >
                            <IconButton
                              onClick={() => deleteFile(index, documentFgts, 'Fgts')}
                              color="error"
                            >
                              <DeleteRounded />
                            </IconButton>
                          </NewTooltip>
                        </DocsItems>
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={12} justifyContent={"center"}>
                      <Typography textAlign={"center"} color={"#838383"}>
                        Nenhum arquivo encontrado
                      </Typography>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      sx={{ textTransform: "capitalize" }}
                      variant="contained"
                      component="label"
                      color="info"
                      disabled={fgts.visualizar === false || !activeFinancing}
                      onChange={(e) => sendFile(e, 'Fgts')}
                      startIcon={<FaFileUpload />}
                      disableElevation
                    >
                      Adicionar Arquivo
                      <VisuallyHiddenInput
                        multiple={true}
                        type="file"
                        accept="image/png,image/jpeg, application/pdf"
                      />
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>)
          }

          {situation === 'Aprovado' && checkFinancing(nameDocument) && // Condição Especial
            <Grid item xs={12}
              sx={{
                marginTop: "1rem",
                backgroundColor: "#edf7ed",
                borderRadius: "5px",
                padding: "0.3rem 0 1rem 0",
                borderLeft: "5px solid #2e7d32",
                position: 'relative',
                overflow: 'hidden'
              }}>
              {!activeFinancing &&
                <LockContent text="Clique em Ativar Financiamento para desbloquear" />
              }
              {/* Condição Especial */}
              <Alert
                sx={{
                  ".MuiAlert-message": { width: "100%", justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' },
                  position: "relative",
                  top: "2px",
                }}
                icon={false}
                severity="success"
              >
                <AlertTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.7rem' }}>
                  <BiSolidOffer size='30px' />
                  <h3>Condição Especial</h3>
                </AlertTitle>
                <p>Atenção: O Cliente irá ter uma condição especial de pagamento?</p>
                <FormGroup
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                    flexDirection: "row",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={conditionSpecial.visualizar === true}
                        onChange={() =>
                          setConditionSpecial({
                            ...conditionSpecial,
                            visualizar: true,
                          })
                        }
                        name="condicoes_especiais_sim"
                        color="primary"
                      />
                    }
                    disabled={!activeFinancing}
                    label={<b>Sim</b>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={conditionSpecial.visualizar === false}
                        onChange={() =>
                          setConditionSpecial({
                            ...conditionSpecial,
                            visualizar: false,
                          })
                        }
                        name="condicoes_especiais_nao"
                        color="primary"
                      />
                    }
                    disabled={!activeFinancing}
                    label={<b>Não</b>}
                  />
                </FormGroup>
              </Alert>
              <Alert
                icon={false}
                severity="success"
                sx={{
                  ".MuiAlert-message": { width: "100%" }
                }}
              >
                <ThemeProvider theme={theme}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={3}>
                      <FormControl
                        fullWidth
                        required
                        color="success"
                        disabled={
                          !activeFinancing || !conditionSpecial.visualizar
                        }
                      >
                        <InputLabel id="Banco" sx={{ color: "green" }}>
                          Banco
                        </InputLabel>
                        <Select
                          labelId="Banco"
                          label="Banco"
                          value={conditionSpecial.banco}
                          sx={{
                            backgroundColor: "#fff",
                            ".MuiSvgIcon-root": {
                              color: "green",
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "green", // Cor da borda normal
                            },
                          }}
                          onChange={(e) =>
                            setConditionSpecial({
                              ...conditionSpecial,
                              banco: e.target.value,
                            })
                          }
                          //   IconComponent={LockIcon}
                          required
                        >
                          {optionsSimulation.map((option) => (
                            <MenuItem key={option.nome} value={option.nome} disabled={option.disabled}>
                              {option.nome}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <FormControl
                        fullWidth
                        required
                        color="success"
                        disabled={
                          !activeFinancing || !conditionSpecial.visualizar
                        }
                      >
                        <InputLabel id="Carência" sx={{ color: "green" }}>
                          Carência
                        </InputLabel>
                        <Select
                          labelId="Carência"
                          label="Carência"
                          value={conditionSpecial.carencia}
                          sx={{
                            backgroundColor: "#fff",
                            ".MuiSvgIcon-root": {
                              color: "green",
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "green", // Cor da borda normal
                            },
                          }}
                          onChange={(e) =>
                            setConditionSpecial({
                              ...conditionSpecial,
                              carencia: e.target.value,
                            })
                          }
                          required
                        >
                          {[90, 120, 360, 720].map((option) => (
                            <MenuItem key={option} value={option}>
                              {option} Dias
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        fullWidth
                        color="success"
                        label="Número de Parcelas"
                        inputProps={{ min: 0 }}
                        InputLabelProps={{
                          sx: {
                            color: "green", // Cor normal do texto da label
                          },
                        }}
                        sx={{
                          backgroundColor: "#fff",
                          ".MuiSvgIcon-root": {
                            color: "green",
                          },
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "green", // Cor da borda normal
                          },
                        }}
                        disabled={
                          !activeFinancing || !conditionSpecial.visualizar
                        }
                        value={installmentsCondition[0].quantidade || ""}
                        onChange={(e) =>
                          handleInstallmentsCondition(
                            e.target.value,
                            0,
                            "quantidade"
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <CurrencyInput
                        customInput={TextField}
                        label="Valor das Parcelas"
                        color="success"
                        placeholder="R$ 00"
                        intlConfig={{
                          locale: "pt-BR",
                          currency: "BRL",
                        }}
                        onValueChange={(value) =>
                          handleInstallmentsCondition(value, 0, "valor")
                        }
                        decimalsLimit={2}
                        disabled={
                          !activeFinancing || !conditionSpecial.visualizar
                        }
                        InputLabelProps={{
                          sx: {
                            color: "green", // Cor normal do texto da label
                          },
                        }}
                        sx={{
                          backgroundColor: "#fff",
                          ".MuiSvgIcon-root": {
                            color: "green",
                          },
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "green", // Cor da borda normal
                          },
                        }}
                        value={installmentsCondition[0].valor || ""}
                        required
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </ThemeProvider>
              </Alert>
              <Box>
                <Grid
                  container
                  spacing={1}
                  xs={12}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography variant="h6">Arquivo Condição</Typography>
                  </Grid>
                  {documentCondition.length > 0 ? (
                    documentCondition?.map((file, index) => (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <DocsItems>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "0.5rem",
                              alignItems: "center",
                            }}
                            onClick={() => file.url ? window.open(file.url, '_blank') : viewDoc(file)}
                          >
                            <InsertDriveFileOutlinedIcon sx={{ fill: "#484848" }} />
                            <Link to="" style={{ color: "#000" }}>
                              {file.url ? `${file?.ref?.split(`Clientes/${customer.id}/`)[1]} [Enviado]` : file?.complete?.name}
                            </Link>
                          </Box>
                          <NewTooltip
                            id="Clickable"
                            title="Excluir"
                            placement="top"
                          >
                            <IconButton
                              onClick={() => deleteFile(index, documentCondition, 'Condição')}
                              color="error"
                            >
                              <DeleteRounded />
                            </IconButton>
                          </NewTooltip>
                        </DocsItems>
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={12} justifyContent={"center"}>
                      <Typography textAlign={"center"} color={"#838383"}>
                        Nenhum arquivo encontrado
                      </Typography>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      sx={{ textTransform: "capitalize" }}
                      variant="contained"
                      component="label"
                      color="info"
                      disabled={conditionSpecial.visualizar === false || !activeFinancing}
                      onChange={(e) => sendFile(e, 'Condição')}
                      startIcon={<FaFileUpload />}
                      disableElevation
                    >
                      Adicionar Arquivo
                      <VisuallyHiddenInput
                        multiple={true}
                        type="file"
                        accept="image/png,image/jpeg, application/pdf"
                      />
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          }

        </Grid>
      </DialogContent>
      <DialogActions
        sx={{ display: "flex", justifyContent: "center", marginBottom: "1rem" }}
      >
        {simulationRef && simulation?.status !== 'Enviado' && simulation?.mensagemVoz ? (
          <Button
            sx={{ textTransform: "capitalize" }}
            variant="contained"
            disableElevation
            onClick={sendSimulation}
            startIcon={<MdOutlineSendToMobile />}
            disabled={!checkButton()}
            color="info"
          >
            Enviar
          </Button>
        ) :
          <ThemeProvider theme={theme}>
            <Button
              sx={{ textTransform: "capitalize" }}
              variant="contained"
              disableElevation
              onClick={saveDoc}
              disabled={!checkButton() || simulationRef?.banco}
              color="success"
            >
              Salvar
            </Button>
          </ThemeProvider>
        }
        <Button
          onClick={closeBox}
          sx={{ textTransform: "capitalize" }}
          disableElevation
          variant="contained"
          color="primary"
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendDoc;
