import { TableRow, Tooltip } from '@mui/material';
import { useState } from 'react'

const NewTableRow = ({ children, onClick, sx, text }) => {
    const [showTooltip, setShowTooltip] = useState(true);

    const handleTrClick = (event) => {
      if (event.target === event.currentTarget || !event.target.closest('.clickable')) {
        onClick();
        setShowTooltip(false);
      }
    };

    const handleMouseOver = (event) => {
      if (event.target.closest('.clickable')) {
        setShowTooltip(false);
      } else {
        setShowTooltip(true);
      }
    };

    return (
      <Tooltip
        title={showTooltip ? text : ''}
        placement='bottom'
        followCursor={true}
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 10], // [skidding, distance]
              },
            },
          ],
        }}
      >
        <TableRow
          hover
          onClick={handleTrClick}
          onMouseOver={handleMouseOver}
          sx={{ cursor: 'pointer', ...sx }}
        >
          {children}
        </TableRow>
      </Tooltip>
    );
  };

export default NewTableRow