import React, { useState, forwardRef, useEffect } from "react";
import { onSnapshot, query, collection, orderBy } from "firebase/firestore";
import { Link } from "react-router-dom";

// import Header from "../../components/Header/Index";
// import Create from "./Components/Create/Index";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

// import  Swal  from "sweetalert2/dist/sweetalert2";
// import axios from 'axios';
import { dataBase } from "../../firebase/database";


// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import DeleteIcon from '@mui/icons-material/Delete';
// import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
// import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import BoltIcon from '@mui/icons-material/Bolt';
import GroupIcon from '@mui/icons-material/Group';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
// import CancelIcon from '@mui/icons-material/Cancel';
// import EditIcon from '@mui/icons-material/Edit';
// import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// Css
import "cooltipz-css";
import style from "./styles.module.scss";
import "../../styles/_buttons.scss";
import Logo from '../../images/logo192.png';

// import AddLead from "./Components/Leads/Add";
import { theme } from "../../data/theme";
import { ThemeProvider } from "@emotion/react";
import { Box, Button, Checkbox, Chip, FormControlLabel, Grid, ListItem, Tooltip } from "@mui/material";
import NewTooltip from "../../components/Tooltip";
import AddCustomer from "./Components/Add";
import { useMember } from "../../hooks/useMember";
import useGoPage from "../../hooks/useGoPage";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import RegisterVisit from "./Components/RegisterVisit";
import useWindowWidth from "../../hooks/useWindowWidth";
import { AutoSizer, List } from "react-virtualized";
import Status from "./Components/Status";

const Customers = ({ isLoaded, userRef, check }) => {
  const width = useWindowWidth();
  const navigate = useNavigate();
  const { getMemberColor } = useMember();
  const goPage = useGoPage();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [viewProspection, setviewProspection] = useState(false);
  const [viewVisit, setviewVisit] = useState(false);
  const [optionList, setOptionList] = useState([]);
  // const [sheets, setSheets] = useState([]);
  const [leadsRef, setLeadsRef] = useState([]);
  const [leadsRefAll, setLeadsRefAll] = useState([]);
  const [leads, setLeads] = useState([]);
  const [members, setMembers] = useState([]);
  const [view, setView] = useState({
    add: false,
    register: false
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const loadingLeads = open && optionList?.length === 0;

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const ListboxComponent = React.forwardRef(function ListboxComponent(
    props,
    ref
  ) {
    const { children, role, ...other } = props;
    const itemCount = Array.isArray(children) ? children.length : 0;
    const itemSize = 49;

    return (
      <div ref={ref}>
        <div {...other} style={{ flex: "1 1 auto" }}>
          <AutoSizer disableHeight>
            {({ width }) => (
              <List
                height={300}
                width={width}
                rowHeight={itemSize}
                overscanCount={5}
                rowCount={itemCount}
                rowRenderer={(props) => {
                  return React.cloneElement(children[props.index], {
                    style: props.style,
                  });
                }}
                role={role}
              />
            )}
          </AutoSizer>
        </div>
      </div>
    );
  });

  useEffect(
    () => {
      const fetchData = async () => {
        onSnapshot(query(collection(dataBase, "Clientes"), orderBy("createAt", 'desc')), (data) => {
          // Atualiza os dados em tempo real
          setLeadsRefAll(
            data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
          );
        });
        onSnapshot(collection(dataBase, "Membros"), (data) => {
          // Atualiza os dados em tempo real
          setMembers(
            data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
          );
        });
      };
      fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  const filterLeads = (filterCondition) => {
    const filteredLeads = leadsRefAll.filter(filterCondition);
    setLeads(filteredLeads);
    setLeadsRef(filteredLeads);
  };

  useEffect(() => {
    if (!leadsRefAll) return;

    // Aplica o filtro geral (combinação de prospecção, visita técnica, etc.)
    applyFilters();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadsRefAll, viewProspection, viewVisit]);

  // Função combinada que aplica todos os filtros
  const applyFilters = () => {
    const deniedConsultora = userRef?.nome === 'Lara' ? 'Erika' : 'Lara';

    // Array para armazenar as condições de filtro
    let filters = [];

    // Filtro para usuários que não são Administradores
    if (userRef?.cargo !== "Administrador" && userRef?.cargo !== "Financeiro") {
      if (viewProspection) {
        filters.push((data) =>
          data.consultora !== deniedConsultora && data.assistente === userRef?.uid
        );
      } else {
        filters.push((data) =>
          data.consultora_uid === userRef.uid ||
          data.representante?.id === userRef.uid ||
          data.assistente === userRef.uid
        );
      }
    } else {
      // Se o usuário é Administrador ou tem nome_ref
      if (!viewProspection) {
        filters.push((data) => data.proposta?.length > 0 || data.consultora === 'Paulo');
      }
    }

    // Adiciona a filtragem de visita técnica se `viewVisit` estiver ativado
    if (viewVisit) {
      filters.push((data) =>
        data?.planilha?.some((item) => item?.visita_tecnica)
      );
    }

    // Combina todos os filtros
    const combinedFilter = (data) => {
      return filters.every((filter) => filter(data));
    };

    // Aplica o filtro combinado aos leads
    filterLeads(combinedFilter);
  };

  console.log(leads);

  useEffect(() => {
    if (leads) {
      const uniqueOptions = leadsRefAll.reduce((uniqueList, currentData) => {
        const isExisting = uniqueList.some(item => item.nome === currentData.nome);

        if (!isExisting && currentData?.nome?.trim() !== '') {
          uniqueList.push({
            id: uniqueList.length,
            nome: currentData.nome,
            cidade: currentData.cidade,
            telefone: currentData.telefone
          });
        }

        return uniqueList;
      }, []);

      setOptionList(uniqueOptions.sort((a, b) => a.nome?.localeCompare(b?.nome)));
    }
  }, [leadsRefAll, leads]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChange = (event, newValue) => {

    if (!newValue) {
      setSearch(''); // Se o novo valor for vazio, mantenha como vazio
    } else {
      // Verifica se o novo valor existe nas opções
      const optionExists = optionList.some(option => option.nome === newValue.nome);
      console.log('OPTIONS', optionExists)
      if (optionExists) {
        setSearch(newValue);
        setLeads(leadsRefAll.filter(data => data.nome === newValue.nome));
      } else {
        // Trate o caso em que o valor não corresponde a nenhuma opção existente, se necessário
      }
    }
  };

  // const handleChange = (event, newValue) => {
  //   if (!newValue) {
  //     setSearch(''); // Se o novo valor for vazio, mantém a busca limpa
  //     setLeads(leadsRef); // Restaura a lista de leads original
  //   } else {
  //     const optionExists = optionList.some(option => 
  //       option.nome === newValue.nome
  //     );

  //     if (optionExists) {
  //       setSearch(newValue.nome);
  //       setLeads(leadsRefAll.filter(data => 
  //         data.nome === newValue.nome &&
  //         data.cidade === newValue.cidade
  //       ));
  //     } else {
  //       // Tratar busca pelo telefone se necessário
  //       setLeads(leadsRefAll.filter(data => 
  //         data.telefone === newValue
  //       ));
  //     }
  //   }
  // };

  const formatarTelefone = (telefone) => {
    // Adiciona os parênteses, traços e espaços conforme o formato do número de telefone
    return telefone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  }

  const formatText = (name) => {
    return name?.length > 20 ? `${name?.substring(0, 20)}...` : name
  };

  const formatTextLink = (name, id) => {
    const linkElement = (
      <Link className="link_black" to={`${id}`} onClick={() => goPage(null, 'top')}>
        {name.length > 19 ? `${name.substring(0, 19)}...` : name}
      </Link>
    );

    if (name.length > 15) {
      return (
        <NewTooltip title={name ?? ''} placement="top">
          {linkElement}
        </NewTooltip>
      );
    } else {
      return linkElement;
    }
  };

  const NewTableRow = ({ children, onClick, sx }) => {
    const [showTooltip, setShowTooltip] = useState(true);

    const handleTrClick = (event) => {
      if (event.target === event.currentTarget || !event.target.closest('.clickable')) {
        onClick();
        setShowTooltip(false);
      }
    };

    const handleMouseOver = (event) => {
      if (event.target.closest('.clickable')) {
        setShowTooltip(false);
      } else {
        setShowTooltip(true);
      }
    };

    return (
      <Tooltip
        title={showTooltip ? 'Clique para visualizar o Cliente' : ''}
        placement='bottom'
        followCursor={true}
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 10], // [skidding, distance]
              },
            },
          ],
        }}
      >
        <TableRow
          hover
          onClick={handleTrClick}
          onMouseOver={handleMouseOver}
          sx={{ cursor: 'pointer', ...sx }}
        >
          {children}
        </TableRow>
      </Tooltip>
    );
  };

  console.log(leads?.filter((data) =>
    data.planilha?.some(ref => ref.feedback)
  ));

  const goCustomer = (value) => {
    navigate(value);
    window.scrollTo(0, 0);
  }

  const getPhotoMember = (idMember) => {
    const member = members.find((member) => member.id === idMember);
    if (member) {
      return member.photo?.url || Logo;
    } else {
      return Logo;
    }
  }

  const getMember = (idMember, prop = "nome") => {
    const member = members.find((member) => member.id === idMember);
    if (member) {
      return member[prop];
    }
    return 'Nenhum'
  }

  return (
    <div className={style.container_panel}>
      <div className={'title-panel'}>
        <GroupIcon sx={{ width: '62px', height: '62px', marginBottom: '0.5rem' }} />
        <h2>Clientes</h2>
      </div>
      <div className={style.content_panel}>
        {isLoaded &&
          <AddCustomer
            isLoaded={isLoaded}
            userRef={userRef}
            leads={leads}
            members={members}
            view={view.add}
            close={() => setView({ ...view, add: false })} />}
        {isLoaded &&
          <RegisterVisit
            userRef={userRef}
            leads={leads}
            members={members}
            view={view.register}
            close={() => setView({ ...view, register: false })} />}
        <div className={style.search}>
          <ThemeProvider theme={theme}>
            <Grid container spacing={2} marginTop="1rem">
              <Grid item xs={12} sm>
                <Autocomplete
                  value={search}
                  disableClearable
                  open={open}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  loading={loadingLeads}
                  loadingText="Carregando..."
                  noOptionsText={'Nenhum Cliente Cadastrado'}
                  sx={{ width: '100%' }}
                  isOptionEqualToValue={(option, value) => option.nome === value}
                  options={optionList}
                  ListboxComponent={ListboxComponent}
                  onInputChange={(event, newInputValue) => {
                    if (newInputValue === '') {
                      setLeads(leadsRef); // Reseta os leads se a entrada for limpa
                      setSearch(''); // Limpa a pesquisa
                      setviewProspection(viewProspection);
                    }
                  }}
                  renderOption={(props, option) => (
                    <ListItem sx={{ display: 'flex', gap: '0.3rem' }} {...props}>
                      {option.nome} <Chip size="small" label={option.cidade || 'Não Informado'} />
                    </ListItem>
                  )} // Exibe o nome e a cidade na lista
                  getOptionLabel={(option) =>
                    option.nome ? option.nome : ""
                  }
                  onChange={handleChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      key={params.id}
                      sx={{ backgroundColor: "#fff" }}
                      label="Pesquisar Cliente"
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={"auto"} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={viewProspection} color="success" onChange={() => setviewProspection(!viewProspection)} name="Clientes sem Orçamento" />
                    }
                    label="Todos Clientes" />
                  <FormControlLabel
                    control={
                      <Checkbox checked={viewVisit} color="success" onChange={() => setviewVisit(!viewVisit)} name="Com Relatório Téc." />
                    }
                    label="Com Relatório Téc." />
                  {(userRef?.cargo === 'Vendedor(a)' || userRef?.cargo === 'Administrador') ?
                    <>
                      {userRef.local === 'Empresa' &&
                        <Button
                          disableElevation
                          sx={{ textTransform: 'capitalize' }}
                          variant='contained'
                          color="secondary"
                          startIcon={<AddLocationAltIcon />}
                          onClick={() => setView({ ...view, register: true })}>
                          Registrar Visita
                        </Button>
                      }
                      {userRef?.local === 'Rua' ?
                        <Button
                          disableElevation
                          sx={{ textTransform: 'capitalize' }}
                          variant='contained'
                          color='success'
                          startIcon={<AddLocationAltIcon />}
                          onClick={() => setView({ ...view, register: true })}>
                          Registrar Visita
                        </Button> :
                        <Button
                          disableElevation
                          sx={{ textTransform: 'capitalize' }}
                          variant='contained'
                          color='success'
                          startIcon={<PersonAddIcon />}
                          onClick={() => setView({ ...view, add: true })}>
                          Novo Cliente
                        </Button>
                      }
                    </> : null
                  }
                </Box>
              </Grid>
            </Grid>
          </ThemeProvider>
        </div>
        <TableContainer className={style.table_sheets} component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              {userRef?.cargo === 'Representante' ?
                <TableRow>
                  <TableCell align="center">Nome</TableCell>
                  <TableCell align="center">Cidade</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow> :
                <TableRow>
                  <TableCell className={style.desktop} align="center">Status</TableCell>
                  {width > 600 &&
                    <><TableCell className={style.desktop} align="center">Data de Inscrição</TableCell>
                      <TableCell className={style.desktop} align="center">Planilhas</TableCell>
                      <TableCell className={style.desktop} align="center">Orçamentos</TableCell></>

                  }
                  <TableCell align="center">Nome</TableCell>
                  {/* {width > 600 &&
                    <TableCell className={style.desktop} align="center">Empresa</TableCell>
                  } */}
                  <TableCell align="center">Cidade</TableCell>
                  <TableCell className={style.desktop} align="center">Telefone</TableCell>
                  {userRef?.cargo === 'Vendedor(a)' && !userRef?.time && userRef.nome !== 'Pós-Venda' ?
                    null :
                    <>
                      <TableCell className={style.desktop} align="center">Responsável</TableCell>
                      <TableCell className={style.desktop} align="center">Assistente</TableCell>
                    </>
                  }
                  {/* <TableCell align="center"></TableCell> */}
                </TableRow>
              }
            </TableHead>
            <TableBody>
              {leads.length > 0 ? leads.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, index) => (
                <NewTableRow
                  hover
                  key={index}
                  onClick={() => goCustomer(`${data.id}`)}
                  sx={data?.status?.valor === 'ganho' && { backgroundColor: "#e8f6ef" }}
                >
                  {userRef?.cargo === 'Representante' ?
                    <>
                      <TableCell align="center">{data.nome}</TableCell>
                      <TableCell align="center">{data.cidade}</TableCell></> :
                    <>
                      <TableCell className={style.desktop} align="center">
                        {/* <Chip
                          {...chipProps[data.status?.valor || 'aberto']}
                          variant='outlined'
                          id="chip-status" /> */}
                        <Status data={data} getMember={getMember} />
                      </TableCell>
                      {width > 600 &&
                        <>
                          <TableCell className={style.desktop} align="center">{moment(data.createAt.toDate()).format("DD/MM/YYYY")}</TableCell>
                          <TableCell align="center"><b>{data.planilha?.length || 0}</b></TableCell>
                          <TableCell align="center"><b>{data.proposta?.length || 0}</b></TableCell>
                        </>
                      }
                      <TableCell align="center">{formatTextLink(data?.nome || '', data.id)}</TableCell>
                      {/* {width > 600 &&
                        <TableCell className={style.desktop} align="center">{formatText(data?.empresa || '', data.id)}</TableCell>
                      } */}
                      <TableCell align="center">{formatText(data.cidade)}</TableCell>
                      <TableCell className={style.desktop} align="center">{formatarTelefone(String(data.telefone ?? 'Não Informado'))}</TableCell>
                      {userRef?.cargo === 'Vendedor(a)' && !userRef?.time && userRef.nome !== 'Pós-Venda' ?
                        null :
                        <><TableCell
                          sx={{ width: '145px' }}
                          align="center" >
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.3rem', width: '100%', padding: '0.2rem', backgroundColor: getMemberColor(data.consultora_uid), borderRadius: '10px' }}>
                            <img src={getPhotoMember(data.consultora_uid)} alt={data.consultora} style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                            <p style={{ color: "#fff" }}>{data.consultora}</p>
                          </Box>
                        </TableCell>
                          <TableCell sx={{ width: '145px' }} align="center">
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.3rem', width: '100%', padding: '0.2rem', backgroundColor: getMemberColor(data.assistente), borderRadius: '10px' }}>
                              <img src={getPhotoMember(data.assistente)} alt={data.assistente} style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                              <p style={{ color: getMember(data.assistente) === 'Nenhum' ? "#000" : "#fff" }}>{getMember(data.assistente)}</p>
                            </Box>
                          </TableCell></>
                      }
                    </>
                  }
                </NewTableRow>
              )) :
                <TableRow>
                  <TableCell align="center" sx={{ fontSize: '17px' }} colSpan={9}>
                    <p style={{ padding: '0.5rem 0' }}>Nenhum Cliente Cadastrado</p>
                  </TableCell>
                </TableRow>}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            labelRowsPerPage="Clientes por página"
            component="div"
            count={leads ? leads.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
      <Snackbar open={check} autoHideDuration={6000}>
        <Alert severity="error" sx={{ width: '100%' }}>
          Você está sem conexão. Verifique a sua conexão com a internet.
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Customers;
