import { Box, Dialog, DialogContent, DialogTitle, IconButton, styled, Tab, Tabs, useMediaQuery } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form';

import CloseIcon from '@mui/icons-material/Close';
import { BsPersonFillAdd } from "react-icons/bs";
import { MdOutlineEdit } from "react-icons/md";

import { theme } from '../../../data/theme';
import Swal from 'sweetalert2';
import { doc, updateDoc } from 'firebase/firestore';
import { dataBase } from '../../../firebase/database';
import moment from 'moment';
import { checkCompareObj } from '../../../functions/checkCompareObj';

import { CustomTabPanel } from '../../../components/CustomTabPanel';
import InfoForm from './InfoForm';
import removeNullProperties from '../../../functions/removeNullProperties';
import { toast } from '../../../components/Toast';
import Loading from "../../../components/Loading";
import { useRegisterInfo } from '../../../contexts/RegisterInfoContext';
import { deleteFilesOnFirebase } from '../../../functions/deleteFilesOnFirebase';
import useUploadDocuments from '../../../hooks/useUploadDocuments';
const InfoCustomer = ({ open, close, customer, checkUser, customerRef, userRef }) => {
  // const [changeData, setChangeData] = useState(false);
  const [tabsValue, setTabsValue] = useState(0);
  const [infoExtra, setInfoExtra] = useState([]);
  // const [address, setAddress] = useState({});
  const { address, setAddress } = useRegisterInfo();
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState([]);
  const { uploadDocuments } = useUploadDocuments();

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const methods = useForm({
    defaultValues: {
      documentos: [],
      infoExtra: [{ documentos: [] }],
    },
  });

  useEffect(() => {
    if (customer && open) {
      setAddress(customer?.endereco ? customer.endereco : {});
      const newExtraInfo = customer?.dados_secundario?.length > 0 ? customer?.dados_secundario : [];
      const newCustomerRef = [{
        aba: customer?.aba || 'Principal',
        nome: customer?.nome,
        cidade: customer?.cidade,
        doc: customer?.doc,
        renda: customer?.renda,
        empresa: customer?.empresa,
        data_nascimento: customer?.data_nascimento,
        telefone: typeof customer?.telefone === 'number' ? customer?.telefone : customer?.telefone?.replace(/\D/g, ''),
        email: customer?.email,
        cep: customer?.cep,
        endereco: customer?.endereco,
        numero: customer?.numero,
        bairro: customer?.bairro,
        complemento: customer?.complemento,
        documentos: customer?.documentos
      }]

      const newDocuments = customer?.documentos ? [customer?.documentos] : []
      const newDocumentsExtra = newExtraInfo?.length > 0 
      ? newExtraInfo.map((data) => data.documentos || []) 
      : [];

      newDocuments.push(...newDocumentsExtra);
      newCustomerRef.push(...newExtraInfo);
      
      console.log('newDocumentsExtra', newDocumentsExtra);
      console.log('NOVO CUSTOMER', newDocuments);

      setDocuments(newDocuments);
      setInfoExtra(removeNullProperties(newCustomerRef));
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, open]);

  console.log('INFOEXTRA', infoExtra)

  const onClose = () => {
    close();
    setTimeout(() => {
      setTabsValue(0);
      setAddress(null);
    }, 300);
  }

  const checkCustomer = () => {
    return checkCompareObj({ newObj: infoExtra[0], oldObj: customerRef, array: ['aba', 'renda', 'cep', 'cidade', 'nome', 'telefone', 'data_nascimento', 'doc', 'email', 'empresa', 'complemento', 'bairro', 'documentos']})
  };

  const StyledTab = styled(Tab)(() => ({
    textTransform: 'capitalize',
    borderRadius: '5px 5px 0 0',
    fontSize: '15px',
    '&.Mui-selected': {
      backgroundColor: "#fff",
      color: "#000",
    },
  }));

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });

  const onSubmit = useCallback(async (values, ind) => {
      try {

      Swal.fire({
        title: 'Atenção',
        html: `Você deseja confirmar a alteração dos dados?`,
        icon: "warning",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (result) => {
        if (result.isConfirmed) {
          // setLoading(true);
          const documentMain = await Promise.all(documents.map((data) => uploadDocuments(customer.id, data, '', 'Clientes')));
          console.log(address, documentMain);

          // Atualiza o cliente com propriedades filtradas e removendo valores nulos
          const valuesUpdate = ind === 0 ? values : infoExtra[0];
          const updatedCustomer = removeNullProperties({
            ...valuesUpdate,
            endereco: address,
            documentos: documentMain[0], // Atribui o primeiro item de documentMain como esperado
          });

          // Atualiza as informações extras com a lista de documentos atualizada
          const updatedInfoExtra = infoExtra
            .filter((_, index) => index !== 0) // Remove o item no índice 0
            .map((item, index) => {
              const updatedItem = index === (ind - 1)
                ? removeNullProperties({ ...values, documentos: documentMain[index + 1] })
                : removeNullProperties({ ...item, documentos: documentMain[index + 1] });
              return updatedItem;
            })

          // Combina os dados do cliente atualizado com as informações secundárias
          const newUpdatedCustomer = { ...updatedCustomer, dados_secundario: updatedInfoExtra };

          const documentsMainOnFirebase = customer?.documentos || [];
          const documentsSecondaryOnFirebase = customer?.dados_secundario?.length > 0 ? customer?.dados_secundario.map((data) => data.documentos || null) : [];
          const allDocumentsOnFirebase = [documentsMainOnFirebase, ...documentsSecondaryOnFirebase ];
          console.log(documentsSecondaryOnFirebase)
          await deleteFilesOnFirebase(allDocumentsOnFirebase.flat(), documents);

          await updateDoc(doc(dataBase, 'Clientes', customer.id), {
            ...newUpdatedCustomer,
            updateAt: new Date(),
            historico: [...customer.historico, {
              data: moment().format('DD/MM/YYYY - HH:mm'),
              evento: 'Cliente Alterado',
              text: `Os seguintes dados cadastrais foram alterados: ${checkCustomer()}.`,
              usuario_id: userRef?.id,
              usuario_nome: userRef?.nome
            }]

          })

          setLoading(false);
          await Swal.fire({
            title: 'Cliente',
            html: `Os dados foram alterados com sucesso.`,
            icon: "success",
            showCloseButton: true,
            confirmButtonColor: "#111",
            confirmButtonText: "Ok",
          })
        }
      })
    } catch (error) {
      console.log('Erro: ' + error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoExtra, address, documents])

  const handleInfo = async (type, nameRef, ind) => {
    const isAdd = type === 'Add';
    const { value: name } = await Swal.fire({
      title: 'Nomear Aba',
      html: `Informe o <b>nome</b> da aba para identificar os dados a serem preenchidos.`,
      showCancelButton: true,
      showCloseButton: true,
      inputValue: nameRef,
      confirmButtonColor: "#0eb05f",
      confirmButtonText: isAdd ? "Adicionar" : "Alterar",
      cancelButtonText: "Fechar",
      input: "text",
      inputAutoFocus: true,
      didOpen: () => {
        const swalContainer = document.querySelector('.swal2-container');
        const modalBody = document.querySelector('.MuiDialog-container');
        if (swalContainer && modalBody) {
          modalBody.appendChild(swalContainer);
        }
      },
      inputValidator: (value) => {
        if (!value) {
          return 'Informe o nome da aba'
        }
      },
    })

    if (name) {
      let infoList = [...infoExtra];
      if (isAdd) {
        const addNewInfo = {
          aba: name
        }
        infoList.push(addNewInfo);
      } else {
        infoList = infoList.map((info, idx) =>
          idx === ind ? { ...info, aba: name } : info
        );
      }
      setInfoExtra(infoList);
      toast({ icon: 'success', text: `Aba ${isAdd ? 'criada' : 'alterada'} com sucesso` })
    }
  }

  const handleTabChange = useCallback(
    (event, newValue) => {
      if (tabsValue !== newValue) {
        const form = document.querySelector(`#form-Extra-${tabsValue}`);
        form?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
        setTabsValue(newValue);
      }
    },
    [tabsValue]
  );

  const saveTabData = useCallback((data, type, send, ind) => {
    console.log(data)

    // if (type === 'Principal') {
    //     setNewCustomer(prevCustomer => ({
    //         ...prevCustomer,
    //         documentos: [...(prevCustomer.documentos || [])],
    //         ...data,
    //     }));
    // } else {
    // }

    setInfoExtra(prevInfo =>
      prevInfo.map((info, idx) =>
        idx === ind ? {
          ...info,
          ...data
        } : info
      )
    );

    if (send) {
      onSubmit(data, ind);
    }

  }, [onSubmit]);

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      maxWidth="md">
      {loading && 
        <Box sx={{ width: '900px', height: infoExtra.length === 0 && '453px' }}>
          <Loading view={loading}/>
        </Box>
      }
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      ><CloseIcon /></IconButton>
      <DialogTitle align='center' marginBottom={'0.3rem'}>Dados Cadastrais</DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%', marginBottom: '1rem' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabsValue}
              onChange={handleTabChange}
              aria-label="Informações do Cliente"
            >
              {infoExtra.map((data, index) => (
                <StyledTab
                  key={index}
                  label={<Box sx={{ display: "flex", alignItems: "center" }}>
                    <span>{data?.aba}</span>
                    {index !== 0 && (
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation(); // Impede a mudança de aba ao clicar no ícone
                          handleInfo('Edit', data.aba, index)
                        }}
                        size="small"
                        sx={{ ml: 1 }}
                      >
                        <MdOutlineEdit size="1rem" />
                      </IconButton>
                    )
                    }
                  </Box>}
                  {...a11yProps(index)} />
              ))}
              <IconButton sx={{ padding: '0.2rem', borderRadius: '5px' }} onClick={() => handleInfo('Add', '')}>
                <BsPersonFillAdd size='1.3rem' />
              </IconButton>
            </Tabs>
          </Box>
        </Box>

        {/* <CustomTabPanel style={{ backgroundColor: '#fff', boxShadow: '0px 2px 10px 0px rgba(25, 93, 194, 0.07)' }} value={tabsValue} index={0}>
        <FormProvider {...methods}>
          <InfoForm 
            initialValues={newCustomer} 
            tabsValue={tabsValue}
            saveTabData={saveTabData}
            onSubmit={onSubmit} 
            type="Principal" 
            formId="form-Principal-0" 
            // sendFile={sendFile}
            deleteFile={deleteFile} 
            close={closeChangeData}
            changeData={true}
            customer={customer}
            setCustomer={setCustomer}
            checkUser={checkUser} />
          </FormProvider>
        </CustomTabPanel> */}
        {infoExtra.map((data, index) => (
          <CustomTabPanel style={{ backgroundColor: '#fff', boxShadow: '0px 2px 10px 0px rgba(25, 93, 194, 0.07)' }} value={tabsValue} index={index}>
            <FormProvider {...methods}>
              <InfoForm
                initialValues={data}
                // tabsValue={tabsValue} 
                saveTabData={saveTabData}
                customerId={customer.id}
                // address={address}
                // setAddress={setAddress} 
                type="Extra"
                formId={`form-Extra-${index}`}
                ind={index}
                documents={documents}
                setDocuments={setDocuments}
                // deleteFile={deleteFile} 
                close={onClose}
                changeData={true}
                checkUser={checkUser} />
            </FormProvider>
          </CustomTabPanel>
        ))}


        {/* <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <TextField
                autoFocus
                fullWidth
                margin="dense"
                label="Nome Completo"
                type="text"
                required
                disabled={!changeData}
                value={customer.nome ? customer.nome : ''}
                onChange={(e) => setCustomer({ ...customer, nome: e.target.value })}
                variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                autoFocus
                fullWidth
                margin="dense"
                label="CNPJ/CPF"
                type="text"
                inputProps={{
                  minLength: 14
                }}
                disabled={!changeData}
                value={customer.doc ? customer.doc : ''}
                onChange={(e) => setCustomer({ ...customer, doc: mask(e.target.value) })}
                variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                autoFocus
                fullWidth
                margin="dense"
                label="Email"
                type="text"
                disabled={!changeData}
                value={customer.email ? customer.email : ''}
                onChange={(e) => setCustomer({ ...customer, email: e.target.value })}
                variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                autoFocus
                fullWidth
                label="Empresa"
                type="text"
                disabled={!changeData}
                value={customer.empresa ? customer.empresa : ''}
                onChange={(e) => setCustomer({ ...customer, empresa: e.target.value })}
                variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <PatternFormat
                value={customer.telefone ? customer.telefone : ''}
                onChange={(e) => setCustomer({ ...customer, telefone: e.target.value })}
                format="(##) ##### ####"
                mask="_"
                fullWidth
                customInput={TextField}
                placeholder="(00) 00000 0000"
                label="Telefone"
                minLength={9}
                variant="outlined"
                disabled={!changeData}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <PatternFormat
                value={customer?.data_nascimento}
                onChange={(e) => setCustomer({ ...customer, data_nascimento: e.target.value })}
                format="##/##/####"
                mask="_"
                fullWidth
                customInput={TextField}
                placeholder="00/00/0000"
                label="Data de Nascimento"
                minLength={10}
                variant="outlined"
                disabled={!changeData}
              />
            </Grid>
            <Grid item xs={12} sm={3} marginTop={1}>
              <PatternFormat
                value={customer.cep}
                onChange={(e) => setCustomer({ ...customer, cep: e.target.value })}
                format="#####-###"
                mask="_"
                fullWidth
                customInput={TextField}
                placeholder="00000-000"
                label="CEP"
                minLength={9}
                variant="outlined"
                disabled={!changeData}
              />
            </Grid>
            <Grid item xs={12} sm={9}>
              <AutocompleteMaps
                KeyMaps={KeyMaps}
                setCustomer={setCustomer}
                check={!changeData}
                address={customer.endereco?.endereco ?? ''}
                title='Endereço com Número' />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                autoFocus
                fullWidth
                label="Complemento"
                type="text"
                disabled={!changeData}
                value={customer.complemento ? customer.complemento : ''}
                onChange={(e) => setCustomer({ ...customer, complemento: e.target.value })}
                variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                autoFocus
                fullWidth
                label="Bairro"
                type="text"
                disabled={!changeData}
                value={customer.bairro ? customer.bairro : ''}
                onChange={(e) => setCustomer({ ...customer, bairro: e.target.value })}
                variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={3}>
              <NewTooltip title={'Só atendemos o estado de São Paulo'} placement={'top'}>
                <TextField
                  autoFocus
                  fullWidth
                  label="Estado"
                  type="text"
                  disabled
                  value={'SP'}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <LockIcon />
                      </InputAdornment>
                  }}
                  variant="outlined" />
              </NewTooltip>
            </Grid>
            <Grid item xs={12} sm={3}>
              <NewTooltip title={'A Cidade é setada automaticamente através do Endereço informado'} placement={'top'}>
                <TextField
                  autoFocus
                  fullWidth
                  label="Cidade"
                  type="text"
                  disabled
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <LockIcon />
                      </InputAdornment>
                  }}
                  value={customer.cidade ? customer.cidade : ''}
                  onChange={(e) => setCustomer({ ...customer, cidade: e.target.value })}
                  variant="outlined" />
              </NewTooltip>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: "#faf8f4",
                borderRadius: '5px', justifyContent: 'center', padding: '0.5rem', borderLeft: '5px solid #7b705b', marginTop: '0.7rem'
              }}>
                <Typography variant='h6'>Documentos</Typography>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.3rem', margin: '1rem 0' }}>
                  {documents.length > 0 ?
                    documents.map((file, index) => (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        <IoCloudDoneOutline size="1.6rem" color='green' />
                        <DocsItems>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "0.5rem",
                              alignItems: "center",
                            }}
                            onClick={() => file.url ? window.open(file.url, '_blank') : viewDoc(file)}
                          >
                            <InsertDriveFileOutlinedIcon sx={{ fill: "#484848" }} />
                            <Link to="" style={{ color: "#000" }}>
                              {file.url ? `${file?.ref?.split(`Clientes/${customer.id}/`)[1]} [Enviado]` : file?.complete?.name}
                            </Link>
                          </Box>
                          <NewTooltip
                            id="Clickable"
                            title="Excluir"
                            placement="top"
                          >
                            <IconButton
                              onClick={() => deleteFile(index, documents)}
                              color="error"
                            >
                              <DeleteRounded />
                            </IconButton>
                          </NewTooltip>
                        </DocsItems>
                      </Box>
                    )) : <Typography sx={{ fontStyle: "italic" }}>Nenhum documento encontrado</Typography>
                  }
                </Box>
                <Button
                  sx={{ textTransform: "capitalize", marginBottom: '0.5rem' }}
                  variant="contained"
                  component="label"
                  disabled={!changeData}
                  onChange={sendFile}
                  startIcon={<FaFileUpload />}
                  disableElevation
                >
                  Adicionar Arquivo
                  <VisuallyHiddenInput
                    multiple={true}
                    type="file"
                    accept="image/png,image/jpeg, application/pdf"
                  />
                </Button>
              </Box>
            </Grid>
          </Grid> */}

      </DialogContent>
    </Dialog>
  )
}

export default InfoCustomer