import React, { createContext, useContext, useState } from 'react';

// Criando o contexto
const AcceptImprovementsContext = createContext();

// Componente provedor que envolve toda a aplicação e fornece os dados do cadastro
export const AcceptProvider = ({ children }) => {
  const [acceptImprovements, setAcceptImprovements] = useState(null);

  return (
    <AcceptImprovementsContext.Provider value={{ acceptImprovements, setAcceptImprovements }}>
      {children}
    </AcceptImprovementsContext.Provider>
  );
};

// Hook personalizado para acessar o contexto
export const useAcceptImprovements = () => {
  const context = useContext(AcceptImprovementsContext);
  if (!context) {
    throw new Error('useAcceptImprovements deve ser usado dentro de um AcceptProvider');
  }
  return context;
};
