import React, { createContext, useState, useEffect, useContext } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { dataBase } from '../firebase/database';

// Criando o contexto
const QueueFirebaseContext = createContext();

export const FirebaseProvider = ({ children }) => {
  const [dataQueue, setDataQueue] = useState([]);
  const [dataCustomers, setDataCustomers] = useState([]);
  const [dataActivitys, setDataActivitys] = useState([]);

  useEffect(() => {
      const collectionRef = collection(dataBase, 'Fila');
      const collectionCustomerRef = collection(dataBase, 'Clientes');
      const collectionActivityRef = collection(dataBase, 'Atividades');
  
      // Função para processar a fila
      const processQueueSnapshot = (snapshot) => {
        const newData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        setDataQueue(newData);
      };
  
      // Função para processar os clientes
      const processCustomerSnapshot = (snapshot) => {
        const newData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        setDataCustomers(newData);
      };

      const processActivitySnapshot = (snapshot) => {
        const newData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        setDataActivitys(newData);
      };
  
      // Criando os listeners
      const unsubscribeQueue = onSnapshot(collectionRef, processQueueSnapshot);
      const unsubscribeCustomers = onSnapshot(collectionCustomerRef, processCustomerSnapshot);
      const unsubscribeActivitys = onSnapshot(collectionActivityRef, processActivitySnapshot);
  
      // Limpeza: garantir que ambos os listeners sejam desativados
      return () => {
        unsubscribeQueue();
        unsubscribeCustomers();
        unsubscribeActivitys();
      };
    }, []);

  return (
    <QueueFirebaseContext.Provider value={{ dataQueue, dataCustomers, dataActivitys }}>
      {children}
    </QueueFirebaseContext.Provider>
  );
};

// Hook personalizado para consumir o contexto
export const useFirebaseData = () => {
  return useContext(QueueFirebaseContext);
};
