import moment from "moment";
import { useCallback } from "react";

export const useGracePeriodMonth = () => {
  const getMonthAfterGracePeriod = useCallback((date) => {
    const currentDate = moment(new Date());
    const futureDate = currentDate.add(date || 90, "days"); // Adiciona 90 dias à data atual
    console.log(futureDate);
    return futureDate.format("MMMM / YYYY"); // Retorna o nome do mês
  }, []);

  return { getMonthAfterGracePeriod };
};
