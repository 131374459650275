import { Alert, AlertTitle, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, styled, TextField, ThemeProvider, Typography, useMediaQuery } from '@mui/material'
import React, { memo, useCallback, useState } from 'react'

import styles from '../styles.module.scss';

import ButtonCB from '../../../components/Button';
import CloseIcon from '@mui/icons-material/Close';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DeleteRounded from "@mui/icons-material/DeleteRounded";
import { FaFileUpload } from "react-icons/fa";
import InfoIcon from '@mui/icons-material/Info';

import SolarMarket from '../../../images/system/icons/SolarMarket.png';
import ClickSign from '../../../images/system/icons/ClickSign.png';

import { PatternFormat } from 'react-number-format';
import Swal from 'sweetalert2';
import axios from 'axios';
import { toast } from '../../../components/Toast';
import { doc, updateDoc } from 'firebase/firestore';

import Loading from '../../../components/Loading';
import { dataBase } from '../../../firebase/database';
import moment from 'moment';
import formatCurrency from '../../../functions/formatCurrency';
import { theme } from '../../../data/theme';
import { VisuallyHiddenInput } from '../../../components/InputFile';
import useViewDocBox from '../../../hooks/useViewDocBox';
import { Link } from 'react-router-dom';
import NewTooltip from '../../../components/Tooltip';
import useUploadPhotos from '../../../hooks/useUploadPhotos';
import { showBoxMessage } from '../../../functions/showBoxMessage';
import { useAcceptImprovements } from '../../../contexts/AcceptImprovementsContext';
// import { useActivity } from '../../../hooks/useActivity';

const ModalContract = ({ open, close, customer, system, index, assistant, setRefresh, paymentData, visit, proposal }) => {
    const [errors, setErrors] = useState({});
    const [documents, setDocuments] = useState([]);
    const { viewDoc } = useViewDocBox();
    // const { addActivity } = useActivity();
    const { acceptImprovements } = useAcceptImprovements();
    const { uploadImages } = useUploadPhotos();
    const [contract, setContract] = useState({
        nome: '',
        doc: '',
        data: '',
        email: ''
    });
    const [loading, setLoading] = useState(false);
    const handleSimulation = (e) => {
        const { name, value } = e.target;
        setContract({ ...contract, [name]: value });
    }

    const hasTrueProperty = (obj) => {
        return Object.values(obj).some(value => value === true);
    }
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const DocsItems = styled(Box)(({ color }) => ({
        padding: "0.5rem 1rem",
        borderRadius: "5px",
        backgroundColor: color || "#fff",
        display: "flex",
        justifyContent: "space-between",
        border: "1px solid rgba(0, 0, 0, 0.12)",
      }));

    const closeBox = () => {
        close();
        setTimeout(() => {
            setContract({
                nome: '',
                doc: '',
                data: '',
                email: ''
            });
            setDocuments([]);
            setErrors({});
            setLoading(false);
        }, 500);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        // await addActivity({
        //    cliente_id: customer.id,
        //    etapa: "Contrato",
        //    createAt: new Date(),
        //    orcamento_id: `${customer.id}-${index}`,
        //    proposta_id: proposal.id,
        //    categoria: 'Financeiro',
        //    responsavel: "NFgBFFbXpQUSd7futEkrarBznig2",
        //    concluido: false

        // })
        console.log(paymentData);
        if (hasTrueProperty(errors)) {
            return null;
        }
        if (documents.length === 0) {
            return await Swal.fire({
                title: "Atenção",
                html: `Para solicitar o contrato, é necessário enviar um documento com foto <b>(CNH ou RG)</b>.`,
                icon: "warning",
                showConfirmButton: true,
                showCloseButton: true,
                confirmButtonColor: "#111",
              });
        }
        const result = await Swal.fire({
            title: 'Atenção',
            html: `Os dados estão corretos?`,
            icon: "warning",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0eb05f",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
        });

        if (result.isConfirmed) {
            try {
                setLoading(true);
                const resultDocuments = await uploadImages(documents, 'Contrato', 0, customer.id, "Clientes");
                const files = resultDocuments ? resultDocuments.map((obj) => ({
                    url: obj.downloadURL,
                    ref: obj.fullPath,
                  })) : [];

                const newContract = {
                   ...contract,
                    documentos: files,
                };

                let newSheets = customer.planilha;
                newSheets.splice(index, 1, {
                    ...system,
                    reserva: true,
                    melhorias: acceptImprovements || false,
                    contrato: newContract,
                    pagamento: paymentData,
                    historico: [
                        ...system.historico,
                        {
                            data: moment().format('DD/MM/YYYY HH:mm'),
                            text: `Orçamento: O cliente aceitou ✅ a reserva dos materiais e optou pelo ${paymentData.tipo}.`,
                        }
                    ]
                })

                const proposals = [...customer.proposta];
                const proposalRef = proposals.find(data => data.id === proposal.id);

                proposalRef.status = paymentData?.tipo?.includes("Financiamento") && acceptImprovements ? 'Pré-Aceito' : 'Aceito';
                proposalRef.contrato = newContract;

                console.log(proposals);
                console.log(newSheets);

                await updateDoc(doc(dataBase, 'Clientes', customer.id), {
                    proposta: proposals,
                    planilha: newSheets,
                    historico: [...customer.historico, {
                        data: moment().format('DD/MM/YYYY - HH:mm'),
                        evento: 'Orçamento',
                        text: `O cliente solicitou o contrato e optou pelo metódo de pagamento <b>${paymentData.tipo}</b>.`,
                        usuario_id: '0',
                        usuario_nome: 'Robô'
                    }]
                });

                await axios.post('https://n8n.corpbrasil.cloud/webhook/a68ee6cd-2d99-4015-85bd-f8cdce709cbc', {
                    nome: customer.nome,
                    cidade: customer.cidade,
                    telefone: customer.telefone,
                    nome_assistente: assistant.nome,
                    telefone_assistente: assistant.telefone,
                    kit: system.kit,
                    consultora: customer.consultora,
                    assistente: assistant.nome,
                    reserva: '✅ Aceito',
                    melhorias: acceptImprovements || paymentData.melhorias ? 'Incluso' : 'Não Incluso',
                    melhorias_custos: visit?.rede_eletrica?.melhorias?.custos || 'Não',
                    chave: 'Contrato',
                    idFull: `${customer.id}-${index}`,
                    id: customer.id,
                    pagamento: paymentData,
                    contrato: contract
                });

                setLoading(false);
                await Swal.fire({
                    title: 'Parabéns!',
                    html: `O <b>Contrato</b> foi solicitado com sucesso! <br /> Em breve entraremos em contato com você.`,
                    icon: "success",
                    showConfirmButton: true,
                    showCloseButton: true,
                    confirmButtonColor: "#111",
                })
                setRefresh(true);

            } catch (error) {
                setLoading(false);
                console.log(error)
                toast({ icon: 'error', text: 'Ocorreu um erro ao enviar os dados. Tente novamente mais tarde.' })
            }
        }
    }

    const checkInput = (type) => {
        const { nome, doc, data, email } = contract;
        switch (type) {
            case 'nome':
                return !isFullName(nome);
            case 'doc':
                return doc.includes("_") || !validaCPF(doc);
            case 'data':
                return data.includes("_") && data.length > 2;
            case 'email':
                return !validaEmail(email);
            default:
                return false;
        }
    }

    const sendFile = async (e) => {
        const ref = e.target.files;
        const files = [];
        Array.from(ref, (image) =>
          files.push({ file: URL.createObjectURL(image), complete: image })
        );
          if (documents) {
            setDocuments([...documents, ...files]);
          } else {
            setDocuments(files);
          }
    }

    const validaEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isFullName = (name) => {
        return (
            typeof name === 'string' &&
            name.trim().split(' ').length >= 2 &&
            name.trim().split(' ').every(part => part.length >= 2)
        );
    }

    const onBlurCheck = (e) => {
        const { name, value } = e.target;
        if (value) {
            setErrors({ ...errors, [name]: checkInput(name) });
        } else {
            setErrors({ ...errors, [name]: null });
        }
    }

    const validaCPF = (cpf) => {
        cpf = cpf.replace(/[^\d]+/g, '');
        if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;
        let soma = 0;
        for (let i = 0; i < 9; i++) soma += parseInt(cpf.charAt(i)) * (10 - i);
        let resto = (soma * 10) % 11;
        if (resto === 10 || resto === 11) resto = 0;
        if (resto !== parseInt(cpf.charAt(9))) return false;
        soma = 0;
        for (let i = 0; i < 10; i++) soma += parseInt(cpf.charAt(i)) * (11 - i);
        resto = (soma * 10) % 11;
        if (resto === 10 || resto === 11) resto = 0;
        return resto === parseInt(cpf.charAt(10));
    }

    // const validaCNPJ = (cnpj) => {
    //     cnpj = cnpj.replace(/[^\d]+/g, '');
    //     if (cnpj.length !== 14) return false;
    //     const validacao = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    //     const cnpjNumeros = cnpj.substr(0, 12).split('').map(num => parseInt(num));
    //     const digito1 = cnpjNumeros.reduce((soma, num, index) => soma + (num * validacao[1 + index]), 0) % 11;
    //     if (cnpj.charAt(12) !== ((digito1 < 2 ? 0 : 11 - digito1).toString())) return false;
    //     const cnpjNumeros2 = cnpj.substr(0, 13).split('').map(num => parseInt(num));
    //     const digito2 = cnpjNumeros2.reduce((soma, num, index) => soma + (num * validacao[index]), 0) % 11;
    //     return cnpj.charAt(13) === ((digito2 < 2 ? 0 : 11 - digito2).toString());
    // }

    const deleteFile = (index, file) => {
        if (index >= 0 && index < file.length) {
          const newPhotos = [...file];
          newPhotos.splice(index, 1);

          setDocuments(newPhotos);
        }
    };

    const valueImprovementsInstallment = useCallback((data) => {
        const { tipo, melhorias } = data;
        console.log(data)

        if (tipo.includes("Financiamento") && (acceptImprovements || paymentData?.aviso?.length > 0) && !melhorias) {
            return <Alert severity='warning' icon={false} sx={{ margin: '0.3rem 0' }}> 
                    Esta simulação não inclui as melhorias elétricas, em breve você receberá uma nova simulação.
                <IconButton 
                    sx={{ padding: '0 0.2rem' }} 
                    size='small' 
                    onClick={() => showBoxMessage({ 
                        title: 'Atenção', 
                        text: `Esta simulação não inclui o valor das melhorias elétricas adicionadas ao orçamento. Você receberá uma nova simulação atualizada em breve.` 
                    })}>
                    <InfoIcon sx={{ fontSize: '18px' }}/>
                </IconButton>
                </Alert>
        }

        return null;
    },[acceptImprovements, paymentData])

    console.log(paymentData)

    return (
        <Dialog
            className={styles.dialog}
            open={open}
            fullWidth
            maxWidth="md"
            fullScreen={fullScreen}
        >
            <IconButton
                aria-label="close"
                onClick={closeBox}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon sx={{ fill: '#000' }} />
            </IconButton>
            <Loading view={loading} backgroundColor='#ffffff8f' />
            {system?.contrato ?
                <Box sx={{ padding: '2rem 1.5rem', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem', color: "#808080", 'h3': { fontSize: '20px' } }}>
                    <VerifiedRoundedIcon sx={{ fill: "#0FA958", fontSize: '100px' }} />
                    <h3>Sua solicitação de contrato foi enviada com sucesso!</h3>
                    <p>Em breve, você receberá uma notificação no seu WhatsApp informando que seu contrato está pronto para assinatura.</p>
                </Box> :
                <><DialogTitle align='center'>Solicitar Contrato</DialogTitle>
                    <form onSubmit={onSubmit}>
                        <DialogContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '0.6rem', paddingTop: 0 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <img src={SolarMarket} alt={'SolarMarket'} />
                                </Grid>
                                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <img src={ClickSign} alt={'ClickSign'} />
                                </Grid>
                                <Grid item xs={12}>
                                    {paymentData ? // Quando o cliente abre o contrato com um pagamento definido
                                        <Alert sx={{ margin: '0' }}>
                                            <AlertTitle>Resumo</AlertTitle>
                                            <Grid container>
                                                <Grid item xs={12} md={6}>
                                                    <p><b>Pagamento Escolhido:</b> {paymentData?.tipo}</p>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <p><b>Entrada:</b> {formatCurrency(paymentData.entrada)}</p>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <p><b>Escolha:</b> {paymentData?.info}</p>
                                                    {valueImprovementsInstallment(paymentData)}
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <p><b>Valor do Projeto:</b> {formatCurrency(system?.orcamento?.valor_parcelado)}</p>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <p><b>Melhorias Elétricas:</b>
                                                        {(acceptImprovements || visit?.rede_eletrica?.melhorias?.custos === 'Não' || paymentData?.aviso?.length > 0 || paymentData.melhorias) ? ` ${formatCurrency(visit?.rede_eletrica?.melhorias?.valor_total)} Incluido ✅` : ' Não Incluido ❌'}
                                                    </p></Grid>
                                                    {acceptImprovements}
                                                <Grid item xs={12}>
                                                    <Box sx={{ backgroundColor: "#d7efd7", padding: '0.3rem', borderRadius: '3px', fontSize: '1.2rem', marginTop: '0.4rem', textAlign: 'center' }}>
                                                        <p><b>Valor Final:</b> {formatCurrency(paymentData?.valor_total)}</p>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Alert> : null
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <DialogContentText align='center'>Solicite o seu contrato, preencha os dados abaixo:</DialogContentText>
                                </Grid>
                                {/* <Grid item xs={12} sx={{ marginTop: '0rem' }}>
                                <FormControl fullWidth>
                                    <InputLabel id="tipo">A Simulação é para uma pessoa:</InputLabel>
                                    <Select
                                        labelId='tipo'
                                        label="A Simulação é para uma pessoa:"
                                        name='tipo'
                                        value={simulation?.tipo || ''}
                                        onChange={handleSimulation}
                                        required>
                                        <MenuItem value="Física">Pessoa Física</MenuItem>
                                        <MenuItem value="Jurídica">Pessoa Jurídica</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid> */}
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="nome"
                                        fullWidth
                                        required
                                        onBlur={onBlurCheck}
                                        error={errors.nome}
                                        placeholder='José Maria da Silva'
                                        label={"Nome Completo"}
                                        value={contract.nome || ''}
                                        helperText={errors.nome && 'O nome não está de acordo'}
                                        onChange={handleSimulation}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <PatternFormat
                                        value={contract.doc || ''}
                                        onChange={handleSimulation}
                                        format={"###.###.###-##"}
                                        mask="_"
                                        name="doc"
                                        required
                                        fullWidth
                                        customInput={TextField}
                                        placeholder={"000.000.000-00"}
                                        label={"CPF"}
                                        minLength={10}
                                        error={errors.doc}
                                        helperText={errors.doc && 'O documento é inválido.'}
                                        onBlur={onBlurCheck}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <PatternFormat
                                        value={contract.data || ''}
                                        onChange={handleSimulation}
                                        format="##/##/####"
                                        mask="_"
                                        name="data"
                                        fullWidth
                                        required
                                        onBlur={onBlurCheck}
                                        error={errors.data}
                                        customInput={TextField}
                                        placeholder="00/00/0000"
                                        helperText={errors.data && 'A data está incompleta'}
                                        label={"Data de Nascimento"}
                                        minLength={10}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="email"
                                        fullWidth
                                        required
                                        onBlur={onBlurCheck}
                                        error={errors.email}
                                        placeholder='jose@gmail.com'
                                        label={"Email"}
                                        value={contract.email || ''}
                                        helperText={errors.email && 'O email não está de acordo'}
                                        onChange={handleSimulation}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box>
                                        <Grid
                                            container
                                            spacing={1}
                                            xs={12}
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                sx={{ display: "flex", justifyContent: "center", flexDirection: 'column', alignItems: 'center' }}
                                            >
                                                <Typography variant="h6">Enviar foto (CNH ou RG)</Typography>
                                                <Typography variant='span' fontSize={'12px'} marginBottom={'0.5rem'}>Máximo até 5 arquivos (Somente Imagem e PDF)</Typography>
                                            </Grid>
                                            {documents.length > 0 ? (
                                                documents?.map((file, index) => (
                                                    <Grid
                                                        key={index}
                                                        item
                                                        xs={12}
                                                        sx={{ display: "flex", justifyContent: "center" }}
                                                    >
                                                        <DocsItems>
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    gap: "0.5rem",
                                                                    alignItems: "center",
                                                                }}
                                                                onClick={() => viewDoc(file)}
                                                            >
                                                                <InsertDriveFileOutlinedIcon sx={{ fill: "#484848" }} />
                                                                <Link to="" style={{ color: "#000" }}>
                                                                    {file?.complete?.name}
                                                                </Link>
                                                            </Box>
                                                            <NewTooltip
                                                                id="Clickable"
                                                                title="Excluir"
                                                                placement="top"
                                                            >
                                                                <IconButton
                                                                    onClick={() => deleteFile(index, documents)}
                                                                    color="error"
                                                                >
                                                                    <DeleteRounded />
                                                                </IconButton>
                                                            </NewTooltip>
                                                        </DocsItems>
                                                    </Grid>
                                                ))
                                            ) : (
                                                <Grid item xs={12} justifyContent={"center"}>
                                                    <Typography textAlign={"center"} color={"#838383"}>
                                                        Nenhum documento encontrado
                                                    </Typography>
                                                </Grid>
                                            )}
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sx={{ display: "flex", justifyContent: "center" }}
                                                >
                                                    <ThemeProvider theme={theme}>
                                                        <ButtonCB
                                                            sx={{ textTransform: "capitalize" }}
                                                            variant="contained"
                                                            component="label"
                                                            background="#209CFF"
                                                            disabled={documents.length > 4}
                                                            onChange={(e) => sendFile(e)}
                                                            startIcon={<FaFileUpload />}
                                                            disableElevation
                                                        >
                                                            Adicionar Foto
                                                            <VisuallyHiddenInput
                                                                type="file"
                                                                accept="image/png,image/jpeg, application/pdf"
                                                            />
                                                        </ButtonCB>
                                                    </ThemeProvider>
                                                </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions sx={{ display: 'flex', justifyContent: 'center', margin: '0rem 0 1rem 0' }}>
                            <ButtonCB fullWidth sx={{ maxWidth: '350px', margin: '0 1rem' }} background='#000000' type='submit'>Solicitar meu Contrato</ButtonCB>
                        </DialogActions>
                    </form></>
            }
        </Dialog>
    )
}

export default memo(ModalContract);