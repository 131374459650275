import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

import { PatternFormat } from "react-number-format";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { theme, themeInput } from "../../../data/theme";
import {
  ThemeProvider,
  DialogContentText,
  InputAdornment,
  Alert,
  ListItemIcon,
  ListItemText,
  Grid,
  Typography,
  useMediaQuery,
  AlertTitle,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import LockIcon from "@mui/icons-material/Lock";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import CloseIcon from "@mui/icons-material/Close";
import { BiSolidOffer } from "react-icons/bi";

import style from "../styles.module.scss";
import { addDoc, collection } from "firebase/firestore";
import { dataBase } from "../../../firebase/database";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { KeyMaps, idPaulo } from "../../../data/Data";
import AutocompleteMaps from "../../../components/AutoCompleteMaps";
import NewTooltip from "../../../components/Tooltip";
import { formatPhoneBR } from "../../../functions/FormatPhoneBR";
import axios from "axios";
import { AutoSizer, List } from "react-virtualized";
import Loading from "../../../components/Loading";
import { toast } from "../../../components/Toast";

const AddCustomer = ({ isLoaded, leads, members, view, close, userRef }) => {
  const navigate = useNavigate();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [responsible, setResponsible] = useState({
    nome: null,
    id: "",
  });
  const [responsibles, setResponsibles] = useState([]);
  const [leadsSheet, setLeadsSheet] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [representative, setRepresentative] = useState({
    nome: "Nenhum",
    id: "0",
    codigo: "Nenhum",
  });
  const filter = createFilterOptions();
  const [representatives, setRepresentatives] = useState([]);
  const [customer, setCustomer] = useState({
    nome: "",
    doc: "",
    email: "",
    empresa: "",
    telefone: "",
    cep: "",
    endereco: {
      endereco: "",
      lat: -23.109715809172528,
      lng: -47.71509284910918,
    },
    cidade: "",
    numero: "",
    condicao_especial: 'Não',
    complemento: "",
    bairro: "",
    estado: "SP",
  });
  const loadingLeads = open && leadsSheet.length === 0;

  const ListboxComponent = React.forwardRef(function ListboxComponent(
    props,
    ref
  ) {
    const { children, role, ...other } = props;
    const itemCount = Array.isArray(children) ? children.length : 0;
    const itemSize = 55;

    return (
      <div ref={ref}>
        <div {...other} style={{ flex: "1 1 auto" }}>
          <AutoSizer disableHeight>
            {({ width }) => (
              <List
                height={200}
                width={width}
                rowHeight={itemSize}
                overscanCount={5}
                rowCount={itemCount}
                rowRenderer={(props) => {
                  return React.cloneElement(children[props.index], {
                    style: props.style,
                  });
                }}
                role={role}
              />
            )}
          </AutoSizer>
        </div>
      </div>
    );
  });

  useEffect(() => {
    if (userRef) {
      setResponsible({
        nome: userRef.nome === "Pós-Venda" ? userRef.nome_ref : userRef.nome,
        id: userRef.id,
        img: userRef.photo?.url,
      });
    }
  }, [userRef, view]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [leadsResult] = await Promise.all([
          axios.get(
            "https://script.google.com/macros/s/AKfycbxad1yCWiFmL9Q2qXIMglIFbH-m9KafsaNoD9UVYrhKgdmdjHpAlbJ-IxeTj-OroUjjsw/exec"
          ),
        ]);

        console.log(leadsResult);

        const leadsData = leadsResult.data.GoogleSheetData.filter(
          (data) => data[0] !== "Data" && data[0].length > 0
        );
        const leadsOptions = leadsData.reduce((uniqueList, currentData) => {
          const telefone = String(currentData[2]).startsWith("55")
            ? String(currentData[2]).slice(2)
            : String(currentData[2]);
          if (!uniqueList.some((item) => item.telefone === telefone)) {
            uniqueList.push({
              nome: currentData[1],
              telefone: telefone,
              cidade_ref: currentData[4],
              consultora_ref: currentData[9],
              assistente: currentData[10],
            });
          }
          return uniqueList;
        }, []);

        setLeadsSheet(leadsOptions);
        console.log(leadsData);
      } catch (error) {
        console.error("Erro ao buscar dados", error);
      }
    };

    fetchData();
  }, []);

  const closeBox = () => {
    close();
    setTimeout(() => {
      setCustomer({
        nome: "",
        doc: "",
        email: "",
        empresa: "",
        telefone: "",
        cep: "",
        endereco: {
          endereco: "",
          lat: -23.109715809172528,
          lng: -47.71509284910918,
        },
        cidade: "",
        numero: "",
        complemento: "",
        bairro: "",
        estado: "SP",
        condicao_especial: 'Não',
      });
      setResponsible({
        nome: userRef?.nome,
        id: userRef?.id,
        img: "",
      });
      setRepresentative({
        nome: "Nenhum",
        id: "0",
        codigo: "Nenhum",
      });
    }, 300);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const unmaskedPhone = customer.telefone.replace(/\D/g, "");

      if (
        unmaskedPhone !== "15000000000" &&
        leads?.some((data) => data.telefone === unmaskedPhone)
      ) {
        return showAlert(
          "Atenção",
          `O Telefone <b>${customer.telefone}</b> já está cadastrado no sistema. <br /><br /> Pesquise o perfil do Cliente através do campo <b>Pesquisar Cliente</b> logo acima ou procure na lista de Clientes.`,
          "warning"
        );
      } else if (unmaskedPhone.length < 11) {
        return showAlert(
          "Atenção",
          `O Número de Telefone está <b>incompleto</b>. Verifique novamente`,
          "warning"
        );
      }

      const result = await Swal.fire({
        title: "Atenção",
        text: `Você deseja cadastrar um novo Cliente?`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#05a937",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });

      if (!result.isConfirmed) return;

      setLoading(true);
      const filteredData = Object.fromEntries(
        Object.entries(customer)
          .map(([key, value]) => {
            if (key === "telefone") {
              // Remove todos os caracteres não numéricos do telefone
              value = value.replace(/\D/g, "");
            }
            return [key, value];
          })
          .filter(([_, value]) => value)
      );
      const filterRepresentative =
        representative.nome !== null ? representative : null;

      const assistantRef = checkSellerMaster() && responsible.id !== userRef?.id ? { id: userRef?.id, nome: userRef?.nome } : customer.assistente?.length > 0 ? members.find((data) => data.id === userRef?.id) : "";

      const createClient = {
        ...filteredData,
        consultora: responsible.nome,
        consultora_uid: responsible.id,
        telefone: unmask(customer.telefone),
        representante: filterRepresentative,
        data: moment().format("DD MMM YYYY - HH:mm"),
        dataRef: moment().format("DD MMM YYYY - HH:mm"),
        createAt: new Date(),
        assistente: assistantRef.id || "0",
        assistente_nome: assistantRef?.nome || "Nenhum",
        condicao_especial: responsible.id === idPaulo ? customer.condicao_especial : 'Não',
        historico: [
          {
            data: moment().format("DD/MM/YYYY - HH:mm"),
            evento: "Cliente Criado",
            text: `O Cliente foi criado pelo metódo <b>Novo Cliente</b>.`,
            usuario_id: userRef?.id,
            usuario_nome: userRef?.nome,
          },
        ],
      };

      // console.log(createClient)

      const resultCustomer = await addDoc(
        collection(dataBase, "Clientes"),
        createClient
      );

      setCustomer({
        nome: "",
        doc: "",
        email: "",
        empresa: "",
        telefone: "",
        cep: "",
        endereco: {
          endereco: "",
          lat: -23.109715809172528,
          lng: -47.71509284910918,
        },
        cidade: "",
        numero: "",
        complemento: "",
        bairro: "",
        estado: "SP",
        condicao_especial: 'Não',
      });
      await sendN8N(resultCustomer.id);
      setLoading(false);

      await Swal.fire({
        title: "Clientes",
        html: `O Cliente <b>${customer.nome}</b> foi cadastrado com sucesso.`,
        icon: "success",
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: "#111",
      });

      navigate(`/clientes/${resultCustomer.id}`);
      closeBox();
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast({ icon: "error", text: "Ocorreu um erro ao criar o cliente." });
      // console.log(error);
    }
  };

  const sendN8N = async (id) => {
    return await axios.post(
      "https://n8n.corpbrasil.cloud/webhook/41485ed1-bb2f-42e9-8a8b-735af046b818",
      {
        id: id,
        status: "Aberto",
        cliente: customer.nome,
        telefone: customer.telefone,
        cidade: customer.cidade,
        responsavel: userRef?.nome,
        analista: customer.assistente_nome || "Nenhum",
        representante: customer.representante?.nome || "Nenhum",
        indicador: "Nenhum",
      }
    );
  };

  const showAlert = (title, message, icon) => {
    return Swal.fire({
      title: title,
      html: message,
      icon: icon,
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonColor: "#111",
    });
  };

  useEffect(() => {
    if (members) {
      const filterResponsibles = members.filter(
        (member) =>
          (member.cargo === "Vendedor(a)" && !member.desativado) ||
          member.nome === "CORPBRASIL"
      );
      const filterRepresentatives = members.filter(
        (member) => member.cargo === "Representante"
      );

      const responsiblesRef = filterResponsibles
        .map((member) => {
          return {
            nome: member.nome === "Pós-Venda" ? member.nome_ref : member.nome,
            id: member.id,
            img: member.photo?.url,
          };
        })
        .sort((a, b) => {
          if (a.nome < b.nome) {
            return -1;
          }
          if (a.nome > b.nome) {
            return 1;
          }
          return 0;
        });

      const responsibleSellerMaster = responsiblesRef.filter(
        (data) => data.id === userRef?.id || data.id === idPaulo
      );

      setResponsibles(
        checkSellerMaster() ? responsibleSellerMaster : responsiblesRef
      );

      const representativesRef = filterRepresentatives
        .map((member) => {
          return {
            nome: member.nome,
            id: member.id,
            codigo: member.id_representante,
          };
        })
        .sort((a, b) => {
          if (a.nome < b.nome) {
            return -1;
          }
          if (a.nome > b.nome) {
            return 1;
          }
          return 0;
        });

      const defaultRepresentative = {
        nome: "Nenhum",
        id: "0",
        codigo: "Nenhum",
      };

      if (userRef && userRef.cargo === "Vendedor(a)" && userRef.time) {
        setRepresentatives([
          defaultRepresentative,
          ...representativesRef.filter((data) =>
            data.codigo.includes(userRef.time)
          ),
        ]);
      } else {
        setRepresentatives([defaultRepresentative, ...representativesRef]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members]);

  const checkSellerMaster = () => {
    if (userRef?.cargo === "Vendedor(a)" && userRef?.time) {
      return true;
    } else {
      return false;
    }
  };

  const mask = (v) => {
    v = v.replace(/\D/g, "");

    if (v.length <= 11) {
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else {
      v = v.substring(0, 14); // limita em 14 números
      v = v.replace(/^(\d{2})(\d)/, "$1.$2");
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
      v = v.replace(/(\d{4})(\d)/, "$1-$2");
    }

    return v;
  };

  const unmask = (v) => {
    if (!v) return "";
    return v.replace(/\D/g, "");
  };

  const checkInputs = () => {
    const {
      nome,
      telefone,
      cidade,
      endereco: { endereco },
    } = customer;

    const isNomeValid = nome.length > 0;
    const isTelefoneValid = telefone.length > 0 && !telefone.includes("_");
    const isEnderecoValid = endereco.length > 0 || cidade.length > 0;
    console.log(customer);

    const areAllInputsValid = isNomeValid && isTelefoneValid && isEnderecoValid;

    if (areAllInputsValid) {
      return true;
    }

    return false;
  };

  const checkLead = (phone) => {
    if (phone === "15000000000") return false;
    return leads.find((data) => data.telefone === phone);
  };

  const detailsLead = async (leadRef) => {
    return Swal.fire({
      title: "Detalhes do Cliente",
      html:
        `Cliente: <b>${leadRef.nome}</b></br>` +
        `Telefone: <b>${formatPhoneBR(leadRef.telefone)}</b></br>` +
        `Consultor(a): <b>${leadRef.consultora}</b></br>` +
        `Cidade: <b>${leadRef.cidade}</b></br></br>`,
      icon: "info",
      showCloseButton: true,
      confirmButtonColor: "#000",
      confirmButtonText: "Fechar",
    });
  };

  console.log(customer);
  console.log(leadsSheet);

  return (
    <Dialog
      className={style.dialog}
      open={view}
      fullScreen={fullScreen}
      maxWidth="md"
      sx={{
        padding: (theme) => theme.spacing(2),
        position: "absolute",
        zIndex: "999 !important",
        right: "0px",
        bottom: "0px",
        top: "0px",
        left: "0px",
      }}
    >
      <IconButton
        aria-label="close"
        onClick={closeBox}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle align="center">Cadastrar Cliente</DialogTitle>
      <DialogContent>
        <Loading view={loading} />
        <DialogContentText sx={{ textAlign: "center", marginBottom: "0.3rem" }}>
          Preencha os campos obrigatórios <b>(*)</b> abaixo para cadastrar um
          novo <b>Cliente</b>.
        </DialogContentText>
        <Alert severity="info">
          <b>Atenção:</b> Por favor, informe o
          <b> endereço completo do cliente</b> para que o sistema possa capturar
          a latitude e longitude e medir com precisão a
          <b> irradiação solar do local</b>.
        </Alert>
        <form onSubmit={onSubmit} autoComplete="off">
          <Grid container spacing={1} sx={{ marginTop: "1rem" }}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                value={responsible}
                disabled={
                  userRef && userRef.cargo === "Vendedor(a)" && !userRef.time
                    ? true
                    : false
                }
                onChange={(event, newValue) => {
                  if (newValue != null) {
                    setResponsible({
                      nome: newValue.nome,
                      id: newValue.id,
                      img: newValue.img,
                    });
                  } else {
                    // Caso seja necessário lidar com a limpeza do campo
                    setResponsible({});
                  }
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                fullWidth
                disableClearable={true}
                id="free-solo-with-text-demo"
                options={responsibles}
                getOptionLabel={(option) => option.nome}
                renderOption={(props, option) => (
                  <li {...props}>
                    <ListItemIcon>
                      <img
                        src={option.img}
                        alt={option.nome}
                        style={{ width: 24, height: 24, borderRadius: "50%" }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={option.nome} />
                  </li>
                )}
                noOptionsText="Responsável não encontrado"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Responsável"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: responsible.img && (
                        <InputAdornment position="start">
                          <img
                            src={responsible.img}
                            alt={responsible.nome}
                            style={{
                              width: 24,
                              height: 24,
                              borderRadius: "50%",
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                value={`${representative.nome} ${
                  representative.id !== "0"
                    ? "(" + representative.codigo + ")"
                    : ""
                }`}
                fullWidth
                sx={{ alignItems: "center", backgroundColor: "#fff" }}
                onChange={(event, newValue) => {
                  setRepresentative({
                    nome: newValue.nome,
                    id: newValue.id,
                    codigo: newValue.codigo,
                  });
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                disableClearable={true}
                id="free-solo-with-text-demo"
                options={representatives}
                disabled={
                  userRef && userRef.cargo === "Vendedor(a)" && !userRef.time
                }
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Opção Regular
                  return option.nome;
                }}
                renderOption={(props, option) => (
                  <li {...props}>
                    {option.nome}{" "}
                    {option.id !== "0" ? <b> ({option.codigo})</b> : null}
                  </li>
                )}
                noOptionsText="Representante não encontrado"
                renderInput={(params) => (
                  <TextField {...params} label="Representante" />
                )}
              />
            </Grid>
            <ThemeProvider theme={themeInput}>
              <Grid item xs={12} sm={6} md={4}>
                <Autocomplete
                  value={customer}
                  sx={{ marginTop: "0.55rem" }}
                  disableListWrap
                  ListboxComponent={ListboxComponent}
                  open={open}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  // ListboxComponent={ListboxComponent}
                  onChange={(event, newValue) => {
                    if (typeof newValue === "string") {
                      setCustomer((prev) => ({ ...prev, telefone: newValue }));
                    } else if (newValue && newValue.inputValue) {
                      setCustomer((prev) => ({
                        ...prev,
                        telefone: newValue.inputValue,
                      }));
                    } else {
                      setCustomer((prev) => ({ ...prev, ...newValue }));
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some(
                      (option) => inputValue === option.telefone
                    );

                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        inputValue,
                        nome: `Adicionar "${inputValue}"`,
                      });
                    }
                    return filtered;
                  }}
                  clearOnBlur
                  handleHomeEndKeys
                  fullWidth
                  loading={loadingLeads}
                  loadingText="Carregando..."
                  disableClearable={true}
                  id="free-solo-with-text-demo"
                  options={leadsSheet}
                  noOptionsText="Lead não encontrado"
                  getOptionLabel={(option) =>
                    option.telefone ? option.telefone : ""
                  }
                  renderOption={(props, option) => (
                    <li {...props}>
                      {option.telefone
                        ? `${formatPhoneBR(option.telefone)} - ${option.nome}(${
                            option.cidade_ref
                          })`
                        : option.nome}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Telefone (Somente Número)"
                      type="text"
                      inputProps={{
                        ...params.inputProps,
                        maxLength: 11,
                        autoComplete: "no-password",
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: checkLead(customer?.telefone) && (
                          <InputAdornment position="start">
                            <InfoRoundedIcon sx={{ fill: "#d32f2f" }} />
                          </InputAdornment>
                        ),
                      }}
                      AutoComplete="no-password"
                      error={checkLead(customer?.telefone)}
                      helperText={
                        checkLead(customer?.telefone) ? (
                          <Typography
                            fontSize={"12px"}
                            sx={{ overflowWrap: "break-word" }}
                          >
                            <strong>Telefone já cadastrado.</strong> Veja mais
                            detalhes{" "}
                            <b>
                              <Link
                                style={{ color: "red" }}
                                to=""
                                onClick={() =>
                                  detailsLead(checkLead(customer?.telefone))
                                }
                              >
                                aqui
                              </Link>
                            </b>
                          </Typography>
                        ) : (
                          ""
                        )
                      }
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.telefone === value.telefone
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  margin="dense"
                  label="Nome Completo"
                  type="text"
                  name="nome"
                  required
                  value={customer.nome ? customer.nome : ""}
                  onChange={(e) =>
                    setCustomer({ ...customer, nome: e.target.value })
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                {isLoaded && view && (
                  <AutocompleteMaps
                    KeyMaps={KeyMaps}
                    setCustomer={setCustomer}
                    title="Endereço com Número"
                  />
                )}
              </Grid>
            </ThemeProvider>
            {responsible?.id === idPaulo && 
              <Grid item xs={12}>
                <Alert icon={<BiSolidOffer />} severity="success">
                  <AlertTitle>Condição Especial</AlertTitle>
                  Atenção: O cliente terá direito a uma condição especial de pagamento?
                  <FormGroup
                    sx={{ display: "flex", gap: "1rem", flexDirection: "row" }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={customer.condicao_especial === 'Sim'}
                          onChange={() =>
                            setCustomer({ ...customer, condicao_especial: 'Sim' })
                          }
                          name="condicoes_especiais_sim"
                          color="primary"
                        />
                      }
                      label={<b>Sim</b>}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={customer.condicao_especial === 'Não'}
                          onChange={() =>
                            setCustomer({ ...customer, condicao_especial: 'Não' })
                          }
                          name="condicoes_especiais_nao"
                          color="primary"
                        />
                      }
                      label={<b>Não</b>}
                    />
                  </FormGroup>
                </Alert>
              </Grid>
            }
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="dense"
                label="CNPJ/CPF"
                type="text"
                inputProps={{
                  minLength: 14,
                }}
                value={customer.doc ? customer.doc : ""}
                onChange={(e) =>
                  setCustomer({ ...customer, doc: mask(e.target.value) })
                }
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="dense"
                label="Email"
                type="email"
                name="email"
                value={customer.email ? customer.email : ""}
                onChange={(e) =>
                  setCustomer({ ...customer, email: e.target.value })
                }
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                margin="dense"
                label="Empresa"
                name="Empresa"
                type="text"
                value={customer.empresa ? customer.empresa : ""}
                onChange={(e) =>
                  setCustomer({ ...customer, empresa: e.target.value })
                }
                fullWidth
                variant="outlined"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PatternFormat
                value={customer.cep ? customer.cep : ""}
                onChange={(e) =>
                  setCustomer({ ...customer, cep: e.target.value })
                }
                sx={{ marginTop: "0.5rem" }}
                format="#####-###"
                mask="_"
                fullWidth
                customInput={TextField}
                placeholder="00000-000"
                label="CEP"
                minLength={9}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label="Complemento"
                name="Complemento"
                type="text"
                value={customer.complemento ? customer.complemento : ""}
                onChange={(e) =>
                  setCustomer({ ...customer, complemento: e.target.value })
                }
                fullWidth
                variant="outlined"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                margin="dense"
                label="Bairro"
                name="Bairro"
                type="text"
                value={customer.bairro ? customer.bairro : ""}
                onChange={(e) =>
                  setCustomer({ ...customer, bairro: e.target.value })
                }
                fullWidth
                variant="outlined"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                margin="dense"
                label="Estado"
                name="Estado"
                type="text"
                value={customer.estado ? customer.estado : ""}
                fullWidth
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                margin="dense"
                label="Cidade"
                name="Cidade"
                type="text"
                value={customer.cidade ? customer.cidade : ""}
                onChange={(e) =>
                  setCustomer({ ...customer, cidade: e.target.value })
                }
                fullWidth
                disabled
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
                autoComplete="new-password"
              />
            </Grid>
          </Grid>
          <DialogActions sx={{ justifyContent: "center" }}>
            <ThemeProvider theme={theme}>
              <NewTooltip
                title={
                  !checkInputs() &&
                  "Preencha os campos obrigatório para cadastrar o cliente"
                }
                placement="top"
              >
                <span>
                  <Button
                    disableElevation
                    variant="contained"
                    color="success"
                    disabled={!checkInputs()}
                    sx={{ textTransform: "capitalize" }}
                    type="submit"
                  >
                    Cadastrar
                  </Button>
                </span>
              </NewTooltip>
              <Button
                disableElevation
                variant="contained"
                sx={{ textTransform: "capitalize" }}
                onClick={closeBox}
              >
                Cancelar
              </Button>
            </ThemeProvider>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddCustomer;
