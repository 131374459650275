import React, { useState } from 'react'
import { Box, Dialog, DialogContent, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, ThemeProvider, Typography, useMediaQuery } from '@mui/material'
import styles from '../styles.module.scss';

import CloseIcon from '@mui/icons-material/Close';
import { theme } from '../../../data/theme';

import { ReactComponent as Aviso } from '../../../images/system/icons/Aviso.svg';
import formatCurrency from '../../../functions/formatCurrency';
import ButtonCB from '../../../components/Button';

const ModalImprovements = ({ open, close, visit, openPaymentContract, type, installment }) => {
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [loading, setLoading] = useState(false);

  const onClose = () => {
    close();
  }

  const onSubmit = async (result) => {
    console.log(result);
    try {
        setLoading(true);
        setTimeout(() => {
          openPaymentContract('Contrato', type, installment?.banco, result);
          setLoading(false);
        }, 200);
    } catch (error) {
      console.log('Erro: ', error);
    }
  }

  return (
    <Dialog
      className={styles.dialog}
      open={open}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="sm"
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        size='small'
        sx={{
          position: 'absolute',
          backgroundColor: "#fff",
          borderRadius: '50%',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon sx={{ fill: '#000' }} />
      </IconButton>
      <ThemeProvider theme={theme}>
        <DialogContent className={styles.dialog_content} sx={{ padding: '20px 24px' }}>
          <div className={styles.modal_header}>
            <Box sx={{ 'svg': { width: '70px', height: '70px' } }}>
              <Aviso />
            </Box>
            <h2 style={{ textAlign: 'center' }}>Melhorias Elétricas Necessárias</h2>
          </div>
          <Table>
            <TableHead sx={{ backgroundColor: "#EDEDED", borderRadius: '5px' }}>
              <TableCell sx={{ padding: '0.2rem 1rem', border: 'none' }}>Descrição</TableCell>
              <TableCell sx={{ padding: '0.2rem', border: 'none' }}>Qntd</TableCell>
              <TableCell sx={{ width: '90px', padding: '0.2rem 1rem', border: 'none' }}>Preço</TableCell>
            </TableHead>
            <TableBody sx={{ 'td': { padding: '0.4rem 0.8rem' } }}>
              {visit?.rede_eletrica?.melhorias?.material?.map((data, index) => (
                <TableRow key={index}>
                  <TableCell>{data.nome}</TableCell>
                  <TableCell>{data.categoria === 'Mão de Obra' ? '1' : data.quantidade}</TableCell>
                  <TableCell>{formatCurrency(data.valor_total)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box sx={{ display: 'flex', margin: '1rem 0 0.5rem 0', justifyContent: 'flex-end', width: '100%' }}>
            <Typography variant='b' component='b' sx={{ padding: '0.65rem 1rem', backgroundColor: "#FFAB00", borderRadius: '15px' }}>Valor total: {formatCurrency(visit?.rede_eletrica?.melhorias?.valor_total)}</Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ padding: '0.6rem', margin: '0.6rem 0', textAlign: 'center' }}>
              <h3>Incluir Serviço Completo?</h3>
            </Box>
              <Grid container spacing={0}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Box sx={{ maxWidth: '400px', gap: '0.7rem', display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <ButtonCB loading={loading} fullWidth colorText="#000" onClick={() => onSubmit(true)}>{!loading && 'Sim'}</ButtonCB>
                    <ButtonCB loading={loading} fullWidth background="#ccc" colorText="#000" onClick={() => onSubmit(false)}>{!loading && 'Não'}</ButtonCB>
                  </Box>
                </Grid>
              </Grid>

            {/* <FormGroup sx={{ marginTop: '0.5rem' }}>
                  <FormControlLabel
                    sx={{ margin: 0 }}
                    control={
                      <Checkbox
                        checked={acceptImprovements}
                        color='success'
                        label='123'
                        onChange={() => {
                          setAcceptImprovements(true); // Executa ação se "melhorias" não estiver ativado
                        }}
                        icon={<RadioButtonUncheckedIcon sx={{ fill: "#0FA958", backgroundColor: "#fff", borderRadius: '50%' }} />}
                        checkedIcon={<CheckCircleIcon sx={{ fill: "#0FA958", backgroundColor: "#fff", borderRadius: '50%' }} />} />
                    }
                    label={
                      <Typography sx={{ fontWeight: 'bold', width: 'calc(100% - 65px)', backgroundColor: "#fff", color: "#4A4A4A", padding: '0.2rem 0.4rem', borderRadius: '5px' }}>
                        ✅ Sim, adicionar melhorias.
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    sx={{ margin: 0 }}
                    control={
                      <Checkbox
                        checked={acceptImprovements === false}
                        color='success'
                        label='123'
                        onChange={() => {
                          setAcceptImprovements(false); // Executa ação se "melhorias" não estiver ativado
                        }}
                        icon={<RadioButtonUncheckedIcon sx={{ fill: "#0FA958", backgroundColor: "#fff", borderRadius: '50%' }} />}
                        checkedIcon={<CheckCircleIcon sx={{ fill: "#0FA958", backgroundColor: "#fff", borderRadius: '50%' }} />} />
                    }
                    label={
                      <Typography sx={{ fontWeight: 'bold', width: 'calc(100% - 65px)', backgroundColor: "#fff", color: "#4A4A4A", padding: '0.2rem 0.4rem', borderRadius: '5px' }}>
                        ❌ Não, manter o orçamento atual.
                      </Typography>
                    }
                  />
            </FormGroup> */}

              </Box>
            </DialogContent>

            {/* <DialogActions sx={{ padding: '0 1rem 2rem 1rem', display: 'flex', justifyContent: 'center' }}>
              <ButtonCB
                fullWidth
                size='large'
                variant='contained'
                disabled={acceptImprovements === null}
                background="#209CFF"
                loading={loading}
                sx={{ textTransform: 'capitalize', fontWeight: 'bold', fontSize: '17px', maxWidth: '450px', letterSpacing: '0.1rem' }}
                onClick={onSubmit}>Continuar</ButtonCB>
            </DialogActions> */}

          </ThemeProvider>
        </Dialog>
        )
}

        export default ModalImprovements;