import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Menu from './Menu/Menu';
import { useLocation } from 'react-router-dom';
import { useCadastro } from '../contexts/CadastroContext';
import { RiArrowRightDoubleLine } from "react-icons/ri";
import { RiArrowLeftDoubleLine } from "react-icons/ri";
import ButtonCB from './Button';
import ActivityBox from '../pages/Activity/Components/ActivityBox';
import Loading from './Loading';
import { useActivity } from '../contexts/ActivityContext';
import Swal from 'sweetalert2';

const AppLayoutContainer = styled(Box)({
  display: 'flex',
  width: '100%',
});



const Layout = ({ userRef, children }) => {
  const location = useLocation();
  const previousUrl = useRef(null);
  const { activityData, setActivityData } = useActivity();
  const [open, setOpen] = useState(Boolean(activityData));
  const { atualizarCadastro } = useCadastro();

  useEffect(() => {
    if(activityData && !open) {
      setOpen(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[activityData])

  useEffect(() => {
    // Função que será chamada quando a URL mudar
    const checkPreviousUrl = () => {
      if (previousUrl.current && previousUrl.current.includes("/visitas/criar/")) {
        atualizarCadastro(null);
        // Adicione aqui a lógica que você deseja executar
      }
      // Atualize a URL anterior com a URL atual
      previousUrl.current = location.pathname;
    };

    checkPreviousUrl(); // Chama a função sempre que a URL mudar
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]); 

  const onClose = async () => {
    const result = await Swal.fire({
      title: 'Atenção',
      html: `Você deseja cancelar a <b>Atividade?</b>`,
      icon: "question",
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: "#0eb05f",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    })

    if(result.isConfirmed) {
      setActivityData(null);
      setOpen(false);
    }
  }

  return (
    <AppLayoutContainer>
        <Loading view={false}/>
        <Menu userRef={userRef} />
        <Box component="main" 
          sx={{ flexGrow: 1, 
          minHeight: '100vh',
          position: 'relative',
          width: '100%',
          backgroundColor: "#F8F8F9" }}>
          {children}
          {Boolean(activityData) && (
            <ButtonCB 
            sx={{ 
              position: 'fixed',
              top: '99px',
              right: open ? 230 : 0,
              transform: 'translateY(-50%)',
              zIndex: 1300,
              padding: '0.2rem',
              minWidth: 0
            }}
            background="#333333" 
            onClick={() => setOpen(!open)}>
              {open ? <RiArrowRightDoubleLine size='1.8rem' /> : <RiArrowLeftDoubleLine size='1.8rem' />}
            </ButtonCB >
          )}
        </Box>
        <ActivityBox open={open} close={() => setOpen(false)} closeAll={onClose} />
    </AppLayoutContainer>
  );
};

export default Layout;