export const compareProperties = (obj1, obj2, properties) => {
    const unequalProperties = [];
  
    if(!obj1 || !obj2) return false;
    
    for (let property of properties) {
      if (obj1[property] !== obj2[property] && obj1[property] !== '') {
        if (property === 'doc') {
          unequalProperties.push('CNPJ/CPF');
        } else {
          unequalProperties.push(property);
        }
      }
    }
  
    return unequalProperties;
  }