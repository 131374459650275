import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
// import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import { useEffect, useState } from "react";

import styles from './styles.module.scss';
import Logo from '../../images/LogoCORPBRASIL.png'
import GroupsIcon from '@mui/icons-material/Groups';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { ReactComponent as ReportIcon } from '../../images/icons/Report.svg';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalculateIcon from '@mui/icons-material/Calculate';
// import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import GroupIcon from '@mui/icons-material/Group';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DescriptionIcon from '@mui/icons-material/Description';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PaymentsIcon from '@mui/icons-material/Payments';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import MenuIcon from '@mui/icons-material/Menu';

import { Box, Divider, Drawer } from '@mui/material';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase/database';
import NewTooltip from '../Tooltip';
import { checkPermission } from '../../functions/checkPermission';
import { MdQueuePlayNext } from 'react-icons/md';

const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});


const DrawerFix = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const Menu = ({ userRef }) => {
  const [open, setOpen] = useState(false);
  const [scrollVisible, setScrollVisible] = useState(false);
  // const [viewPopover, setviewPopover] = useState(false);

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '0.5rem',
    // marginBottom: '4px',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const logoff = () => {
    signOut(auth)
      .then(() => {
        document.location.replace("/");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const renderDrawerContent = () => (
    <>
    <DrawerHeader>
    <div className={styles.logo}>
      <Link to="/" aria-label="Voltar ao inicio" data-cooltipz-dir="bottom" onClick={() => setOpen(false)}>
        <img src={Logo} alt="CORPBRASIL"/>
      </Link>
    </div>
        <NewTooltip title={open ? 'Minimizar Menu' : 'Expandir Menu'} placement={'right'}>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </NewTooltip>
      </DrawerHeader>
      <ListItem disablePadding sx={{ display: 'block' }}>
      <NewTooltip title={!open ? 'Meu Perfil' : null} placement={'right'}>
        <Link className={styles.link} to={'/meu_perfil'} onClick={() => setOpen(false)}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: 'initial',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 3,
                  justifyContent: 'center',
                }}
              >
                <AccountCircleRoundedIcon sx={{ fill: "#111", fontSize: '30px' }} />
              </ListItemIcon>
              <ListItemText sx={{ fontWeight: 'bold' }} primary={'Meu Perfil'} />
            </ListItemButton>
        </Link>
      </NewTooltip>
      <Divider />
      </ListItem>
      {(userRef?.cargo !== 'Terceirizado' && userRef?.cargo !== 'Instalador') ?
        <List 
          sx={{ overflowX: 'hidden' }} 
          className={`${scrollVisible ? styles.scroll : styles.scroll_visible}`}
          onMouseEnter={() => setScrollVisible(true)}
          onMouseLeave={() => setScrollVisible(false)}>
            {open ?
              <ListItem sx={{ display: 'block' }}>
                  <ListItemText primary={'Principal'} sx={{ marginLeft: '1rem', fontWeight: 'bold' }} />
              </ListItem> : null
            }
            
            {userRef?.cargo === "Administrador" && (
              <ListItem disablePadding sx={{ display: 'block' }}>
              <NewTooltip title={!open ? 'Atividades' : null} placement={'right'}>
                <Link className={styles.link} to={'/atividades'} onClick={() => setOpen(false)}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 3,
                          justifyContent: 'center',
                        }}
                      >
                        <MdQueuePlayNext size="24px" color="#5900FF" />
                      </ListItemIcon>
                      <ListItemText primary={'Atividades'} />
                    </ListItemButton>
                </Link>
              </NewTooltip>
              </ListItem>
            )}

            <ListItem disablePadding sx={{ display: 'block' }}>
            <NewTooltip title={!open ? 'Visitas' : null} placement={'right'}>
              <Link className={styles.link} to={'/visitas'} onClick={() => setOpen(false)}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: 'initial',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 3,
                        justifyContent: 'center',
                      }}
                    >
                      <CalendarMonthIcon sx={{ fill: "#0ca358" }} />
                    </ListItemIcon>
                    <ListItemText primary={'Visitas'} />
                  </ListItemButton>
              </Link>
            </NewTooltip>
            </ListItem>
            {(userRef?.cargo === 'Representante' || userRef?.cargo === 'Administrador' || (userRef?.cargo === 'Vendedor(a)' && userRef?.time)) ?
              <ListItem disablePadding sx={{ display: 'block' }}>
                <NewTooltip title={!open ? 'Comissões' : null} placement={'right'}>
                  <Link className={styles.link} to={'/comissao'} onClick={() => setOpen(false)}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: 'initial',
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: 3,
                            justifyContent: 'center',
                          }}
                        >
                          <PaymentsIcon sx={{ fill: "#ffa91f" }} />
                        </ListItemIcon>
                        <ListItemText primary={'Comissões'} />
                      </ListItemButton>
                  </Link>
                </NewTooltip>
              </ListItem> : null
            }
            {userRef && (userRef.cargo === 'Representante') ?
            <ListItem disablePadding sx={{ display: 'block' }}>
              <NewTooltip title={!open ? 'Clientes' : null} placement={'right'}>
                <Link className={styles.link} to={'/clientes'}onClick={() => setOpen(false)}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 3,
                          justifyContent: 'center',
                        }}
                      >
                        <GroupIcon />
                      </ListItemIcon>
                      <ListItemText primary={'Clientes'} />
                    </ListItemButton>
                </Link>
              </NewTooltip>
            </ListItem> : null }
            {((checkPermission(["Administrador", "Técnico", "Financeiro"], userRef)) || userRef?.nome_ref) ?
            <ListItem disablePadding sx={{ display: 'block' }}>
              <NewTooltip title={!open ? 'Financeiro' : null} placement={'right'}>
                <Link className={styles.link} to={'/financeiro'} onClick={() => setOpen(false)}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 3,
                          justifyContent: 'center',
                        }}
                      >
                        <LocalAtmIcon sx={{ fill: "#3163eb" }} />
                      </ListItemIcon>
                      <ListItemText primary={'Financeiro'} />
                    </ListItemButton>
                </Link>
              </NewTooltip>
            </ListItem> : null }
            {userRef && (userRef.nome === "Pós-Venda" || userRef.cargo === "Administrador") ?
            <ListItem disablePadding sx={{ display: 'block' }}>
              <NewTooltip title={!open ? 'Limpezas' : null} placement={'right'}>
                <Link className={styles.link} to={'/limpeza'} onClick={() => setOpen(false)}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 3,
                          justifyContent: 'center',
                        }}
                      >
                        <CleaningServicesIcon sx={{ fill: "#fe5729" }} />
                      </ListItemIcon>
                      <ListItemText primary={'Limpezas'} />
                    </ListItemButton>
                </Link>
              </NewTooltip>
            </ListItem> : null }
            {userRef && (userRef.nome === "Pós-Venda" || userRef.cargo === "Administrador") ?
            <ListItem disablePadding sx={{ display: 'block' }}>
              <NewTooltip title={!open ? 'Instalação' : null} placement={'right'}>
                <Link className={styles.link} to={'/instalacao'} onClick={() => setOpen(false)}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 3,
                          justifyContent: 'center',
                        }}
                      >
                        <HomeRepairServiceIcon sx={{ fill: "#233e9f" }} />
                      </ListItemIcon>
                      <ListItemText primary={'Instalações'} />
                    </ListItemButton>
                </Link>
              </NewTooltip>
            </ListItem> : null }
            {userRef && (userRef.cargo === "Administrador" || userRef.nome === 'Bruna') ?
            <ListItem disablePadding sx={{ display: 'block' }}>
              <NewTooltip title={!open ? 'Gestão Comercial' : null} placement={'right'}>
                <Link className={styles.link} to={'/gestao-comercial'} onClick={() => setOpen(false)}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 3,
                          justifyContent: 'center',
                        }}
                      >
                        <QueryStatsIcon sx={{ fill: "#=#3da3ab" }} />
                      </ListItemIcon>
                      <ListItemText primary={'Gestão Comercial'} />
                    </ListItemButton>
                </Link>
              </NewTooltip>
            </ListItem> : null }
            {userRef && (userRef.cargo !== 'Representante' && userRef.cargo !== 'Técnico') ? 
              open ?
                <ListItem sx={{ display: 'block' }}>
                    <ListItemText primary={'Cadastro'} sx={{ marginLeft: '1rem', fontWeight: 'bold' }} />
                </ListItem> : <Divider />
              : null
            }
            {userRef && (userRef.email === "admin@corpbrasil.com" || userRef.time) ?
            <ListItem disablePadding sx={{ display: 'block' }}>
              <NewTooltip title={!open ? 'Painel Administrativo' : null} placement={'right'}>
                <Link className={styles.link} to={'/admin'} onClick={() => setOpen(false)}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 3,
                          justifyContent: 'center',
                        }}
                      >
                        <AdminPanelSettingsIcon sx={{ fill: "#000" }} />
                      </ListItemIcon>
                      <ListItemText primary={'Painel Administrativo'} />
                    </ListItemButton>
                </Link>
              </NewTooltip>
            </ListItem> : null }
            {(checkPermission(["Administrador", "Vendedor(a)", "Financeiro"], userRef)) ?
            <ListItem disablePadding sx={{ display: 'block' }}>
              <NewTooltip title={!open ? 'Clientes' : null} placement={'right'}>
                <Link className={styles.link} to={'/clientes'}onClick={() => setOpen(false)}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 3,
                          justifyContent: 'center',
                        }}
                      >
                        <GroupIcon />
                      </ListItemIcon>
                      <ListItemText primary={'Clientes'} />
                    </ListItemButton>
                </Link>
              </NewTooltip>
            </ListItem> : null }
            {userRef && (userRef.cargo === 'Vendedor(a)' || userRef.cargo === 'Administrador') ? 
              <ListItem disablePadding sx={{ display: 'block' }}>
                <NewTooltip title={!open ? 'Planilha de Crédito' : null} placement={'right'}>
                  <Link className={styles.link} to={'/credito'} onClick={() => setOpen(false)}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: 'initial',
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: 3,
                            justifyContent: 'center',
                          }}
                        >
                          <DescriptionIcon sx={{ fill: "#4cda00" }} />
                        </ListItemIcon>
                        <ListItemText primary={'Planilha de Crédito'} />
                      </ListItemButton>
                  </Link>
                </NewTooltip>
              </ListItem> : null }
            {userRef && ((userRef.cargo === 'Vendedor(a)' && !userRef.time) || userRef.cargo === 'Administrador') ? 
            <ListItem disablePadding sx={{ display: 'block' }}>
              <NewTooltip title={!open ? 'Relatório' : null} placement={'right'}>
                <Link className={styles.link} to={'/relatorio'} onClick={() => setOpen(false)}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 3,
                          justifyContent: 'center',
                        }}
                      >
                        <ReportIcon />
                      </ListItemIcon>
                      <ListItemText primary={'Relatório'} />
                    </ListItemButton>
                </Link>
              </NewTooltip>
            </ListItem> : null }
            {userRef && userRef.email === "admin@corpbrasil.com" ?              
            <ListItem disablePadding sx={{ display: 'block' }}>
              <NewTooltip title={!open ? 'Financiamento' : null} placement={'right'}>
                <Link className={styles.link} to={'/financiamento'} onClick={() => setOpen(false)}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 3,
                          justifyContent: 'center',
                        }}
                      >
                        <AccountBalanceIcon sx={{ fill: "#3e585c" }} />
                      </ListItemIcon>
                      <ListItemText primary={'Financiamento'} />
                    </ListItemButton>
                </Link>
              </NewTooltip>
            </ListItem> : null }
            {/* {userRef && ((userRef.cargo === 'Vendedor(a)' && !userRef.time) || userRef.cargo === 'Administrador') ? 
            <ListItem disablePadding sx={{ display: 'block' }}>
              <NewTooltip title={!open ? 'Prospecção' : null} placement={'right'}>
                <Link className={styles.link} to={'/prospeccao'} onClick={() => setOpen(false)}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 3,
                          justifyContent: 'center',
                        }}
                      >
                        <PersonSearchIcon sx={{ fill: "#404040" }} />
                      </ListItemIcon>
                      <ListItemText primary={'Prospecção'} />
                    </ListItemButton>
                </Link>
              </NewTooltip>
            </ListItem> : null } */}
            {userRef && (userRef.cargo !== 'Representante' && userRef.cargo !== 'Técnico') ?
            open ?
              <ListItem sx={{ display: 'block' }}>
                  <ListItemText primary={'Outros'} sx={{ marginLeft: '1rem', fontWeight: 'bold' }} />
              </ListItem> : <Divider />
             : null }
            {userRef && userRef.leads ?
              <ListItem disablePadding sx={{ display: 'block' }}>
              <a className={styles.link} href={userRef && userRef.leads} target='_blank' rel='noreferrer'>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: 'initial',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 3,
                        justifyContent: 'center',
                      }}
                    >
                      <GroupsIcon sx={{ fill: "#7aca11" }} />
                    </ListItemIcon>
                    <ListItemText primary={'Meu Leads'} />
                  </ListItemButton>
              </a>
              </ListItem> : null }
          {userRef && (userRef.cargo !== 'Representante' && userRef.cargo !== 'Técnico') ?
            <ListItem disablePadding sx={{ display: 'block' }}>
              <NewTooltip title={!open ? 'Calculadora Híbrida' : null} placement={'right'}>
                <a className={styles.link} href={'https://calculadora.app.deyeinversores.com.br/pt-BR'} target='_blank' rel='noreferrer'>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 3,
                          justifyContent: 'center',
                        }}
                      >
                        <CalculateIcon sx={{ fill: "#111" }} />
                      </ListItemIcon>
                      <ListItemText primary={'Calculadora Híbrida'} />
                    </ListItemButton>
                </a>
              </NewTooltip>
            </ListItem> : null }
            <Divider />
        </List> : null
      }
      <ListItem disablePadding sx={{ display: 'block' }}>
      <NewTooltip title={!open ? 'Sair' : null} placement={'right'}>
        <Link className={styles.link} onClick={logoff}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: 'initial',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 3,
                  justifyContent: 'center',
                }}
              >
                <ExitToAppIcon sx={{ fill: "#111" }} />
              </ListItemIcon>
              <ListItemText sx={{ fontWeight: 'bold' }} primary={'Sair'} />
            </ListItemButton>
        </Link>
      </NewTooltip>
      </ListItem></>
  )

  return (
      <div className={styles.menu}>
        <Box sx={{ 
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '80px'
         }}>
          <IconButton size='large' onClick={() => setOpen(true)}>
            <MenuIcon />
          </IconButton>
        </Box>
        {windowWidth >= 900 ?
          <DrawerFix 
            variant='permanent'
            className={` ${styles.drawer}
            print`}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            open={open}>
              {renderDrawerContent()}
          </DrawerFix> : 
          <Drawer 
          variant='temporary'
          className={` ${styles.drawer}
          print`}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          onClose={() => setOpen(false)} 
          open={open}>
            {renderDrawerContent()}
          </Drawer>
      }
      </div>
  )
}

export default Menu