import React, { useEffect, useRef, useState } from 'react';

import styles from '../styles.module.scss';

// import { ReactComponent as Confirm } from '../../../images/system/icons/Confirm.svg';
import { ReactComponent as Google } from '../../../images/system/icons/Google.svg';
import VerifiedIcon from '@mui/icons-material/Verified';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';

// import Posicao from '../../../images/system/PosicaoSol1.jpeg';

import Logo from '../../../images/LogoCORPBRASIL.png';
import LogoMulher from '../../../images/LogoMulher.png';
import Mulher from '../../../images/Mulher.png';
import EstruturaR1 from '../../../images/system/EstruturaR1.jpg';
import EstruturaR2 from '../../../images/system/EstruturaR2.jpg';
import EstruturaR3 from '../../../images/system/EstruturaR3.jpg';
import EstruturaB1 from '../../../images/system/EstruturaB1.jpg';
import EstruturaB2 from '../../../images/system/EstruturaB2.jpg';
import EstruturaB3 from '../../../images/system/EstruturaB3.jpg';

import Cabos from '../../../images/system/Cabos.png';

import { Box, Collapse, Divider, Grid, styled, TextField, ThemeProvider } from '@mui/material';
import ButtonSection from '../components/ButtonSection';
import { themeSystem } from '../../../data/theme';
import Loading from '../../../components/Loading';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { dataBase } from '../../../firebase/database';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import NewTooltip from '../../../components/Tooltip';
import NewButton from '../components/NewButton';
import ModalCase from '../components/ModalCase';
// import ModalDistributor from '../components/ModalDistributor';
// import ModalStructure from '../components/ModalStructure';
import Modulos from '../../../images/system/Modulo.jpg';
import Inversores from '../../../images/system/Inversores.png';
// import ModalStructureQuality from '../components/ModalStructureQuality';


// import CustomAudioPlayerFixed from '../../../components/AudioPlayerFixed/Index'; // Ativar depois
// import audio from '../../../assets/audio/Michele-MateriaisFotovoltaicos.mp3';


import { Helmet, HelmetProvider } from 'react-helmet-async';
import Footer from '../components/Footer';

const questions = [
    {
        perguntaId: 1,
        pergunta: "Existe algum fator que você considere crucial para seu projeto de energia solar?"
    },
    {
        perguntaId: 2,
        pergunta: "Como essas informações contribuirão para sua escolha na aquisição do sistema de energia solar?"
    },
    // Adicione mais perguntas conforme necessário
];
const responseLength = 25; // Quantidade de caracteres minimo para liberar o botão de enviar

const Materials = () => {
    const { combinedParam } = useParams();
    const [idCustomer] = combinedParam.split('-');
    const [open, setOpen] = useState({
        distribuidor: false,
        estrutura: false,
        estruturaQualidade: false,
        modal: false
    });
    const [customer, setCustomer] = useState();
    const [loading, setLoading] = useState(true);
    const [setView] = useState(true);
    const [responses, setResponses] = useState([]);
    const irradiationRef = useRef(null);
    const headerRef = useRef(null);
    const [viewedIrradiation, setViewedIrradiation] = useState(false);

    console.log(responses)

    useEffect(() => {
        const fetchData = async () => {
            const customerDoc = await getDoc(doc(dataBase, 'Clientes', idCustomer));
            if (customerDoc.exists()) {
                setCustomer({ ...customerDoc.data(), id: customerDoc.id });
            }
            setLoading(false);
        }

        setTimeout(() => {
            setView(false)
        }, 5000);

        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idCustomer])

    const checkQuestion = (id) => {
        if (customer) {
            const responses = customer?.feedback?.respostas;

            if (responses) {
                return responses.find((data) => data.perguntaId === id) ? true : false;
            }
        }

        return false;
    }

    const checkQuestionButton = (id) => {
        const checkResponse = responses.find(data => data.perguntaId === id);
        if (checkResponse) {
            console.log(responseLength - checkResponse.resposta.length)

            return {
                disabled: checkResponse.resposta.length >= responseLength ? false : true,
                remaining: responseLength - checkResponse.resposta.length
            }
        }

        return {
            disabled: true,
            remaining: 25
        };
    }

    const handleResponse = (id, value) => {
        console.log(value);

        setResponses(prevResponses => {
            const existingResponseIndex = prevResponses.findIndex(response => response.perguntaId === id);

            // Se o valor estiver vazio, remover a resposta do estado
            if (value.length === 0) {
                if (existingResponseIndex !== -1) {
                    return prevResponses.filter(response => response.perguntaId !== id);
                }
                return prevResponses;
            }

            // Adicionar ou atualizar a resposta
            const newResponse = {
                perguntaId: id,
                pergunta: questions.find(q => q.perguntaId === id).pergunta,
                resposta: value,
                timestamp: new Date()
            };

            if (existingResponseIndex !== -1) {
                // Atualizar a resposta existente
                const updatedResponses = [...prevResponses];
                updatedResponses[existingResponseIndex] = newResponse;
                return updatedResponses;
            } else {
                // Adicionar uma nova resposta
                return [...prevResponses, newResponse];
            }
        });
    };

    console.log(customer)

    const onSubmit = async (id) => {

        try {
            const result = await Swal.fire({
                title: 'Atenção',
                html: `Você deseja realizar essa ação?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#00B707',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não'
            });

            if (result.isConfirmed) {
                let feedbackRef = customer.feedback || [];
                // const feedback = customer.planilha[index].feedback;
                console.log(questions);
                console.log(...responses);

                feedbackRef = {
                    ...feedbackRef,
                    respostas: [
                        ...questions,
                        ...responses
                    ]
                }

                await updateDoc(doc(dataBase, 'Clientes', idCustomer), {
                    feedback: feedbackRef,
                })

                const newCustomerData = { ...customer };
                newCustomerData.feedback = feedbackRef;

                setCustomer(newCustomerData);

                // setResponseCheck(responseCheckRef);

            }
        } catch (error) {

        }
    }

    const NewDivider = styled(Divider)(({ height, color, margin }) => ({
        width: '100%',
        height: height || '4px',
        border: 'none',
        margin: margin || 0,
        background: `linear-gradient(90deg, rgba(243, 146, 0, 0) 0%, ${color || '#F39200'} 49.5%, rgba(243, 146, 0, 0) 100%)`,
    }));

    useEffect(() => {
        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (entry.target === irradiationRef.current && entry.isIntersecting) {
                    setViewedIrradiation(true);
                } else if (entry.target === headerRef.current && entry.isIntersecting) {
                    setViewedIrradiation(false);
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.5, // Considera 50% de visibilidade para acionar
        });

        const orcamentoElement = irradiationRef.current;
        const headerElement = headerRef.current;

        if (orcamentoElement) {
            observer.observe(orcamentoElement);
        }
        if (headerElement) {
            observer.observe(headerElement);
        }

        return () => {
            if (orcamentoElement) {
                observer.unobserve(orcamentoElement);
            }
            if (headerElement) {
                observer.unobserve(headerElement);
            }
        };
    }, [irradiationRef, headerRef]);

    const NewBox = styled('div')(({ padding, margin }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '15px',
        width: '100%',
        backgroundColor: '#fff',
        margin: margin || 0,
        padding: padding || '1rem',
        boxSizing: 'border-box',
        boxShadow: '0px 10px 24px 0px #0000001A'
    }));

    const Square = styled('div')(() => ({
        padding: '0.5rem',
        backgroundColor: "#FFAB00"
    }))

    return (
        <div className={styles.estimate}>
            <HelmetProvider>
                <Helmet>
                    <title>
                        Materiais Fotovoltaicos | CORPBRASIL Energia Renovável</title>
                    <meta name="title" content="
Materiais Fotovoltaicos | CORPBRASIL Energia Renovável" />
                    <meta name="description" content="A Experiência dos Nossos Clientes com Energia Solar" />
                </Helmet>
            </HelmetProvider>
            <Loading view={loading} />
            {/* <div className={styles.player_fixed}>
                <CustomAudioPlayerFixed url={audio} view={view} setView={setView} />
            </div> */}
            <div className={styles.estimate_header_fixed}>
                <div>
                    <img src={Logo} alt='' />
                    <h4>{viewedIrradiation ? 'Irradiação' : 'Materiais Fotovoltaicos'}</h4>
                </div>
                <NewDivider />
            </div>
            <ModalCase open={open.modal} close={() => setOpen({ ...open, modal: false })} />
            <main className={`${styles.system_container} ${styles.estimate}`}>
                <Grid className={styles.flex_center} container spacing={1} sx={{ width: '100%', maxWidth: '800px' }}>
                    <Grid item xs={9}>
                        <NewBox>
                            <Box sx={{ lineHeight: '25px' }}>
                                <h3>Olá {customer?.nome},</h3>
                                <p>Somos a sua melhor opção.</p>
                            </Box>
                        </NewBox>
                    </Grid>
                    <Grid item xs={3} sm={'auto'} >
                        <NewBox padding='0.5rem'>
                            <img className={styles.logo} src={LogoMulher} alt='CORPBRASIL' />
                        </NewBox>
                    </Grid>
                </Grid>

                <Grid container spacing={1} sx={{ width: '100%', maxWidth: '1200px' }}>
                    <Grid item xs={12}>
                        <Box ref={headerRef} lineHeight={'30px'} padding={'2rem 1rem'}>
                            <div className={styles.system_equipment_title}>
                                <Square />
                                <h3>Materiais Fotovoltaicos</h3>
                            </div>
                            <p>Entenda como fazemos as escolhas dos melhores materiais fotovoltaicos para você.</p>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <NewBox margin='0.5rem 0 0 0' sx={{ flex: 1 }}>
                            <h3>Módulos Fotovoltaicos</h3>
                            <NewDivider height='3px' color='#F1F1F1' margin='0.3rem 0 0.5rem 0' />
                            <Box sx={{ padding: '1rem', width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                <p className={styles.text_justify}>Tem a função de coletar os fotons de luz através da irradiação solar, gerando a corrente elétrica contínua (C.C.).</p>
                                <div className={styles.flex_center}>
                                    <img src={Modulos} alt='Módulos' />
                                </div>
                                <p className={styles.text_justify}>Nossos orçamentos incluem módulos fotovoltaicos de altíssima qualidade, reconhecidos mundialmente, possuem certificações internacionais, assegurando a melhor tecnologia disponível no mercado.</p>
                            </Box>
                        </NewBox>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ display: 'flex' }}>
                        <NewBox margin='0.5rem 0 0 0' sx={{ flex: 1 }}>
                            <h3>Inversores Fotovoltaicos</h3>
                            <NewDivider height='3px' color='#F1F1F1' margin='0.3rem 0 0.5rem 0' />
                            <Box sx={{ padding: '1rem', width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                <p className={styles.text_justify}>Os inversores transformam a corrente contínua (CC) produzida pelos módulos fotovoltaicos em corrente alternada (CA) compatível com a rede elétrica da concessionária.</p>
                                <div className={styles.flex_center}>
                                    <img src={Inversores} alt='Inversores' />
                                </div>
                                <p>A CorpBrasil oferece a seus clientes inversores confiáveis de alto rendimento, com configuração simples e segura, além de gerenciamento inteligente.</p>
                            </Box>
                        </NewBox>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex' }}>
                        <NewBox margin='0.5rem 0 0 0' sx={{ flex: 1 }}>
                            <h3>Cabos e Conectores Solar</h3>
                            <NewDivider height='3px' color='#F1F1F1' margin='0.3rem 0 0.5rem 0' />
                            <Box sx={{ padding: '1rem', width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                <p className={styles.text_justify}>Os cabos são especialmente projetados para energia de corrente contínua e produzidos com materiais de alta resistência às intempéries, incluindo proteção UV. Eles são capazes de suportar temperaturas de até 120ºC em operação contínua.</p>
                                <div className={styles.flex_center}>
                                    <img src={Cabos} alt='Cabos' />
                                </div>
                                <p className={styles.text_justify}>Os conectores MC4 e MC6 garantem conexões seguras entre o inversor e os módulos fotovoltaicos, assegurando uma geração contínua de energia.</p>
                            </Box>
                        </NewBox>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex' }}>
                        <NewBox margin='0.5rem 0 0 0' sx={{ flex: 1 }}>
                            <h3>Estruturas Fotovoltaicas</h3>
                            <NewDivider height='3px' color='#F1F1F1' margin='0.3rem 0 0.5rem 0' />
                            <Box sx={{ padding: '1rem', width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                <p className={styles.text_justify}>Nossas estruturas para telhados de cerâmica, fibrocimento e metálicos são fabricadas com matéria-prima de alta qualidade, utilizando liga de alumínio de alta densidade. Os perfis, produzidos com técnicas de extrusão, garantem máxima resistência e segurança. Todos os parafusos são feitos de aço inoxidável. As estruturas para solo são galvanizadas a fogo, mantendo-se resistentes à corrosão.</p>
                                <div className={styles.system_structure}>
                                    <div className={styles.system_structure_text}>
                                        Telhado Cerâmico
                                    </div>
                                    <div className={styles.sketchfab}>
                                        <iframe title="Gancho Colonial Explosão" frameBorder="0" allowFullScreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking="true" execution-while-out-of-viewport='true' execution-while-not-rendered='true' web-share='true' src="https://sketchfab.com/models/0b715a3091a240eda3b8ef47b2c8b217/embed"></iframe>
                                    </div>
                                    {/* <ModalStructure open={open.estrutura} close={() => setOpen({ ...open, estrutura: false })} />
                                    <NewButton onClick={() => setOpen({ ...open, estrutura: true })}>
                                        Veja outras estruturas
                                    </NewButton> */}
                                    <ButtonSection text="Veja outras estruturas" open={open.estrutura} align="center" sx={{ padding: '0.95rem' }} onClick={() => setOpen({ ...open, estrutura: !open.estrutura })} />
                                    <Collapse sx={{ width: '100%', '.MuiCollapse-root': { width: '100%' } }} in={open.estrutura} timeout="auto" unmountOnExit>
                                        <div className={styles.system_structure}>
                                            <div className={styles.system_structure_text}>
                                                Telhado Fibrocimento
                                            </div>
                                            <div className={styles.sketchfab}>
                                                <iframe title="Prisioneiro Madeira" frameBorder="0" allowFullScreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking="true" execution-while-out-of-viewport="true" execution-while-not-rendered="true" web-share="true" src="https://sketchfab.com/models/9772a68d88194ba9a5f73e3a317536bd/embed"> </iframe>
                                            </div>
                                            <div className={styles.system_structure_text}>
                                                Telhado Metálico
                                            </div>
                                            <div className={styles.sketchfab}>
                                                <iframe title="Mini Trilho" frameBorder="0" allowFullScreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking="true" execution-while-out-of-viewport="true" execution-while-not-rendered="true" web-share="true" src="https://sketchfab.com/models/c1ae9f81829747abaad5c96c3c77704d/embed"> </iframe>
                                            </div>
                                            <div className={styles.system_structure_text}>
                                                Estrutura Solo
                                            </div>
                                            <div className={styles.sketchfab}>
                                                <iframe title="Solo Aluminio" frameBorder="0" allowFullScreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking="true" execution-while-out-of-viewport="true" execution-while-not-rendered="true" web-share="true" src="https://sketchfab.com/models/4cc7a00332924654a30a3b1e71f63a80/embed"> </iframe>
                                            </div>

                                        </div>
                                    </Collapse>
                                </div>
                            </Box>
                        </NewBox>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex' }}>
                        <div className={styles.system_box}>
                            <div className={styles.system_equipment}>
                                <div className={styles.system_equipment_title}>
                                    <Square />
                                    <h3>Qual a Importância de uma Boa Estrutura no Sistema Fotovoltaico</h3>
                                </div>
                                <p className={styles.text_justify}>Uma boa estrutura oferece suporte seguro e estável para os painéis solares, assegurando que eles estejam corretamente alinhados e posicionados para captar a máxima quantidade de luz solar. Além disso, ela protege os painéis contra danos causados por fatores ambientais, como ventos fortes, chuvas intensas e outros eventos climáticos, reduzindo a necessidade de manutenções frequentes e custos adicionais.</p>
                                <div className={styles.structure}>
                                    <div className={`${styles.structure_item} ${styles.denied}`}>
                                        <CancelRoundedIcon sx={{ fill: "#E04F5F" }} />
                                        <Box sx={{ textAlign: 'center' }}>
                                            <h3>Concorrência</h3>
                                            <h4 style={{ color: "#FF302F" }}>Sem garantia</h4>
                                        </Box>
                                        <img alt='' src={EstruturaR1} />
                                        <img alt='' src={EstruturaR2} />
                                        <img alt='' src={EstruturaR3} />
                                    </div>
                                    <div className={`${styles.structure_item} ${styles.accept}`}>
                                        <VerifiedRoundedIcon sx={{ fill: "#0E8C4A" }} />
                                        <Box sx={{ textAlign: 'center' }}>
                                            <h3>CORPBRASIL</h3>
                                            <h4 style={{ color: "#00B707" }}>Com garantia</h4>
                                        </Box>
                                        <img alt='' src={EstruturaB1} />
                                        <img alt='' src={EstruturaB2} />
                                        <img alt='' src={EstruturaB3} />
                                    </div>
                                </div>
                                <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '0.4rem', padding: '0rem 1rem 0rem 1rem', boxSizing: 'border-box' }}>
                                    <Box sx={{ lineHeight: '35px', marginBottom: '1rem' }}>
                                        <h3 className={styles.flex_center_align}><CancelRoundedIcon sx={{ fontSize: '25px', fill: "#E04F5F" }} /> Sem garantia</h3>
                                        <p>Estrutura ruim com pontos de oxidação, reduz a vida útil das placas solares, traz riscos de quebras e, em dias chuvosos, pode causar acidentes graves.</p>
                                    </Box>
                                    <Box sx={{ lineHeight: '35px' }}>
                                        <h3 className={styles.flex_center_align}><VerifiedRoundedIcon sx={{ fontSize: '25px', fill: "#0E8C4A" }} />Com garantia</h3>
                                        <p>Estrutura feita em liga de alumínio de alta qualidade, garante a longevidade, segurança e eficiência do sistema.</p>
                                    </Box>
                                </Box>
                            </div>
                        </div>
                    </Grid>
                    <div className={styles.system_box} style={{ marginTop: '3.5rem' }}>
                        {checkQuestion(1) ?
                            <div className={styles.system_question_item}>
                                <div className={styles.logo_circle}>
                                    <img src={Mulher} alt='' />
                                </div>
                                <VerifiedIcon sx={{ fontSize: '80px', fill: "#72cd4e" }} />
                                <h2>{customer?.nome}, agradecemos o seu Feedback!</h2>
                                <p>Sua resposta nos ajuda a entender que <b style={{ color: "#43b317" }}>estamos no caminho certo</b> para <b style={{ color: "#43b317" }}>garantir as melhores escolhas</b> para você!
                                    {/* <br />Continue descendo a página para ver mais detalhes sobre o <b>Estudo de Irradiação.</b> */}
                                </p>
                            </div> :
                            <div className={styles.system_question_item}>
                                <div className={styles.logo_circle}>
                                    <img src={Mulher} alt='' />
                                </div>
                                <h3>Existe algum fator que você considere crucial para seu projeto de energia solar?</h3>
                                <ThemeProvider theme={themeSystem}>
                                    <TextField
                                        multiline
                                        fullWidth
                                        color='primary'
                                        placeholder='Digite aqui...'
                                        sx={{ borderRadius: '10px', backgroundColor: "#F7F8F9" }}
                                        onChange={(e) => handleResponse(1, e.target.value)}
                                        rows={4} />
                                    {checkQuestionButton(1).remaining > 0 && <span>(Mínimo: {checkQuestionButton(1).remaining} Caracteres)</span>}
                                    <NewTooltip title={checkQuestionButton(1).remaining > 0 ? `Responda com mais de ${checkQuestionButton(1).remaining} caracteres para liberar o botão de Enviar` : ''} placement='top'>
                                        <Box component={'span'} width={'100%'}>
                                            <NewButton
                                                onClick={() => onSubmit(1)}
                                                color='success'
                                                disabled={checkQuestionButton(1).disabled}
                                                sx={{ textTransform: 'capitalize', fontWeight: 'bold', fontSize: '17px', maxWidth: '450px', letterSpacing: '0.1rem' }}
                                            >Enviar</NewButton>
                                        </Box>
                                    </NewTooltip>
                                </ThemeProvider>
                            </div>
                        }
                    </div>
                    {/* <Grid item xs={12} sx={{ display: 'flex' }}>
                        <Box lineHeight={'30px'} padding={'1rem'}>
                            <div className={styles.system_equipment_title}>
                                <Square />
                                <h3>Histórias reais</h3>
                            </div>
                            <p>Descubra a importância do estudo de irradiação e viabilidade em um sistema fotovoltaico.</p>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex' }}>
                        <div className={styles.system_box}>
                            <Box sx={{ width: '100%', textAlign: 'center' }}>
                                <h3>Estudo de Irradiação Solar</h3>
                                <NewDivider height='3px' color='#F1F1F1' margin='0.3rem 0 0.5rem 0' />
                            </Box>
                            <div className={styles.system_cases}>
                                <p>
                                    <b>Aconteceu:</b> Um cliente desejava instalar um sistema fotovoltaico para reduzir os custos com energia elétrica.
                                </p>
                                <p>
                                    <b>Análise Técnica:</b> Utilizando um software especializado para calcular as Horas de Sol Pleno (HSP), identificamos um problema significativo.
                                </p>
                                <div className={styles.system_cases_img_content}>
                                    <img alt='' src={Posicao} />
                                </div>
                                <p>
                                    <b>Conclusão:</b> Esta condição resultará em uma perda considerável de eficiência do sistema, reduzindo a geração de energia em aproximadamente 4 horas por dia. Isso implica em uma economia menor para o cliente, comprometendo os benefícios esperados da instalação do sistema fotovoltaico.
                                </p>
                                <p>Para uma análise detalhada, assista ao vídeo completo da nossa avaliação técnica.</p>
                                <div className={styles.system_cases_img_content}>
                                    <div className={styles.system_cases_video}>
                                        <img src='https://firebasestorage.googleapis.com/v0/b/corpbrasilagenda.appspot.com/o/Assets%2FPosicaoSolar_fast%20(1).gif?alt=media&token=a9580f50-e224-4f95-8209-2c23864e45f1' alt='' />
                                    </div>
                                </div>
                                <NewButton onClick={() => setOpen({ ...open, modal: true })}>
                                    Veja outro caso
                                </NewButton>
                            </div>
                        </div>
                    </Grid> */}
                </Grid>

                {/* <div className={styles.system_box} style={{ marginTop: '3.5rem' }}>
                    {checkQuestion(2) ?
                        <div className={styles.system_question_item}>
                            <VerifiedIcon sx={{ fontSize: '155px', fill: "#72cd4e" }} />
                            <h2>{customer?.nome}, muito obrigado pelo seu Feedback!</h2>
                        </div> :
                        <div className={styles.system_question_item}>
                            <div className={styles.logo_circle}>
                                <img src={Mulher} alt='' />
                            </div>
                            <h3>Como essas informações contribuirão para sua escolha na aquisição do sistema de energia solar?</h3>
                            <ThemeProvider theme={themeSystem}>
                                <TextField
                                    multiline
                                    fullWidth
                                    color='primary'
                                    placeholder='Digite aqui...'
                                    sx={{ borderRadius: '10px', backgroundColor: "#F7F8F9" }}
                                    onChange={(e) => handleResponse(2, e.target.value)}
                                    rows={4} />
                                {checkQuestionButton(2).remaining > 0 && <span>(Mínimo: {checkQuestionButton(2).remaining} Caracteres)</span>}
                                <NewTooltip title={checkQuestionButton(2).remaining > 0 ? `Responda com mais de ${checkQuestionButton(2).remaining} caracteres para liberar o botão de Enviar` : ''} placement='top'>
                                    <Box component={'span'} width={'100%'}>
                                        <NewButton
                                            onClick={() => onSubmit(2)}
                                            color='success'
                                            disabled={checkQuestionButton(2).disabled}
                                            sx={{ textTransform: 'capitalize', fontWeight: 'bold', fontSize: '17px', maxWidth: '450px', letterSpacing: '0.1rem' }}
                                        >Enviar</NewButton>
                                    </Box>
                                </NewTooltip>
                            </ThemeProvider>
                        </div>
                    }
                </div> */}

                <Box width={'100%'} padding={'1rem'} boxSizing={'border-box'}>
                    <div className={styles.system_equipment_title}>
                        <Square />
                        <h3>Conheça nossos parceiros</h3>
                    </div>
                </Box>
                <div className={styles.system_distributing}>
                    <div className={styles.system_box}>
                        <Box sx={{ width: '100%', textAlign: 'center' }}>
                            <h3>Amara Nzero</h3>
                            <NewDivider height='3px' color='#F1F1F1' margin='0.3rem 0 0.5rem 0' />
                        </Box>
                        <Box className={styles.video_container}>
                            <div>
                                <iframe allowFullScreen title="Apresentação" src="https://streamable.com/e/wntjam?loop=0" style={{ border:"none", width:"100%", height:"100%", position: "absolute", left:"0px", top:"0px", overflow: "hidden" }} />
                            </div>
                        </Box>
                        {/* <ModalDistributor open={open.distribuidor} close={() => setOpen({ ...open, distribuidor: false })} /> */}
                        {/* <NewButton onClick={() => setOpen({ ...open, distribuidor: true })}>
                            Outros parceiros
                        </NewButton> */}
                        <ButtonSection text="Outros parceiros" open={open.distribuidor} align="center" sx={{ padding: '0.95rem' }} onClick={() => setOpen({ ...open, distribuidor: !open.distribuidor })}></ButtonSection>
                        <Collapse in={open.distribuidor} sx={{ width: '100%' }} timeout="auto" unmountOnExit>
                            <Box sx={{ width: '100%', textAlign: 'center', marginTop: '0.5rem' }}>
                                <h3>Belenus</h3>
                                <NewDivider height='3px' color='#F1F1F1' margin='0.3rem 0 0.5rem 0' />
                            </Box>
                            <Box className={styles.video_container}>
                            <div>
                                <iframe allowFullScreen title="Apresentação" src="https://streamable.com/e/3lr5ip?loop=0" style={{ border:"none", width:"100%", height:"100%", position: "absolute", left:"0px", top:"0px", overflow: "hidden" }} />
                            </div>
                            </Box>
                            <Box sx={{ width: '100%', textAlign: 'center', marginTop: '1rem' }}>
                                <h3>CorSolar</h3>
                                <NewDivider height='3px' color='#F1F1F1' margin='0.3rem 0 0.5rem 0' />
                            </Box>
                            <Box className={styles.video_container}>
                            <div>
                                <iframe allowFullScreen title="Apresentação" src="https://streamable.com/e/wmm3ty?loop=0" style={{ border:"none", width:"100%", height:"100%", position: "absolute", left:"0px", top:"0px", overflow: "hidden" }} />
                            </div>
                            </Box>
                            <Box sx={{ width: '100%', textAlign: 'center', marginTop: '1rem' }}>
                                <h3>JNG</h3>
                                <NewDivider height='3px' color='#F1F1F1' margin='0.3rem 0 0.5rem 0' />
                            </Box>
                            <Box className={styles.video_container}>
                            <div>
                                <iframe allowFullScreen title="Apresentação" src="https://streamable.com/e/56pus5?loop=0" style={{ border:"none", width:"100%", height:"100%", position: "absolute", left:"0px", top:"0px", overflow: "hidden" }} />
                            </div>
                            </Box>
                        </Collapse>
                    </div>
                </div>

                {/* <div className={styles.system_box}>
                    <div className={styles.system_equipment}>
                        <div className={styles.system_equipment_title}>
                            <div />
                            <h3>Módulos Fotovoltaicos</h3>
                        </div>
                        <p className={styles.text_justify}>Tem a função de coletar os fotons de luz através da irradiação solar, gerando a corrente elétrica contínua (C.C.).</p>
                        <img src={Modulos} alt='' />
                        <p className={styles.text_justify}>Nossos orçamentos incluem módulos fotovoltaicos de altíssima qualidade, com certificações internacionais reconhecidas mundialmente por assegurar a melhor tecnologia disponível no mercado.</p>
                    </div>
                </div>
                <div className={styles.system_box}>
                    <div className={styles.system_equipment}>
                        <div className={styles.system_equipment_title}>
                            <div />
                            <h3>Inversores Fotovoltaicos</h3>
                        </div>
                        <p className={styles.text_justify}>Os inversores transformam a corrente contínua (CC) produzida pelos módulos fotovoltaicos em corrente alternada (CA) compatível com a rede elétrica da concessionária.</p>
                        <img src={Inversor} alt='' />
                        <p className={styles.text_justify}>A CorpBrasil oferece a seus clientes inversores confiáveis de alto rendimento, com gerenciamento inteligente, configuração simples e altamente segura.</p>
                    </div>
                </div>
                 <div className={styles.system_box}>
                    <div className={styles.system_equipment}>
                        <div className={styles.system_equipment_title}>
                            <div />
                            <h3>Cabos e Conectores Solar</h3>
                        </div>
                        <p className={styles.text_justify}>Os cabos são especialmente projetados para energia de corrente contínua e produzidos com materiais de alta resistência às intempéries, incluindo proteção UV. Eles são capazes de suportar temperaturas de até 120ºC em operação contínua.</p>
                        <img style={{ maxWidth: '600px' }} src={Cabos} alt='' />
                        <p className={styles.text_justify}>Os conectores MC4 e MC6 garantem conexões seguras entre o inversor e os módulos fotovoltaicos, assegurando uma geração contínua de energia.</p>
                    </div>
                </div>
                <div className={styles.system_box}>
                    <div className={styles.system_equipment}>
                        <div className={styles.system_equipment_title}>
                            <div />
                            <h3>Estruturas Fotovoltaicas</h3>
                        </div>
                        <p className={styles.text_justify}>As estruturas que utilizamos para telhados de cerâmica, fibrocimento e metálicos são fabricadas com matéria-prima de qualidade, em liga de alumínio de alta densidade. Os perfis, produzidos com técnicas de extrusão, garantem máxima leveza, resistência e segurança. Todos os parafusos são feitos de aço inoxidável. As estruturas para solo são galvanizadas a fogo, mantendo-se resistentes à corrosão.</p>
                        <div className={styles.system_structure}>
                            <div className={styles.system_structure_text}>
                                Telhado Cerâmico
                            </div>
                            <div className="sketchfab-embed-wrapper">
                                <iframe title="Gancho Colonial Explosão" frameBorder="0" allowFullScreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking="true" execution-while-out-of-viewport='true' execution-while-not-rendered='true' web-share='true' src="https://sketchfab.com/models/0b715a3091a240eda3b8ef47b2c8b217/embed"></iframe>
                            </div>
                            <ModalStructure open={open.estrutura} close={() => setOpen({ ...open, estrutura: false })} />
                            <NewButton onClick={() => setOpen({ ...open, estrutura: true })}>
                                Veja Outras Estruturas
                            </NewButton>
                        </div>
                    </div>
                </div> */}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center', margin: '2rem 0 0 0' }}>
                    <p className={styles.text_center}>Veja um pouco que nossos clientes estão dizendo sobre nós!</p>
                    <a href={`https://corpbrasil.app/saiba-mais?nome=${customer?.nome?.split(" ")[0]}`} target='_blank' rel="noreferrer">
                        <Google />
                    </a>
                </Box>
            </main>
            <Footer />
        </div>
    )
}

export default Materials;