export const darkenColor = (hexColor = "#000", percentage = 0.2) => {
  // Remove o símbolo #
  let color = hexColor.replace('#', '');

  // Se a cor estiver em formato curto (3 dígitos), converta para o formato completo (6 dígitos)
  if (color.length === 3) {
      color = color.split('').map(c => c + c).join('');
  }

  // Converte HEX para RGB
  let r = parseInt(color.substring(0, 2), 16);
  let g = parseInt(color.substring(2, 4), 16);
  let b = parseInt(color.substring(4, 6), 16);
  
  // Reduz cada componente RGB pela porcentagem fornecida
  r = Math.max(0, Math.min(255, Math.floor(r * (1 - percentage))));
  g = Math.max(0, Math.min(255, Math.floor(g * (1 - percentage))));
  b = Math.max(0, Math.min(255, Math.floor(b * (1 - percentage))));
  
  // Converte de volta para HEX, garantindo 2 dígitos por componente
  const newColor = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  
  return newColor;
}