import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, ThemeProvider, useMediaQuery } from '@mui/material'
import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import NewTooltip from '../../../components/Tooltip'
import { VisuallyHiddenInput } from '../../../components/InputFile'
import { theme } from '../../../data/theme'
import CloseIcon from '@mui/icons-material/Close';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import DeleteRounded from '@mui/icons-material/DeleteRounded';

import styles from './styles.module.scss';
import useViewDocBox from '../../../hooks/useViewDocBox';
import AudioRecorder from '../../../components/AudioRecorder/Index';
import Swal from 'sweetalert2';
import { toast } from '../../../components/Toast';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import useUploadPhotos from '../../../hooks/useUploadPhotos';
import { doc, updateDoc } from 'firebase/firestore';
import { dataBase } from '../../../firebase/database';
import Loading from '../../../components/Loading';
import moment from 'moment'
import axios from 'axios'
import useWindowWidth from '../../../hooks/useWindowWidth'

const AddProposal = ({ members, userRef, customer, view, close }) => {
    const storage = getStorage();
    const width = useWindowWidth();
    const { uploadImages } = useUploadPhotos();
    const [audio, setAudio] = useState(null);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [loading, setLoading] = useState(false);
    const { viewDoc } = useViewDocBox();
    const inputRef = useRef(null);
    const [name, setName] = useState('');
    const [roof, setRoof] = useState('');
    const [consumption, setConsumption] = useState(null);
    const [invoiceGenerator, setInvoiceGenerator] = useState([]);
    const [invoiceBenefit, setInvoiceBenefit] = useState([]);
    const [open, setOpen] = useState({
        generator: {
            open: false,
            type: ''
        },
        benefits: {
            open: false,
            type: ''
        },
    });

    const sendAudio = (e) => {
        const audio = e.target.files[0];
        setAudio({ file: URL.createObjectURL(audio), complete: audio });
    }

    const sendInvoice = async (e, type) => {
        const ref = e.target.files;
        const files = [];
        Array.from(ref, (image) => files.push({ file: URL.createObjectURL(image), complete: image }));
        if (type === 'geradora') {
            if (invoiceGenerator) {

                setInvoiceGenerator([...invoiceGenerator, ...files])
            } else {
                setInvoiceGenerator(files);
            }
        } else if (type === 'beneficiaria') {
            if (invoiceBenefit) {
                setInvoiceBenefit([...invoiceBenefit, ...files])
            } else {
                setInvoiceBenefit(files);
            }
        }
    }

    const deleteInvoice = (index, photos, type) => {

        if (index >= 0 && index < photos.length) {
            const newPhotos = [...photos];
            newPhotos.splice(index, 1);

            console.log("🚀 ~ deletePhoto ~ type:", newPhotos)
            switch (type) {
                case 'geradora':
                    setInvoiceGenerator(newPhotos);
                    break;
                case 'beneficiaria':
                    setInvoiceBenefit(newPhotos);
                    break;
                default:
            }
        }
    }

    const checkDoc = (doc) => {
        if (doc === 'generator') {
            if ((invoiceGenerator.length > 0 && open.generator.type === 'doc') ||
                (consumption?.geradora?.length > 1 && open.generator.type === 'input')) {
                return true
            } return false
        } else {
            if ((invoiceBenefit.length > 0 && open.benefits.type === 'doc') ||
                (consumption?.beneficiaria?.length > 1 && open.benefits.type === 'input')) {
                return true
            } return false
        }
    }

    const checkAll = () => {
        if (audio && (checkDoc('generator') || checkDoc('beneficiaria')) && name.length > 2 && roof.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    const backInvoice = (type) => {
        if (type === 'geradora') {
            setOpen({ ...open, generator: { open: false, type: '' } });
        } else {
            setOpen({ ...open, benefits: { open: false, type: '' } });
        }
    }

    const handleUpload = async (id) => {

        if (!audio) {
            // alert("Por favor, selecione um arquivo de áudio e uma foto primeiro!");
            return;
        }

        const audioRef = ref(storage, `Clientes/${id}/Mensagem-${Date.now()}.webm`);

        try {
            // Upload do áudio
            const audioSnapshot = await uploadBytes(audioRef, audio.complete);
            const audioURL = await getDownloadURL(audioSnapshot.ref);

            console.log('Áudio enviado com sucesso! URL:', audioURL);

            const filesRef = {};

            if (audio) {
                filesRef.mensagemVoz = {
                    url: audioURL,
                    ref: audioSnapshot.ref.fullPath
                };
            }

            return filesRef;

        } catch (error) {
            console.error('Erro ao enviar arquivos:', error);
        }
    };

    const requestProposal = async () => {
        try {

            const resultAlert = await Swal.fire({
                title: 'Atenção',
                html: `Você deseja solicitar um novo <b>Orçamento?</b>`,
                icon: "question",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0eb05f",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
            })

            let file = {
                geradora: {
                    url: [],
                    ref: []
                },
                beneficiaria: {
                    url: [],
                    ref: []
                }
            };

            if (resultAlert.isConfirmed) {
                setLoading(true);

                const resultGeradora = invoiceGenerator.length > 0 ? await uploadImages(invoiceGenerator, 'geradora', 0, customer.id, 'Clientes') : null;
                if (resultGeradora) {
                    file.geradora = resultGeradora.map(object => ({
                        url: object.downloadURL,
                        ref: object.fullPath
                    }));
                }

                const resultBeneficiaria = invoiceBenefit.length > 0 ? await uploadImages(invoiceBenefit, 'beneficiaria', 1, customer.id, 'Clientes') : null;
                if (resultBeneficiaria) {
                    file.beneficiaria = resultBeneficiaria.map(object => ({
                        url: object.downloadURL,
                        ref: object.fullPath
                    }));
                }

                const audioDoc = await handleUpload(customer.id);

                const proposalCustomer = customer.proposta ? [...customer.proposta] : [];
                let dateMaterial = customer.horario_materiais || null;
                if((!customer.envio_materiais && proposalCustomer.length === 0) || !customer.envio_materiais) {
                    await axios.post('https://n8n.corpbrasil.cloud/webhook/c79ec60d-8757-4dc3-8d9c-d8ede689f347', {
                        nome: customer.nome,
                        telefone: customer.telefone,
                        id: `${customer.id}-0`,
                        chave: 'Materiais'
                    })
                    dateMaterial = new Date();
                }
                await updateDoc(doc(dataBase, 'Clientes', customer.id), {
                    proposta: [
                        ...proposalCustomer,
                        {
                            nome: name,
                            telhado: roof,
                            createAt: new Date(),
                            documentos: {
                                geradora: open.generator.type === 'input' ? [{
                                    valor: consumption.geradora
                                }] :
                                    invoiceGenerator ? file.geradora : {}
                                ,
                                beneficiaria: open.benefits.type === 'input' ? [{
                                    valor: consumption.beneficiaria
                                }] :
                                    invoiceBenefit ? file.beneficiaria : {}
                            },
                            ...audioDoc
                        }
                    ],
                    envio_materiais: true,
                    horario_materiais: dateMaterial,
                    historico: [
                        ...customer.historico,
                        {
                            data: moment().format('DD/MM/YYYY - HH:mm'),
                            evento: 'Orçamento Solicitado',
                            text: `Solicitou um novo <b>Orçamento</b> chamado <b>${name}</b>.`,
                            usuario_id: userRef?.id,
                            usuario_nome: userRef?.nome
                        }
                    ]
                })

                const assistantRef = members.find(data => data.id === customer.assistente);
                const sellerRef = members.find(data => data.id === customer.consultora_uid);

                await axios.post('https://n8n.corpbrasil.cloud/webhook/72464d42-6085-47e9-b1ff-5035524d900c', {
                    orcamento: name,
                    cliente: customer.nome,
                    consultor: customer.consultora,
                    id: customer.id,
                    telefone: assistantRef?.telefone,
                    assistente: assistantRef?.nome,
                    cidade: customer.cidade,
                    telefone_cliente: customer.telefone,
                    id_vendedor: sellerRef?.id_sm || userRef?.id_sm,
                    id_assistente: customer.condicao_especial ? assistantRef?.id_sm_extra : assistantRef?.id_sm,
                    manychat: customer.horario_orcamento ? true : false
                });

                await Swal.fire({
                    title: 'Orçamento',
                    html: `O novo orçamento foi solicitado com sucesso!`,
                    icon: "success",
                    showConfirmButton: true,
                    showCloseButton: true,
                    confirmButtonColor: "#111",
                });

                setLoading(false);
                closeBox();

                // console.log({
                //     proposta: {
                //         nome: name,
                //         createAt: new Date(),
                //         fatura: {
                //             geradora: open.generator.type === 'input' ? {
                //                 valor: consumption.geradora
                //             } :
                //                 invoiceGenerator ? { ...file.geradora } : {}
                //             ,
                //             beneficiaria: open.benefits.type === 'input' ? {
                //                 valor: consumption.beneficiaria
                //             } :
                //                 invoiceBenefit ? { ...file.beneficiaria } : {}
                //         },
                //         ...audioDoc
                //     },
                // })
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
            toast({ icon: 'error', text: 'Ocorreu um erro ao solicitar um novo orçamento.' });
        }
    }

    const closeBox = () => {
        setTimeout(() => {
            setName('');
            setRoof('');
            setAudio(null);
            setInvoiceGenerator([]);
            setInvoiceBenefit([]);
            setOpen({
                generator: {
                    open: false,
                    type: ''
                },
                benefits: {
                    open: false,
                    type: ''
                }
            });
            setConsumption(null);
        }, 500);
        close();
    }

    return (
        <Dialog
            sx={{ overflow: 'hidden' }}
            open={view}
            fullScreen={fullScreen}
            maxWidth="md">
            <Loading view={loading} backgroundColor='#ffffffa1' height='100%' />
            <ThemeProvider theme={theme}>
                <IconButton
                    aria-label="close"
                    onClick={closeBox}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                ><CloseIcon /></IconButton>
                <DialogTitle align='center'>Solicitação de Orçamento</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <DialogContentText align='center'>O envio dos documentos <b>não é obrigatório</b>, porém facilita a geração do orçamento.</DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                required
                                label="Nome do Orçamento"
                                variant='outlined'
                                value={name || ''}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <InputLabel id="telhado">Telhado</InputLabel>
                                <Select
                                    labelId='telhado'
                                    label="Telhado"
                                    value={roof}
                                    onChange={(e) => setRoof(e.target.value)}
                                    required>
                                    <MenuItem value="Cerâmico">Cerâmico</MenuItem>
                                    <MenuItem value="Carport">Carport</MenuItem>
                                    <MenuItem value="Fibrocimento">Fibrocimento</MenuItem>
                                    <MenuItem value="Metálico">Metálico</MenuItem>
                                    <MenuItem value="Laje">Laje</MenuItem>
                                    <MenuItem value="Ecológico - PVC Plástico">Ecológico - PVC Plástico</MenuItem>
                                    <MenuItem value="Shingle">Shingle</MenuItem>
                                    <MenuItem value="Solo">Solo</MenuItem>
                                    <MenuItem value="Zipado">Zipado</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={`${styles.extra_container_item} ${checkDoc('generator') ? styles.confirm_color : ''}`}>
                                <ThemeProvider theme={theme}>
                                    <div className={styles.extra_container_item_title}>
                                        {open.generator.open &&
                                            <NewTooltip title='Voltar' placement='top'>
                                                <IconButton sx={{ position: 'absolute', left: 0 }} onClick={() => backInvoice('geradora')}>
                                                    <KeyboardReturnIcon />
                                                </IconButton>
                                            </NewTooltip>
                                        }
                                        <h3>Consumo da Geradora {checkDoc('generator') ? ' ✅' : null}</h3>
                                        {open.generator.type === 'doc' &&
                                            <Box sx={width > 600 && { position: 'absolute', display: 'flex', justifyContent: 'center', right: 0 }}>
                                                <Button
                                                    disableElevation
                                                    sx={{ textTransform: 'capitalize' }}
                                                    component="label"
                                                    variant="contained"
                                                    size='small'
                                                    onChange={(e) => sendInvoice(e, 'geradora')}
                                                    startIcon={<AddToPhotosIcon />}>
                                                    Enviar Imagem / PDF
                                                    <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg, application/pdf" />
                                                </Button>
                                            </Box>
                                        }
                                    </div>
                                    {open.generator.open ?
                                        open.generator.type === 'doc' ?
                                            invoiceGenerator?.map((invoice, index) => (
                                                <div key={index} className={styles.extra_container_item_file}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }} onClick={() => viewDoc(invoice)}>
                                                        <InsertDriveFileOutlinedIcon sx={{ fill: "#484848" }} />
                                                        <Link to='' style={{ color: "#000" }}>{invoice?.complete?.name}</Link>
                                                    </Box>
                                                    <NewTooltip id="Clickable" title='Excluir' placement='top'>
                                                        <IconButton className='clickable' color='error' onClick={() => deleteInvoice(index, invoiceGenerator, 'geradora')}>
                                                            <DeleteRounded />
                                                        </IconButton>
                                                    </NewTooltip>
                                                </div>
                                            )) :
                                            <TextField
                                                label="Digite o consumo médio"
                                                sx={{ backgroundColor: "#fff" }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="start">kWh</InputAdornment>,
                                                    inputProps: { style: { textAlign: 'center' } }
                                                }}
                                                type='number'
                                                value={consumption?.geradora ?? ''}
                                                onChange={(e) => setConsumption({ ...consumption, geradora: e.target.value })}
                                            /> :
                                        <div>
                                            <p>Você possui a <b>imagem</b> ou <b>PDF</b> da fatura?</p>
                                            <Box sx={{ display: 'flex', gap: '0.5rem', margin: '0.5rem', justifyContent: 'center' }}>
                                                <Button
                                                    disableElevation
                                                    variant='contained'
                                                    color='success'
                                                    onClick={() => setOpen({ ...open, generator: { open: true, type: 'doc' } })}
                                                    sx={{ textTransform: 'capitalize' }}>
                                                    Sim
                                                </Button>
                                                <Button
                                                    disableElevation
                                                    variant='contained'
                                                    onClick={() => setOpen({ ...open, generator: { open: true, type: 'input' } })}
                                                    sx={{ textTransform: 'capitalize' }}>
                                                    Não
                                                </Button>
                                            </Box>
                                        </div>
                                    }
                                </ThemeProvider>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={`${styles.extra_container_item} ${checkDoc('') ? styles.confirm_color : ''}`}>
                                <ThemeProvider theme={theme}>
                                    <div className={styles.extra_container_item_title}>
                                        {open.benefits.open &&
                                            <NewTooltip title='Voltar' placement='top'>
                                                <IconButton sx={{ position: 'absolute', left: 0 }} onClick={() => backInvoice('beneficiaria')}>
                                                    <KeyboardReturnIcon />
                                                </IconButton>
                                            </NewTooltip>
                                        }
                                        <h3>Consumo da Beneficiária{checkDoc('') ? ' ✅' : null}</h3>
                                        {open.benefits.type === 'doc' &&
                                            <Box sx={width > 600 && { position: 'absolute', display: 'flex', justifyContent: 'center', right: 0 }}>
                                                <Button
                                                    disableElevation
                                                    sx={{ textTransform: 'capitalize' }}
                                                    component="label"
                                                    variant="contained"
                                                    size='small'
                                                    onChange={(e) => sendInvoice(e, 'beneficiaria')}
                                                    startIcon={<AddToPhotosIcon />}>
                                                    Enviar Imagem / PDF
                                                    <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg, application/pdf" />
                                                </Button>
                                            </Box>}
                                    </div>
                                </ThemeProvider>
                                {open.benefits.open ?
                                    open.benefits.type === 'doc' ?
                                        invoiceBenefit?.map((invoice, index) => (
                                            <div key={index} className={styles.extra_container_item_file}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }} onClick={() => viewDoc(invoice)}>
                                                    <InsertDriveFileOutlinedIcon sx={{ fill: "#484848" }} />
                                                    <Link to='' style={{ color: "#000" }}>{invoice?.complete?.name}</Link>
                                                </Box>
                                                <NewTooltip id="Clickable" title='Excluir' placement='top'>
                                                    <IconButton className='clickable' color='error' onClick={() => deleteInvoice(index, invoiceBenefit, 'beneficiaria')}>
                                                        <DeleteRounded />
                                                    </IconButton>
                                                </NewTooltip>
                                            </div>
                                        )) :
                                        <TextField
                                            label="Digite o consumo médio"
                                            sx={{ backgroundColor: "#fff" }}
                                            type='number'
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">kWh</InputAdornment>,
                                                inputProps: { style: { textAlign: 'center' } }
                                            }}
                                            value={consumption?.beneficiaria ?? ''}
                                            onChange={(e) => setConsumption({ ...consumption, beneficiaria: e.target.value })}
                                        /> :
                                    <div>
                                        <p>Você possui a <b>imagem</b> ou <b>PDF</b> da fatura?</p>
                                        <Box sx={{ display: 'flex', gap: '0.5rem', margin: '0.5rem', justifyContent: 'center' }}>
                                            <Button
                                                disableElevation
                                                variant='contained'
                                                color='success'
                                                onClick={() => setOpen({ ...open, benefits: { open: true, type: 'doc' } })}
                                                sx={{ textTransform: 'capitalize' }}>
                                                Sim
                                            </Button>
                                            <Button
                                                disableElevation
                                                variant='contained'
                                                onClick={() => setOpen({ ...open, benefits: { open: true, type: 'input' } })}
                                                sx={{ textTransform: 'capitalize' }}>
                                                Não
                                            </Button>
                                        </Box>
                                    </div>
                                }
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={`${styles.extra_container_item} ${audio ? styles.confirm_color : ''}`}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <h3>Áudio *{audio ? ' ✅' : null}</h3>
                                    <p>Grave ou envie um áudio para complementar o <b>Orçamento</b>.</p>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem', flexDirection: 'column' }}>
                                    <AudioRecorder setAudio={setAudio} audio={audio} disabled={false} />
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                                        <Button
                                            disableElevation
                                            sx={{ textTransform: 'capitalize' }}
                                            component="label"
                                            variant="contained"
                                            onChange={(e) => sendAudio(e)}
                                            startIcon={<AudioFileIcon />}>
                                            Escolher Áudio
                                            <VisuallyHiddenInput ref={inputRef} multiple={false} type="file" accept="audio/*" />
                                        </Button>
                                    </Box>
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
                    <ThemeProvider theme={theme}>
                        <Button
                            sx={{ textTransform: 'capitalize' }}
                            variant='contained'
                            disableElevation
                            onClick={requestProposal}
                            disabled={!checkAll()}
                            color="success">
                            Solicitar
                        </Button>
                        <Button
                            sx={{ textTransform: 'capitalize' }}
                            disableElevation
                            onClick={closeBox}
                            variant='contained'
                            color="primary">
                            Fechar
                        </Button>
                    </ThemeProvider>
                </DialogActions>
            </ThemeProvider>
        </Dialog>
    )
}

export default AddProposal