import { useState, useEffect, memo } from 'react';
import moment from 'moment';
import { Typography } from '@mui/material';

const CountdownTimer = memo(({ targetDate, onFinish }) => { // Revisar o targetDate
  const [timeLeft, setTimeLeft] = useState("00:00");

  useEffect(() => {
    if (!targetDate || !targetDate.seconds) return;

    const targetMoment = moment(targetDate.seconds * 1000);

    const updateCountdown = () => {
      const now = moment();
      const duration = moment.duration(targetMoment.diff(now));

      if (duration.asMilliseconds() <= 0) {
        setTimeLeft("00:00");
        onFinish(); // Chama o callback ao terminar o contador
        clearInterval(interval);
        return;
      }

      const minutes = String(Math.floor(duration.asMinutes())).padStart(2, '0');
      const seconds = String(duration.seconds()).padStart(2, '0');
      setTimeLeft(`${minutes}:${seconds}`);
    };

    const interval = setInterval(updateCountdown, 1000);
    updateCountdown();

    return () => clearInterval(interval);
  }, [targetDate, onFinish]);

  return (
    <Typography fontWeight="bold">{timeLeft}</Typography>
  );
});

export const useCountdown = () => {
  return { CountdownTimer };
};
