import { Box, CircularProgress, Typography } from '@mui/material'

import Logo from '../images/Logo.png';
import { useEffect } from 'react';

const Loading = ({ view, backgroundColor = "#f5f5f5", height = '100%', fixed = true, iconSize = 50, width="100%" }) => {

  useEffect(() => {
    if (view && fixed) {
      window.scrollTo(0, 0);
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    // Limpeza no desmontagem do componente
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [view, fixed]);

  return (
    <Box
      display={view ? 'flex' : 'none'}
      bottom={0}
      top={0}
      left={0}
      right={0}
      position="absolute"
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      width={width}
      gap='0.5rem'
      overflow={'hidden'}
      height={height}
      zIndex='11112'
      backgroundColor={backgroundColor}>
      <CircularProgress sx={{ position: 'absolute' }} value={100} size={iconSize + 20} />
      <Box
        top={5}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" position={'relative'} component="div" color="textSecondary">
          <img style={{ width: iconSize }} src={Logo} alt='' />
        </Typography>
      </Box>
    </Box>
  )
}

export default Loading