// Função para importar todas as imagens de um diretório, removendo números e a extensão do nome do arquivo
function importAllImages(requireContext) {
  try {
    const keys = requireContext.keys();
    console.log('Imagens encontradas:', keys); // Verifica se o require.context encontra as imagens

    return keys.map((filePath) => {
      const fileName = filePath
        .replace('./', '') // Remove './' do caminho
        .replace(/\.[^/.]+$/, '') // Remove a extensão do arquivo
        .replace(/\d+/g, ''); // Remove os números do nome do arquivo

      const fileContent = requireContext(filePath); // Importa o conteúdo da imagem

      return {
        name: fileName, // Nome do arquivo sem números e sem extensão
        src: fileContent, // Conteúdo ou caminho da imagem
      };
    });
  } catch (error) {
    console.error("Erro ao importar imagens:", error);
    return [];
  }
}

// Importação de imagens dos projetos
const imagesProject = importAllImages(
  require.context('../images/projetos', false, /\.(png|jpe?g|svg)$/)
);

// Importação de imagens dos parceiros
const imagesPartners = importAllImages(
  require.context('../images/partners', false, /\.(png|jpe?g|svg)$/)
);

// Exporta as imagens importadas (nome sem números, sem extensão e caminho)
export { imagesProject, imagesPartners };
