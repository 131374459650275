import HMS_2000 from '../../../images/system/inversores/HMS-2000DW-4T.png';
import R6_10K from '../../../images/system/inversores/R6-10K-T2.png';
import SOLIS_S6 from '../../../images/system/inversores/SOLIS_S6-GR1P4K-S_MONOF.220V.png';
import TSOL_MS2000 from '../../../images/system/inversores/TSOL-MS2000.png';
import X1_BOOST from '../../../images/system/inversores/X1-BOOST_G4.png';
import X1_MINI from '../../../images/system/inversores/X1-MINI_6-5K_G4.png';
import SG30RS_L from '../../../images/system/inversores/SG3.0RS-L.png';
import SG40_50_60RS_L from '../../../images/system/inversores/SG4.0-5.0-6.0RS-L.png';
import SG10_0RS_L from '../../../images/system/inversores/SG10.0RS-L.png';
import X3_HYBRID_G4 from '../../../images/system/inversores/X3-HYBRID-G4.png';
import SAJ_R5 from '../../../images/system/inversores/SAJ-R5.png';
import SAJ_M2 from '../../../images/system/inversores/MICROSAJ-M2-2.25K-S4.png';
import Inversor from '../../../images/system/Inversor.png';
import BDM_2000 from '../../../images/system/inversores/NEP-BDM-2000-PCL.png';

import styles from '../styles.module.scss';
import { useMemo } from 'react';

const inversores = {
    Inversor: {
        'Padrão': Inversor,
        'SAJ R6': R6_10K,
        'SOLIS': SOLIS_S6,
        'X1-BOOST': X1_BOOST,
        'X1-MINI': X1_MINI,
        'SG3.0': SG30RS_L,
        'SG4.0': SG40_50_60RS_L,
        'SG5.0': SG40_50_60RS_L,
        'SG6.0': SG40_50_60RS_L,
        'SG10.0': SG10_0RS_L,
        'X3-HYBRID': X3_HYBRID_G4,
        'SAJ R5': SAJ_R5,
    },
    Micro_Inversor: {
        'SAJ M2': SAJ_M2,
        'BDM-2000': BDM_2000,
        'HMS-2000DW-4T': HMS_2000,
        'TSOL': TSOL_MS2000,
    }
};

const getInversorImageSource = (inversorName) => {
    for (const key in inversores.Inversor) {
        if (inversorName.includes(key)) {
            return { image: inversores.Inversor[key], typeInversor: 'Inversor' };
        }
    }
    for (const key in inversores.Micro_Inversor) {
        if (inversorName.includes(key)) {
            return { image: inversores.Micro_Inversor[key], typeInversor: 'Micro-Inversor' };
        }
    }
    return { image: Inversor, type: 'Inversor' };
};

const FindInversor = ({ inversorName, maxWidth, type }) => {
    // Usa useMemo para armazenar o resultado de getInversorImageSource até que inversorName mude
    const { image, typeInversor } = useMemo(() => getInversorImageSource(inversorName), [inversorName]);

    if (!inversorName) return null;

    if (type === 'info') return typeInversor;

    return (
        <div className={styles.irradiation_img} style={{ maxHeight: '500px' }}>
            <img src={image} alt='Inversor' style={{ maxWidth: maxWidth }} />
        </div>
    );
};

export default FindInversor;