import React, { useEffect, useState } from 'react'
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import NewTooltip from '../../../components/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, Tab, Tabs, TextField } from '@mui/material';
import { CustomTabPanel } from '../../../components/CustomTabPanel';
import moment from 'moment';
import { doc, updateDoc } from 'firebase/firestore';
import { dataBase } from '../../../firebase/database';
import sortData from '../../../functions/sortData';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import Swal from 'sweetalert2';
import User from '../../../images/icons/User.png';

import { formatPhoneBR } from '../../../functions/FormatPhoneBR';
import { toast } from '../../../components/Toast';
import axios from 'axios';


const TaskAssignment = ({ customer, responsible, setResponsible, userRef, checkComission, members,
    setAssistant, setRepresentative, setIndicator, assistant, representative, indicator, indicatorAll }) => {
    const [tabsValue, setTabsValue] = useState(0);
    const [responsibles, setResponsibles] = useState([]);
    const [representatives, setRepresentatives] = useState([]);
    const [assistantAll, setAssistantAll] = useState([]);
    const [open, setOpen] = useState({
        responsibles: false,
        representatives: false,
        indicators: false,
        modal: false,
    });
    const loadingResponsibles = open.responsibles && responsibles.length === 0;
    const loadingRepresentatives = open.representatives && representatives.length === 0;
    const loadingIndicators = open.indicators && indicatorAll.length === 0;

    useEffect(() => {
        if (members) {
            const filterResponsibles = members.filter((member) => member.cargo === 'Vendedor(a)' && !member.desativado)
            const filterRepresentatives = members.filter((member) => member.cargo === 'Representante')
            const filterSellersMaster = members.filter((member) => member.cargo === 'Vendedor(a)' && member.time);
            console.log(filterSellersMaster)
            setAssistantAll(filterSellersMaster.map((member) => {
                return {
                    nome: member.nome,
                    id: member.id,
                    img: member.photo?.url,
                    telefone: member.telefone
                }
            }).sort((a, b) => {
                if (a.nome < b.nome) {
                    return -1;
                }
                if (a.nome > b.nome) {
                    return 1;
                }
                return 0;
            }));

            setResponsibles(sortData(filterResponsibles.map((member) => {
                return {
                    nome: member.nome === 'Pós-Venda' ? member.nome_ref : member.nome,
                    id: member.id,
                    img: member.photo?.url
                }
            }), 'nome'));

            const representativesRef = sortData(filterRepresentatives.map((member) => {
                return {
                    nome: member.nome === 'Pós-Venda' ? member.nome_ref : member.nome,
                    id: member.id,
                    completo: `${member.nome}(${member.id_representante})`,
                    codigo: member.id_representante,
                    telefone: member.telefone,
                    cidade: member.cidade,
                    pix: member.pix
                }
            }), 'nome');

            const defaultRepresentative = {
                nome: 'Nenhum',
                id: '0',
                codigo: 'Nenhum'
            };

            if (userRef && userRef.cargo === 'Vendedor(a)' && userRef.time) {
                setRepresentatives([
                    defaultRepresentative,
                    ...representativesRef.filter(data => data.codigo.includes(userRef.time))]);
            } else {
                setRepresentatives([
                    defaultRepresentative,
                    ...representativesRef]);
            }
        }
    }, [members, userRef])

    useEffect(() => {
        if (!customer) return;

        // Find the responsible data only once
        const responsibleData = responsibles.find(data => data.id === customer.consultora_uid);
        console.log(responsibleData)
        const newResponsible = {
            nome: customer.consultora,
            id: customer.consultora_uid,
            img: responsibleData ? responsibleData.img : undefined,
        };
        setResponsible(prev => (JSON.stringify(prev) !== JSON.stringify(newResponsible) ? newResponsible : prev));

        // Set assistant only if it's not '0' or null
        if (customer.assistente && customer.assistente !== '0' && assistantAll) {
            const assistantRef = assistantAll.find(data => data.id === customer.assistente) || {};
            const newAssistant = {
                nome: assistantRef.nome || 'Nenhum',
                id: customer.assistente,
                img: assistantRef.img
            };
            setAssistant(prev => (JSON.stringify(prev) !== JSON.stringify(newAssistant) ? newAssistant : prev));
        }

        // Set representative
        if (customer.representante) {
            const newRepresentative = {
                nome: customer.representante.nome,
                id: customer.representante.id,
                codigo: customer.representante.codigo,
            };
            setRepresentative(prev => (JSON.stringify(prev) !== JSON.stringify(newRepresentative) ? newRepresentative : prev));
        }

        // Set indicator
        // setIndicator(prev => (prev !== customer.indicador ? customer.indicador : prev));
        if (customer.indicador) {
            setIndicator(customer.indicador);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer, assistantAll, responsibles]);

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleData = async (data, type) => {
        console.log(data, type);

        try {
            console.log(data)
            let props = {};
            if (type === 'responsável') {
                props = {
                    consultora: data.nome,
                    consultora_uid: data.id,
                    historico: [...customer.historico, {
                        data: moment().format('DD/MM/YYYY - HH:mm'),
                        evento: 'Cliente Alterado',
                        text: `[Responsável]: ${customer.consultora} > ${data.nome}`,
                        usuario_id: userRef?.id,
                        usuario_nome: userRef?.nome
                    }]
                }
                if (checkComission) {
                    await updateDoc(doc(dataBase, 'Comissoes', customer.id), {
                        backoffice_id: data.id
                    })
                }
            } else if (type === 'representante') {
                props = {
                    representante: data,
                    historico: [...customer.historico, {
                        data: moment().format('DD/MM/YYYY - HH:mm'),
                        evento: 'Cliente Alterado',
                        text: `[Representante]: ${customer.representante?.nome ?? 'Nenhum'} > ${data.nome}`,
                        usuario_id: userRef?.id,
                        usuario_nome: userRef?.nome
                    }]
                }
            } else if (type === 'indicador') {
                props = {
                    indicador: data,
                    historico: [...customer.historico, {
                        data: moment().format('DD/MM/YYYY - HH:mm'),
                        evento: 'Cliente Alterado',
                        text: `<b>[Indicador]:</b> ${customer.indicador?.nome ?? 'Nenhum'} > ${data.nome}`,
                        usuario_id: userRef?.id,
                        usuario_nome: userRef?.nome
                    }]
                }
            }
            await updateDoc(doc(dataBase, 'Clientes', customer.id), props);

        } catch (error) {
            textError(`o ${type}`);
            console.log('Erro: ', error)
        }
    }

    const handleAssistant = async (e) => {
        const { value } = e.target;

        try {
            const searchAssistant = assistantAll.find(data => data.nome === value) || { nome: 'Nenhum', id: '0' };

            setAssistant({
                nome: searchAssistant.nome,
                id: searchAssistant.id,
                img: searchAssistant.photo?.url
            });

            await updateDoc(doc(dataBase, 'Clientes', customer.id), {
                assistente: searchAssistant.id,
                assistente_nome: searchAssistant.nome,
                historico: [...customer.historico, {
                    data: moment().format('DD/MM/YYYY - HH:mm'),
                    evento: 'Cliente Alterado',
                    text: `<b>[Assistente]:</b> ${customer.assistente_nome || 'Nenhum'} > ${searchAssistant.nome}`,
                    usuario_id: userRef?.id,
                    usuario_nome: userRef?.nome
                }]
            })
            if(value !== 'Nenhum') {
                await axios.post('https://backend.botconversa.com.br/api/v1/webhooks-automation/catch/45898/KrVPOrmDDbo6/', {
                    cliente: customer.nome,
                    assistente: searchAssistant.nome,
                    telefone: searchAssistant.telefone,
                    id: customer.id
                })
            }
        } catch (error) {
            console.log('Erro: ', error)
            textError('a Assistente');
        }
    };

    const textError = (type) => {
        return toast({ icon: 'error', text: `Ocorreu um erro ao alterar ${type}.` })
    }

    const renderValue = (selectedValue) => {
        const selectedItem = assistantAll.find(option => option.nome === selectedValue) || {};

        const { img = '', nome = 'Nenhum' } = selectedItem;

        return (
            <Box display="flex" alignItems="center">
                {img && (
                    <img
                        src={img}
                        alt={nome}
                        style={{ width: 25, height: 25, marginRight: 8, borderRadius: '50%' }}
                    />
                )}
                <span>{nome}</span>
            </Box>
        );
    };

    const viewProfile = async (data) => {
        try {
          const userData = members.find(member => member.id === data.id) ?? data;
          return Swal.fire({
            title: userData?.nome,
            iconHtml: `<img src=${userData?.photo?.url ?? User} style="width: 100px" />`,
            customClass: {
              icon: 'no-border'
            },
            html: `Código: <b>${userData?.codigo ?? userData.id_representante}</b><br />` +
              `Telefone: <b>${formatPhoneBR(userData?.telefone)}</b><br />` +
              `Cidade: <b>${userData?.cidade}</b><br />` +
              `${userData?.pix ? 'Pix: <b>' + userData?.pix + '</b>' : ''}`,
            showCloseButton: true,
            confirmButtonText: 'Fechar',
            confirmButtonColor: "#000"
          })
        } catch (error) {
          console.log('ERRO:', error)
        }
      }

    const handleOpenModal = (index) => {
        setTabsValue(index);
        setOpen((prev) => ({...prev, modal: true}));
    }

    return (
        <><AvatarGroup sx={{ cursor: 'pointer' }} max={4} >
            <NewTooltip title={responsible.nome} placement='top'>
                <Avatar onClick={() => handleOpenModal(0)} alt={responsible.nome} src={responsible.img} />
            </NewTooltip>
            <NewTooltip title={assistant.nome} placement='top'>
                <Avatar onClick={() => handleOpenModal(1)} alt={responsible.nome} src={assistant.img} />
            </NewTooltip>
            <NewTooltip title={<div>
                {representative.nome}
                {userRef?.cargo === 'Administrador' && customer?.representante && customer?.representante?.nome !== 'Nenhum' &&      
                    <Button variant='contained' size='small' disableElevation sx={{ textTransform: 'capitalize', padding: '0', marginLeft: '0.3rem' }} onClick={() => viewProfile(customer.representante)}>Detalhes</Button>
                }
                </div>} placement='top'>
                <Avatar onClick={() => handleOpenModal(2)} alt={representative.nome} src="/static/images/avatar/3.jpg" />
            </NewTooltip>
            <NewTooltip title={<div>
                {indicator.nome}
                {userRef?.cargo === 'Administrador' && customer?.indicador && customer?.indicador?.nome !== 'Nenhum' && 
                    <Button variant='contained' size='small' disableElevation sx={{ textTransform: 'capitalize', padding: '0', marginLeft: '0.3rem' }} onClick={() => viewProfile(customer.indicador)}>Detalhes</Button>
                }
                </div>} placement='top'>
                <Avatar onClick={() => handleOpenModal(3)} alt={indicator.nome} src="/static/images/avatar/4.jpg" />
            </NewTooltip>
        </AvatarGroup>
            <Dialog
                open={open.modal}
                fullWidth
                maxWidth="sm">
                <IconButton
                    aria-label="close"
                    onClick={() => setOpen((prev) => ({...prev, modal: false}))}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                ><CloseIcon /></IconButton>
                <DialogTitle align="center">Alterar</DialogTitle>
                <DialogContent>
                    <Tabs value={tabsValue} onChange={(e, newValue) => setTabsValue(newValue)} aria-label="Informações do Lead" centered>
                        <Tab sx={{ textTransform: 'capitalize' }} label="Responsável" {...a11yProps(1)} />
                        <Tab sx={{ textTransform: 'capitalize' }} label="Assistente" {...a11yProps(2)} />
                        <Tab sx={{ textTransform: 'capitalize' }} label="Representante" {...a11yProps(3)} />
                        <Tab sx={{ textTransform: 'capitalize' }} label="Indicador" {...a11yProps(4)} />
                    </Tabs>
                    <CustomTabPanel value={tabsValue} index={0}>
                        <Autocomplete
                            value={responsible}
                            fullWidth
                            open={open.responsibles}
                            onOpen={() => {
                                setOpen((prev) => ({ ...prev, responsibles: true }));
                            }}
                            onClose={() => {
                                setOpen((prev) => ({ ...prev, responsibles: false }));
                            }}
                            loading={loadingResponsibles}
                            loadingText="Carregando..."
                            disabled={userRef?.cargo === 'Vendedor(a)' && !userRef?.time}
                            sx={{ alignItems: 'center', backgroundColor: "#fff", margin: '1rem 0' }}
                            onChange={(event, newValue) => {
                                if (newValue != null) {
                                    setResponsible({
                                        nome: newValue.nome,
                                        id: newValue.id,
                                        img: newValue.img
                                    });
                                    handleData({
                                        nome: newValue.nome,
                                        id: newValue.id
                                    }, 'responsável');
                                } else {
                                    // Caso seja necessário lidar com a limpeza do campo
                                    setResponsible({});
                                }
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            disableClearable={true}
                            id="free-solo-with-text-demo"
                            options={responsibles}
                            getOptionLabel={(option) => option.nome}
                            renderOption={(props, option) =>
                                <li {...props}>
                                    <ListItemIcon>
                                        <img src={option.img} alt={option.nome} style={{ width: 24, height: 24, borderRadius: '50%' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={option.nome} />
                                </li>
                            }
                            noOptionsText="Responsável não encontrado"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Responsável"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment:
                                            <InputAdornment position="start">
                                                <img src={responsible.img} alt={responsible.nome} style={{ width: 24, height: 24, borderRadius: '50%' }} />
                                            </InputAdornment>
                                    }} />
                            )}
                            isOptionEqualToValue={(option, value) => option.nome === value.nome}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={tabsValue} index={1}>
                        <FormControl fullWidth sx={{  margin: '1rem 0'  }}>
                            <InputLabel id="select-label">Assistente</InputLabel>
                            <Select
                                labelId="select-label"
                                id="select"
                                sx={{ backgroundColor: "#fff"}}
                                value={assistant.nome}
                                disabled={userRef?.cargo === 'Vendedor(a)' && userRef?.id !== customer.consultora_uid}
                                // disabled={userRef?.cargo === 'Vendedor(a)'}
                                label="Assistente"
                                onChange={handleAssistant}
                                renderValue={renderValue}
                            >
                                <MenuItem value={'Nenhum'}>
                                    <ListItemText primary={'Nenhum'} />
                                </MenuItem>
                                {assistantAll && assistantAll.map((data, index) => (
                                    <MenuItem key={index} value={data.nome}>
                                        <ListItemIcon>
                                            <img src={data.img} alt={data.nome} style={{ width: 24, height: 24, borderRadius: '50%' }} />
                                        </ListItemIcon>
                                        <ListItemText primary={data.nome} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </CustomTabPanel>
                    <CustomTabPanel value={tabsValue} index={2}>
                        <NewTooltip title={checkComission ? 'Não é possivel alterar o Representante quando o Cliente contém uma proposta ativa' : ''} placement={'top'}>
                            <span>
                                <Autocomplete
                                    value={representative.nome}
                                    disabled={checkComission || (userRef && !userRef.time)}
                                    sx={{ alignItems: 'center', backgroundColor: "#fff", margin: '1rem 0'  }}
                                    onChange={(event, newValue) => {
                                        if (newValue != null) {
                                            setRepresentative(newValue);
                                            handleData(newValue, 'representante');
                                        } else {
                                            // Caso seja necessário lidar com a limpeza do campo
                                            setRepresentative({});
                                        }
                                    }}
                                    open={open.representatives}
                                    onOpen={() => {
                                        setOpen((prev) => ({ ...prev, representatives: true }));
                                    }}
                                    onClose={() => {
                                        setOpen((prev) => ({ ...prev, representatives: false }));
                                    }}
                                    loading={loadingRepresentatives}
                                    loadingText="Carregando..."
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    disableClearable={true}
                                    id="free-solo"
                                    options={representatives}
                                    getOptionLabel={(option) => {

                                        if (typeof option === 'string') {
                                            return option;
                                        }

                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }

                                        return option.nome;
                                    }}
                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                    renderOption={(props, option) => (
                                        <ListItemButton {...props} selected={option.id === representative.id}>
                                            <ListItemText primary={option.nome} secondary={option.codigo !== 'Nenhum' ? option.codigo : ''} />
                                        </ListItemButton>
                                    )}
                                    noOptionsText="Representante não encontrado"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Representante"
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment:
                                                    <InputAdornment position="start">
                                                        <AccountCircleRoundedIcon />
                                                        {/* <NewTooltip title='Visualizar Perfil' placement='top'>
                            <span>
                              <IconButton disabled={userRef?.cargo !== 'Administrador' || !customer?.representante || customer?.representante?.nome === 'Nenhum'} onClick={() => viewProfile(customer.representante)}><AccountCircleRoundedIcon /></IconButton>
                            </span>
                          </NewTooltip> */}
                                                    </InputAdornment>
                                            }}
                                        />
                                    )}
                                />
                            </span>
                        </NewTooltip>
                    </CustomTabPanel>
                    <CustomTabPanel value={tabsValue} index={3}>
                        <Autocomplete
                            value={indicator?.nome}
                            // disabled={userRef?.id !== customer.consultora_uid && userRef.cargo !== 'Administrador'}
                            // disabled={userRef.cargo !== 'Administrador'}
                            sx={{ alignItems: 'center', backgroundColor: "#fff", margin: '1rem 0'  }}
                            onChange={(event, newValue) => {
                                if (newValue != null) {
                                    setIndicator(newValue);
                                    handleData(newValue, 'indicador');
                                } else {
                                    // Caso seja necessário lidar com a limpeza do campo
                                    setIndicator({});
                                }
                            }}
                            open={open.indicators}
                            onOpen={() => {
                                setOpen((prev) => ({ ...prev, indicators: true }));
                            }}
                            onClose={() => {
                                setOpen((prev) => ({ ...prev, indicators: false }));
                            }}
                            loading={loadingIndicators}
                            loadingText="Carregando..."
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            disableClearable={true}
                            id="free-solo"
                            options={indicatorAll}
                            getOptionLabel={(option) => {

                                if (typeof option === 'string') {
                                    return option;
                                }

                                if (option.inputValue) {
                                    return option.inputValue;
                                }

                                return option.nome;
                            }}
                            isOptionEqualToValue={(option, value) => option?.codigo === value?.codigo}
                            renderOption={(props, option) => (
                                <ListItemButton {...props} selected={option.codigo === indicator.codigo}>
                                    <ListItemText primary={option.nome} secondary={option.codigo !== '0' ? option.codigo : ''} />
                                </ListItemButton>
                            )}
                            noOptionsText="Indicador não encontrado"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Indicador"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment:
                                            <InputAdornment position="start">
                                                <AccountCircleRoundedIcon />
                                                {/* <NewTooltip title='Visualizar Perfil' placement='top'>
                                                    <span>
                                                        <IconButton disabled={userRef?.cargo !== 'Administrador' || !customer?.indicador || customer?.indicador?.nome === 'Nenhum'} onClick={() => viewProfile(customer.indicador)}><AccountCircleRoundedIcon /></IconButton>
                                                    </span>
                                                </NewTooltip> */}
                                            </InputAdornment>
                                    }}
                                />
                            )} />
                    </CustomTabPanel>
                </DialogContent>
                <DialogActions sx={{ padding: '1rem 2rem 1rem 2rem'}}>
                    <Button disableElevation sx={{ textTransform: 'capitalize' }} onClick={() => setOpen((prev) => ({...prev, modal: false}))} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog></>
    )
}

export default TaskAssignment