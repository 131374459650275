import { ThemeProvider } from '@emotion/react';
import React from 'react'
import { theme } from '../../../data/theme';
import { Box, IconButton } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ButtonCB from '../../../components/Button';


const ButtonSection = ({
    color = "#000000",
    onClick,
    children,
    endIcon,
    sx = {},
    icon,
    viewArrow = true,
    text,
    radius = '10px',
    align = 'start',
    subText,
    size = 'small',
    open = false,
    maxWidth = '350px',
    textDirection = 'column',
    ...props
}) => {
    return (

        <ThemeProvider theme={theme}>
            <ButtonCB
                disableElevation
                fullWidth
                sx={{
                    ...sx,
                    borderRadius: radius,
                }}
                variant="contained"
                endIcon={endIcon}
                background={color}
                onClick={onClick}
                {...props}
            >
                <Box 
                    sx={{ display: 'flex', 
                        justifyContent: align, 
                        alignItems: 'center', 
                        width: '100%', 
                        textTransform: 'capitalize', 
                        paddingLeft: '0.5rem',
                        minHeight: '38.5px' 
                    }}>
                    <Box sx={{ display: 'flex', gap: '1rem', fontSize: '16px', alignItems: 'center' }}>
                        {icon === false ? null : icon}
                        <Box sx={{
                            'span': {
                                color: "#BDFF00",
                                fontWeight: '400',
                                fontSize: '13px',
                                textAlign: align
                        },
                        "b": {
                            textAlign: align,
                            fontSize: size === 'small' ? '16px' : '20px'
                        },
                        display: 'flex',
                        gap: textDirection === 'row' ? '0.3rem' : '0',
                        flexDirection: textDirection,
                        alignItems: textDirection === 'row' ? 'center' : 'flex-start',
                        flexWrap: 'wrap'
                         }}>
                        <b>{text}</b><span>{subText}</span>
                    </Box>
                </Box>
                {viewArrow && 
                    <IconButton sx={{ position: 'absolute', right: '10px' }}>
                        {!open ?
                            <ExpandMoreIcon sx={{ fill: "#fff" }} /> :
                            <ExpandLessIcon sx={{ fill: "#fff" }} />
                        }
                    </IconButton>
                }
            </Box>
            {children}
        </ButtonCB>
        </ThemeProvider >
    );
};

export default ButtonSection