import React, { useEffect, useRef, useState } from 'react';

import styles from '../styles.module.scss';

// import { ReactComponent as Confirm } from '../../../images/system/icons/Confirm.svg';
import { ReactComponent as Google } from '../../../images/system/icons/Google.svg';
// import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
// import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

import Posicao from '../../../images/system/PosicaoSol1.jpeg';
import Logo from '../../../images/LogoCORPBRASIL.png';
import LogoMulher from '../../../images/LogoMulher.png';
import Telhado from '../../../images/system/projeto/Telhado.jpg';
import Irradiacao from '../../../images/system/projeto/Irradiacao.jpg';
import Projeto from '../../../images/system/projeto/Projeto.jpg';
import Projeto_Final from '../../../images/system/projeto/Projeto_Final.jpg';
import Padrao_Antigo from '../../../images/system/projeto/Padrao_Antigo.jpg';
import Padrao_Novo from '../../../images/system/projeto/Padrao_Novo.jpg';

// import EstruturaR3 from '../../../images/system/EstruturaR3.jpg';
// import EstruturaB1 from '../../../images/system/EstruturaB1.jpg';
// import EstruturaB2 from '../../../images/system/EstruturaB2.jpg';
// import EstruturaB3 from '../../../images/system/EstruturaB3.jpg';

// import Cabos from '../../../images/system/Cabos.png';

import { Box, Divider, Grid, styled } from '@mui/material';
// import ButtonSection from '../components/ButtonSection';
// import ReactPlayer from 'react-player';
import Loading from '../../../components/Loading';
import { doc, getDoc } from 'firebase/firestore';
import { dataBase } from '../../../firebase/database';
import { useParams } from 'react-router-dom';
// import ModalDistributor from '../components/ModalDistributor';
// import ModalStructure from '../components/ModalStructure';
// import Modulos from '../../../images/system/Modulo.jpg';
// import Inversores from '../../../images/system/Inversores.png';
// import ModalStructureQuality from '../components/ModalStructureQuality';
// import CustomAudioPlayerFixed from '../../../components/AudioPlayerFixed/Index';
// import audio from '../../../assets/audio/Michele-MateriaisFotovoltaicos.mp3';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ButtonCB from '../../../components/Button';
import Visit from '../components/Visit';
import Footer from '../components/Footer';

// const questions = [
//     {
//         perguntaId: 1,
//         pergunta: "Existe algum fator que você considere crucial para seu projeto de energia solar?"
//     },
//     {
//         perguntaId: 2,
//         pergunta: "Como essas informações contribuirão para sua escolha na aquisição do sistema de energia solar?"
//     },
//     // Adicione mais perguntas conforme necessário
// ];


const Projects = () => {
    const { combinedParam } = useParams();
    const [idCustomer, index] = combinedParam.split('-');
    const [open, setOpen] = useState({
        visit: false,
    });
    const [customer, setCustomer] = useState();
    const [loading, setLoading] = useState(true);
    const headerRef = useRef(null);
    const [refresh, setRefresh] = useState(false);
    const [assistant, setAssistant] = useState(null);
    const [seller, setSeller] = useState(null);
    const [system, setSystem] = useState(null);

    useEffect(() => {
        const fetchCustomerData = async () => {
            try {
                const customerDoc = await getDoc(doc(dataBase, 'Clientes', idCustomer));

                if (!customerDoc.exists()) {
                    setCustomer({});
                    setLoading(false);
                    window.location.replace("https://corpbrasil.com/");
                    return;
                }

                const customerData = { ...customerDoc.data(), id: customerDoc.id };
                setCustomer(customerData);

                const fetchMemberData = async (uid) => {
                    const memberDoc = await getDoc(doc(dataBase, 'Membros', uid));
                    return memberDoc.exists() ? { ...memberDoc.data(), id: memberDoc.id } : {};
                };

                const [assistant, seller] = await Promise.all([
                    fetchMemberData(customerData.assistente),
                    fetchMemberData(customerData.consultora_uid),
                ]);

                setAssistant(assistant);
                setSeller(seller);

                if (customerData && index !== undefined) {
                    // console.log('oi22')
                    const systemRef = customerData.planilha ? customerData.planilha[index] : null;
                    // console.log(systemRef)

                    // if (systemRef) {
                    //     setEstimate(customerData?.proposta?.find(data =>
                    //         data.nome.replace(/ /g, '_') === systemRef?.orcamento_solicitado.replace(/ /g, '_') || data.id === systemRef?.orcamento_id));
                    // }

                    if (!systemRef || !systemRef.extra) {
                        window.location.replace("https://corpbrasil.com/");
                        return;
                    }

                    if (systemRef.extra) {
                        setSystem(systemRef);
                        // const proposalData = customerData.proposta?.find((data) => data.simulacao_enviada === true && (data.nome === systemRef.orcamento_solicitado || data.id === systemRef.orcamento_id)) || {};
                        // const simulationData = proposalData?.simulacoes ? proposalData.simulacoes?.find((data) => data.status === 'Enviado') : null;
                        // setProposal(proposalData);
                        // setSimulation(simulationData);
                        // setInstallmentOptions(simulationData?.parcelas || []);
                        // setAnalyst(await fetchMemberData(systemRef?.visita_tecnica.responsavel_id || {}));
                        // const equipments = systemRef.equipamentos || [];
                        // setKwhTotal(equipments.reduce((acc, item) => acc + item.kwh_total, 0).toFixed(2));
                    } else {
                        window.location.replace("https://corpbrasil.com/");
                    }

                }

                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };
        fetchCustomerData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idCustomer, index, dataBase, refresh]);

    // const checkQuestion = (id) => {
    //     if (customer) {
    //         const responses = customer.planilha[index].feedback?.respostas;

    //         if (responses) {
    //             return responses.find((data) => data.perguntaId === id) ? true : false;
    //         }
    //     }

    //     return false;
    // }

    // const checkQuestionButton = (id) => {
    //     const checkResponse = responses.find(data => data.perguntaId === id);
    //     if (checkResponse) {
    //         console.log(responseLength - checkResponse.resposta.length)

    //         return {
    //             disabled: checkResponse.resposta.length >= responseLength ? false : true,
    //             remaining: responseLength - checkResponse.resposta.length
    //         }
    //     }

    //     return {
    //         disabled: true,
    //         remaining: 25
    //     };
    // }

    // const handleResponse = (id, value) => {
    //     console.log(value);

    //     setResponses(prevResponses => {
    //         const existingResponseIndex = prevResponses.findIndex(response => response.perguntaId === id);

    //         // Se o valor estiver vazio, remover a resposta do estado
    //         if (value.length === 0) {
    //             if (existingResponseIndex !== -1) {
    //                 return prevResponses.filter(response => response.perguntaId !== id);
    //             }
    //             return prevResponses;
    //         }

    //         // Adicionar ou atualizar a resposta
    //         const newResponse = {
    //             perguntaId: id,
    //             pergunta: questions.find(q => q.perguntaId === id).pergunta,
    //             resposta: value,
    //             timestamp: new Date()
    //         };

    //         if (existingResponseIndex !== -1) {
    //             // Atualizar a resposta existente
    //             const updatedResponses = [...prevResponses];
    //             updatedResponses[existingResponseIndex] = newResponse;
    //             return updatedResponses;
    //         } else {
    //             // Adicionar uma nova resposta
    //             return [...prevResponses, newResponse];
    //         }
    //     });
    // };

    // console.log(customer)

    // const onSubmit = async (id) => {

    //     try {
    //         const result = await Swal.fire({
    //             title: 'Atenção',
    //             html: `Você deseja realizar essa ação?`,
    //             icon: 'question',
    //             showCancelButton: true,
    //             confirmButtonColor: '#00B707',
    //             cancelButtonColor: '#d33',
    //             confirmButtonText: 'Sim',
    //             cancelButtonText: 'Não'
    //         });

    //         if (result.isConfirmed) {
    //             let feedbackRef = customer.feedback || [];
    //             // const feedback = customer.planilha[index].feedback;
    //             console.log(questions);
    //             console.log(...responses);

    //             feedbackRef = {
    //                 ...feedbackRef,
    //                 respostas: [
    //                     ...questions,
    //                     ...responses
    //                 ]
    //             }

    //             await updateDoc(doc(dataBase, 'Clientes', idCustomer), {
    //                 feedback: feedbackRef,
    //             })

    //             const newCustomerData = { ...customer };
    //             newCustomerData.feedback = feedbackRef;

    //             setCustomer(newCustomerData);

    //             // setResponseCheck(responseCheckRef);

    //         }
    //     } catch (error) {

    //     }
    // }

    const NewDivider = styled(Divider)(({ height, color, margin }) => ({
        width: '100%',
        height: height || '4px',
        border: 'none',
        margin: margin || 0,
        background: `linear-gradient(90deg, rgba(243, 146, 0, 0) 0%, ${color || '#F39200'} 49.5%, rgba(243, 146, 0, 0) 100%)`,
    }));

    const NewBox = styled('div')(({ padding, margin }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '15px',
        width: '100%',
        backgroundColor: '#fff',
        margin: margin || 0,
        padding: padding || '20px',
        boxSizing: 'border-box',
        boxShadow: '0px 10px 24px 0px #0000001A'
    }));

    const Square = styled('div')(() => ({
        padding: '0.5rem',
        backgroundColor: "#FFAB00"
    }))

    return (
        <div className={styles.estimate}>
            <HelmetProvider>
                <Helmet>
                    <title>
                        Projetos Fotovoltaicos | CORPBRASIL Energia Renovável</title>
                    <meta name="title" content="Projetos Fotovoltaicos | CORPBRASIL Energia Renovável" />
                    <meta name="description" content="A Experiência dos Nossos Clientes com Energia Solar" />
                </Helmet>
            </HelmetProvider>
            <Loading view={loading} />
            {/* <div className={styles.player_fixed}>
                <CustomAudioPlayerFixed url={audio} view={view} setView={setView} />
            </div> */}
            <div className={styles.estimate_header_fixed}>
                <div>
                    <img src={Logo} alt='' />
                    <h4>Projetos Fotovoltaicos</h4>
                </div>
                <NewDivider />
            </div>
            {/* <ModalCase open={open.modal} close={() => setOpen({ ...open, modal: false })} /> */}
            <Visit open={open.visit} close={() => setOpen({...open, visit: false})} customer={customer} index={index} assistant={assistant} seller={seller} paymentData={null} type={'Gostou'} system={system} setRefresh={setRefresh} page='Projeto'/>
            <main className={`${styles.system_container} ${styles.estimate}`}>
                <Grid className={styles.flex_center} container spacing={1} sx={{ width: '100%', maxWidth: '800px' }}>
                    <Grid item xs={9}>
                        <NewBox>
                            <Box sx={{ lineHeight: '25px' }}>
                                <h3>Olá {customer?.nome},</h3>
                                <p>veja um pouco sobre projetos!</p>
                            </Box>
                        </NewBox>
                    </Grid>
                    <Grid item xs={3} sm={'auto'} >
                        <NewBox padding='0.5rem'>
                            <img className={styles.logo} src={LogoMulher} alt='CORPBRASIL' />
                        </NewBox>
                    </Grid>
                </Grid>

                <Grid container spacing={1} sx={{ width: '100%', maxWidth: '1200px' }}>
                    {/* <Grid item xs={12}>
                        <Box ref={headerRef} lineHeight={'30px'} padding={'2rem 1rem'}>
                            <div className={styles.system_equipment_title}>
                                <Square />
                                <h3>Materiais Fotovoltaicos</h3>
                            </div>
                            <p>Entenda como fazer as escolhas dos materiais fotovoltaicos.</p>
                        </Box>
                    </Grid> */}
                    <Grid item xs={12}>
                        <NewBox margin='0.5rem 0 0 0' sx={{ flex: 1 }}>
                            <h3>A importância da visita técnica</h3>
                            <NewDivider height='3px' color='#F1F1F1' margin='0.3rem 0 0.5rem 0' />
                            <Box sx={{ padding: '1rem', width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                <p className={styles.text_justify}>A visita técnica é fundamental para garantir que seu sistema de energia solar seja projetado de forma ideal, e o mais legal? <b>Já está tudo incluído no orçamento que você recebeu!</b></p>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.3rem', margin: '0.5rem 0' }}>
                                    <div className={styles.system_equipment_title}>
                                        <Square />
                                        <h3>Imagem aérea</h3>
                                    </div>
                                    <div className={styles.flex_center}>
                                        <img src={Telhado} alt='Módulos' />
                                    </div>
                                    <p className={styles.text_justify}>Utilizamos imagens aéreas capturadas por drone para calcular a área útil do telhado, projetar as alturas das quedas e otimizar o layout.</p>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.3rem', margin: '0.5rem 0' }}>
                                    <div className={styles.system_equipment_title}>
                                        <Square />
                                        <h3>Projeto</h3>
                                    </div>
                                    <div className={styles.flex_center}>
                                        <img src={Projeto} alt='Módulos' />
                                    </div>
                                    <p className={styles.text_justify}>Com um software de engenharia israelense, desenvolvemos o projeto e simulamos possíveis obstáculos, identificando pontos de sombreamento que possam interferir na eficiência da geração de energia.</p>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.3rem', margin: '0.5rem 0' }}>
                                    <div className={styles.system_equipment_title}>
                                        <Square />
                                        <h3>Estudo de irradiação solar</h3>
                                    </div>
                                    <div className={styles.flex_center}>
                                        <img src={Irradiacao} alt='Módulos' />
                                    </div>
                                    <p className={styles.text_justify}>Nesta etapa, identificamos a melhor área do telhado para a instalação dos módulos fotovoltaicos, com base nos maiores índices de irradiação solar disponíveis em cada face do telhado.</p>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.3rem', margin: '0.5rem 0' }}>
                                    <div className={styles.system_equipment_title}>
                                        <Square />
                                        <h3>Projeto finalizado</h3>
                                    </div>
                                    <div className={styles.flex_center}>
                                        <img src={Projeto_Final} alt='Módulos' />
                                    </div>
                                    <p className={styles.text_justify}>Com o projeto finalizado, podemos agora apresentar ao cliente a disposição dos módulos em seu imóvel, garantindo total segurança e eficiência na geração de energia.</p>
                                </Box>
                            </Box>
                        </NewBox>
                    </Grid>
                    <Grid item xs={12}> {/* Prazo máximo de entrega */}
                        <NewBox margin='0.5rem 0 0 0' sx={{ flex: 1, border: '1px solid #FFAB00' }}>
                            <h3>Você sabia?</h3>
                            <NewDivider height='2px' color='#FFAB00' margin='0.3rem 0 1rem 0' />
                            <Box sx={{ padding: '0 1rem 1rem 1rem' }}>
                                <p>A <b>CorpBrasil Energia Renovável</b> é uma das poucas empresas no Brasil que realiza um estudo de irradiação e uma análise de viabilidade em todos os projetos <b>antes da assinatura do contrato.</b></p>
                            </Box>
                        </NewBox>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ maxWidth: '440px', width: '400px', margin: '1rem 0' }}>
                        {system?.visita ? 
                            <ButtonCB fullWidth size='large' startIcon={<EventAvailableIcon />} colorText="#209CFF" background="transparent" variant='outlined'>Visita Solicitada</ButtonCB> :
                            <ButtonCB fullWidth size='large' background="#209CFF" onClick={() => setOpen({...open, visit: true})}>Agendar Visita Técnica</ButtonCB>
                        }
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex' }}>
                        <NewBox margin='0.5rem 0 0 0' sx={{ flex: 1 }}>
                            <h3>Rede elétrica</h3>
                            <NewDivider height='3px' color='#F1F1F1' margin='0.3rem 0 0.5rem 0' />
                            <Box sx={{ padding: '1rem', width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                <p className={styles.text_justify}>Vamos falar sobre a rede elétrica do imóvel.</p>
                                <p>A norma NBR 5410 garante a segurança das instalações elétricas. Para novos pedidos de conexão ou aumento de carga, é essencial que o imóvel esteja em conformidade com essa norma. Somente após essa adequação a concessionária de energia aprovará o projeto de energia solar.</p>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.3rem', margin: '0.5rem 0' }}>
                                    <div className={styles.system_equipment_title}>
                                        <Square />
                                        <h3>Antes da adequação</h3>
                                    </div>
                                    <div className={styles.flex_center}>
                                        <img src={Padrao_Antigo} alt='Módulos' />
                                    </div>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.3rem', margin: '0.5rem 0' }}>
                                    <div className={styles.system_equipment_title}>
                                        <Square />
                                        <h3>Depois da adequação</h3>
                                    </div>
                                    <div className={styles.flex_center}>
                                        <img src={Padrao_Novo} alt='Módulos' />
                                    </div>
                                </Box>
                                <p>Em nossa visita técnica identificamos algumas melhorias elétricas necessárias para aprovação do projeto.</p>
                                <Box>
                                    <b>Principais melhorias:</b>
                                    <ul style={{ padding: '0.9rem 1rem 0 1rem', lineHeight: '25px' }}>
                                        <li>Troca dos disjuntores;</li>
                                        <li>Instalações de dispositivo de proteção contra surtos (DPS);</li>
                                        <li>Organização de cabos;</li>
                                        <li>Substituições de cabos elétricos;</li>
                                        <li>Reforma de padrão.</li>
                                    </ul>
                                </Box>
                            </Box>
                        </NewBox>
                    </Grid>
                    <Grid item xs={12}> {/* Prazo máximo de entrega */}
                        <NewBox margin='0.5rem 0 0 0' sx={{ flex: 1, border: '1px solid #FFAB00' }}>
                            <h3>Você sabia?</h3>
                            <NewDivider height='2px' color='#FFAB00' margin='0.3rem 0 1rem 0' />
                            <Box sx={{ padding: '0 1rem 1rem 1rem', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                <p>Na <b>CorpBrasil Energia Renovável</b> a visita técnica é completa!</p>
                                <p>Identificamos todos os pontos que podem prejudicar sua instalação, desde o telhado até a rede elétrica.</p>
                                <p>Nós cuidaremos de tudo para você, com total segurança e eficiência.</p>
                            </Box>
                        </NewBox>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ maxWidth: '440px', width: '400px', margin: '1rem 0' }}>
                        {system?.visita ? 
                            <ButtonCB fullWidth size='large' startIcon={<EventAvailableIcon />} colorText="#209CFF" background="transparent" variant='outlined'>Visita Solicitada</ButtonCB> :
                            <ButtonCB fullWidth size='large' background="#209CFF" onClick={() => setOpen({...open, visit: true})}>Agendar Visita Técnica</ButtonCB>
                        }
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex' }}>
                    <Box ref={headerRef} className={styles.flex_center_column} sx={{ width: '100%', padding: '16px 16px 0 16px' }}>
                        <div className={styles.system_equipment_title}>
                            <Square />
                            <h3>Histórias reais</h3>
                        </div>
                        <p>Descubra a importância do estudo de irradiação e viabilidade em um sistema fotovoltaico.</p>
                    </Box>
                    </Grid>    
                    <Grid item xs={12} sx={{ display: 'flex' }}>
                        <div className={styles.system_box}>
                            <Box sx={{ width: '100%', textAlign: 'center' }}>
                                <h3>Estudo de Irradiação Solar</h3>
                                <NewDivider height='3px' color='#F1F1F1' margin='0.3rem 0 0.5rem 0' />
                            </Box>
                            <div className={styles.system_cases}>
                                <p>
                                    <b>Aconteceu:</b> Um cliente desejava instalar um sistema fotovoltaico para reduzir os custos com energia elétrica.
                                </p>
                                <p>
                                    <b>Análise Técnica:</b> Utilizando um software especializado para calcular as Horas de Sol Pleno (HSP), identificamos um problema significativo.
                                </p>
                                <div className={styles.system_cases_img_content}>
                                    <img alt='' src={Posicao} />
                                </div>
                                <p>
                                    <b>Conclusão:</b> Esta condição resultará em uma perda considerável de eficiência do sistema, reduzindo a geração de energia em aproximadamente 4 horas por dia. Isso implica em uma economia menor para o cliente, comprometendo os benefícios esperados da instalação do sistema fotovoltaico.
                                </p>
                                <p>Para uma análise detalhada, assista ao vídeo completo da nossa avaliação técnica.</p>
                                <div className={styles.system_cases_img_content}>
                                    <div className={styles.system_cases_video}>
                                        <img src='https://firebasestorage.googleapis.com/v0/b/corpbrasilagenda.appspot.com/o/Assets%2FPosicaoSolar_fast%20(1).gif?alt=media&token=a9580f50-e224-4f95-8209-2c23864e45f1' alt='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ maxWidth: '440px', width: '400px', marginTop: '0.5rem' }}>
                        {system?.visita ? 
                            <ButtonCB fullWidth size='large' startIcon={<EventAvailableIcon />} colorText="#209CFF" background="transparent" variant='outlined'>Visita Solicitada</ButtonCB> :
                            <ButtonCB fullWidth size='large' background="#209CFF" onClick={() => setOpen({...open, visit: true})}>Agendar Visita Técnica</ButtonCB>
                        }
                        </Box>
                    </Grid>
                    {/* <div className={styles.system_box} style={{ marginTop: '3.5rem' }}>
                        {checkQuestion(1) ?
                            <div className={styles.system_question_item}>
                                <div className={styles.logo_circle}>
                                    <img src={Mulher} alt='' />
                                </div>
                                <VerifiedIcon sx={{ fontSize: '80px', fill: "#72cd4e" }} />
                                <h2>{customer?.nome}, agradecemos o seu Feedback!</h2>
                                <p>Sua resposta nos ajuda a entender que <b style={{ color: "#43b317" }}>estamos no caminho certo</b> para <b style={{ color: "#43b317" }}>garantir as melhores escolhas</b> para você!
                                    <br />Continue descendo a página para ver mais detalhes sobre o <b>Estudo de Irradiação.</b></p>
                            </div> :
                            <div className={styles.system_question_item}>
                                <div className={styles.logo_circle}>
                                    <img src={Mulher} alt='' />
                                </div>
                                <h3>Existe algum fator que você considere crucial para seu projeto de energia solar?</h3>
                                <ThemeProvider theme={themeSystem}>
                                    <TextField
                                        multiline
                                        fullWidth
                                        color='primary'
                                        placeholder='Digite aqui...'
                                        sx={{ borderRadius: '10px', backgroundColor: "#F7F8F9" }}
                                        onChange={(e) => handleResponse(1, e.target.value)}
                                        rows={4} />
                                    {checkQuestionButton(1).remaining > 0 && <span>(Mínimo: {checkQuestionButton(1).remaining} Caracteres)</span>}
                                    <NewTooltip title={checkQuestionButton(1).remaining > 0 ? `Responda com mais de ${checkQuestionButton(1).remaining} caracteres para liberar o botão de Enviar` : ''} placement='top'>
                                        <Box component={'span'} width={'100%'}>
                                            <NewButton
                                                onClick={() => onSubmit(1)}
                                                color='success'
                                                disabled={checkQuestionButton(1).disabled}
                                                sx={{ textTransform: 'capitalize', fontWeight: 'bold', fontSize: '17px', maxWidth: '450px', letterSpacing: '0.1rem' }}
                                            >Enviar</NewButton>
                                        </Box>
                                    </NewTooltip>
                                </ThemeProvider>
                            </div>
                        }
                    </div> */}

                    {/* <Grid item xs={12} sx={{ display: 'flex' }}>
                        <Box lineHeight={'30px'} padding={'1rem'}>
                            <div className={styles.system_equipment_title}>
                                <Square />
                                <h3>Histórias reais</h3>
                            </div>
                            <p>Descubra a importância do estudo de irradiação e viabilidade em um sistema fotovoltaico.</p>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex' }}>
                        <div className={styles.system_box}>
                            <Box sx={{ width: '100%', textAlign: 'center' }}>
                                <h3>Estudo de Irradiação Solar</h3>
                                <NewDivider height='3px' color='#F1F1F1' margin='0.3rem 0 0.5rem 0' />
                            </Box>
                            <div className={styles.system_cases}>
                                <p>
                                    <b>Aconteceu:</b> Um cliente desejava instalar um sistema fotovoltaico para reduzir os custos com energia elétrica.
                                </p>
                                <p>
                                    <b>Análise Técnica:</b> Utilizando um software especializado para calcular as Horas de Sol Pleno (HSP), identificamos um problema significativo.
                                </p>
                                <div className={styles.system_cases_img_content}>
                                    <img alt='' src={Posicao} />
                                </div>
                                <p>
                                    <b>Conclusão:</b> Esta condição resultará em uma perda considerável de eficiência do sistema, reduzindo a geração de energia em aproximadamente 4 horas por dia. Isso implica em uma economia menor para o cliente, comprometendo os benefícios esperados da instalação do sistema fotovoltaico.
                                </p>
                                <p>Para uma análise detalhada, assista ao vídeo completo da nossa avaliação técnica.</p>
                                <div className={styles.system_cases_img_content}>
                                    <div className={styles.system_cases_video}>
                                        <img src='https://firebasestorage.googleapis.com/v0/b/corpbrasilagenda.appspot.com/o/Assets%2FPosicaoSolar_fast%20(1).gif?alt=media&token=a9580f50-e224-4f95-8209-2c23864e45f1' alt='' />
                                    </div>
                                </div>
                                <NewButton onClick={() => setOpen({ ...open, modal: true })}>
                                    Veja outro caso
                                </NewButton>
                            </div>
                        </div>
                    </Grid> */}
                </Grid>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center', margin: '2rem 0 0 0' }}>
                    <p className={styles.text_center}>Veja um pouco que nossos clientes estão dizendo sobre nós!</p>
                    <a href={`https://corpbrasil.app/saiba-mais?nome=${customer?.nome?.split(" ")[0]}`} target='_blank' rel="noreferrer">
                        <Google />
                    </a>
                </Box>
            </main>
            <Footer />
        </div>
    )
}

export default Projects;