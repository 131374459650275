import { Alert, Autocomplete, Box, Button, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, List, ListItem, MenuItem, Select, styled, TextField, ThemeProvider, Typography } from '@mui/material'
import { useCallback, useEffect, useRef, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close';
import MuiDrawer from '@mui/material/Drawer';

import { AiOutlineLike } from "react-icons/ai";
import { AiOutlineDislike } from "react-icons/ai";
import { LiaHandPaperSolid } from "react-icons/lia";
import { MdDeleteForever } from "react-icons/md";
import { IoMdAddCircle } from "react-icons/io";
import { TbReport } from "react-icons/tb";
import { TiMessages } from "react-icons/ti";
import PreviewIcon from '@mui/icons-material/Preview';

import ButtonCB from '../../../components/Button';
import { theme } from '../../../data/theme';
import Swal from 'sweetalert2';
import { toast } from '../../../components/Toast';
import NewTooltip from '../../../components/Tooltip';
import { useActivity } from '../../../contexts/ActivityContext';
import useGoPage from "../../../hooks/useGoPage";
import { updateObj } from '../../../functions/updateObj';
import LockContent from '../../../components/LockContent';
import { useCountdown } from '../../../hooks/useCountdown';
import MessageBox from './MessageBox';
import { doc, getDoc } from 'firebase/firestore';
import { dataBase } from '../../../firebase/database';
import { Controller, useForm } from 'react-hook-form';
// import LockContent from '../../../components/LockContent';
import { TbReportMedical } from "react-icons/tb";

const analytsOptions = {
    'Pós-Vendas': [
        { value: 'FLNgLtdFELPoWV67PnHIMoUeIKC2', label: 'Ana Flávia' },
        { value: 'K6eQ7aNbB6d6s9R89Dx6K2rFxFB2', label: 'Kauana' },
    ],
    default: [
        { value: 'kYpEI7u8f4TlQuqPcHUXdAFWw0c2', label: 'Lara' },
        { value: '7z33h3zwBjdlmBgwDHOxtSA9Hjz1', label: 'Erika' },
        { value: 'NFgBFFbXpQUSd7futEkrarBznig2', label: 'Clécia' },
        { value: 'f71LYZnIFCWXmhHdKoj00DsYDi12', label: 'CorpBrasil' },
    ],
};

const ActivityBox = ({ open, close }) => {
    const { activityData, newActivitys, addNewActivity, updateActivityAtIndex, deleteNewActivity } = useActivity();
    const { CountdownTimer } = useCountdown();
    const [isFinished, setIsFinished] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [openBox, setOpenBox] = useState(false);
    const goPage = useGoPage();
    const customerRef = useRef(null); // Armazena o cliente atual sem renderizar
    const activityRef = useRef(null);
    const [feedback, setFeedback] = useState({
        nome: "",
        label: '',
        categoria: "",
        comentario: ""
    });
    const [activity, setActivity] = useState({});
    const { control } = useForm({
        defaultValues: {}
    });
    const [options] = useState([
        { label: "Oferta Aceita", category: "Positivo", disabled: false },
        { label: "Aguardando Retorno", category: "Neutro", disabled: false },
        { label: "Resposta Pendente", category: "Neutro", disabled: false },
        { label: "Fechamento Próximo", category: "Neutro", disabled: false },
        { label: "Aguardando Outros Orçamentos", category: "Neutro", disabled: false },
        { label: "Comprou com Concorrente", category: "Negativo", disabled: false },
        { label: "Cliente Desistiu", category: "Negativo", disabled: false },
        { label: "Sem Interesse", category: "Negativo", disabled: false },
        { label: "Oferta Rejeitada", category: "Negativo", disabled: false },
        { label: "Sem Opção de Crédito", category: "Negativo", step: "Financeiro", disabled: false },
        { label: "Sem Cartão de Crédito", category: "Negativo", step: "Financeiro", disabled: false },
        { label: "Sem Recursos Financeiros", category: "Negativo", step: "Financeiro", disabled: false },
        { label: "Sem Limite Aposentadoria", category: "Negativo", step: "Financeiro", disabled: false },
        { label: "Sem Saldo FGTS", category: "Negativo", step: "Financeiro", disabled: false },
    ])

    const [optionsActivitys, setOptionsActivitys] = useState([
        // { label: "Visita Técnica", category: "Positivo", step: "Visita Técnica", disabled: false },
        { label: "Contrato e Recebíveis", category: "Positivo", step: "Contrato", disabled: false },
        { label: "Orçamento", category: "Positivo", step: "Orçamento", disabled: false },
        { label: "Facilitação Financeira", category: "Positivo", step: "Simulação", disabled: false },
        { label: "Pós-Vendas", category: "Positivo", step: "Pós-Vendas", disabled: false },
        { label: "Faturamento", category: "Positivo", step: "Faturamento", disabled: false },
    ]);

    const onClose = () => {
        close();
    }

    const handleCountdownFinish = useCallback(() => {
        setIsFinished(true);
        console.log('')
    }, []);


    useEffect(() => {
        const fetchCustomerData = async () => {
            if (activityData && !customerRef.current) {
                try {
                    const customerDoc = await getDoc(doc(dataBase, 'Clientes', activityData?.cliente_id));
                    if (customerDoc.exists()) {
                        const customerRef = { id: customerDoc.id, ...customerDoc.data() };
                        setCustomer(customerRef);
                        customerRef.current = customerRef;
                    }
                } catch (error) {
                    console.error("Error fetching customer data:", error);
                }
            }
        }

        fetchCustomerData();
    }, [activityData]);

    useEffect(() => {
        if (activityData && !activityRef.current) {
            setActivity(activityData);
            activityRef.current = activityData;
        }
    }, [activityData]);

    const GroupHeader = styled('div')(({ color, background }) => ({
        position: 'sticky',
        top: '-8px',
        padding: '4px 10px',
        color: color,
        backgroundColor: background,
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        zIndex: '111'
    }));

    const GroupItems = styled('ul')({
        padding: 0,
    });

    const colorGroup = {
        Positivo: {
            color: '#1e4620',
            background: "#edf7ed",
            icon: <AiOutlineLike color='#1e4620' />,
        },
        Negativo: {
            color: '#5f2120',
            background: "#fdeded",
            icon: <AiOutlineDislike color='#5f2120' />,
        },
        Neutro: {
            color: '#663c00',
            background: "#fff4e5",
            icon: <LiaHandPaperSolid color='#663c00' />,
        },
    }

    const changeNewActivitySelected = (previousActivity, value) => {
        if (previousActivity) {
            // Reabilita a opção anterior, definindo 'disabled: false'
            setOptionsActivitys((prevOptions) =>
                prevOptions.map((option) =>
                    option.label === previousActivity ? { ...option, disabled: false } : option
                )
            );
        }

        // Desabilita a nova opção selecionada, definindo 'disabled: true'
        if (value) {
            setOptionsActivitys((prevOptions) =>
                prevOptions.map((option) =>
                    option.label === value ? { ...option, disabled: true } : option
                )
            );
        }
    };

    console.log('ATIVIDADES', optionsActivitys)

    // const handleFeedback = (event, newValue, index) => {

    //     if (newValue != null) {
    //         const newFeedback = {
    //             nome: newValue.label,
    //             categoria: newValue.category,
    //             comentario: feedback[index].comentario,
    //         }
    //         const allFeedback = [...feedback];
    //         allFeedback[index] = newFeedback;
    //         let newAllFeedback = allFeedback;
    //         if (index === 0) {
    //             newAllFeedback = allFeedback.map((data) => (
    //                 data.categoria === newValue.category ? { ...data } : { ...data, categoria: "", nome: "" }
    //             ))
    //         }
    //         setFeedback(newAllFeedback);
    //         changeFeedbackSelected(feedback[index].nome, newValue.label);

    //     } else {
    //         const newFeedback = {
    //             nome: "",
    //             categoria: "",
    //             comentario: "",
    //         }
    //         const allFeedback = [...feedback];
    //         allFeedback[index] = newFeedback;
    //         setFeedback(allFeedback);
    //         changeFeedbackSelected(feedback[index].nome, newValue?.label);
    //     }
    // };

    const localeResponsible = (typeActivity) => {
        switch (typeActivity) {
            case 'Contrato e Recebíveis':
                return 'NFgBFFbXpQUSd7futEkrarBznig2';
            case 'Orçamento':
                return customer?.assistente;
            case 'Facilitação Financeira':
                return 'NFgBFFbXpQUSd7futEkrarBznig2';
            case 'Pós-Vendas':
                return '';
            case 'Faturamento':
                return 'f71LYZnIFCWXmhHdKoj00DsYDi12';
            default:
                return '';
        }
    }

    const handleActivitys = (event, newValue, index) => {
        if (newValue != null) {
            const newActivity = {
                nome: newValue.label,
                categoria: newValue.category,
                etapa: newValue.step,
                titulo: 'Demanda',
                documentos: [],
                iniciado: false,
                responsavel: localeResponsible(newValue.label)
            };

            // Atualiza a atividade no índice especificado usando o contexto
            updateActivityAtIndex(index, newActivity);
            openActivityReport(newValue.step, index);
            changeNewActivitySelected(newActivitys[index].nome, newValue.label);

            // Verifique se é a primeira atividade e ajuste as categorias das demais
            if (index === 0) {
                newActivitys.forEach((activity, idx) => {
                    if (activity.categoria !== newValue.category && idx !== index) {
                        updateActivityAtIndex(idx, { ...activity, categoria: "", nome: "" });
                    }
                });
            }
        } else {
            const newActivity = { nome: "", categoria: "", etapa: "", documentos: [] };
            changeNewActivitySelected(newActivitys[index].nome, null);
            updateActivityAtIndex(index, newActivity);
        }
    };

    // const handleCommentary = (value, index) => {
    //     setNewActivitys(prevActivity =>
    //         prevActivity.map((item, idx) =>
    //             idx === index ? { ...item, comentario: value } : item
    //         )
    //     );
    // };

    console.log(newActivitys)

    const handleAddActivity = useCallback(() => {
        const newActivity = {
            nome: "",
            categoria: "",
            comentario: "",
            documentos: [],
            titulo: 'Demanda',
            responsavel: ""
        };

        addNewActivity(newActivity); // Adiciona atividade localmente
    }, [addNewActivity]);

    const removeActivity = async (index) => {
        try {
            const result = await Swal.fire({
                title: 'Atenção',
                html: `Você deseja excluir a <b>Atividade?</b>`,
                icon: "question",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0eb05f",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
            })
            if (result.isConfirmed) {
                changeNewActivitySelected(newActivitys[index]?.nome, null);
                deleteNewActivity(index);
                openActivityReport('Atual');
                // setNewActivitys(newActivity);
                // updateActivityAtIndex(index, { ...newActivity});
                toast({ icon: 'success', text: 'Atividade excluida com sucesso.' });
            }
        } catch (error) {
            toast({ icon: 'error', text: 'Ocorreu um erro ao excluir o feedback.', error: error });
        }
    };

    const drawerWidth = 240;

    const openedMixin = (theme) => ({
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    });

    const closedMixin = (theme) => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(0)})`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(0)})`,
        },
    });

    const DrawerFix = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            overflowX: 'hidden',
            boxSizing: 'border-box',
            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
        }),
    );

    const openActivityReport = (ref, index = 0) => {
        goPage(`/atividade/${activityData?.id}?mode=${ref}-${index}`, null);
    }

    return (
        <Box sx={{ position: 'relative' }}>
            <DrawerFix
                variant='permanent'
                anchor="right"
                ModalProps={{
                    keepMounted: true,
                }}
                open={open}>
                <Box sx={{ padding: 2 }}>
                    <IconButton
                        aria-label="close"
                        onClick={close}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon sx={{ fill: '#000' }} />
                    </IconButton>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant='p'>Atividade</Typography>
                        <Typography variant='h6'>{activityData?.titulo}</Typography>
                    </Box>
                    <Divider sx={{ margin: '0.5rem 0 1rem 0' }} />
                    <Alert icon={false} severity='info' sx={{ ".MuiAlert-message": { textWrap: 'auto' } }}>
                        <Typography>{activityData?.orientacao?.texto}</Typography>
                    </Alert>
                </Box>
                <Box sx={{ position: 'relative', padding: '0.5rem 0', }}>
                    <Grid container spacing={1} sx={{ marginLeft: '-4px' }}>
                        <Grid item xs={12} sx={{ padding: '0 1rem!important' }}>
                            <ButtonCB startIcon={<PreviewIcon />} variant='text' background="transparent" colorText="#00a5ff" onClick={() => window.open(`https://corpbrasil.app/orcamento/${activity?.orcamento_id}`, "_blank")}>Visualizar Orçamento</ButtonCB>
                        </Grid>
                        <Grid item xs={12} sx={{ padding: '0 1rem!important' }}>
                            <MessageBox open={openBox} customer={customer} close={() => setOpenBox(false)} />
                            <ButtonCB startIcon={<TiMessages />} background="transparent" colorText="#000000" onClick={() => setOpenBox(true)}>Mensagens Prontas</ButtonCB>
                        </Grid>
                        <Grid item xs={12} sx={{ padding: '0.3rem 1rem!important' }}>
                            <FormControl sx={{ margin: '0.1rem 0' }} fullWidth>
                                <InputLabel id="demo-simple-select-label">Atividade Concluida</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    MenuProps={{
                                        disableScrollLock: true, // Não deixa o scroll ser ocultado
                                    }}
                                    value={activity?.atividade?.concluido || ''}
                                    label="Atividade Concluida"
                                    required
                                    onChange={(e) => setActivity(updateObj(activity, 'atividade.concluido', e.target.value))}
                                >
                                    <MenuItem value={"Com Sucesso"}>✅ Com Sucesso</MenuItem>
                                    <MenuItem value={"Sem Sucesso"}>⭕️ Sem Sucesso</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={1} rowSpacing={2} sx={{ position: 'relative', marginTop: '0.2rem', marginLeft: '-4px' }}>
                        {!isFinished && (
                            <LockContent
                                text={<Box>
                                    <Typography>Desbloqueado em</Typography>
                                    <CountdownTimer targetDate={activityData?.desbloquear} onFinish={handleCountdownFinish} />
                                </Box>}
                            />
                        )}
                        {activityData?.titulo.includes("Contato 2") &&
                            <Grid item xs={12} sx={{ padding: '0.3rem 1rem!important' }}>
                                <FormControl sx={{ margin: '0.1rem 0' }} fullWidth>
                                    <InputLabel id="demo-simple-select-label">Teve Interação do Cliente?</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        MenuProps={{
                                            disableScrollLock: true,
                                        }}
                                        value={activity?.atividade?.interacao || ''}
                                        label="Teve Interação do Cliente?"
                                        required
                                        onChange={(e) => setActivity(updateObj(activity, 'atividade.interacao', e.target.value))}
                                    >
                                        <MenuItem value={"Sim"}>✅ Sim</MenuItem>
                                        <MenuItem value={"Não"}>⭕️ Não</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={12} sx={{ marginBottom: '0.3rem', padding: '0.3rem 1rem!important' }}>
                            <ButtonCB fullWidth startIcon={<TbReport />} onClick={() => openActivityReport('Atual')}>Resumo da Atividade</ButtonCB>
                        </Grid>
                        {/* <Divider sx={{ width: '100%', border: '1px dashed #BEBEBE', margin: '0.7rem 0 1rem 0' }} /> */}
                        <Grid item xs={12} sx={{ display: 'flex', gap: '0.2rem', padding: '1rem 1rem!important', backgroundColor: "#fff8d6", boxShadow: "0px 4px 24px 0px #0000001A" }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        fullWidth
                                        options={options}
                                        groupBy={(option) => option.category}
                                        getOptionLabel={(option) => option.label || ""}
                                        onChange={(event, newValue) => {
                                            setFeedback(newValue || { nome: "", categoria: "", comentario: "" });
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Feedback"
                                                required
                                                sx={{ backgroundColor: "#fff" }}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            {colorGroup[feedback?.categoria]?.icon}
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        )}
                                        renderOption={(props, option) => (
                                            <ListItem
                                                aria-disabled={option.disabled}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '0.3rem',
                                                    opacity: option.disabled ? 0.5 : 1,
                                                    pointerEvents: option.disabled ? "none" : "auto",
                                                }}
                                                {...props}
                                            >
                                                {colorGroup[option.category].icon} {/* Renderizando o ícone */}
                                                {option.label} {/* Renderizando o rótulo da opção */}
                                            </ListItem>
                                        )}
                                        renderGroup={(params) => (
                                            <List key={params.key}>
                                                <Box>
                                                    <GroupHeader color={colorGroup[params.group].color} background={colorGroup[params.group].background}>
                                                        {colorGroup[params.group].icon}{params.group}
                                                    </GroupHeader>
                                                    <GroupItems>{params.children}</GroupItems>
                                                </Box>
                                            </List>
                                        )}
                                        value={feedback?.label ? feedback : null}
                                        isOptionEqualToValue={(option, value) => {
                                            if (!value) return false; // Considera valores nulos como não iguais
                                            return option.label === value?.nome || option.label === value?.label;
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="comentario"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                required
                                                fullWidth
                                                multiline
                                                rows={3}
                                                sx={{ backgroundColor: "#fff" }}
                                                label={`Resumo ${feedback.label ? `(${feedback?.label})` : ''}`}
                                                onBlur={(e) => {
                                                    field.onBlur(); // Aciona o evento padrão de onBlur do Controller
                                                    setFeedback({ ...feedback, comentario: field.value }) // Chama a lógica desejada
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', gap: '0.2rem', padding: '1rem 1rem!important', backgroundColor: "#e3e3e3", marginTop: '1rem', boxShadow: "0px 4px 24px 0px #0000001A" }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        {newActivitys.length > 0 && (
                                            newActivitys.map((data, index) => (
                                                <>
                                                    <Grid item xs={12} sx={{ display: 'flex', gap: '0.2rem', padding: '0.3rem 0' }}>
                                                        <Autocomplete
                                                            fullWidth
                                                            options={optionsActivitys}
                                                            getOptionLabel={(option) => option.label}
                                                            onChange={(event, newValue) => handleActivitys(event, newValue, index)}
                                                            renderInput={(params) =>
                                                                <TextField {...params}
                                                                    label="Nova Atividade"
                                                                    sx={{ backgroundColor: "#fff" }}
                                                                    required />
                                                            }
                                                            renderOption={(props, option) => (
                                                                <ListItem aria-disabled={option.disabled}
                                                                    sx={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        gap: '0.3rem',
                                                                        opacity: option.disabled || option.label === activityData?.etapa ? 0.5 : 1,
                                                                        pointerEvents: option.disabled || option.label === activityData?.etapa ? "none" : "auto",
                                                                    }}
                                                                    {...props}>
                                                                    {option.label}
                                                                </ListItem>
                                                            )}
                                                            renderGroup={(params) => (
                                                                <List key={params.key}>
                                                                    <Box>
                                                                        <GroupHeader color={colorGroup[params.group].color} background={colorGroup[params.group].background}>
                                                                            {params.group}
                                                                        </GroupHeader>
                                                                        <GroupItems>{params.children}</GroupItems>
                                                                    </Box>
                                                                </List>
                                                            )}
                                                            value={newActivitys[index]?.nome ? optionsActivitys.find(option => option.label === newActivitys[index]?.nome) : null}
                                                            isOptionEqualToValue={(option, value) => {
                                                                // A função compara se as opções são iguais com base no valor selecionado
                                                                return option.label === value?.nome || option.label === value?.label;
                                                            }}
                                                        />
                                                        <NewTooltip title="Excluir Feedback" placement="top">
                                                            <IconButton color='error' sx={{ background: "#fdeded", borderRadius: '5px' }} onClick={() => removeActivity(index)}>
                                                                <MdDeleteForever />
                                                            </IconButton>
                                                        </NewTooltip>
                                                    </Grid>
                                                    <Grid item xs={12} sx={{ padding: '0.6rem 0' }}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">Analista</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                disabled={data.etapa !== 'Pós-Vendas'}
                                                                sx={{ backgroundColor: "#fff" }}
                                                                value={data.responsavel}
                                                                label="Analista"
                                                                onChange={(e) => updateActivityAtIndex(index, { ...newActivitys[index], responsavel: e.target.value })}
                                                            >
                                                                {(analytsOptions[data.etapa] || analytsOptions.default).map((option) => (
                                                                    <MenuItem key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} sx={{ padding: '0.6rem 0' }}>
                                                        <ButtonCB fullWidth background="#000"
                                                            disabled={data?.etapa?.length === 0 || !data?.etapa}
                                                            startIcon={<TbReportMedical />} onClick={() => openActivityReport(data?.etapa, index)}>Ficha da Atividade</ButtonCB>
                                                    </Grid>
                                                    {/* <Grid item xs={12}>
                                                        <Controller
                                                            name="comentario 2"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    required
                                                                    fullWidth
                                                                    multiline
                                                                    rows={3}
                                                                    label={`Resumo ${data.nome && `(${data.nome})`}`}

                                                                />
                                                            )}
                                                        />
                                                    </Grid> */}
                                                </>
                                            ))
                                        )}
                                    </Grid>
                                </Grid>
                                {newActivitys.length <= 3 && (
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <ButtonCB fullWidth background="#0288d1" startIcon={<IoMdAddCircle />} onClick={handleAddActivity}>
                                            Gerar Atividade
                                        </ButtonCB>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <ThemeProvider theme={theme}>
                        <Grid item xs={12}>
                            <Grid container spacing={1} sx={{ padding: '0 1rem 0 0.5rem' }}>
                                <Grid item xs={6}>
                                    <Button fullWidth variant='contained' sx={{ textTransform: 'capitalize' }} disableElevation onClick={onClose} color="success">
                                        Enviar
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button fullWidth variant='contained' sx={{ textTransform: 'capitalize' }} disableElevation onClick={onClose} color="primary">
                                        Cancelar
                                    </Button>
                                </Grid>
                            </Grid>       
                        </Grid>               
                        </ThemeProvider>
                    </Grid>
                </Box>
            </DrawerFix>
        </Box>
    )

}

export default ActivityBox;