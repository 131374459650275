export const formatDate = (timestamp) => {
    const date = timestamp.toDate(); // Converte o Firebase Timestamp para um objeto Date

    // Extrai os componentes da data
    const day = ('0' + date.getDate()).slice(-2); // Adiciona o zero à esquerda, se necessário
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // O mês começa do 0, então é necessário somar 1
    const year = date.getFullYear();

    // Extrai os componentes da hora
    const hours = ('0' + date.getHours()).slice(-2); // Adiciona o zero à esquerda, se necessário
    const minutes = ('0' + date.getMinutes()).slice(-2); // Adiciona o zero à esquerda, se necessário

    // Monta a string no formato desejado
    return `${day}/${month}/${year} às ${hours}:${minutes}`;
}
