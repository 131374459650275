import React, { useEffect, useRef, useState, memo } from 'react';
import Logo from '../../../images/LogoCORPBRASIL.png';

import styles from '../styles.module.scss';

import { Avatar, Box, Divider, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import { useParams } from 'react-router-dom';
import CustomAudioPlayer from '../../../components/AudioPlayerFixed/CustomAudioPlayer';
import { doc, getDoc } from 'firebase/firestore';
import { dataBase } from '../../../firebase/database';

import formatCurrency from '../../../functions/formatCurrency';
import Loading from '../../../components/Loading';
import { useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import ButtonCB from '../../../components/Button';
import Footer from '../components/Footer';
// import Payment from '../components/Payment';


const VisitTec = () => {
    const navigate = useNavigate();
    const { combinedParam } = useParams();
    const [idCustomer, index, keyURL] = combinedParam.split('-');
    const [customer, setCustomer] = useState({});
    const [analyst, setAnalyst] = useState(null);
    const [visit, setVisit] = useState(null);
    const [system, setSystem] = useState(null);
    const [loading, setLoading] = useState(true);
    // const [acceptImprovements, setAcceptImprovements] = useState(null);

    const NewDivider = styled(Divider)(({ height, color, margin }) => ({
        width: '100%',
        height: height || '4px',
        border: 'none',
        margin: margin || 0,
        background: `linear-gradient(90deg, rgba(243, 146, 0, 0) 0%, ${color || '#F39200'} 49.5%, rgba(243, 146, 0, 0) 100%)`,
    }));

    const NewBox = styled('div')(({ padding, margin }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '15px',
        width: '100%',
        backgroundColor: '#fff',
        margin: margin || 0,
        padding: padding || '1rem',
        boxSizing: 'border-box',
        overflow: 'hidden',
        boxShadow: '0px 10px 24px 0px #0000001A'
    }));

    const Square = styled('div')(() => ({
        padding: '0.5rem',
        backgroundColor: "#FFAB00"
    }))

    // const ButtonST = styled(Button)(({ colorText = "#fff", background }) => ({
    //     borderRadius: '10px',
    //     fontSize: '15px',
    //     boxShadow: 'none',
    //     fontWeight: '600',
    //     textTransform: 'initial',
    //     borderColor: colorText,
    //     color: colorText || "#fff",
    //     backgroundColor: background || '#FFAB00',
    //     '&:hover': {
    //         boxShadow: 'none',
    //         backgroundColor: darkenColor(background || "#FFAB00", 0.1),
    //     }
    // }));

    const orcamentoRef = useRef(null);
    const headerRef = useRef(null);
    const [viewedOrcamento, setViewedOrcamento] = useState(false);
    const visitaRef = useRef(null);
    const simulacaoRef = useRef(null);

    useEffect(() => {
        if (keyURL === 'visita' && visitaRef.current) {
            setTimeout(() => {
                visitaRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 500); // Ajuste o tempo conforme necessário
        } else if (keyURL === 'simulacao' && simulacaoRef.current) {
            setTimeout(() => {
                simulacaoRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 500); // Ajuste o tempo conforme necessário
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, system]);

    useEffect(() => {
        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Atualize o estado com o id do elemento visível
                    setViewedOrcamento(entry.target.id);
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.9, // Considera 50% de visibilidade para acionar
        });

        // Salve as referências dentro do effect
        const orcamentoElement = orcamentoRef.current;
        const headerElement = headerRef.current;
        const visitaElement = visitaRef.current;

        if (orcamentoElement) {
            observer.observe(orcamentoElement);
        }
        if (headerElement) {
            observer.observe(headerElement);
        }
        if (visitaElement) {
            observer.observe(visitaElement);
        }

        return () => {
            // Utilize as referências salvas no momento da execução do efeito
            if (orcamentoElement) {
                observer.unobserve(orcamentoElement);
            }
            if (headerElement) {
                observer.unobserve(headerElement);
            }
            if (visitaElement) {
                observer.unobserve(visitaElement);
            }
        };
    }, [orcamentoRef, headerRef, visitaRef]);


    console.log(viewedOrcamento)

    useEffect(() => {
        const fetchCustomerData = async () => {
            try {
                const customerDoc = await getDoc(doc(dataBase, 'Clientes', idCustomer));

                if (!customerDoc.exists()) {
                    setCustomer({});
                    setLoading(false);
                    window.location.replace("https://corpbrasil.com/");
                    return;
                }

                const customerData = { ...customerDoc.data(), id: customerDoc.id };
                setCustomer(customerData);

                const fetchMemberData = async (uid) => {
                    const memberDoc = await getDoc(doc(dataBase, 'Membros', uid));
                    return memberDoc.exists() ? { ...memberDoc.data(), id: memberDoc.id } : {};
                };


                if (customerData && index !== undefined) {
                    // console.log('oi22')
                    const systemRef = customerData.planilha ? customerData.planilha[index] : null;
                    // console.log(systemRef)

                    if (!systemRef.visita_tecnica) {
                        window.location.replace("https://corpbrasil.com/");
                        return;
                    }

                    if (systemRef) {
                        setSystem(systemRef);
                        // const proposalData = customerData.proposta?.find((data) => data.simulacao_enviada === true && (data.nome === systemRef.orcamento_solicitado || data.id === systemRef.orcamento_id)) || {};
                        // const simulationData = proposalData?.simulacoes ? proposalData.simulacoes?.find((data) => data.status === 'Enviado') : null;
                        // setProposal(proposalData);
                        // setSimulation(simulationData);
                        setAnalyst(await fetchMemberData(systemRef?.visita_tecnica.responsavel_id || {}));
                        // const equipments = systemRef.equipamentos || [];
                        // setKwhTotal(equipments.reduce((acc, item) => acc + item.kwh_total, 0).toFixed(2));
                    } else {
                        window.location.replace("https://corpbrasil.com/");
                    }

                }

                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };
        fetchCustomerData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idCustomer, index, dataBase, navigate]);

    useEffect(() => {
        if (system) {
            setVisit(system?.visita_tecnica || null);
            
            // if (system?.melhorias) {
            //     setAcceptImprovements(true);
            // }
        }
    }, [system])

    // const onSubmit = async (type, value) => {

    //     const result = await Swal.fire({
    //         title: 'Parabéns! Você está fazendo uma ótima escolha!',
    //         iconHtml: `<img src=${LogoMulher} style="width: 120px" />`,
    //         customClass: {
    //             icon: 'no-border'
    //         },
    //         html: `Ao confirmar o método de pagamento, você garantirá a <b>reserva dos equipamentos</b> e o <b>agendamento de sua visita técnica</b>. Deseja prosseguir?`,
    //         icon: 'question',
    //         showCancelButton: true,
    //         showCloseButton: true,
    //         confirmButtonColor: '#00B707',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Sim',
    //         cancelButtonText: 'Não'
    //     });

    //     if (result.isConfirmed) {
    //         try {
    //             setLoading(true);

    //             const payment = type === 'Pagamento à Vista' ? {
    //                 tipo: type,
    //                 valor: Number(system.orcamento?.valor)
    //             } : {
    //                 tipo: type,
    //                 parcelas: value.quantidade,
    //                 valor: Number(value.valor),
    //             };

    //             let newSheets = customer.planilha;
    //             newSheets.splice(index, 1, {
    //                 ...system,
    //                 reserva: true,
    //                 pagamento: payment,
    //                 historico: [
    //                     ...system.historico,
    //                     {
    //                         data: moment().format('DD/MM/YYYY HH:mm'),
    //                         text: `Orçamento: O cliente aceitou ✅ a reserva dos materiais e optou pelo ${type}.`,
    //                     }
    //                 ]
    //             })

    //             const proposals = customer.proposta;
    //             if (type === 'Financiamento') {
    //                 proposal.status = 'Aceito';
    //             }

    //             await updateDoc(doc(dataBase, 'Clientes', idCustomer), {
    //                 planilha: newSheets,
    //                 proposta: proposals,
    //                 historico: [...customer.historico, {
    //                     data: moment().format('DD/MM/YYYY - HH:mm'),
    //                     evento: 'Orçamento',
    //                     text: `O Cliente ✅ aceitou a reserva dos materias do orçamento <b>${system.orcamento_solicitado}</b> e selecionou o método de pagamento '${type}'.`,
    //                     usuario_id: '0',
    //                     usuario_nome: 'robo'
    //                 }]
    //             });

    //             await axios.post('https://n8n.corpbrasil.cloud/webhook/a68ee6cd-2d99-4015-85bd-f8cdce709cbc', {
    //                 nome: customer.nome,
    //                 cidade: customer.cidade,
    //                 telefone: customer.telefone,
    //                 telefone_assistente: assistant.telefone,
    //                 kit: system.kit,
    //                 consultora: customer.consultora,
    //                 assistente: assistant.nome,
    //                 reserva: '✅ Aceito',
    //                 metodo: type,
    //                 chave: 'Reserva',
    //                 valor: value,
    //                 idFull: `${idCustomer}-${index}`,
    //                 id: idCustomer,
    //             });

    //             setLoading(false);
    //             await Swal.fire({
    //                 title: 'Parabéns!',
    //                 html: `A <b>Reserva</b> foi solicitada com sucesso.`,
    //                 icon: "success",
    //                 showConfirmButton: true,
    //                 showCloseButton: true,
    //                 confirmButtonColor: "#111",
    //             })
    //             setRefresh(true);

    //         } catch (error) {
    //             setLoading(false);
    //             console.log('Erro ao enviar feedback:', error);
    //         }
    //     }
    // }

    // const onSubmit = async (type, value) => {

    //     const result = await Swal.fire({
    //         title: 'Parabéns! Você está fazendo uma ótima escolha!',
    //         iconHtml: `<img src=${LogoMulher} style="width: 120px" />`,
    //         customClass: {
    //             icon: 'no-border'
    //         },
    //         html: `Ao confirmar o método de pagamento, você garantirá a <b>reserva dos equipamentos</b> e o <b>agendamento de sua visita técnica</b>. Deseja prosseguir?`,
    //         icon: 'question',
    //         showCancelButton: true,
    //         showCloseButton: true,
    //         confirmButtonColor: '#00B707',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Sim',
    //         cancelButtonText: 'Não'
    //     });

    //     if (result.isConfirmed) {
    //         try {
    //             setLoading(true);

    //             const payment = type === 'Pagamento à Vista' ? {
    //                 tipo: type,
    //                 valor: Number(system.orcamento?.valor)
    //             } : {
    //                 tipo: type,
    //                 parcelas: value.quantidade,
    //                 valor: Number(value.valor),
    //             };

    //             let newSheets = customer.planilha;
    //             newSheets.splice(index, 1, {
    //                 ...system,
    //                 reserva: true,
    //                 pagamento: payment,
    //                 historico: [
    //                     ...system.historico,
    //                     {
    //                         data: moment().format('DD/MM/YYYY HH:mm'),
    //                         text: `Orçamento: O cliente aceitou ✅ a reserva dos materiais e optou pelo ${type}.`,
    //                     }
    //                 ]
    //             })

    //             const proposals = customer.proposta;
    //             if(type === 'Financiamento') {
    //                 proposal.status = 'Aceito';
    //             }

    //             await updateDoc(doc(dataBase, 'Clientes', idCustomer), {
    //                 planilha: newSheets,
    //                 proposta: proposals,
    //                 historico: [...customer.historico, {
    //                     data: moment().format('DD/MM/YYYY - HH:mm'),
    //                     evento: 'Orçamento',
    //                     text: `O Cliente ✅ aceitou a reserva dos materias do orçamento <b>${system.orcamento_solicitado}</b> e selecionou o método de pagamento '${type}'.`,
    //                     usuario_id: '0',
    //                     usuario_nome: 'robo'
    //                 }]
    //             });

    //             await axios.post('https://n8n.corpbrasil.cloud/webhook/a68ee6cd-2d99-4015-85bd-f8cdce709cbc', {
    //                 nome: customer.nome,
    //                 cidade: customer.cidade,
    //                 telefone: customer.telefone,
    //                 telefone_assistente: assistant.telefone,
    //                 kit: system.kit,
    //                 consultora: customer.consultora,
    //                 assistente: assistant.nome,
    //                 reserva: '✅ Aceito',
    //                 metodo: type,
    //                 chave: 'Reserva',
    //                 valor: value,
    //                 idFull: `${idCustomer}-${index}`,
    //                 id: idCustomer,
    //             });

    //             setLoading(false);
    //             await Swal.fire({
    //                 title: 'Parabéns!',
    //                 html: `A <b>Reserva</b> foi solicitada com sucesso.`,
    //                 icon: "success",
    //                 showConfirmButton: true,
    //                 showCloseButton: true,
    //                 confirmButtonColor: "#111",
    //             })
    //             setRefresh(true);

    //         } catch (error) {
    //             setLoading(false);
    //             console.log('Erro ao enviar feedback:', error);
    //         }
    //     }
    // }
    // const installmentValue = (value, rate = 3.05, installmentsValue = 60) => {
    //     let taxaJurosMensal = rate / 100;
    //     let numeroParcelas = installmentsValue;
    //     let pmt = (value * taxaJurosMensal * Math.pow(1 + taxaJurosMensal, numeroParcelas)) /
    //         (Math.pow(1 + taxaJurosMensal, numeroParcelas) - 1);
    //     return pmt.toFixed(2);
    // }

    // const installmentBox = async () => {

    //     let values = [];
    //     const rates = [4.16, 3.15, 2.72, 2.46, 2.29, 2.17, 2.08, 2.01, 1.95, 1.90, 1.86, 1.83, 1.88, 1.86, 1.83, 1.81, 1.79, 1.77];

    //     for (let i = 0; i < 18; i++) {
    //         values.push({
    //             texto: `${i + 1}x de ${formatCurrency(installmentValue(system?.orcamento?.valor, rates[i], i + 1))}`,
    //             quantidade: i + 1,
    //             valor: installmentValue(system?.orcamento?.valor, rates[i], i + 1)
    //         })
    //     }

    //     const inputOptions = values.reduce((acc, parcela, index) => {
    //         acc[index] = parcela.texto;
    //         return acc;
    //     }, {});

    //     const { value: estimate } = await Swal.fire({
    //         title: "Cartão de Crédito",
    //         html: `Selecione em quantas vezes você deseja parcelar`,
    //         input: "select",
    //         inputOptions: inputOptions,
    //         showCloseButton: true,
    //         inputPlaceholder: "Selecione a quantidade de parcelas",
    //         showCancelButton: true,
    //         confirmButtonText: 'Confirmar',
    //         confirmButtonColor: "#0eb05f",
    //         cancelButtonText: 'Fechar',
    //         inputValidator: (value) => {
    //             if (!value) {
    //                 return "Selecione uma quantidade";
    //             }
    //         }
    //     });

    //     if (estimate) {
    //         const selectedEstimate = values[estimate];
    //         onSubmit('Cartão de Crédito', selectedEstimate);
    //     }
    // }

    const openWhatsApp = (phoneNumber) => {
        const url = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
        window.open(url, '_blank');
    }

    const getFirstName = (fullName) => {
        if (!fullName) {
            return ''
        }
        const nameParts = fullName?.trim().split(' ');
        return nameParts[0];
    }

    return (
        <><style>
            {`
            .benefits {
                text-align: left!important;
                line-height: 30px!important;
            }
            `}
        </style>
            <HelmetProvider>
                <Helmet>
                    <title>
                        Orçamento | CORPBRASIL Energia Renovável</title>
                    <meta name="title" content="Visita Técnica | CORPBRASIL Energia Renovável" />
                    <meta name="description" content="A Experiência dos Nossos Clientes com Energia Solar" />
                </Helmet>
            </HelmetProvider>
            <div className={styles.estimate}>
                <Loading view={loading} />
                <div className={styles.estimate_header_fixed}>
                    <div>
                        <img src={Logo} alt='' />
                        <h3>{viewedOrcamento || 'Visita Técnica'}</h3>
                    </div>
                    <NewDivider />
                </div>
                <div className={`${styles.estimate_content} ${styles.flex_center}`}>
                    <Grid className={styles.flex_center} container spacing={1} sx={{ width: '100%', maxWidth: '800px' }}>
                        <Grid item xs={12}>
                            <Grid className={styles.flex_center} container spacing={1} sx={{ width: '100%', maxWidth: '800px', marginBottom: '1rem' }}>
                                <Grid item xs={9}>
                                    <NewBox sx={{ minHeight: '83px', justifyContent: 'center' }}>
                                        <Box sx={{ lineHeight: '25px', fontSize: '20px' }}>
                                            <p>
                                                <b>Olá {getFirstName(customer?.nome)},</b> sou {analyst?.nome_ref}, Analista de Projetos!
                                            </p>
                                        </Box>
                                    </NewBox>
                                </Grid>
                                <Grid item xs={3} sm={'auto'} >
                                    <NewBox padding='0.5rem'>
                                        <img style={{ borderRadius: '50%', maxWidth: '69px' }} src={analyst?.photo?.url} alt='CORPBRASIL' />
                                    </NewBox>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box ref={headerRef} className={styles.flex_center_column} padding={2}>
                            <div className={styles.system_equipment_title}>
                                <Square />
                                <h3>Visita Técnica</h3>
                            </div>
                            <p>Irei te explicar como é que garantimos a você a qualidade em nossos projetos e a
                            precisão na geração de energia solar.</p>
                        </Box>
                        {visit?.audio_apresentacao?.url ?
                            <Grid item xs={12} sm={12}> {/* Audio */}
                                <NewBox margin='1rem 0 0 0' sx={{ flex: 1 }}>
                                    <h3>Entenda a visita técnica</h3>
                                    <NewDivider height='3px' color='#F1F1F1' margin='0.3rem 0 0.5rem 0' />
                                    <Box className={styles.flex_row} sx={{ width: '100%', maxWidth: '500px' }}>
                                        <Avatar src={analyst?.photo?.url} alt='' sx={{ width: 60, height: 60 }} />
                                        <Box className={styles.flex_center}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'end' }} fontSize={14}>
                                                <b>Analista de projetos:</b>
                                                <p>{analyst?.nome_completo}</p>
                                            </Box>
                                            <CustomAudioPlayer url={visit?.audio_apresentacao?.url || ''} color='#747474' />
                                        </Box>
                                    </Box>
                                </NewBox>
                            </Grid> : null
                        }
                        <Grid item xs={12} sm={12}> {/* Visita Técnica - Projeto */}
                            <NewBox margin='1rem 0' sx={{ flex: 1 }}>
                                <div className={styles.visit_container}>
                                    <div className={styles.system_equipment_title}>
                                        <Square />
                                        <h3>Imagem Aérea</h3>
                                    </div>
                                    {Array.isArray(visit?.projeto?.telhado) ?
                                        visit?.projeto?.telhado?.map((data, index) => (
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }} key={index}>
                                                <img src={data.url} alt='irradiação' />
                                                {index === 0 ?
                                                    <div className={styles.visit_commentary}>
                                                        <b>Análise:</b>
                                                        <p>Nesta primeira imagem, utilizamos uma foto aérea capturada por drone, feita pelo nosso
                                                            técnico responsável pela vistoria. Durante essa etapa, analisamos a área útil, a altura e o
                                                            layout do seu telhado.</p>
                                                    </div> : null
                                                }
                                            </Box>
                                        )) :
                                        <Box>
                                            <img src={visit?.projeto?.telhado?.url || ''} alt='irradiação' />
                                            <div className={styles.visit_commentary}>
                                                <b>Análise:</b>
                                                <p>Nesta primeira imagem, utilizamos uma foto aérea capturada por drone, feita pelo nosso
                                                    técnico responsável pela vistoria. Durante essa etapa, analisamos a área útil, a altura e o
                                                    layout do seu telhado.</p> {/* {visit?.projeto?.telhado?.comentario} */}
                                            </div>
                                        </Box>
                                    }
                                </div>
                                <div className={styles.visit_container}>
                                    <div className={styles.system_equipment_title}>
                                        <Square />
                                        <h3>Projeto</h3>
                                    </div>
                                    <img src={visit?.projeto?.projeto?.url || ''} alt='irradiação' />
                                    <div className={styles.visit_commentary}>
                                        <b>Análise:</b>
                                        <p> Com um software de engenharia israelense, projetamos a área onde o
                                            sistema será instalado e analisamos qualquer possível obstáculo que pode interferir na
                                            geração de energia </p> {/* {visit?.projeto?.projeto?.comentario} */}
                                    </div>
                                </div>
                                <div className={styles.visit_container}>
                                    <div className={styles.system_equipment_title}>
                                        <Square />
                                        <h3>Estudo de Irradiação</h3>
                                    </div>
                                    <img src={visit?.projeto?.irradiacao?.url || ''} alt='irradiação' />
                                    <div className={styles.visit_commentary}>
                                        <b>Análise:</b>
                                        <p>Essa etapa é muito importante para garantir que nossos clientes tenham a melhor geração
                                            de energia. Nela, fazemos um estudo da luz solar no telhado, encontramos o melhor lugar
                                            para instalar as placas solares e aproveitamos ao máximo a eficiência, de acordo com a
                                            inclinação e a posição do telhado.
                                        </p> {/* {visit?.projeto?.irradiacao?.comentario} */}
                                    </div>
                                </div>
                                <div className={styles.visit_container}>
                                    <div className={styles.system_equipment_title}>
                                        <Square />
                                        <h3>Projeto Finalizado</h3>
                                    </div>
                                    <img src={visit?.projeto?.projeto_final?.url || ''} alt='irradiação' />
                                    <div className={styles.visit_commentary}>
                                        <b>Análise:</b>
                                        <p>Esta imagem é para você, {getFirstName(customer?.nome)}, visualizar como os módulos ficarão lindos instalados
                                            no telhado do seu imóvel, aproveitando a luz do sol e transformando em economia</p> {/* {visit?.projeto?.projeto_final?.comentario} */}
                                    </div>
                                </div>
                                <ButtonCB startIcon={<WhatsAppIcon />} background="#00cc4c" onClick={() => openWhatsApp(analyst?.telefone)}>Dúvidas? Clique aqui</ButtonCB>
                            </NewBox>
                        </Grid>
                        <Grid item xs={12} sm={12}> {/* Audio */}
                            <NewBox margin='1rem 0 0 0' sx={{ flex: 1 }}>
                                <h3>Áudio da analista de projetos</h3>
                                <NewDivider height='3px' color='#F1F1F1' margin='0.3rem 0 0.5rem 0' />
                                <Box className={styles.flex_row} sx={{ width: '100%', maxWidth: '500px' }}>
                                    <Avatar src={analyst?.photo?.url} alt='' sx={{ width: 60, height: 60 }} />
                                    <Box className={styles.flex_center}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'end' }} fontSize={14}>
                                            <b>Analista de projetos:</b>
                                            <p>{analyst?.nome_completo}</p>
                                        </Box>
                                        <CustomAudioPlayer url={visit?.projeto?.audio?.url || ''} color='#747474' />
                                    </Box>
                                </Box>
                            </NewBox>
                        </Grid>
                        <Grid item xs={12} sm={12}> {/* Visita Técnica - Rede Elétrica */}
                            <NewBox margin='1rem 0 0 0' sx={{ flex: 1 }}>
                                <h3>Rede Elétrica</h3>
                                <NewDivider height='3px' color='#F1F1F1' margin='0.3rem 0 1rem 0' />
                                {visit?.rede_eletrica?.analise?.map((data, index) => (
                                    <div className={styles.visit_container} key={index}>
                                        <img src={data.url} alt='irradiação' />
                                        {data.comentario ?
                                            <div className={styles.visit_commentary}>
                                                <b>Análise:</b>
                                                <p>{data.comentario}</p>
                                            </div> : null
                                        }
                                    </div>
                                ))}
                                <ButtonCB startIcon={<WhatsAppIcon />} background="#00cc4c" onClick={() => openWhatsApp(analyst?.telefone)}>Fale com Analista</ButtonCB>
                            </NewBox>
                        </Grid>
                        {visit?.rede_eletrica?.melhorias ?
                            <>
                                <Grid item xs={12}> {/* Visita Técnica - Melhorias Elétrica */}
                                    <NewBox margin='0.5rem 0 0 0'>
                                        <h3>Melhorias elétricas</h3>
                                        <NewDivider height='3px' color='#F1F1F1' margin='0.3rem 0 1rem 0' />
                                        <Table>
                                            <TableHead sx={{ backgroundColor: "#EDEDED", borderRadius: '5px' }}>
                                                <TableCell sx={{ padding: '0.2rem 1rem', border: 'none' }}>Descrição</TableCell>
                                                <TableCell sx={{ padding: '0.2rem', border: 'none' }}>Qntd</TableCell>
                                                <TableCell sx={{ width: '90px', padding: '0.2rem 1rem', border: 'none' }}>Preço</TableCell>
                                            </TableHead>
                                            <TableBody sx={{ 'td': { padding: '0.4rem 0.8rem' } }}>
                                                {visit?.rede_eletrica?.melhorias?.material?.map((data, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{data.nome}</TableCell>
                                                        <TableCell>{data.categoria === 'Mão de Obra' ? '1' : data.quantidade}</TableCell>
                                                        <TableCell>{formatCurrency(data.valor_total)}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <Box sx={{ display: 'flex', margin: '1rem 0 0.5rem 0', justifyContent: 'flex-end', width: '100%' }}>
                                            <Typography variant='b' component='b' sx={{ padding: '0.65rem 1rem', backgroundColor: "#FFAB00", borderRadius: '5px' }}>Valor total: {formatCurrency(visit?.rede_eletrica?.melhorias?.valor_total)}</Typography>
                                        </Box>
                                    </NewBox>
                                </Grid>
                                {/* 
                                {visit?.rede_eletrica?.melhorias?.custos === 'Sim' ?
                                    <Grid item xs={12}>
                                        <NewBox margin='1rem 0 0 0' sx={{ flex: 1, border: '1px solid #FFAB00' }}>
                                            <h3>Atenção - Melhorias elétricas</h3>
                                            <NewDivider height='3px' color='#FFAB00' margin='0.3rem 0 1rem 0' />
                                            <Box sx={{ padding: '0 0.6rem 0.6rem 0.6rem' }}>
                                                <p>Gostaria de incluir esse valor no seu orçamento e agendar as melhorias elétricas com a CorpBrasil para garantir um serviço de qualidade?</p>
                                                <FormGroup sx={{ marginTop: '1rem' }}>
                                                    <FormControlLabel
                                                        sx={{ margin: 0 }}
                                                        control={
                                                            <Checkbox
                                                                checked={system?.melhorias ? system?.melhorias : acceptImprovements}
                                                                color='success'
                                                                label='123'
                                                                onChange={() => {
                                                                    if (system?.melhorias) {
                                                                        return; // Não faz nada se "melhorias" estiver ativado
                                                                    }
                                                                    setAcceptImprovements(true); // Executa ação se "melhorias" não estiver ativado
                                                                }}
                                                                icon={<RadioButtonUncheckedIcon sx={{ fill: "#0FA958", backgroundColor: "#fff", borderRadius: '50%' }} />}
                                                                checkedIcon={<CheckCircleIcon sx={{ fill: "#0FA958", backgroundColor: "#fff", borderRadius: '50%' }} />} />
                                                        }
                                                        label={
                                                            <Typography sx={{ fontWeight: 'bold', width: 'calc(100% - 65px)', backgroundColor: "#fff", color: "#4A4A4A", padding: '0.2rem 0.4rem', borderRadius: '5px' }}>
                                                                Sim, incluir em meu orçamento.
                                                            </Typography>
                                                        }
                                                    />
                                                    <FormControlLabel
                                                        sx={{ margin: 0 }}
                                                        control={
                                                            <Checkbox
                                                                checked={system?.melhorias ? system?.melhorias : acceptImprovements === false}
                                                                color='success'
                                                                label='123'
                                                                onChange={() => {
                                                                    if (system?.melhorias) {
                                                                        return; // Não faz nada se "melhorias" estiver ativado
                                                                    }
                                                                    setAcceptImprovements(false); // Executa ação se "melhorias" não estiver ativado
                                                                }}
                                                                icon={<RadioButtonUncheckedIcon sx={{ fill: "#0FA958", backgroundColor: "#fff", borderRadius: '50%' }} />}
                                                                checkedIcon={<CheckCircleIcon sx={{ fill: "#0FA958", backgroundColor: "#fff", borderRadius: '50%' }} />} />
                                                        }
                                                        label={
                                                            <Typography sx={{ fontWeight: 'bold', width: 'calc(100% - 65px)', backgroundColor: "#fff", color: "#4A4A4A", padding: '0.2rem 0.4rem', borderRadius: '5px' }}>
                                                                Não desejo incluir em meu orçamento.
                                                            </Typography>
                                                        }
                                                    />
                                                </FormGroup>
                                            </Box>
                                        </NewBox>
                                    </Grid> : null
                                } */}

                            </> : null
                        }
                        <Grid item xs={12} sm={12}>
                            <NewBox margin='1rem 0 0 0' sx={{ flex: 1, padding: '1rem 0 0rem 0' }}>
                                <h3>Áudio da analista sobre rede elétrica</h3>
                                <NewDivider height='3px' color='#F1F1F1' margin='0.3rem 0 0.5rem 0' />
                                <Box className={styles.flex_row} sx={{ width: '100%', maxWidth: '500px', padding: '0 1rem 0.5rem 1rem', boxSizing: 'border-box' }}>
                                    <Avatar src={analyst?.photo?.url} alt='' sx={{ width: 60, height: 60 }} />
                                    <Box className={styles.flex_center}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'end' }} fontSize={14}>
                                            <b>Analista de projetos:</b>
                                            <p>{analyst?.nome_completo}</p>
                                        </Box>
                                        <CustomAudioPlayer url={visit?.rede_eletrica?.audio_rede?.url || ''} color='#747474' />
                                    </Box>
                                </Box>
                                {!visit?.rede_eletrica?.audio_reversao?.url && 
                                    <Footer />
                                }
                            </NewBox>
                        </Grid>
                        {visit?.rede_eletrica?.audio_reversao?.url ?
                            <>
                                <Grid item xs={12}> {/* Análise de reversão de fluxo*/}
                                    <NewBox margin='0.5rem 0 0 0' sx={{ flex: 1, border: '1px solid #FFAB00' }}>
                                        <h3>Análise de reversão de fluxo </h3>
                                        <NewDivider height='3px' color='#FFAB00' margin='0.3rem 0 1rem 0' />
                                        <Box sx={{ padding: '0 0.6rem 0.6rem 0.6rem' }}>
                                            <p>De acordo com a norma da Aneel 1.098, projetos de energia solar com inversores <b>acima de 7.5 kW</b> precisam passar por uma análise para verificar se a rede pode suportar o sistema.</p>
                                            <Box marginTop={'1rem'}>
                                                <p>Projetos que tiverem beneficiarias mesmo <b>abaixo de 7.5 kW</b> será submetido a análise de reversão de fluxo pela concessionária de energia.</p>
                                            </Box>
                                        </Box>
                                    </NewBox>
                                </Grid>
                                <Grid item xs={12} sm={12}> {/* Audio - reversão de fluxo*/}
                                    <NewBox margin='1rem 0 0 0' sx={{ flex: 1, padding: '1rem 0 0 0' }}>
                                        <h3>Áudio da analista - Reversão de fluxo</h3>
                                        <NewDivider height='3px' color='#F1F1F1' margin='0.3rem 0 0.5rem 0' />
                                        <Box className={styles.flex_row} sx={{ width: '100%', maxWidth: '500px', padding: '0 1rem', boxSizing: 'border-box' }}>
                                            <Avatar src={analyst?.photo?.url} alt='' sx={{ width: 60, height: 60 }} />
                                            <Box className={styles.flex_center}>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'end' }} fontSize={14}>
                                                    <b>Analista de projetos:</b>
                                                    <p>{analyst?.nome_completo}</p>
                                                </Box>
                                                <CustomAudioPlayer url={visit?.rede_eletrica?.audio_reversao?.url || ''} color='#747474' />
                                            </Box>
                                        </Box>
                                            <Footer />
                                    </NewBox>
                                </Grid>
                                </> : null
                                }
                    </Grid>
                </div>
            </div></>
    )
}

export default memo(VisitTec)
