import { useState, useEffect, memo, useRef } from 'react'
import styles from './styles.module.scss';
import { Button, IconButton, TextField, FormControl, Select, InputLabel, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, InputAdornment, Grid } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GoogleMap, DistanceMatrixService, MarkerF } from '@react-google-maps/api';
import { usePlacesWidget } from "react-google-autocomplete";
import moment from 'moment';

import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'; // Visita Comercial
import PeopleIcon from '@mui/icons-material/People'; // Tecnica + Comercial
import RestaurantIcon from '@mui/icons-material/Restaurant'; // Almoço
import EngineeringIcon from '@mui/icons-material/Engineering'; // Pós Venda
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AddLinkIcon from '@mui/icons-material/AddLink';
import { ReactComponent as CheckIcon } from "../../../images/icons/Check.svg";
import { IoCheckmarkDone } from "react-icons/io5";
import { FcGoogle } from "react-icons/fc";

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import { theme } from '../../../data/theme';
import { KeyMaps } from '../../../data/Data';
import Swal from "sweetalert2";
import "cooltipz-css";
import useVisit from '../../../hooks/useVisit';
import { collection, doc, updateDoc } from 'firebase/firestore';
import { dataBase } from '../../../firebase/database';
import Requirement from '../../../components/Box/Requirement/Index';
import { useCadastro } from '../../../contexts/CadastroContext';
import Loading from '../../../components/Loading';

const CreateVisit = ({ isLoaded, userRef, visitsAll, sellers, tecs, representativesRef }) => {
  const { atualizarCadastro, cadastro } = useCadastro();
  const inputRef = useRef(null);
  const hoursRef = useRef(null);
  const [info, setInfo] = useState({
    cliente: '',
    endereco: '',
    dataTexto: '',
    horarioTexto: '08:00',
    visitaNumero: 1800,
    representante: 'Nenhum',
    tecnicoTexto: null,
    concessionaria: '',
    observacao: '',
    cidade: '',
    numero: 0
  });
  const [cliente, setCliente] = useState('');
  const [preData, setPreData] = useState(null);
  const [view, setView] = useState(false);
  // const [loading, setLoading] = useState(false);
  const scheduleRef = collection(dataBase,"Visitas");
  const { createVisit, loading } = useVisit(scheduleRef, sellers); // Custom Hook para Criar Visitas
  const [visitsFindCount, setVisitsFindCount] = useState();
  const [visitsFind, setVisitsFind] = useState();
  const [saidaCliente, setSaidaCliente] = useState(undefined);
  // const [ki, setki] = useState([]);
  // const [valorInicial, setValorInicial] = useState([]);
  const [saidaTexto, setSaidaTexto] = useState(undefined);
  const [chegadaTexto, setChegadaTexto] = useState(undefined);
  const [visits, setVisits]= useState([]);
  const [rotaTempo, setRotaTempo] = useState(undefined);
  const [tempoTexto, setTempoTexto] = useState(undefined);
  const [rotaTempo2, setRotaTempo2] = useState(undefined);
  const [tempoTexto2, setTempoTexto2] = useState(undefined);
  const [tecRefUID, setTecRefUID] = useState(tecs[0]);
  const [sellerRef, setSellerRef] = useState(sellers[0]);
  const [city, setCity] = useState(undefined);
  const [createVisitGroup, setCreateVisitGroup] = useState({ 
    check: false,
    info: {
      tempo: '0 minutos'
    }
   });
  const [addressComplete, setAddressComplete] = useState(undefined);
  const [monthNumber, setMonthNumber] = useState(String(new Date().getMonth() + 1).padStart(2, "0"));
  // eslint-disable-next-line no-unused-vars
  const [consultoras, setConsultoras] = useState([]);
  const [consultoraTexto, setConsultoraTexto] = useState('');
  const [driver, setDriver] = useState(); // Para escolher o motorista/tecnico de acordo com o tipo de visita
  const navigate = useNavigate();
  const { type } = useParams();
  const [position, setPosition] = useState({
    lat: -23.109655741119823,
    lng: -47.71509175415125
  });
  const [positionRef, setPositionRef] = useState({
    lat: -23.109655741119823,
    lng: -47.71509175415125
  });
  const [representatives, setRepresentatives] = useState([]);

  useEffect(() => {
    if(type) {
      // type !== 'pos_venda' && type !== 'tecnica' && 
      if((!preData && !cadastro)) {
        setView(true);
      } else {
        setView(false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[type, cadastro]);

  // useEffect(() => {
  //   if(userRef) {
  //     const fethName = () => {
  //       setInfo({...info, consultoraTexto: userRef.nome})
  //     }
  //     fethName();
  //   }

  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[userRef]);

  useEffect(() => {
    if(representativesRef) {
      if(userRef && userRef.time && userRef.cargo === 'Vendedor(a)') {
        setRepresentatives(representativesRef.filter(data => data.grupo.time === userRef.time))
      } else {
        setRepresentatives(representativesRef);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[representativesRef])

  useEffect(() => {
    if(cadastro) {
      setCliente(cadastro.preData.nome);
      setConsultoraTexto((cadastro.consultora === 'Ana Flávia' || cadastro.consultora === 'Kauana') ? 'Pós-Venda' : cadastro.consultora); // Ajustar Depois - 25/09
      
      if(cadastro.endereco) {
        setCity(cadastro.endereco?.cidade || '');
        setAddressComplete(cadastro.endereco?.endereco || '');
        setPosition({
          lat: cadastro.endereco?.lat || -23.109655741119823,
          lng: cadastro.endereco?.lng || -47.71509175415125
        });

        if(cadastro.endereco.endereco) {
          inputRef.current.value = cadastro.endereco?.endereco; // Define o valor inicial do endereço
        }
      }
      console.log(cadastro);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[cadastro]);

  function visitaNome(valor) {
    switch (valor) {
      case 'comercial':
        return 'Comercial';
      case 'comercial_tecnica':
        return 'Comercial + Técnica';
      case 'pos_venda':
        return 'Pós-Venda';
      case 'tecnica':
        return 'Técnica';
      default:
        return valor;
    }
  }

  const { ref } = usePlacesWidget({
    apiKey: KeyMaps,
    onPlaceSelected: (place) => {
      const address = place.formatted_address;
      const cityRef = place.address_components.find(ref => ref.types[0] === 'administrative_area_level_2');
      // if (place.address_components[0].types[0] === "street_number") {
      //   const numberRef = place.address_components.find(ref => ref.types[0] === "street_number");
      //   setNumberAddress(numberRef.long_name);
      // } else {
      //   setNumberAddress(undefined);
      // }
      setCity(cityRef.long_name);
      setAddressComplete(address.substring(0, address.length - 19));
      setPosition({
        lat: place.geometry?.location?.lat(),
        lng: place.geometry?.location?.lng()
      })
      // setCheck(true); // Habilita o serviço de calculo de distancia do google
    },
    options: {
      types: ["geocode", "establishment"],
      componentRestrictions: { country: "br" },
      fields: ["formatted_address", "address_components", "geometry.location"],
    },
  });

  useEffect(() => {
    if(info.tecnicoTexto) {
      setTecRefUID(tecs.find((tec) => tec.nome === info.tecnicoTexto)); 
    }
    if(consultoraTexto) {
      setSellerRef(sellers.find((sel) => sel.nome === consultoraTexto)); 
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.dataTexto, info.tecnicoTexto, consultoraTexto]);

  useEffect(() => { // Seleciona a visita de acordo com o tipo escolhido
    const lunch = () => {
      let tecRef = '';
      let consultoraTexto = null;
      switch (type) {
        case 'lunch':
          setDriver(tecs.filter((ref) => ref.nome === userRef.nome))
          tecRef = userRef && userRef.nome;
          //driverRef.current = 'Bruna';
          break
        case 'comercial':
          setDriver(tecs.filter((ref) => ref.nome === "Bruna" || ref.nome === "Lia"))
          tecRef = 'Bruna';
          consultoraTexto =  userRef && userRef.cargo !== 'Administrador' ? userRef.nome : 'Bruna';
          //driverRef.current = 'Bruna';
          break
        case 'comercial_tecnica':
          setDriver(tecs.filter((ref) => ref.nome === "Neto" || ref.nome === "Bruna"))
          tecRef = 'Neto';
          consultoraTexto = userRef && userRef.cargo !== 'Administrador' ? userRef.nome : 'Bruna';
          //driverRef.current = 'Lucas';
          break
        case 'tecnica':
          setDriver(tecs.filter((ref) => ref.nome === "Neto"))
          tecRef = 'Neto';
          consultoraTexto = userRef && userRef.cargo !== 'Administrador' ? userRef.nome : 'Bruna';
          //driverRef.current = 'Lucas';
          break
        case 'pos_venda':
          setDriver(tecs.filter((ref) => ref.nome === "Neto"))
          tecRef = 'Neto';
          consultoraTexto = 'Pós-Venda';
          break
        default:
          setDriver(tecs)
          // setVisits(visitsAll)
      }
      setInfo({
        ...info, 
        tecnicoTexto: tecRef
      })
      if(!cadastro) {
        setConsultoraTexto(consultoraTexto);
      }
    };
    lunch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(info)

  // useEffect(() => {
  //   // let visitsType = schedule.filter((visit) => visit.tecnico === "Lucas" && visit.tecnico === "Luis");
  //   let visitsData;
  //   let visitsType;
  //   switch (type) {
  //     case 'comercial':
  //       visitsData = visitsAll.filter((visit) => visit.data === info.dataTexto && (visit.tecnico !== "Lucas" && visit.tecnico !== "Neto"));
  //       visitsType = visitsAll.filter((visit) => visit.tecnico !== "Lucas" && visit.tecnico !== "Neto" && moment(visit.data).month() + 1 === parseInt(monthNumber) && moment(visit.data).year() === parseInt('2024'));
  //       break
  //     case 'lunch':
  //       visitsData = visitsAll.filter((visit) => visit.data === info.dataTexto);
  //       visitsType = visitsAll.filter((visit) =>  moment(visit.data).month() + 1 === parseInt(monthNumber) && moment(visit.data).year() === parseInt('2024'));
  //       break
  //     default:
  //       visitsData = visitsAll.filter((visit) => visit.data === info.dataTexto && (visit.tecnico === "Lucas" || visit.tecnico === "Neto" || visit.categoria === 'lunch'));
  //       visitsType = visitsAll.filter((visit) => (visit.tecnico === "Lucas" || visit.tecnico === "Neto" || visit.categoria === 'lunch') &&
  //        moment(visit.data).month() + 1 === parseInt(monthNumber) && moment(visit.data).year() === parseInt('2024'))
  //   }
  //   if(info.dataTexto) {
  //     if (visitsData && info.dataTexto.substring(8,10) !== "00") {
  //       setVisits(visitsData);
  //     }
  //   } else {
  //     setVisits(visitsType);
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[info.dataTexto, visitsAll, type, monthNumber])

  // useEffect(() => {
  //   // console.log(visitaNumero);
  //   if (info.horarioTexto && info.visitaNumero) {
  //     moment.locale("pt-br");

  //     const saidaEmpresa = moment(info.horarioTexto, "hh:mm"); //Horario de chegada
  //     const chegadaCliente = moment(info.horarioTexto, "hh:mm"); //Horario de chegada

  //     saidaEmpresa.subtract(rotaTempo, "seconds").format("hh:mm"); // Pega o tempo que o tecnico vai precisar sair da empresa

  //     setSaidaTexto(saidaEmpresa.format("kk:mm"));

  //     chegadaCliente.add(info.visitaNumero, "seconds").format("hh:mm"); //Adiciona tempo de viagem volta
  //     setSaidaCliente(chegadaCliente.format("kk:mm"));
  //     chegadaCliente.add(rotaTempo, "seconds").format("hh:mm"); //Adiciona tempo de viagem volta
  //     // console.log(chegadaCliente)
  //     setChegadaTexto(chegadaCliente.format("kk:mm"));
  //   }

  //     let saidaEmpresaRef, ChegadaEmpresaRef;
  //     moment.locale("pt-br");
  //     saidaEmpresaRef = saidaTexto;
  //     ChegadaEmpresaRef = chegadaTexto;

  //     const saidaFormatada = moment(saidaEmpresaRef, "hh:mm");
  //     const chegadaFormatada = moment(ChegadaEmpresaRef, "hh:mm");
  //     let check = [];
  //     let visitsFindData = [];


  //     const dataRef = visitsAll.filter(
  //       (dia) => dia.data === info.dataTexto && (dia.tecnico === info.tecnicoTexto || (type === 'lunch' && dia.consultora === info.tecnicoTexto))
  //     );

  //       dataRef.map((ref) => {
  //         // console.log("eae");
  //         if (
  //           saidaFormatada <= moment(ref.saidaEmpresa, "hh:mm") &&
  //           chegadaFormatada <= moment(ref.saidaEmpresa, "hh:mm")
  //         ) {
  //           check.push(ref);
  //         } else {
  //           if (saidaFormatada >= moment(ref.chegadaEmpresa, "hh:mm"))
  //             check.push(ref);
  //         }
  //         return dataRef;
  //       });
  //     // console.log(visitsFindCount);

  //     dataRef.map((a) => {
  //       //Percorre todos os arrays de 'dataRef' e compara se os arrays são iguais
  //       if (check.includes(a) === false) {
  //         visitsFindData.push(a);
  //       }
  //       return setVisitsFind(visitsFindData);
  //     });
  //     setVisitsFindCount(dataRef.length - check.length)

  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [info.horarioTexto, info.visitaNumero, chegadaTexto, saidaTexto, rotaTempo, info.cidade, info.dataTexto, info.tecnicoTexto]);

  useEffect(() => {
    let visitsData;
    let visitsType;
    
    const filterByDateAndTechnician = (visit) => {
      const isLucasOrNeto = visit.tecnico === "Lucas" || visit.tecnico === "Neto" || visit.tecnico === "Bruna";
      const isTargetMonthYear = moment(visit.data).month() + 1 === parseInt(monthNumber) && moment(visit.data).year() === parseInt('2024');
      return isTargetMonthYear && isLucasOrNeto;
    };

    const filterByDateAndSeller = (visit) => {
      const isBruna = visit.tecnico === "Bruna";
      const isTargetMonthYear = moment(visit.data).month() + 1 === parseInt(monthNumber) && moment(visit.data).year() === parseInt('2024');
      return isTargetMonthYear && isBruna;
    };
  
    switch (type) {
      case 'comercial':
        visitsData = visitsAll?.filter((visit) => visit.data === info.dataTexto && (visit.tecnico !== "Lucas" && visit.tecnico !== "Neto"));
        visitsType = visitsAll?.filter(filterByDateAndSeller);
        break;
      case 'lunch':
        visitsData = visitsAll?.filter((visit) => visit.data === info.dataTexto);
        visitsType = visitsAll?.filter((visit) => moment(visit.data).month() + 1 === parseInt(monthNumber) && moment(visit.data).year() === parseInt('2024'));
        break;
      default:
        visitsData = visitsAll?.filter((visit) => visit.data === info.dataTexto && (visit.tecnico === "Lucas" || visit.tecnico === "Neto" || visit.categoria === 'lunch'));
        visitsType = visitsAll?.filter(filterByDateAndTechnician);
    }
   
    if (info.dataTexto && info.dataTexto.substring(8, 10) !== "00") {
      setVisits(visitsData);
    } else {
      setVisits(visitsType);
    }
  }, [info.dataTexto, visitsAll, type, monthNumber]);

  useEffect(() => {
    if(sellers) {
      setConsultoras(sellers.filter(obj => obj.nome_ref !== 'Kauana' && obj.nome !== 'Ana' && obj.nome !== 'Leticia'));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sellers])

  useEffect(() => {
    if (!createVisitGroup) return;
  
    let horarioTextRef = info.horarioTexto;
    let newData = createVisitGroup.info.dia ? moment(new Date(createVisitGroup.info.dia)).format('YYYY-MM-DD') : info.dataTexto;
    let newTecnicoTexto = createVisitGroup.info.tecnico ? createVisitGroup.info.tecnico : type === 'comercial' ? 'Bruna' : 'Neto';
    let newPositionRef = {
      lat: createVisitGroup.info.lat,
      lng: createVisitGroup.info.lng
    };
  
    if (createVisitGroup.type === 'depois') {
      const saida = moment(createVisitGroup.info.saidaDoCliente, "hh:mm").add(rotaTempo2, 'seconds');
      horarioTextRef = saida.format('kk:mm');
    } else if (createVisitGroup.type === 'antes') {
      const chegada = moment(createVisitGroup.info.chegadaCliente, "hh:mm").subtract(rotaTempo2, 'seconds');
      horarioTextRef = chegada.subtract(info.visitaNumero, 'seconds').format('kk:mm');
    } else {
      horarioTextRef = info.horarioTexto;
    }
  
    setSaidaTexto(createVisitGroup.info.saidaDoCliente);
    setChegadaTexto(createVisitGroup.info.chegadaEmpresa);
    setInfo({
      ...info,
      dataTexto: newData,
      tecnicoTexto: newTecnicoTexto,
      horarioTexto: horarioTextRef
    });
    setPositionRef(newPositionRef);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createVisitGroup, rotaTempo2, info.visitaNumero]);

  useEffect(() => {
    moment.locale("pt-br");
    const saidaEmpresa = moment(info.horarioTexto, "hh:mm"); //Horario de chegada
    const chegadaCliente = moment(info.horarioTexto, "hh:mm"); //Horario de chegada
    if(createVisitGroup.type === 'antes') {
        saidaEmpresa.subtract(rotaTempo, "seconds").format("hh:mm"); // Pega o tempo que o tecnico vai precisar sair da empresa
        setSaidaTexto(saidaEmpresa.format("kk:mm"));
        chegadaCliente.add(Number(info.visitaNumero), "seconds").format("hh:mm"); //Adiciona tempo de viagem volta
        setSaidaCliente(chegadaCliente.format("kk:mm"));
        chegadaCliente.add(rotaTempo2, "seconds").format("hh:mm"); //Adiciona tempo de viagem volta
        setChegadaTexto(chegadaCliente.format("kk:mm"));
    } else if(createVisitGroup.type === 'depois') {
        saidaEmpresa.subtract((rotaTempo2 - 60), "seconds").format("hh:mm"); // Pega o tempo que o tecnico vai precisar sair da empresa
        setSaidaTexto(saidaEmpresa.format("kk:mm"));
        chegadaCliente.add(Number(info.visitaNumero), "seconds").format("hh:mm"); //Adiciona tempo de viagem volta
        setSaidaCliente(chegadaCliente.format("kk:mm"));
        chegadaCliente.add(rotaTempo, "seconds").format("hh:mm"); //Adiciona tempo de viagem volta
        setChegadaTexto(chegadaCliente.format("kk:mm"));
    } else {
      saidaEmpresa.subtract(rotaTempo, "seconds").format("hh:mm"); // Pega o tempo que o tecnico vai precisar sair da empresa
      setSaidaTexto(saidaEmpresa.format("kk:mm"));
      chegadaCliente.add(info.visitaNumero, "seconds").format("hh:mm"); //Adiciona tempo de viagem volta
      setSaidaCliente(chegadaCliente.format("kk:mm"));
      chegadaCliente.add(rotaTempo, "seconds").format("hh:mm"); //Adiciona tempo de viagem volta
      setChegadaTexto(chegadaCliente.format("kk:mm"));
    }

        let saidaEmpresaRef,ChegadaEmpresaRef;
        moment.locale("pt-br");
        saidaEmpresaRef = saidaTexto;
        ChegadaEmpresaRef = chegadaTexto;

        const saidaFormatada = moment(saidaEmpresaRef, "hh:mm");
        const chegadaFormatada = moment(ChegadaEmpresaRef, "hh:mm");
        console.log(saidaFormatada)
        const check = [];
        let visitsFindData = [];

        const dataRefVisit = visitsAll?.filter(
            (dia) => dia.data === info.dataTexto &&
            (dia.tecnicoUID === tecRefUID.uid || (dia.categoria === 'lunch' && dia.tecnico === consultoraTexto))
            );

            if(createVisitGroup.check === true) {
              dataRefVisit?.map((ref) => {
                if (
                  saidaFormatada <= moment(ref.chegadaCliente, "hh:mm") &&
                  chegadaFormatada <= moment(ref.chegadaCliente, "hh:mm")
                ) {
                  check.push(ref);
                } else {
                  if (saidaFormatada >= moment(ref.saidaDoCliente, "hh:mm"))
                  check.push(ref);
              }
                return dataRefVisit;
              });
            } else {
              dataRefVisit?.map((ref) => {
                if (
                  saidaFormatada <= moment(ref.saidaEmpresa, "hh:mm") &&
                  chegadaFormatada <= moment(ref.saidaEmpresa, "hh:mm")
                ) {
                  check.push(ref);
                } else {
                  if (saidaFormatada >= moment(ref.chegadaEmpresa, "hh:mm"))
                  check.push(ref);
              }
                return dataRefVisit;
              });
            }

          dataRefVisit?.map((a) => {
            //Percorre todos os arrays de 'dataRef' e compara se os arrays são iguais
            if (check.includes(a) === false) {
              visitsFindData.push(a);
            }
            return visitsFindData;
          });
          console.log(check);
          setVisitsFindCount(dataRefVisit?.length - check.length);
          setVisitsFind(visitsFindData);
        
        console.log(visitsFind);
        console.log(visitsFindCount);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.horarioTexto, info.visitaNumero, chegadaTexto, saidaTexto, rotaTempo, city, visitsAll, createVisitGroup, info.dataTexto]);

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      console.log(cadastro);
      console.log(position);

      if(isAfterPermittedHours(info.horarioTexto)) {
       return Swal.fire({
          title: 'Atenção!',
          html: `O horário permitido para marcar uma <b>Visita Comercial + Técnica</b> é entre ás <b>06:00 da manhã até 18:00 da tarde</b>.`,
          icon: "warning",
          showConfirmButton: true,
          showCloseButton: true,
          confirmButtonColor: "#111",
        }).then((result) => {
          if(result.isConfirmed) {
            setTimeout(() => {
              hoursRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
              hoursRef.current.focus();
            }, 500);
          }
        });
      }
      
      if(checkVisitRoute()) {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        return ref.current.focus();
      }

      if(!city) {
        Swal.fire({
          title: 'Atenção!',
          html: `O <b>Endereço</b> fornecido não foi encontrado pelo sistema. Verifique o Endereço.`,
          icon: "warning",
          showConfirmButton: true,
          showCloseButton: true,
          confirmButtonColor: "#111",
        }).then((result) => {
          if(result.isConfirmed) {
            setTimeout(() => {
              ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
              ref.current.focus();
            }, 500);
          }
        });
      } else if (visitsFindCount < 0 || visitsFindCount > 0) {
        let c = 1;
        const visits = visitsFind.map(
          (e) =>
            `Visita <b>` +
            c++ +
            "</b> - Saida: <b>" +
            e.saidaEmpresa +
            "</b> Chegada: <b>" +
            e.chegadaEmpresa +
            "</b></br>"
        );
        Swal.fire({
          title: 'Visitas',
          html:
            `Foram encontrado(s) <b>${visitsFindCount}</b> visita(s) marcada(s) nesse periodo.</br></br>` +
            visits,
          icon: "warning",
          showConfirmButton: true,
          showCloseButton: true,
          confirmButtonColor: "#111",
        });
      } else {
        
        Swal.fire({
          title: 'Visitas',
          html: `Você deseja criar uma nova <b>Visita?</b>`,
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: "#0eb05f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if(createVisitGroup.check === true) {
                  visitGroup(createVisitGroup.type);
                } else {
                  visitOnly();
                } 
            }
          })
      };
    } catch (error) {
    }
  };

const collectData = (data) => {
  setPreData(data);
  setCliente(data.nome);
  if(data.consultora.length > 0) {
    setConsultoraTexto(data.consultora);
  }
  setView(false);
}

const openBox = () => {
  setView(true);
}

const closeBox = () => {
  setView(false);
  atualizarCadastro(null);

  if(!preData) {
    navigate('/visitas');
  }
}

const visitGroup = async (direction, proposta = null) => {
  try {
    let SaidaClienteRef2;
    const visitRef = createVisitGroup.info;
    const scheduleVisitRef = createVisitGroup.ref;
    const indicadorRef = representatives.find((data) => data.id_representante === info.representante);
    let props = {};
    if(cadastro) {
      props = {
        ...cadastro,
        endereco: addressComplete
      }
    } else {
        if(preData) {
          props = {
            preData: {
              ...preData
            },
            cliente_id: preData.cliente_id
          }
        }
      }
    if(direction === 'antes') { // Verifica se existe uma referencia de visita abaixo da visita a ser criada
      if(visitRef.categoria === "lunch") {
        SaidaClienteRef2 = visitRef.saidaEmpresa;
      } else {
        await updateDoc(scheduleVisitRef, {
          saidaEmpresa: visitRef.chegadaCliente,
          tempoRotaConjunta: rotaTempo2,
          tempoConjunta: tempoTexto2,
          groupRef: 'antes',
          visitaConjunta: true,
          tipo: "Visita Conjunta"
         })
         SaidaClienteRef2 = visitRef.chegadaCliente;
      }
      const visita = {
        dia: moment(info.dataTexto).format("YYYY MM DD"),
        saidaEmpresa: saidaTexto,
        chegadaCliente: info.horarioTexto,
        visita: moment('00:00', "HH:mm").add(info.visitaNumero, 'seconds').format('HH:mm'),
        visitaNumero: info.visitaNumero,
        saidaDoCliente: saidaCliente,
        chegadaEmpresa: SaidaClienteRef2,
        chegadaEmpresaRef: visitRef.chegadaEmpresa,
        saidaEmpresaRef: visitRef.saidaEmpresa,
        consultora: consultoraTexto,
        uid: sellerRef.id,
        cor: sellerRef.cor,
        tecnico: tecRefUID.nome,
        tecnicoUID: tecRefUID.uid,
        veiculo: tecRefUID.veiculo,
        cidade: city,
        endereco: addressComplete,
        cliente: cliente,
        observacao: info.observacao,
        tempoRota: rotaTempo,
        tempo: tempoTexto,
        tempoRotaConjunta: rotaTempo2,
        tempoConjunta: tempoTexto2,
        lng: position.lng,
        lat: position.lat,
        data: info.dataTexto,
        idRef: visitRef.id,
        group: 'antes',
        confirmar: false,
        tipo: "Visita Conjunta",
        categoria: type,
        corTec: tecRefUID.cor,
        createVisit: new Date(),
        dataRef: new Date(`${info.dataTexto}T${info.horarioTexto}`),
        concessionaria: info.concessionaria,
        responsavel: {
          nome: userRef?.nome,
          id: userRef?.id
        },
        indicador: indicadorRef ? {
          nome: indicadorRef.nome,
          id: indicadorRef.uid,
          ref_indicador: indicadorRef.id_representante,
          cidade: indicadorRef.cidade,
          telefone: indicadorRef.telefone
        } : {
          nome: 'Nenhum',
          id: '0',
          ref_indicador: 'Nenhum'
        },
        ...props
      };

      createVisit(visita, proposta)
    } else {
      if(visitRef.categoria !== "lunch") {
        await updateDoc(scheduleVisitRef, {
          chegadaEmpresa: createVisitGroup.info.saidaDoCliente,
          groupRef: 'depois',
          visitaConjunta: true,
          tipo: "Visita Conjunta"
         })
      } 
        //====================================== DEPOIS
        const visita = {
          dia: moment(info.dataTexto).format("YYYY MM DD"),
          saidaEmpresa: visitRef.tipo === "Almoço" ? visitRef.saidaDoCliente : saidaTexto,
          chegadaCliente: info.horarioTexto,
          visita: moment('00:00', "HH:mm").add(info.visitaNumero, 'seconds').format('HH:mm'),
          visitaNumero: info.visitaNumero,
          saidaDoCliente: saidaCliente,
          chegadaEmpresa: chegadaTexto,
          consultora: consultoraTexto,
          uid: sellerRef.id,
          cor: sellerRef.cor,
          tecnico: tecRefUID.nome,
          tecnicoUID: tecRefUID.uid,
          cidade: city,
          endereco: addressComplete,
          veiculo: tecRefUID.veiculo,
          lat: position.lat,
          lng: position.lng,
          cliente: cliente,
          observacao: info.observacao,
          tempoRota: rotaTempo,
          tempo: tempoTexto,
          tempoRotaConjunta: rotaTempo,
          tempoConjunta: tempoTexto,
          lngRef: position.lng,
          latRef: position.lat,
          data: info.dataTexto,
          confirmar: false,
          group: 'depois',
          visitaConjunta: true,
          tipo: 'Visita Conjunta',
          categoria: type,
          corTec: tecRefUID.cor,
          createVisit: new Date(),
          dataRef: new Date(`${info.dataTexto}T${info.horarioTexto}`),
          concessionaria: info.concessionaria,
          responsavel: {
            nome: userRef?.nome,
            id: userRef?.id
          },
          indicador: indicadorRef ? {
            nome: indicadorRef.nome,
            id: indicadorRef.uid,
            ref_indicador: indicadorRef.id_representante,
            cidade: indicadorRef.cidade,
            telefone: indicadorRef.telefone
          } : {
            nome: 'Nenhum',
            id: '0',
            ref_indicador: 'Nenhum'
          },
          ...props
        };

        
        createVisit(visita, proposta)
    }
  } catch (error) {

  }
}

const visitOnly = (proposta) => {  
  const indicadorRef = representatives.find(
    (data) => data.id_representante === info.representante
  );

  const props = cadastro ? { ...cadastro, endereco: addressComplete } : { preData: { ...preData } };

  const createIndicador = (ref) => {
    return ref
      ? {
          nome: ref.nome,
          id: ref.uid,
          ref_indicador: ref.id_representante,
          cidade: ref.cidade,
          telefone: ref.telefone,
        }
      : {
          nome: "Nenhum",
          id: "0",
          ref_indicador: "Nenhum",
        };
  };

  const visita = {
    cliente_id: cadastro ? cadastro.cliente_id : preData?.cliente_id || null,
    dia: moment(info.dataTexto).format("YYYY MM DD"),
    saidaEmpresa: saidaTexto,
    chegadaCliente: info.horarioTexto,
    visita: moment("00:00", "HH:mm")
      .add(info.visitaNumero, "seconds")
      .format("HH:mm"),
    visitaNumero: info.visitaNumero,
    saidaDoCliente: saidaCliente,
    chegadaEmpresa: chegadaTexto,
    consultora: consultoraTexto,
    uid: sellerRef.id,
    cor: sellerRef.cor,
    tecnico: tecRefUID.nome,
    tecnicoUID: tecRefUID.uid,
    cidade: city,
    endereco: addressComplete,
    veiculo: tecRefUID.veiculo,
    lat: position.lat,
    lng: position.lng,
    cliente: cliente,
    observacao: info.observacao,
    tempoRota: rotaTempo,
    tempo: tempoTexto,
    data: info.dataTexto,
    confirmar: false,
    tipo: "Visita",
    categoria: type,
    corTec: tecRefUID.cor,
    createVisit: new Date(),
    dataRef: new Date(`${info.dataTexto}T${info.horarioTexto}`),
    concessionaria: info.concessionaria,
    responsavel: {
      nome: userRef?.nome === 'Pós-Venda' ? userRef.nome_ref : userRef.nome,
      id: userRef?.id
    },
    indicador: createIndicador(indicadorRef),
    ...props,
  };

  // console.log(visita);
  createVisit(visita, proposta);
};



const addVisit = (ref) => {
  Swal.fire({
    title: 'Visitas',
    html: `Você deseja que a Visita seja conectada <b>antes</b> ou <b>depois</b> da Visita escolhida? </br></br>
    <b>Atenção: Verifique se já existe alguma Visita criada proxima a Visita escolhida.</b>`,
    icon: "question",
    showDenyButton: true,
    showCloseButton: true,
    confirmButtonColor: "#0eb05f",
    confirmButtonText: "Antes",
    denyButtonText: `Depois`,
  }).then((result) => {
    if (result.isConfirmed) {
      let msg;
      const antes = findVisits("antes", ref);
      if (antes.length > 0) {
        if (antes[0].tipo === "Almoço") {
          msg = "um <b>Almoço</b> criado";
        } else {
          msg = "uma <b>Visita</b> criada";
        }
        Swal.fire({
          title: 'Visitas',
          html:
            `Já existe ${msg} acima dessa visita.<br/>` +
            `<b>Exclua</b> ou <b>Altere</b> ${
              antes[0].tipo === "Almoço"
                ? "o <b>Almoço</b>"
                : "a <b>Visita</b>"
            } para poder criar uma <b>Visita Conjunta</b>`,
          icon: "warning",
          showConfirmButton: true,
          showCloseButton: true,
          confirmButtonColor: "#111",
        });
      } else {
        if(userRef && userRef.nome === 'Pós-Venda') {
          setTimeout(() => {
            setCreateVisitGroup({
              check: true,
              type: "antes",
              info: ref,
              typeRef: 'pos_venda',
              ref: doc(dataBase, "Visitas", ref.id)
            });
          }, 400);
        } else {
          setTimeout(() => {
            setCreateVisitGroup({
              check: true,
              type: "antes",
              info: ref,
              typeRef: type,
              ref: doc(dataBase, "Visitas", ref.id),
            });
          }, 400);
        }
      }
    } else if (result.isDenied) {
      let msg;
      const depois = findVisits("depois", ref);
      if (depois.length > 0) {
        if (depois[0].tipo === "Almoço") {
          msg = "um <b>Almoço</b> criado";
        } else {
          msg = "uma <b>Visita</b> criada";
        }
        Swal.fire({
          title: 'Visitas',
          html:
            `Já existe ${msg} abaixo dessa visita.<br/>` +
            `<b>Exclua</b> ou <b>Altere</b> ${
              depois[0].tipo === "Almoço"
                ? "o <b>Almoço</b>" 
                : "a <b>Visita</b>"
            } para poder criar uma <b>Visita Conjunta</b>`,
          icon: "warning",
          showConfirmButton: true,
          showCloseButton: true,
          confirmButtonColor: "#111",
        });
      } else {
        setTimeout(() => {
          setCreateVisitGroup({
            check: true,
            type: "depois",
            info: ref,
            typeRef: type,
            ref: doc(dataBase, "Visitas", ref.id)
          });
        }, 400);
      }
    }
  })
}

const removeVisit = (ref) => {
  Swal.fire({
    title: 'Visitas',
    html: `Você deseja desconectar essa <b>Visita?</b>`,
    icon: "question",
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonColor: "#0eb05f",
    cancelButtonColor: "#d33",
    confirmButtonText: "Sim",
    cancelButtonText: "Não",
  }).then(async (result) => {
    if(result.isConfirmed) {
      setCreateVisitGroup({ 
        check: false,
        info: {
          tempo: '0 minutos'
        }
       });
      Swal.fire({
        title: 'Visitas',
        html: `A Visita foi <b>desconectada</b> com sucesso.`,
        icon: "success",
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: "#111",
      })
    }
  })
}

const findVisits = (type, visit) => {
  if (type === "antes")
    return visitsAll.filter(
      (ref) =>
        ref.data === visit.data && ref.chegadaEmpresa === visit.saidaEmpresa &&
        ref.tecnico === visit.tecnico && ref.tipo !== "Almoço" && !ref.visitaAlmoco
    );
  if (type === "depois")
    return visitsAll.filter(
      (ref) =>
        ref.data === visit.data && ref.saidaEmpresa === visit.chegadaEmpresa &&
        ref.tecnico === visit.tecnico && ref.tipo !== "Almoço" && !ref.visitaAlmoco
    );
};

// const handlePropostaChange = (index, field, value) => {
//   setPropostas(currentPropostas => {
//     const newPropostas = [...currentPropostas];
//     const newProposta = { ...newPropostas[index], [field]: value };
//     newPropostas[index] = newProposta;
//     return newPropostas;
//   });
// };

// const addNewProposta = () => {
//   // Define o objeto com os valores iniciais para a nova proposta
//   const newProposta = {
//     kit: '',
//     ativo: false,
//     valor_inicial: '',
//     valor_final: '',
//     comissao: 0
//   };

//   // Atualiza o estado para incluir a nova proposta no array de propostas
//   setPropostas(currentPropostas => [...currentPropostas, newProposta]);
// };

// const removeProposta = (index) => {
//   Swal.fire({
//     title: 'Proposta',
//     html: `Você deseja remover a <b>Proposta?</b>`,
//     icon: "question",
//     showCancelButton: true,
//     showCloseButton: true,
//     confirmButtonColor: "#0eb05f",
//     cancelButtonColor: "#d33",
//     confirmButtonText: "Sim",
//     cancelButtonText: "Não",
//   }).then(async (result) => {
//       if (result.isConfirmed) {
//         setPropostas(currentPropostas => currentPropostas.filter((_, i) => i !== index));
//       }})
// };

// const ativeProposta = (index) => {
//   if(propostas[index].ativo === true) {
//     Swal.fire({
//       title: 'Proposta',
//       html: `Você deseja <b>desmarcar</b> a Proposta como <b>Aceita</b>?`,
//       icon: "question",
//       showCancelButton: true,
//       showCloseButton: true,
//       confirmButtonColor: "#0eb05f",
//       cancelButtonColor: "#d33",
//       confirmButtonText: "Sim",
//       cancelButtonText: "Não",
//     }).then(async (result) => {
//         if (result.isConfirmed) {
//           setPropostas(setInativo(propostas, index));
//         }})
//   } else {
//     Swal.fire({
//       title: 'Proposta',
//       html: `Você deseja <b>marcar</b> a Proposta como <b>Aceita</b>?`,
//       icon: "question",
//       showCancelButton: true,
//       showCloseButton: true,
//       confirmButtonColor: "#0eb05f",
//       cancelButtonColor: "#d33",
//       confirmButtonText: "Sim",
//       cancelButtonText: "Não",
//     }).then(async (result) => {
//         if (result.isConfirmed) {
//           setPropostas(setAtivo(propostas, index));
//           Swal.fire({
//             title: 'Proposta',
//             html: `A Proposta foi marcada com sucesso como <b>Aceita</b>.`,
//             icon: "success",
//             showConfirmButton: true,
//             showCloseButton: true,
//             confirmButtonColor: "#111",
//           })
//         }})
//   }
// };


// const setAtivo = (propostasRef, indexAtivo) => {
//   return propostasRef.map((proposta, index) => ({
//     ...proposta,
//     ativo: index === indexAtivo,
//   }));
// };

// const setInativo = (propostasRef, indexAtivo) => {
//   return propostasRef.map((proposta, index) => ({
//     ...proposta,
//     ativo: index === indexAtivo ? !proposta.ativo : proposta.ativo,
//   }));
// };

const isAfterPermittedHours = (time) => {
  const inputTime = moment(time, "HH:mm");
  const sixPM = moment("18:00", "HH:mm");
  const sixAM = moment("06:00", "HH:mm");

  if(type === 'comercial_tecnica' || type === 'tecnica') {
    return inputTime.isBefore(sixAM) || inputTime.isAfter(sixPM);
  } else return false

}

const checkVisitRoute = () => {
  if(type === 'comercial_tecnica' || type === 'tecnica') {
    return rotaTempo > 14400;
  } else {
    return false;
  }
}

  return (
      <div className={styles.panel}>
        <Loading view={loading} backgroundColor={'#ffffffd1'} />
        <Requirement typeVisit={type} userRef={userRef} type={type} view={view} collectData={collectData} openBox={openBox} closeBox={closeBox} />
        <div className={styles.panel_header}>
          <IconButton className={styles.back_button} onClick={() => navigate(`/visitas`)}><ArrowCircleLeftOutlinedIcon sx={{ fontSize: '50px' }} /></IconButton>
          <div className={styles.panel_header_title}>
            {type === 'comercial' ? <RequestQuoteIcon sx={{ fontSize: '45px', fill: "#0eb05f" }} /> : null}
            {type === 'comercial_tecnica' ? <PeopleIcon sx={{ fontSize: '45px', fill: "#576af5" }} /> : null}
            {type === 'pos_venda' ? <EngineeringIcon sx={{ fontSize: '45px', fill: "#e24725" }} /> : null}
            {type === 'tecnica' ? <EngineeringIcon sx={{ fontSize: '45px', fill: "#1c256e" }} /> : null}
            <h1>Criar Visita {visitaNome(type)}</h1>
          </div>  
        </div>
      <form onSubmit={onSubmit} className={styles.panel_content}>
        <h2>Dados do Cliente</h2>
        <div className={styles.panel_content_box}>
        <div className={styles.panel_content_title}>
            <h3>Endereço</h3>
          </div>
          <div className={styles.panel_content_title}>
          {isLoaded ?
            <GoogleMap mapContainerStyle={{ width: '100%', height: '300px' }} zoom={18} center={{ lat: position.lat, lng: position.lng }}>
             {position && <MarkerF  position={{ lat: position.lat, lng: position.lng }} />}
              <DistanceMatrixService
                options={{
                  destinations: [{ lat: position.lat, lng: position.lng }],
                  origins: [{ lng: -47.715045, lat: -23.109731}],
                  travelMode: "DRIVING",
                  drivingOptions: { departureTime: new Date(), trafficModel: 'bestguess'}, // Pega o trafico no tempo de criação da visita
                }}
                callback={(response, status) => {
                  if (status === "OK") {
                    // console.log(response);
                    if (
                      rotaTempo === undefined || rotaTempo !== response?.rows[0].elements[0].duration.value
                      ) {
                        setRotaTempo(response?.rows[0].elements[0].duration.value);
                        setTempoTexto(response?.rows[0].elements[0].duration.text);
                        console.log(response?.rows[0].elements[0].duration.value)
                      // setCheck(false);
                    }
                  }
                }}
              />
              {createVisitGroup.check ?
              <DistanceMatrixService
                options={{
                  destinations: [{ lat: position.lat, lng: position.lng }],
                  origins: [{ lng: positionRef.lng, lat: positionRef.lat}],
                  travelMode: "DRIVING",
                  drivingOptions: { departureTime: new Date(), trafficModel: 'bestguess'}, // Pega o trafico no tempo de criação da visita
                }}
                callback={(response, status) => {
                  if (status === "OK") {
                    // console.log(response);
                    if (
                      rotaTempo === undefined || rotaTempo !== response?.rows[0].elements[0].duration.value
                      ) {
                        setRotaTempo2(response?.rows[0].elements[0].duration.value);
                        setTempoTexto2(response?.rows[0].elements[0].duration.text);
                      // setCheck(false);
                    }
                  }
                }}
              /> : null
              }
            </GoogleMap> : 
            <Stack width='100%'>
              <Skeleton variant='rounded' width={"100%"} height={300} />
            </Stack>
          }
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <TextField
                margin="dense"
                label="Rua / Avenida"
                sx={{
                  '& .MuiInputLabel-root': { color: !checkVisitRoute() ? 'green' : 'grey' }, // Cor do label
                  '& .MuiInputLabel-root.Mui-focused': { color: !checkVisitRoute() ? 'green' : 'grey' }, // Cor do label quando focado
                  '& .MuiOutlinedInput-root': {
                    '& > fieldset': { borderColor: !checkVisitRoute() ? 'green' : 'grey' }, // Cor da borda padrão
                    '&:hover fieldset': { borderColor: !checkVisitRoute() ? 'green' : 'grey' }, // Cor da borda ao passar o mouse
                    '&.Mui-focused fieldset': { borderColor: !checkVisitRoute() ? 'green' : 'grey' }, // Cor da borda quando focado
                  },
                }}
                helperText={checkVisitRoute() ? 'O Endereço ultrapassa o limite de 4 horas de viagem.' : tempoTexto}
                fullWidth
                required
                InputProps={{
                  endAdornment: ( !checkVisitRoute() &&
                    <InputAdornment position="end">
                      <IoCheckmarkDone size='1.6rem' color="green"/>
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start">
                      <FcGoogle size='1.6rem'/>
                    </InputAdornment>
                  ),
                }}
                error={checkVisitRoute() ? true : false}
                variant="outlined"
                autoComplete='off'
                InputLabelProps={{ shrink: true }}
                inputRef={(el) => {
                  ref.current = el; // Conecta a ref do autocomplete à input
                  inputRef.current = el; // Mantém a ref da input localmente
                }}
              />
            </Grid>
            <Grid item xs={12} md={2.33}>
              <TextField
                margin="dense"
                type='date'
                label="Data"
                value={info.dataTexto || '0000-00-00'}
                onChange={(e) => setInfo({ ...info, dataTexto: e.target.value })}
                fullWidth
                required
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={2.33}>
              <TextField
                margin="dense"
                type='time'
                inputRef={hoursRef}
                label="Horário Marcado"
                disabled={createVisitGroup.check}
                value={info.horarioTexto ?? ''}
                onChange={(e) => setInfo({ ...info, horarioTexto: e.target.value })}
                fullWidth
                required
                helperText={isAfterPermittedHours(info.horarioTexto) ? 'Horário permitido: das 06:00 até 18:00hrs.' : null}
                error={isAfterPermittedHours(info.horarioTexto)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={2.32}>
              <FormControl fullWidth margin='dense'>
              <InputLabel id="disjuntor">Tempo de Visita</InputLabel>
                <Select
                  labelId='disjuntor'
                  label="Tempo de Visita"
                  value={info.visitaNumero ?? ''}
                  onChange={(e) => setInfo({...info, visitaNumero: e.target.value})}
                  required>
                  <MenuItem value={1800}>00:30</MenuItem>    
                  <MenuItem value={3600}>01:00</MenuItem>    
                  <MenuItem value={5400}>01:30</MenuItem>    
                  <MenuItem value={7200}>02:00</MenuItem>    
                  </Select>
              </FormControl>
            </Grid>
          </Grid>
          <div className={styles.panel_content_title}>
              <h3>Visitas de</h3>
              {info.dataTexto ? 
                <h3 style={{ marginLeft: '0.3rem' }}> {moment(info.dataTexto).format('DD/MM/YYYY')}</h3> : 
                <select
                    value={monthNumber}
                    name="month"
                    className={styles.month_visits}
                    onChange={(e) => setMonthNumber(e.target.value)}
                  >
                    <option value="01">Janeiro</option>
                    <option value="02">Fevereiro</option>
                    <option value="03">Março</option>
                    <option value="04">Abril</option>
                    <option value="05">Maio</option>
                    <option value="06">Junho</option>
                    <option value="07">Julho</option>
                    <option value="08">Agosto</option>
                    <option value="09">Setembro</option>
                    <option value="10">Outubro</option>
                    <option value="11">Novembro</option>
                    <option value="12">Dezembro</option>
                  </select>
              }
            </div>
          {visits && visits.length > 0  ?
            <div className={styles.panel_content_title}>
            <TableContainer className="table-visits" component={Paper} sx={{ maxHeight: 240 }}>
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className="table-visits_header">
                    <TableCell align="center"></TableCell>
                    <TableCell align="center" padding="none">Visita</TableCell>
                    {/* {type !== 'lunch' && 
                      <TableCell align="center">Ação</TableCell>
                    } */}
                    <TableCell align="center">Dia</TableCell>
                    <TableCell align="center">Saida</TableCell>
                    <TableCell align="center">Chegada</TableCell>
                    <TableCell align="center">Motorista</TableCell>
                    <TableCell align="center">Cidade</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {visits.map((visita) => (
                    <TableRow
                      key={visita.id}
                      sx={createVisitGroup.info && createVisitGroup.info.id === visita.id ? { backgroundColor: "#ffffd5" } : null}
                      // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell
                      aria-label={visita.consultora}
                      data-cooltipz-dir="right"
                      sx={{ backgroundColor: `${visita.cor}`, color: '#fff', width: 30 }} 
                      align="center" component="th" scope="row">
                        {visita.consultora.substring(0, 1)}
                      </TableCell>
                      {visita.categoria === "lunch" && <TableCell style={{ filter: 'contrast' }} className="type-icon lunch" aria-label="Almoço" data-cooltipz-dir="right"><RestaurantIcon /></TableCell>}
                      {visita.categoria === "comercial" && <TableCell className="type-icon comercial" aria-label="Visita Comercial" data-cooltipz-dir="right"><RequestQuoteIcon /></TableCell>}
                      {visita.categoria === "comercial_tecnica" && <TableCell className="type-icon comercial_tec" aria-label="Comercial + Técnica" data-cooltipz-dir="right"><PeopleIcon /></TableCell>}
                      {visita.categoria === "pos_venda" && <TableCell className="type-icon pos_venda" aria-label="Pós-Venda" data-cooltipz-dir="right"><EngineeringIcon /></TableCell>}
                      {visita.categoria === "tecnica" && <TableCell className="type-icon tec" aria-label="Técnica" data-cooltipz-dir="right"><EngineeringIcon /></TableCell>}
                      <TableCell sx={{ width: 30 }} align="center" scope="row">
                        {visita.dia.substring(8, 10)}
                      </TableCell>
                      <TableCell align="center">{createVisitGroup.type === 'antes' && createVisitGroup.info.id === visita.id ? visita.chegadaCliente : visita.saidaEmpresa}</TableCell>
                    <TableCell align="center">{createVisitGroup.type === 'depois' && createVisitGroup.info.id === visita.id ? visita.saidaDoCliente : visita.chegadaEmpresa}</TableCell>
                      {/* <TableCell align="center">{visita.saidaEmpresa}</TableCell>
                      <TableCell align="center">{visita.chegadaEmpresa}</TableCell> */}
                      <TableCell align="center">{visita.tecnico}</TableCell>
                      {visita.categoria !== "lunch" && 
                        <TableCell align="center">{visita.cidade && visita.cidade}</TableCell>
                      }
                      {visita.categoria === 'lunch' && 
                      <TableCell />
                      }
                      <TableCell sx={{ padding: 0 }} align="center">
                        {createVisitGroup.info && createVisitGroup.info.id === visita.id ? 
                        <IconButton 
                          aria-label='Desconectar Visita'
                          data-cooltipz-dir="left"
                          onClick={() => removeVisit(visita)}
                          >
                          <AddLinkIcon
                            sx={{ fill: "#949408" }}
                           />
                        </IconButton> : 
                        <IconButton 
                          aria-label='Conectar Visita' 
                          data-cooltipz-dir="left"
                          onClick={() => addVisit(visita)}>
                          <AddLinkIcon />
                        </IconButton>
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              </TableContainer>
            </div> : 
            <div style={{ display: 'none!important' }} className="visit-aviso">
                <h1>Nenhuma Visita Encontrada</h1>
            </div>
            }
          <div className={styles.panel_content_title}>
          {(city && info.visitaNumero && info.horarioTexto ? 
              <div className={visitsFindCount < 0 || visitsFindCount > 0 ? "box-visit__info prev error-aviso" : "box-visit__info prev check"}>
              <span className="">Previsão de Visita {(visitsFindCount < 0 || visitsFindCount > 0) ?
               <div aria-label="Essa Visita ultrapassa o horário de uma Visita já existente. Verifique os horários disponiveis"
                data-cooltipz-dir="top" data-cooltipz-size="large" ><ErrorOutlineIcon  sx={{ fill: 'red' }} /></div>
              :
              <div aria-label="A Visita pode ser criada"
                data-cooltipz-dir="top" ><CheckIcon className="check-icon" /></div>
              }
              </span>
              <p className="notice">
              <ArrowCircleRightIcon className="saida" />Saindo às <b>{saidaTexto}</b>
              </p>
              <p className="notice">
              <ArrowCircleLeftIcon className="saida" />Chegando às <b>{chegadaTexto}</b>
              </p>
            </div> :
              <div className="visit-aviso">
              <h2>Preencha o Endereço e Horário Marcado para visualizar a Previsão de Horário</h2>
             </div>
             )}
          </div>
          <div className={styles.panel_content_input}>
          <TextField
            margin="dense"
            id="name"
            label="Cliente"
            type="text"
            value={cliente ?? ''}
            onChange={(e) => setCliente(e.target.value)}
            fullWidth
            required
            variant="outlined"
          />
          <FormControl fullWidth margin='dense'>
          <InputLabel id="disjuntor">Consultor(a)</InputLabel>
            <Select
              labelId='disjuntor'
              label="Consultor(a)"
              value={consultoraTexto ?? ''}
              disabled={userRef && userRef.cargo === "Vendedor(a)" ? true : false}
              onChange={(e) => setConsultoraTexto(e.target.value)}
              required>
              {consultoras && consultoras.map((data, index) => (
                <MenuItem key={index} value={data.nome}>{data.nome}</MenuItem>
              ))}     
              </Select>
          </FormControl>
          <FormControl fullWidth margin='dense'>
          <InputLabel id="tecnico">Técnico</InputLabel>
            <Select
              labelId='tecnico'
              label="Técnico"
              value={info.tecnicoTexto || ''}
              onChange={(e) => setInfo({...info, tecnicoTexto: e.target.value})}
              required>
              {driver && driver.map((data, index) => (
                <MenuItem key={index} value={data.nome}>{data.nome}</MenuItem>
              ))}     
              </Select>
          </FormControl>
          <TextField
            margin="dense"
            id="name"
            label="Veiculo"
            type="text"
            value={(tecRefUID && tecRefUID.veiculo) || ''}
            fullWidth
            disabled
            variant="outlined"
          />
          </div>
          <div className={styles.panel_content_input}>
          <FormControl fullWidth margin='dense'>
          <InputLabel id="disjuntor">Concessionária</InputLabel>
            <Select
              labelId='disjuntor'
              label="Concessionária"
              value={info.concessionaria ?? ''}
              onChange={(e) => setInfo({...info, concessionaria: e.target.value})}
              required>
                <MenuItem value='CPFL'>CPFL</MenuItem>
                <MenuItem value='Elektro'>Elektro</MenuItem>
                <MenuItem value='Enel'>Enel</MenuItem>
                <MenuItem value='Cerim'>Cerim</MenuItem>
                <MenuItem value='Ceripa'>Ceripa</MenuItem>   
                <MenuItem value='EDP São Paulo'>EDP São Paulo</MenuItem>   
            </Select>
          </FormControl>
            <TextField
              margin="dense"
              id="name"
              label="Observação"
              type="text"
              value={info.observacao ?? ''}
              onChange={(e) => setInfo({ ...info, observacao: e.target.value })}
              fullWidth
              variant="outlined"
              />
            {type === 'comercial_tecnica' && (userRef && (userRef.cargo === 'Administrador' || userRef.time)) ? 
              <FormControl fullWidth margin='dense'>
              <InputLabel id="representante">Representante</InputLabel>
                <Select
                  labelId='representante'
                  label="Representante"
                  value={info.representante ?? ''}
                  onChange={(e) => setInfo({...info, representante: e.target.value})}
                  required>
                    <MenuItem value='Nenhum'>Nenhum</MenuItem>
                      {representatives.map((data, index) => (
                        <MenuItem key={index} value={data.id_representante}>{data.nome} <b> ({data.id_representante})</b></MenuItem>
                    ))}   
                </Select>
              </FormControl> : null
            }
          </div>
        </div>
        {/* {info.representante !== 'Nenhum' ? 
          (<><h2>Propostas</h2>
          <div className={styles.panel_content_box}>
            <div className={styles.panel_content_title}>
              <h3>Detalhes</h3>
            </div>
            {propostas.map((data, index) => (
              <div key={index} className={[styles.panel_content_input]} style={{ alignItems: 'center' }}>
                <IconButton 
                  onClick={() => ativeProposta(index)}
                  sx={{ height: '40px' }}>
                  {data.ativo ? 
                    <StarIcon sx={{ fill: '#ffe44c' }} /> : 
                    <StarBorderIcon sx={{ fill: '#ccc' }} />
                  }
                </IconButton>
                <TextField
                    label="Kit Fotovoltaico"
                    type="text"
                    value={data.kit ?? ''}
                    onChange={(e) => handlePropostaChange(index, 'kit', e.target.value)}
                    fullWidth
                    required
                    variant="outlined"
                />
                <CurrencyInput
                  customInput={TextField}
                  style={{ margin: '0.5rem 0rem 0.7rem 0' }}
                  label="Valor Inicial"
                  placeholder="R$ 00"
                  intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                  onValueChange={(value) => handlePropostaChange(index, 'valor_inicial', value)}
                  decimalsLimit={2}
                  value={data.valor_inicial || ''}
                  required
                  fullWidth
                  />
                <CurrencyInput
                  customInput={TextField}
                  style={{ margin: '0.5rem 0rem 0.7rem 0' }}
                  label="Valor Final"
                  placeholder="R$ 00"
                  intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                  onValueChange={(value) => handlePropostaChange(index, 'valor_final', value)}
                  decimalsLimit={2}
                  value={data.valor_final || ''}
                  required
                  fullWidth
                  />
                    <IconButton 
                      color='error'
                      sx={{ height: '40px' }}
                      disabled={index === 0 || (userRef && userRef.cargo === "Vendedor(a)")}
                      onClick={() => removeProposta(index)}><DeleteForeverIcon /></IconButton>
              </div>
            ))}
            <div className={styles.panel_content_title}>
              <Button
                disableElevation
                variant='contained'
                startIcon={<AddCircleIcon />}
                sx={{ textTransform: 'capitalize' }}
                onClick={addNewProposta}>Adicionar Proposta</Button>
            </div>
            <div className={styles.panel_content_value}>
              <h3>{proposta.comissao.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})} ({proposta.porcentagem}%)</h3>
            </div> 
          </div>
            </>) : null
        } */}
        <div className={styles.button_footer}>
        <ThemeProvider theme={theme}>
          <Button 
            disableElevation 
            color='secondary'
            variant={'contained'}
            type='submit'
            style={{ textTransform: 'capitalize' }}>
              Criar
          </Button>
          <Link to={'/visitas'}>
            <Button 
              disableElevation 
              variant={'outlined'}
              onClick={closeBox}
              style={{ textTransform: 'capitalize' }}>
                Cancelar
            </Button>
          </Link>
        </ThemeProvider>
        </div>
      </form>
    </div>
  )
}

export default memo(CreateVisit);