import React, { useMemo, useCallback } from 'react';
import { Badge, Box, ThemeProvider } from '@mui/material';
import { BiMoneyWithdraw } from 'react-icons/bi';
import { MdEngineering } from 'react-icons/md';
import NewTooltip from '../../../components/Tooltip';
import { theme } from '../../../data/theme';

import PanelSolar from '../../../images/icons/PainelSolar.png';

const Status = React.memo(({ data, getMember }) => {
  // Inicializamos as variáveis antes de qualquer condição
  const checkEstimate = useMemo(() => {
    return data?.planilha?.find(item => item.extra || item.geracao);
  }, [data?.planilha]);

  const checkSimulation = useMemo(() => {
    return data?.proposta?.find(item => item.simulacao_enviada || item.nome);
  }, [data?.proposta]);

  const checkVisit = useMemo(() => {
    return data?.planilha?.find(item => item?.visita_tecnica?.enviado || item.visita_tecnica);
  }, [data?.planilha]);

  const checkStatusSimulation = useCallback((simulationRef) => {
    if (!simulationRef) return null;
    const { simulacao_enviada, status } = simulationRef;
    
    if (simulacao_enviada) {
      return { text: 'Enviada', color: '#58abfd', status };
    } else if (status) {
      return { text: 'Gerada', color: '#f5c625', status };
    }
    return null;
  }, []);

  const checkStatusVisit = useCallback((visitRef) => {
    if (!visitRef) return null;
    const { visita_tecnica } = visitRef;
    const { responsavel_id, rede_eletrica: { melhorias } = {} } = visita_tecnica || {};

    if (melhorias) {
      return { text: 'Com Melhorias', color: '#f5c625', responsible: getMember(responsavel_id) };
    } else if (responsavel_id) {
      return { text: 'Sem Melhorias', color: '#02b902', responsible: getMember(responsavel_id) };
    }
    return null;
  }, [getMember]);

  const checkStatusSystem = useCallback((data) => {
    if (!data) return null;
    const { extra, reserva, sistemaRecomendado } = data;

    if (extra) {
      if (reserva) return { text: 'Contrato', color: '#02b902' };
      if (sistemaRecomendado) return { text: 'Enviado', color: '#58abfd' };
      return { text: 'Gerado', color: '#f5c625' };
    }
    return null;
  }, []);

  const checkEstimateStatus = checkStatusSystem(checkEstimate);
  const checkSimulationStatus = checkStatusSimulation(checkSimulation);
  const checkVisitStatus = checkStatusVisit(checkVisit);

  const renderStatusIcon = useCallback((condition, icon, title, defaultOpacity = 0.2) => {
    return condition ? (
      <ThemeProvider theme={theme}>
        <Badge
          variant="dot"
          sx={{ "& .MuiBadge-badge": { backgroundColor: condition?.color, color: "#fff" } }}
        >
          <NewTooltip title={title} placement="top">
            <span>{icon}</span>
          </NewTooltip>
        </Badge>
      </ThemeProvider>
    ) : (
      <NewTooltip title={title} placement="top">
        <span style={{ opacity: defaultOpacity }}>{icon}</span>
      </NewTooltip>
    );
  }, []);

  // Agora fazemos o early return após todos os hooks serem executados
  if (!data) return null;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {renderStatusIcon(
        checkVisitStatus,
        <MdEngineering size="27px" />,
        checkVisitStatus?.text ? `Visita ${checkVisitStatus?.text}` : 'Sem Visita',
        0.2
      )}
      {renderStatusIcon(
        checkSimulationStatus,
        <BiMoneyWithdraw size="27px" />,
        checkSimulationStatus?.text ? `Simulação ${checkSimulationStatus?.text}` : 'Sem Simulação',
        0.2
      )}
      {renderStatusIcon(
        checkEstimateStatus,
        <img src={PanelSolar} alt="Panel Solar" style={{ width: 35, height: 35, cursor: 'pointer' }} />,
        checkEstimateStatus?.text ? `Orçamento ${checkEstimateStatus?.text}` : 'Sem Orçamento',
        0.3
      )}
    </Box>
  );
});

export default Status;
