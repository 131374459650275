import { useState } from 'react';
import { getDownloadURL, ref, uploadBytesResumable, getStorage } from "firebase/storage";

const useUploadPhotos = () => {
  const [changeProgress, setChangeProgress] = useState([]);
  const storage = getStorage();

  const uploadImage = async ( image, type, index, id, locale) => {
    return new Promise((resolve, reject) => {
      
      console.log("🚀 ~ uploadImage ~ image:", image)
      const storageReff = ref(storage, `${locale}/${id}/${type}_${image.complete.name}`);
      const uploadTask = uploadBytesResumable(storageReff, image.complete, { contentType: image.complete.type });

      uploadTask.on('state_changed', (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        handleProgress(progress, index);
        console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        console.error(error);
        reject(error);
      }, 
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // handleRef(storageReff.fullPath, index); 
          console.log(storageReff)
          resolve({ 
            downloadURL, 
            fullPath: storageReff.fullPath
           });
        });
      });
    });
  };


  const handleProgress = (progress, index) => {
    setChangeProgress((prevChangeProgress) => {
      const newChangeProgress = [...prevChangeProgress];
      newChangeProgress[index] = progress;
      return newChangeProgress;
    });
  };

  const uploadImages = async (images, type, index, id, locale = 'Relatorio') => {
    // Verifica se o array de imagens está vazio antes de tentar fazer upload
    if (!images || images.length === 0) {
      throw new Error("Nenhuma imagem para upload.");
    }

    const imagePromises = Array.from(images, (image) => uploadImage(image, type, index, id, locale));
    const imageRes = await Promise.all(imagePromises);
    return imageRes;
  };

  return { uploadImages, changeProgress };
};

export default useUploadPhotos;
