import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import styles from '../styles.module.scss';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

import Mulher from '../../../images/Mulher.png';

// import LogoFacilit from '../../../images/LogoFacilit.png';
// import { ReactComponent as GrupoCorpBrasil } from '../../../images/Logo_Grupo.svg';
// import { ReactComponent as LogoCorpBrasil } from '../../../images/Logo_CorpBrasil.svg';
import { ReactComponent as GrupoCorpBrasilWhite } from '../../../images/Logo_Grupo_Branco.svg';
import Logo from '../../../images/LogoCORPBRASIL.png';
import BancoDoBrasil from '../../../images/icons/BancodoBrasil.jpg';
import Bradesco from '../../../images/icons/Bradesco.jpg';
import Santander from '../../../images/icons/Santander.jpg';
import Caixa from '../../../images/icons/Caixa.jpeg';
import CloseIcon from '@mui/icons-material/Close';

import { Avatar, Box, Button, Collapse, Divider, Grid, IconButton, styled, Typography } from '@mui/material';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import CustomAudioPlayer from '../../../components/AudioPlayerFixed/CustomAudioPlayer';
import ButtonSection from '../components/ButtonSection';
import PixRounded from '@mui/icons-material/PixRounded';
import ButtonCB from '../../../components/Button';
import NewTooltip from '../../../components/Tooltip';
import { copyText } from '../../../functions/copyText';

import { getAuth, signInAnonymously, getIdToken } from 'firebase/auth';
import { ref, uploadBytesResumable, getDownloadURL, getStorage } from 'firebase/storage';
import axios from 'axios';
import { toast } from '../../../components/Toast';
import { Link, useParams } from 'react-router-dom';
import { VisuallyHiddenInput } from '../../../components/InputFile';
import useViewDocBox from '../../../hooks/useViewDocBox';

const paymentsMethods = [
  {
    name: "Banco do Brasil",
    pix: "compras@corpbrasil.com",
    image: BancoDoBrasil,
    agency: '0713-7',
    account: '37480-6'
  },
  {
    name: "Bradesco",
    pix: "contrato@corpbrasil.com",
    image: Bradesco,
    agency: '0521',
    account: '320250'
  },
  {
    name: "Caixa Econômica Federal",
    pix: "34.691.677/0001-35",
    image: Caixa,
    agency: '1214',
    op: '003',
    account: '00001640-4'
  },
  {
    name: "Santander",
    pix: "financeiro@corpbrasil.com",
    image: Santander,
    agency: '0045',
    account: '13.006176-1'
  }
]

const Payments = () => {
  const { viewDoc } = useViewDocBox();
  const [assistant] = useState(null);
  const [system] = useState(null);
  const storage = getStorage();
  const auth = getAuth();
  const { combinedParam } = useParams();
  const [idCustomer] = combinedParam.split("-");
  const [showPayment, setShowPayment] = useState('');
  const [idToken, setIdToken] = useState('');
  const [file, setFile] = useState([]);

  const NewDivider = styled(Divider)(({ height, color, margin }) => ({
    width: '100%',
    height: height || '4px',
    border: 'none',
    margin: margin || 0,
    background: `linear-gradient(90deg, rgba(243, 146, 0, 0) 0%, ${color || '#F39200'} 49.5%, rgba(243, 146, 0, 0) 100%)`,
  }));

  const memoizedProps = useMemo(() => {
    if (!system?.extra?.mensagemVoz?.url) return null;
    return {
      url: system.extra.mensagemVoz.url,
      color: '#747474'
    };
  }, [system?.extra?.mensagemVoz?.url]);

  const NewBox = styled('div')(({ padding, margin }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '15px',
    width: '100%',
    backgroundColor: '#fff',
    margin: margin || 0,
    padding: padding || '1rem',
    boxSizing: 'border-box',
    boxShadow: '0px 10px 24px 0px #0000001A'
  }));

  useEffect(() => {
    // Autentica o cliente anonimamente
    const autenticarCliente = async () => {
      try {
        // Autentica o cliente anonimamente
        const userCredential = await signInAnonymously(auth);  
        const token = await getIdToken(userCredential.user);
        setIdToken(token); // Armazena o token de autenticação
  
      } catch (error) {
        console.error('Erro ao autenticar ou extrair claims do token:', error);
      }
    };
  
    autenticarCliente();
  }, [auth]);

  const enviarComprovante = async () => {
    if (!file) {
      return null;
    }

    try {
      // Referência ao caminho no Cloud Storage
      const storageRef = ref(storage, `comprovantes/${idCustomer}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Monitorando o progresso do upload
      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Progresso do upload: ' + progress + '%');
        },
        (error) => {
          console.error('Erro ao fazer upload do arquivo:', error);
          toast({ icon: 'error', text: 'Erro ao fazer upload do arquivo.', error: error })
        },
        async () => {
          // Após o upload ser concluído, obter a URL do arquivo
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

          // Enviar a URL do comprovante para o backend (Firebase Function)
          const response = await axios.post(
            'https://us-central1-corpbrasilagenda.cloudfunctions.net/api/envio-comprovante',
            { idCustomer, filePath: downloadURL },
            {
              headers: {
                Authorization: `Bearer ${idToken}`,
              },
            }
          );
          toast({ icon: 'success', text: response.data.message})
        }
      );
    } catch (error) {
      console.error('Erro ao enviar comprovante:', error);
      toast({ icon: 'error', text: 'Erro ao enviar comprovante.', error: error })
    }
  };

  console.log(enviarComprovante());

  const PaymentDetails = memo(({ data }) => (
    <Box sx={{ backgroundColor: '#fff', padding: '1rem', boxShadow: '0px 10px 24px 0px #0000001A', borderRadius: '15px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.7rem' }}>
        <b>Transferência Bancária</b>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.3rem', paddingBottom: '1rem', borderBottom: '1px dashed #ccc' }}>
          <p>CorpBrasil Energia Renovável Ltda</p>
          <p><b>Agência:</b> {data.agency}
            {data.op && (
              <Typography component='span' sx={{ padding: '0 0.6rem' }}>
                <b>OP:</b> {data.op}
              </Typography>
            )}
            <b> CC:</b> {data.account}</p>
          <p><b>CNPJ:</b> 34.691.677/0001-35</p>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.3rem', padding: '1rem 0 0.5rem 0', width: '100%' }}>
        <b>Pagamento via PIX</b>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.3rem', padding: '0.5rem', backgroundColor: "#fff", boxShadow: '0px 4px 24px 0px #0000001A', maxWidth: '500px', width: '100%', borderRadius: '10px' }}>
            <PixRounded sx={{ fill: "#30B6A8" }} />
            <p>{data.pix}</p>
          </Box>
          <NewTooltip title="Clique aqui para copiar a Chave Pix" placement='top'>
            <ButtonCB background="#30B6A8" onClick={() => copyText(data.pix, 'Pix')}>
              <ContentCopyOutlinedIcon />
            </ButtonCB>
          </NewTooltip>
        </Box>
      </Box>
    </Box>
  ));

  const sendFile = useCallback((e) => {
    const ref = e.target.files;
    const files = [];

    Array.from(ref).forEach((image) => {
      const img = new Image();
      img.src = URL.createObjectURL(image);
  
      img.onload = () => {
        // Verificar o aspecto da imagem
        const aspectRatio = img.width / img.height;
        let aspectClass = '';
  
        // Determinar a classe com base na proporção da imagem
        if (aspectRatio > 1) {
          aspectClass = 'landscape';  // Larga
        } else if (aspectRatio < 1) {
          aspectClass = 'portrait';   // Alta
        } else {
          aspectClass = 'square';     // Quadrada
        }
  
        // Adicionar a imagem ao array de arquivos com a classe de estilo
        files.push({ file: img.src, complete: image, aspectClass });
        
        // Atualize o estado do arquivo
        const fileCheck = file ? [...file, ...files] : files;
        setFile(fileCheck);
      };
    });

  },[file])

  console.log(file);

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <HelmetProvider>
        <Helmet>
          <title>Nossa História - CorpBrasil Energia Renovável</title>
          <meta name="title" content="Nossa História - CorpBrasil Energia Renovável" />
          <meta name="description" content="A Experiência dos Nossos Clientes com Energia Solar" />
        </Helmet>
      </HelmetProvider>
      <div className={styles.estimate_header_fixed}>
        <div>
          <img src={Logo} alt='' />
          <h3>Financeiro</h3>
        </div>
        <NewDivider />
      </div>
      <div className={styles.assessments_content}>
        <Grid container spacing={2} sx={{ maxWidth: '800px', marginTop: '55px' }}>
          <Grid item xs={12} sx={{ 'img': { width: '100%' } }}>
            <div className={styles.background_payments}>
              <img src={Mulher} alt='' />
            </div>
            <Box sx={{ backgroundColor: "#f5f5f5", padding: '1rem 1rem 0 1rem', top: '-20px', position: 'relative', borderRadius: '15px' }}>
              <NewBox>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', 'h3': { fontSize: '20px' } }}>
                  <h3>🥳 Parabéns pela sua escolha! </h3>
                  <p>Olá [Nome do Cliente], Estamos muito felizes em recebê-lo!</p>
                  <p><b>Seu contrato foi oficialmente assinado</b>, e agora começaremos o cronograma de todos os processos necessários para a instalação do seu sistema de energia solar.</p>
                  <p>CorpBrasil - Energia que move o presente!</p>
                </Box>
              </NewBox>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} paddingTop={'0!important'}>
                <Box padding={'0 1rem'}>
                  <NewBox margin='0.5rem 0 0 0' sx={{ flex: 1 }}>
                    <h3>Financeiro</h3>
                    <NewDivider height='3px' color='#F1F1F1' margin='0.3rem 0 0.5rem 0' />
                    <Box className={styles.flex_row} sx={{ width: '100%', maxWidth: '500px' }}>
                      <Avatar src={assistant?.photo?.url} alt='' sx={{ width: 60, height: 60 }} />
                      <Box className={styles.flex_center}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'end' }} fontSize={14}>
                          <b>Analista fotovoltaica:</b>
                          <p>{assistant?.nome_completo}</p>
                        </Box>
                        {memoizedProps ? <CustomAudioPlayer {...memoizedProps} /> : null}
                      </Box>
                    </Box>
                  </NewBox>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box padding={'0 1rem'}>
                  <NewBox>
                    <h3>Valor de Entrada</h3>
                    <NewDivider height='3px' color='#F1F1F1' margin='0.3rem 0 0.5rem 0' />
                    <Typography fontSize={'39px'} color="#4A4A4A" padding='0.5rem 0' component='h1' fontWeight={'bold'}>R$4.500,00</Typography>
                    <p>Selecione o banco de sua preferência:</p>
                    {paymentsMethods.map((data, index) => (
                      <Grid key={data.name || index} container spacing={2} marginTop={'0.3rem'}>
                        <Grid item sx={{ "img": { borderRadius: '10px', maxWidth: '50px' } }}>
                          <img src={data.image} alt={data.name} />
                        </Grid>
                        <Grid item xs sx={{ display: 'flex', alignItems: 'center' }}>
                          <ButtonSection icon={false} sx={{ padding: "0.67rem" }} text={data.name} onClick={() => setShowPayment(data.name)} />
                        </Grid>
                        {data.name === showPayment && (
                          <Grid item xs={12}>
                            <Collapse in={data.name === showPayment} timeout={'auto'} unmountOnExit>
                              <PaymentDetails data={data}/>
                            </Collapse>
                          </Grid>
                        )}
                      </Grid>
                    ))}
                  </NewBox>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box padding={'0 1rem'}>
                  <NewBox sx={{ flex: 1, border: "1px solid #FFAB00", padding: '1.3rem' }}>
                    <h3>Comprovante de Pagamento</h3>
                    <NewDivider height='3px' color='#FFAB00' margin='0.3rem 0 0.2rem 0' />
                    <Grid container spacing={2} marginTop={'0rem'} paddingTop={'0rem'}>
                      <Grid item xs={12}>
                        <Box sx={{ backgroundColor: "#D9D9D9", borderRadius: '5px', display: 'flex', overflow: 'hidden',
                           justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '0.3rem', "a": { padding: '0.2rem 0.5rem' } }}>
                          <Box 
                            sx={{ padding: '1rem', display: 'flex',boxSizing: 'border-box', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', backgroundColor: "#CFFFD9",
                             "a": { padding: '0.2rem 0.5rem', width: '100%' } }}>
                            {file.map((data, index) => (
                              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <Link key={index} to={''} onClick={() => viewDoc(data)}>{data.complete.name}</Link>
                                <IconButton color='error' size='small'>
                                  <CloseIcon />
                                </IconButton>
                              </Box>
                            ))}
                          </Box>
                          <Button 
                            variant='text' 
                            component="label" 
                            sx={{ textTransform: 'capitalize', fontSize: '16px', padding: '0.2rem' }}
                            onChange={(e) => sendFile(e)}>
                          Selecionar Comprovante
                          <VisuallyHiddenInput
                              type="file"
                              multiple={true}
                              accept="image/png,image/jpeg, application/pdf"
                            />
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={12}></Grid>
                    </Grid>
                  </NewBox>
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ backgroundColor: "#282828" }}>
                <Box sx={{ color: "#fff", display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem', padding: '1.5rem 1rem 2rem 1rem' }}>
                  <GrupoCorpBrasilWhite />
                  <Box sx={{ '.path': { fill: "#fff" }, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <b>CorpBrasil Energia Renovável LTDA</b>
                    <b>34.691.677/0001-35</b>
                  </Box>
                  <Box sx={{ marginTop: '1rem', textAlign: 'center' }}>
                    <p>Copyright © 2024 CorpBrasil Energia Renovável.</p>
                    <p>Todos os direitos reservados.</p>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Box>
  )
}

export default Payments;