import { ThemeProvider } from "@emotion/react";
import { theme } from "../../../data/theme";
import { Box, Button, Grid, IconButton, InputAdornment, styled, TextField, Typography } from "@mui/material";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import removeNullProperties from "../../../functions/removeNullProperties";
import { PatternFormat } from "react-number-format";
import { KeyMaps } from "../../../data/Data";
import NewTooltip from "../../../components/Tooltip";
import useViewDocBox from "../../../hooks/useViewDocBox";
import { Link } from "react-router-dom";
import { VisuallyHiddenInput } from "../../../components/InputFile";
import { FaFileUpload } from "react-icons/fa";
import { PiCloudArrowUpLight } from "react-icons/pi";
import { PiCloudCheckLight } from "react-icons/pi";

import LockIcon from '@mui/icons-material/Lock';
import CheckCircle from '@mui/icons-material/CheckCircle';
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DeleteRounded from "@mui/icons-material/DeleteRounded";
import { useCallback, useState } from "react";
import AutocompleteMapsV2 from "../../../components/AutoCompleteMapsV2";
import { toast } from "../../../components/Toast";
import { useRegisterInfo } from "../../../contexts/RegisterInfoContext";
import { checkPermission } from "../../../functions/checkPermission";
import useAuth from "../../../hooks/useAuth";
import ButtonCB from "../../../components/Button";
import CurrencyInput from "react-currency-input-field";

const validationSchema = Yup.object({
  nome: Yup.string().required("Nome é obrigatório"),
  cidade: Yup.string().required("Cidade é obrigatório"),
  telefone: Yup.string().matches(/^[^_]*$/, 'O número de Telefone está incorreto.'),
  // Adicione outras validações conforme necessário
});

const DocsItems = styled(Box)(({ color }) => ({
  padding: "0.5rem 1rem",
  borderRadius: "5px",
  backgroundColor: color || "#fff",
  display: "flex",
  justifyContent: "space-between",
  border: "1px solid rgba(0, 0, 0, 0.12)",
}));

const InfoForm = ({ initialValues, documents, setDocuments, saveTabData, type, formId, ind, changeData,
  customerId, close, checkUser, viewClose = true, location }) => {
  const { viewDoc } = useViewDocBox();
  const { user } = useAuth();
  const { setValue, trigger } = useFormContext();
  const { address, setAddress } = useRegisterInfo();
  const [send, setSend] = useState(false);
  const { handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const handleFormSubmit = async (values) => {
    console.log('VALORES', values);
    const valuesFormated = removeNullProperties({ ...values, telefone: values?.telefone?.replace(/\D/g, "") });
    saveTabData(valuesFormated, type, send, ind);

    if (send) {
      setSend(false);
    }
  };

  const sendFile = useCallback((value, index) => {
    const files = Array.from(value).map((image) => ({
      file: URL.createObjectURL(image),
      complete: image,
    }));

    setDocuments((prevDocuments) => {
      // Cria uma cópia profunda do array de documentos atual para evitar mutações diretas
      const updatedDocuments = [...prevDocuments];

      // Garante que o índice `ind` é inicializado como um array vazio, se ainda não existir
      if (!Array.isArray(updatedDocuments[ind])) {
        updatedDocuments[ind] = [];
      }

      // Adiciona os novos arquivos ao array no índice específico
      updatedDocuments[ind] = [...updatedDocuments[ind], ...files];

      return updatedDocuments; // Retorna a matriz atualizada
    });

  }, [setDocuments, ind]);

  const deleteFile = useCallback((index) => {

    setDocuments((prevDocuments) => {
      // Cria uma cópia do array de documentos para evitar mutações diretas
      const updatedDocuments = [...prevDocuments];

      // Garante que o índice `ind` é inicializado como um array vazio, se ainda não existir
      if (!Array.isArray(updatedDocuments[ind])) {
        updatedDocuments[ind] = [];
      }

      // Remove o arquivo específico do índice `ind`
      updatedDocuments[ind] = updatedDocuments[ind].filter((_, i) => i !== index);

      return updatedDocuments; // Retorna a matriz atualizada de documentos
    })

  }, [setDocuments, ind]);


  const mask = (v) => {
    v = v.replace(/\D/g, "")

    if (v.length <= 11) {
      v = v.replace(/(\d{3})(\d)/, "$1.$2")
      v = v.replace(/(\d{3})(\d)/, "$1.$2")
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    } else {
      v = v.substring(0, 14); // limita em 14 números
      v = v.replace(/^(\d{2})(\d)/, "$1.$2")
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
      v = v.replace(/(\d{4})(\d)/, "$1-$2")
    }

    return v
  }

  return (
    <form id={formId} onSubmit={handleSubmit(handleFormSubmit)}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <Controller
            name="nome"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                margin="dense"
                label="Nome Completo"
                disabled={!changeData}
                error={!!errors.nome}
                helperText={errors.nome ? errors.nome.message : ''}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="doc"
            control={control}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                fullWidth
                margin="dense"
                label="CNPJ/CPF"
                disabled={!changeData}
                inputProps={{ minLength: 14 }}
                value={value || ""}
                onChange={(e) => onChange(mask(e.target.value))}
                onBlur={onBlur}
                inputRef={ref}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                margin="dense"
                disabled={!changeData}
                label="Email"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="empresa"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                disabled={!changeData}
                label="Empresa"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="telefone"
            control={control}
            render={({ field }) => (
              <PatternFormat
                {...field}
                format="(##) ##### ####"
                mask="_"
                fullWidth
                disabled={!checkPermission(["Administrador", "Vendedor(a)"], user) || !changeData}
                customInput={TextField}
                placeholder="(00) 00000 0000"
                label="Telefone"
                error={!!errors.telefone}
                helperText={errors.telefone ? errors.telefone.message : ''}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="data_nascimento"
            control={control}
            render={({ field }) => (
              <PatternFormat
                {...field}
                format="##/##/####"
                mask="_"
                fullWidth
                customInput={TextField}
                disabled={!changeData}
                placeholder="00/00/0000"
                label="Data de Nascimento"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="renda"
            control={control}
            render={({ field: { onChange, name, value } }) => (
              <CurrencyInput
                customInput={TextField}
                label="Renda Mensal"
                placeholder="R$ 00"
                name={name}
                intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                decimalsLimit={2}
                value={value}
                onValueChange={(value) => onChange(value || '')}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="cep"
            control={control}
            render={({ field }) => (
              <PatternFormat
                {...field}
                format="#####-###"
                mask="_"
                fullWidth
                margin='dense'
                customInput={TextField}
                placeholder="00000-000"
                label="CEP"
                minLength={9}
                variant="outlined"
                disabled={!changeData}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          {ind === 0 ?
            <AutocompleteMapsV2
              KeyMaps={KeyMaps}
              label="Endereço com Número (Google)"
              disabled={!changeData}
              defaultValue={initialValues?.endereco?.endereco ?? ''}
              newValue={address?.endereco}
              onPlaceSelected={async (place) => {
                // Define os valores de endereço, cidade e cep usando `setValue`
                if (place?.endereco !== address?.endereco) {
                  toast({ icon: 'success', text: 'Endereço localizado com sucesso' })
                }
                setAddress({ endereco: place.endereco, lat: place.lat, lng: place.lng });
                setValue("cidade", place.cidade);

                await trigger(["endereco", "cidade", "cep", "lat", "lng"]);
              }}
            /> :
            <Controller
              name="endereco"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  margin='dense'
                  disabled={!changeData}
                  label="Endereço com Número"
                />
              )}
            />
          }
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="complemento"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                disabled={!changeData}
                label="Complemento"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="bairro"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                disabled={!changeData}
                label="Bairro"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <NewTooltip title="Só atendemos o estado de São Paulo" placement="top">
            <TextField
              fullWidth
              label="Estado"
              disabled
              value="SP"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
            />
          </NewTooltip>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="cidade"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Cidade"
                error={!!errors.cidade}
                helperText={errors.cidade ? errors.cidade.message : ''}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Box sx={{
            display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: "#faf8f4",
            borderRadius: '5px', justifyContent: 'center', padding: '0.5rem', borderLeft: '5px solid #7b705b', marginTop: '0.7rem'
          }}>
            <Typography variant='h6'>Documentos</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.3rem', margin: '1rem 0' }}>
              {documents[ind]?.length > 0 ?
                documents[ind]?.map((file, index) => (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    {file?.url ? <PiCloudCheckLight size="1.6rem" color='green' /> : <PiCloudArrowUpLight size="1.6rem" color='#000' />}
                    <DocsItems>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "0.5rem",
                          alignItems: "center",
                        }}
                        onClick={() => file.url ? window.open(file.url, '_blank') : viewDoc(file)}
                      >
                        <InsertDriveFileOutlinedIcon sx={{ fill: "#484848" }} />
                        <Link to="" style={{ color: "#000" }}>
                          {file.url ? `${file?.ref?.split(`Clientes/${customerId}/`)[1]} [Enviado]` : file?.complete?.name}
                        </Link>
                      </Box>
                      <NewTooltip
                        id="Clickable"
                        title="Excluir"
                        placement="top"
                      >
                        <span>
                          <IconButton
                            disabled={!changeData}
                            onClick={() => deleteFile(index)}
                            color="error"
                          >
                            <DeleteRounded />
                          </IconButton>
                        </span>
                      </NewTooltip>
                    </DocsItems>
                  </Box>
                )) : <Typography sx={{ fontStyle: "italic" }}>Nenhum documento encontrado</Typography>
              }
            </Box>
            <Button
              sx={{ textTransform: "capitalize", marginBottom: '0.5rem' }}
              variant="contained"
              component="label"
              disabled={!changeData}
              onChange={(e) => sendFile(e.target.files, ind)}
              startIcon={<FaFileUpload />}
              disableElevation
            >
              Adicionar Arquivo
              <VisuallyHiddenInput
                multiple={true}
                type="file"
                accept="image/png,image/jpeg, application/pdf"
              />
            </Button>
          </Box>
        </Grid>

        {location === 'activity' && (
          <Grid item xs={12}>
            <Controller
              name="informacao_cadastral"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  rows={2}
                  label="Informações Adicionais"
                />
              )}
            />
          </Grid>
        )}

      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: '0.7rem', marginTop: '0.7rem' }}>
        <ButtonCB
          variant='contained'
          disableElevation
          background={location === 'activity' ? '#000' : 'green'}
          type="submit"
          disabled={!checkUser || !changeData}
          onClick={() => setSend(true)}
          startIcon={<CheckCircle />}
        >{location === 'activity' ? 'Salvar Ficha de Simulação' : 'Salvar'}</ButtonCB>
        {viewClose && (
          <ThemeProvider theme={theme}>
            <Button
              variant='contained'
              disableElevation
              color='primary'
              sx={{ textTransform: 'capitalize' }}
              onClick={close}
            >Fechar</Button>
          </ThemeProvider>
        )}
      </Box>
    </form>
  )
};

export default InfoForm;