export const colorsBanks = {
    "Padrão": ['#000', '#000', "#000", "#fff", "#fff"],
    "Santander": ['#E00000', '#B70000', '#E00000', "#fff", "#fff"],
    "Banco BV": ['#1D30AF', '#152486', '#1D30AF', "#fff", "#fff"],
    "Losango": ['#C51134', '#A3102D', '#014277', "#fff", "#fff"],
    "Banco Do Brasil": ['#FFF500', '#FFF500', '#455CFF', '#455CFF', "#fff"],
    "Sicoob": ['#004453', '#002D37', '#004453', "#fff", "#fff"],
    "Sicredi": ['#40B93C', '#328B2F', '#0F6828', "#fff", "#fff"],
    "Sol Agora": ['#3C38D9', '#2B2899', '#3C38D9', "#fff", "#fff"],
    "FGTS": ['#003F74', '#032847', '#032847', "#fff", "#fff"],
    "Banco CorSolar": ['#2e5780', '#032847', '#032847', "#fff", "#fff"]
  }