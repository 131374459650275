import { Badge, Box, Button, Chip, Collapse, createTheme, Grid, IconButton, styled, Tab, Tabs, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { CustomTabPanel } from '../../../components/CustomTabPanel';
import { ThemeProvider } from '@mui/material/styles';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import NewTooltip from '../../../components/Tooltip';
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ArticleIcon from '@mui/icons-material/Article';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import DescriptionIcon from '@mui/icons-material/Description';
import { MdEditDocument } from "react-icons/md";
import { SlDocs } from "react-icons/sl";
import { BiMoneyWithdraw, BiSolidOffer } from "react-icons/bi";
import { FaClipboardList } from "react-icons/fa";
import { BsBagCheckFill } from "react-icons/bs";
import { BsBuildingFillAdd } from "react-icons/bs";
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { TbDeviceMobileCheck } from "react-icons/tb";

import styles from '../styles.module.scss';
import CustomAudioPlayerFixed from '../../../components/AudioPlayerFixed/Index';
import { VisuallyHiddenInput } from '../../../components/InputFile';
import { toast } from '../../../components/Toast';
import Swal from 'sweetalert2';
import moment from 'moment';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';
import { dataBase } from '../../../firebase/database';
import { renderToString } from 'react-dom/server';
import { capitalizeString } from '../../../functions/capitalizeString';
import { handleDownload } from '../../../functions/handleDownload';
import Loading from '../../../components/Loading';
import SendDoc from './SendDoc';
import { asSvgIcon } from '../../../functions/asSvgIcon';
import { PiSolarRoof } from "react-icons/pi";
import { TbDeviceMobileShare } from "react-icons/tb";
import { TbDeviceMobileSearch } from "react-icons/tb";

import { useNavigate } from 'react-router-dom';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { banksList } from '../../System/data/Banks';

const Proposal = ({ customer, userRef }) => {
    const storage = getStorage();
    const navigate = useNavigate();
    const width = useWindowWidth();
    const [indexProposal, setIndexProposal] = useState(null);
    const [view, setView] = useState(false);
    const [sheetRef, setSheetRef] = useState({});
    const [open, setOpen] = useState(false);
    const [simulation, setSimulation] = useState(null);
    const [type, setType] = useState('');
    const [loading, setLoading] = useState(false);
    const [tabsValue, setTabsValue] = useState(0);
    const a11yProps = (index) => ({
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    });

    const themeTabs = createTheme({
        components: {
            MuiTabs: {
                styleOverrides: {
                    indicator: {
                        backgroundColor: '#575757', // Cor do indicador
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        color: '#575757', // Cor do texto das abas inativas
                        '&.Mui-selected': {
                            color: '#575757', // Cor do texto da aba selecionada
                        },
                    },
                },
            },
        },
        palette: {
            primary: {
                main: '#575757',
                contrastText: '#fff'
            },
        }
    });

    const DocsItems = styled(Box)(({ color }) => ({
        padding: '0.5rem 1rem',
        borderRadius: '5px',
        backgroundColor: color || "#fff",
        display: 'flex',
        justifyContent: 'space-between',
        border: '1px solid rgba(0, 0, 0, 0.12)',
    }));

    const ChipItem = styled(Chip)(({ colorItem, onClick }) => ({
        backgroundColor: `${colorItem}14` || "#00000014",
        color: colorItem || "#ccc",
        "& svg": {
            fill: colorItem,
        },
        border: `1px solid ${colorItem}`,
        "&:hover": onClick ? {
            backgroundColor: `${colorItem}25`,
        } : {},
    }));

    const openSimulation = (index, ind, docRef) => {
        const proposalRef = customer.proposta[index].documentos.simulacoes[ind];
        setSimulation(proposalRef);
        console.log(proposalRef)
        setSheetRef(checkSheet(docRef));
        handleDoc(index, 'enviar simulação');
    }

    const sendPhoto = async (e, type, index) => {

        try {
            const image = e.target.files[0]
            if (!image) {
                toast({ icon: 'error', text: 'Nenhuma imagem selecionada.' });
                return;
            }
            setLoading(true);
            // const files = [];
            // console.log(type)
            // Array.from(ref, (image) => files.push({ file: URL.createObjectURL(image), complete: image }));

            const fileFormated = { file: URL.createObjectURL(image), complete: image }

            // setInvoice({ file: URL.createObjectURL(image), complete: image });

            const filesStorage = await handleUpload(fileFormated, type);
            console.log(filesStorage)

            const proposta = customer.proposta;
            const fatura = proposta[index].documentos;

            // Inicializa o array de documentos se necessário
            if (!fatura[type]) {
                fatura[type] = [];
            }

            // Adiciona o novo documento ao array
            fatura[type].push(filesStorage);
            const docType = filesStorage.ref.split(`Clientes/${customer.id}/`)[1];

            await updateDoc(doc(dataBase, 'Clientes', customer.id), {
                proposta: proposta,
                historico: [
                    ...customer.historico,
                    {
                        data: moment().format('DD/MM/YYYY - HH:mm'),
                        evento: 'Orçamento Alterado',
                        text: `O documento <b>${docType}</b> foi ADICIONADO na aba <b>${capitalizeString(type)}</b> do orçamento <b>${proposta[index].nome}</b>.`,
                        usuario_id: userRef?.id,
                        usuario_nome: userRef?.nome
                    }
                ]
            })

            setLoading(false);
            toast({ icon: 'success', text: 'O Documento foi adicionado com sucesso!' })
            console.log(fatura[type])
            console.log(proposta)

        } catch (error) {
            setLoading(false);
            toast({ icon: 'error', text: 'Ocorreu um erro ao enviar o documento.' });
        }

    }

    const deleteInvoice = async (type, index, ind, docRef = 'file') => {

        try {
            const resultAlert = await Swal.fire({
                title: 'Atenção',
                html: `Você deseja excluir o <b>Documento?</b>`,
                icon: "warning",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0eb05f",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
            })

            if (resultAlert.isConfirmed) {
                setLoading(true);
                // Clone the proposta to avoid direct mutation
                const proposta = [...customer.proposta];
                let fatura = proposta[index].documentos;

                // Check if the type and the ref array exist
                if (Array.isArray(fatura[type])) {
                    // Remove the item at the specified index
                    if (docRef === 'file') {
                        const refLink = fatura[type][ind].ref;
                        console.log('Ref Link:', refLink);
                        const fileRef = ref(storage, refLink);
                        await deleteObject(fileRef);
                    }

                    const docType = docRef === 'file' ? fatura[type][ind].ref.split(`Clientes/${customer.id}/`)[1] : `${fatura[type][ind].valor} kWh`;
                    fatura[type].splice(ind, 1);

                    await updateDoc(doc(dataBase, 'Clientes', customer.id), {
                        proposta: proposta,
                        historico: [
                            ...customer.historico,
                            {
                                data: moment().format('DD/MM/YYYY - HH:mm'),
                                evento: 'Orçamento Alterado',
                                text: `O documento <b>${docType}</b> foi EXCLUIDO da aba <b>${capitalizeString(type)}</b> do orçamento <b>${proposta[index].nome}</b>.`,
                                usuario_id: userRef?.id,
                                usuario_nome: userRef?.nome
                            }
                        ]
                    });

                    setLoading(false);
                    toast({ icon: 'success', text: 'O Documento foi excluído com sucesso!' });
                    console.log(fatura[type]);
                    console.log(proposta);
                } else {
                    setLoading(false);
                    toast({ icon: 'error', text: 'Ocorreu um erro ao excluir o documento. Tipo ou referência não encontrada.' });
                }
            }
        } catch (error) {
            toast({ icon: 'error', text: 'Ocorreu um erro ao excluir o documento.' });
            console.error(error);
        }
    }

    const handleUpload = async (fileFormated, type) => {

        if (!fileFormated) return;

        const fileRef = ref(storage, `Clientes/${customer.id}/${type}-${Date.now()}.${fileFormated.complete.type.split('/')[1]}`);

        try {
            // Upload do áudio
            const invoiceSnapshot = await uploadBytes(fileRef, fileFormated.complete);
            const invoiceURL = await getDownloadURL(invoiceSnapshot.ref);

            const filesRef = {
                url: invoiceURL,
                ref: invoiceSnapshot.ref.fullPath
            };

            return filesRef;

        } catch (error) {
            console.error('Erro ao enviar arquivos:', error);
        }
    };

    const handleOpen = (index) => {
        setOpen((prevState) => ({ [index]: !prevState[index] }));
    }

    const addKwh = async (type, index) => {
        try {
            const iconSvg = renderToString(<MdEditDocument style={{ fontSize: 60 }} />);
            const { value: kwh } = await Swal.fire({
                iconHtml: iconSvg,
                customClass: {
                    icon: 'no-border',
                },
                html: `Informe o <b>kWh</b> da <b>${capitalizeString(type)}</b>.<br /> (Somente número. Ex: 150)`,
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0eb05f",
                confirmButtonText: "Enviar",
                cancelButtonText: "Fechar",
                input: 'number',
                inputValidator: (value) => {
                    if (!value) {
                        return 'Informe o kWh para prosseguir'
                    }
                },
            })

            if (kwh) {
                setLoading(true);
                const proposta = customer.proposta;
                const fatura = proposta[index].documentos;

                // Inicializa o array de documentos se necessário
                if (!fatura[type]) {
                    fatura[type] = [];
                }

                // Adiciona o novo documento ao array
                fatura[type].push({ valor: kwh });

                await updateDoc(doc(dataBase, 'Clientes', customer.id), {
                    proposta: proposta,
                    historico: [
                        ...customer.historico,
                        {
                            data: moment().format('DD/MM/YYYY - HH:mm'),
                            evento: 'Orçamento Alterado',
                            text: `<b>${kwh} kWh</b> ADICIONADO na aba <b>${capitalizeString(type)}</b> do orçamento <b>${proposta[index].nome}</b>.`,
                            usuario_id: userRef?.id,
                            usuario_nome: userRef?.nome
                        }
                    ]
                });
                setLoading(false);
                toast({ icon: 'success', text: 'O kWh foi adicionado com sucesso!' });
                console.log(fatura)
            }
        } catch (error) {
            setLoading(false);
            toast({ icon: 'error', text: 'Ocorreu um erro ao adicionar o kWh.' });
        }
    }

    const handleDoc = (index, typeDoc, data) => {
        setIndexProposal(index);
        setType(typeDoc);
        setView(true);
        setSheetRef(checkSheet(data, index));
    }

    const deleteProposal = async (proposal, index) => {
        console.log(proposal);
        try {
            const resultAlert = await Swal.fire({
                title: 'Atenção',
                html: `Você deseja excluir o <b>Orçamento?</b>`,
                icon: "warning",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0eb05f",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
            })

            if (!resultAlert.isConfirmed) return null;
            setLoading(true);
            await deleteFiles(proposal);
            const newProposalList = customer.proposta;
            newProposalList.splice(index, 1);
            await updateDoc(doc(dataBase, 'Clientes', customer.id), {
                proposta: newProposalList,
                historico: [
                    ...customer.historico,
                    {
                        data: moment().format('DD/MM/YYYY - HH:mm'),
                        evento: 'Orçamento Excluido',
                        text: `Excluiu o orçamento chamado <b>${proposal.nome}</b>.`,
                        usuario_id: userRef?.id,
                        usuario_nome: userRef?.nome
                    }
                ]
            })
            toast({ icon: 'success', text: 'Orçamento excluido com sucesso!' });
            setLoading(false);
        } catch (error) {
            toast({ icon: 'error', text: 'Ocorreu um erro ao excluir o orçamento.' })
        }
    }

    const deleteFiles = async (obj) => {
        for (let key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                deleteFiles(obj[key]);
            } else if (key === 'ref') {
                const refObj = obj[key];
                const fileRef = ref(storage, refObj);

                try {
                    await deleteObject(fileRef);
                    console.log(`File deleted: ${refObj}`);
                } catch (error) {
                    console.error(`Error deleting file: ${refObj}`, error);

                }
            }
        }
    };

    const checkSheet = useCallback((item, type) => {
        const { id, nome } = item;

        if (type === 'index') {
            return customer?.planilha?.findIndex(data => data.orcamento_solicitado === nome?.replace(/ /g, '_') || data.orcamento_id === id?.replace(/ /g, '_'))
        } else {
            return customer?.planilha?.find(data => data.orcamento_solicitado === nome?.replace(/ /g, '_') || data.orcamento_id === id?.replace(/ /g, '_'))
        }
    }, [customer.planilha]);

    const checkSimulation = useCallback((id, simulations) => {
        if (id) {
            return simulations.find(data => data.id === id) || null;
        }
        return null;
    }, []);

    const ChipSimulation = ({ obj }) => {

        const objRef = obj;
        const color = objRef?.situacao === "Aprovado" ? "#3fb003" : "#ff4343";

        return (
            <>
                <ChipItem sx={{ 'span': { color: color } }} icon={false} colorItem={color} label={objRef?.situacao} size='small' />
                {objRef?.condicao_especial?.visualizar &&
                    <Chip sx={{ 'span': { color: '#fff' }, backgroundColor: "#1bac22", cursor: 'pointer' }} icon={<BiSolidOffer size='1.2rem' color='#fff' />} label={<p>Condição Especial - {objRef?.condicao_especial?.banco}</p>} size='small' />
                }
                {objRef?.status === 'Enviado' &&
                    <ChipItem sx={{ 'span': { color: "#000" } }} icon={<BiMoneyWithdraw size='1.05rem' />} colorItem='#3fb003' label={'Simulação Enviada'} size='small' />
                }
            </>
        )
    }

    return (
        <ThemeProvider theme={themeTabs}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', position: 'relative' }}>
                {(Array.isArray(customer?.proposta) && customer?.proposta.length > 0) ? customer.proposta.map((data, index) => (
                    <Box>
                        <DocsItems
                            key={index}
                            borderColor={data.status === 'Aceito' ? '#d0f0de!important' : "#575757"}
                            backgroundColor={data.status === 'Aceito' ? '#e8f6ef!important' : "#fff"}>
                            <Box sx={{ display: 'flex', gap: '0.3rem' }}>
                                <InsertDriveFileRoundedIcon sx={{ fontSize: '25px', fill: data.status === 'Aceito' ? '#3fb003' : "#575757" }} />
                                <Box sx={{ display: 'flex', flexDirection: 'column', lineHeight: '1.2rem', justifyContent: 'center' }}>
                                    <Box sx={{ display: 'flex', gap: '0.5rem', flexDirection: 'column', flexWrap: 'wrap', marginBottom: '0.3rem' }}>
                                        <Box color={data.status === 'Aceito' ? '#3fb003' : "#000"}>
                                            <p><b>{data.nome}</b> {data.status === 'Aceito' && `(${data.status})`}</p>
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center', flexWrap: 'wrap', overflow: 'auto' }}>
                                            <Chip icon={<PiSolarRoof />} label={data.telhado} size='small' />
                                            {checkSheet(data) &&
                                                <ChipItem onClick={() => navigate(`/credito/${customer.id}/${checkSheet(data, 'index')}`)} icon={<DescriptionIcon />} colorItem='#497fd3' label={`Kit ${checkSheet(data)?.kit} kWp`} size='small' />
                                            }
                                            {(Array.isArray(data?.documentos?.proposta) && data?.documentos?.proposta?.length > 0) &&
                                                <ChipItem icon={<FaClipboardList size='1.05rem' />} colorItem='#3fb003' label={'Proposta Pronta'} size='small' />
                                            }
                                            {data.simulacao &&
                                                <ChipItem sx={{ 'span': { color: "#000" } }} icon={<BiMoneyWithdraw size='1.05rem' />} colorItem='#f6942c' label={'Simulação Solicitada'} size='small' />
                                            }
                                            {data.simulacao_enviada &&
                                                <ChipItem sx={{ 'span': { color: "#000" } }} icon={<BiMoneyWithdraw size='1.05rem' />} colorItem='#3fb003' label={'Simulação Enviada'} size='small' />
                                            }
                                            {data.nova_simulacao &&
                                                <ChipItem sx={{ 'span': { color: "#c94911" } }} icon={<BiMoneyWithdraw size='1.05rem' />} colorItem='#c94911' label={'Nova Simulação Solicitada'} size='small' />
                                            }
                                            {checkSheet(data)?.visita &&
                                                <ChipItem sx={{ 'span': { color: "#6f64ff" } }} icon={<CalendarMonthRoundedIcon />} colorItem='#6f64ff' label={'Visita Solicitada'} size='small' />
                                            }
                                            {checkSheet(data)?.visita_tecnica &&
                                                <ChipItem sx={{ 'span': { color: "#1C256E" } }} icon={<EngineeringIcon />} colorItem='#1C256E' label={'Visita Técnica'} size='small' />
                                            }
                                            {checkSheet(data)?.pagamento?.tipo &&
                                                <ChipItem icon={<BsBagCheckFill size='1.03rem' />} colorItem='#479e19' label={`Contrato Solicitado`} size='small' />
                                            }
                                        </Box>
                                    </Box>
                                    <Typography variant='span' color="#575757" fontSize='0.9rem'>Criado em <b>{moment(data.createAt.toDate()).format("DD/MM/YYYY - HH:mm").replace("-", "às")}</b></Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                {userRef?.cargo === 'Administrador' ?
                                    <NewTooltip title='Excluir' placement='top'>
                                        <IconButton color='error' onClick={() => deleteProposal(data, index)}>
                                            <DeleteRounded />
                                        </IconButton>
                                    </NewTooltip> : null
                                }
                                <IconButton onClick={() => handleOpen(index)}>
                                    {open[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            </Box>
                        </DocsItems>
                        <Collapse in={open[index]} timeout="auto" unmountOnExit>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs
                                    sx={{
                                        backgroundColor: "#F9FAFC",
                                        borderRadius: '5px 5px 0 0',
                                        minHeight: '42', // Altura mínima dos tabs
                                        '& .MuiTab-root': {
                                            minHeight: '52px', // Altura mínima das abas
                                            padding: '6px 12px', // Ajuste do padding para diminuir a altura
                                        },
                                    }}
                                    value={tabsValue}
                                    variant={width > 600 ? "fullWidth" : "scrollable"}
                                    scrollButtons
                                    allowScrollButtonsMobile
                                    onChange={(e, newValue) => setTabsValue(newValue)}
                                    aria-label="Informações do Lead"
                                // variant="fullWidth"
                                >
                                    <Tab iconPosition='start' icon={<Badge color='success' invisible={!data?.documentos?.proposta || data?.documentos?.proposta?.length === 0} badgeContent={'✔'}>{asSvgIcon(<FaClipboardList color="#14d51f" size='1.3rem' />)}</Badge>} sx={{ textTransform: 'capitalize' }} label="Proposta" {...a11yProps(2)} />
                                    <Tab iconPosition='start' icon={<Badge color='success' badgeContent={data.simulacao_enviada && '✔'}>{asSvgIcon(<BiMoneyWithdraw color='#000' />)}</Badge>} sx={{ textTransform: 'capitalize' }} label={'Simulações'} {...a11yProps(3)} />
                                    <Tab iconPosition='start' icon={<DescriptionRoundedIcon sx={{ fill: "#1984ff" }} />} sx={{ textTransform: 'capitalize' }} label="Geradora" {...a11yProps(0)} />
                                    <Tab iconPosition='start' icon={<DescriptionRoundedIcon sx={{ fill: "#f1c947" }} />} sx={{ textTransform: 'capitalize' }} label="Beneficiária" {...a11yProps(1)} />
                                    <Tab iconPosition='start' icon={asSvgIcon(<SlDocs color="#000" size='1.3rem' />)} sx={{ textTransform: 'capitalize' }} label="Docs" {...a11yProps(4)} />
                                </Tabs>
                            </Box>
                            <CustomTabPanel className={styles.docs_container} style={{ backgroundColor: "#f7f7f7", borderRadius: '0 0 5px 5px', height: '180px', overflow: 'auto' }} value={tabsValue} index={2}>
                                <Grid container spacing={2} sx={{ p: 2 }}>
                                    {(Array.isArray(data.documentos?.geradora) && data.documentos.geradora.length > 0) ?
                                        data.documentos.geradora.map((doc, ind) => (
                                            <Grid key={ind} item xs={12}>
                                                {doc?.ref ?
                                                    <DocsItems>
                                                        <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                                                            <InsertDriveFileOutlinedIcon sx={{ fill: "#484848" }} />
                                                            <a className='link_black' href={doc.url} target='_blank' rel='noreferrer'>{doc.ref.split(`Clientes/${customer.id}/`)[1]}</a>
                                                        </Box>
                                                        <NewTooltip id="Clickable" title="Excluir" placement="top">
                                                            <IconButton className="clickable" disabled={userRef?.cargo !== 'Administrador' && userRef?.id !== customer.consultora_uid} color="error" onClick={() => deleteInvoice('geradora', index, ind)}>
                                                                <DeleteRounded />
                                                            </IconButton>
                                                        </NewTooltip>
                                                    </DocsItems>
                                                    :
                                                    <DocsItems color='#fbfbed'>
                                                        <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                                                            <ArticleIcon sx={{ fill: "#b3b2ae" }} />
                                                            <Typography color='#696969'>{doc.valor} kWh</Typography>
                                                        </Box>
                                                        <NewTooltip id="Clickable" title="Excluir" placement="top">
                                                            <IconButton className="clickable" disabled={userRef?.cargo !== 'Administrador' && userRef?.id !== customer.consultora_uid} color="error" onClick={() => deleteInvoice('geradora', index, ind, 'doc')}>
                                                                <DeleteRounded />
                                                            </IconButton>
                                                        </NewTooltip>
                                                    </DocsItems>
                                                }
                                            </Grid>
                                        )) : <Grid item xs={12}>
                                            <Typography color='#a1a1a1' textAlign='center' variant='h5' margin='1rem 0'>Nenhum documento encontrado</Typography>
                                        </Grid>}
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', gap: '0.5rem' }}>
                                        <Button
                                            sx={{ textTransform: 'capitalize' }}
                                            variant='contained'
                                            color='info'
                                            onClick={() => addKwh('geradora', index)}
                                            startIcon={<MdEditDocument />}
                                            disableElevation>Adicionar kWh
                                        </Button>
                                        <Button
                                            sx={{ textTransform: 'capitalize' }}
                                            variant='contained'
                                            component="label"
                                            onChange={(e) => sendPhoto(e, 'geradora', index)}
                                            startIcon={<CloudDownloadIcon />}
                                            disableElevation>Adicionar Documento
                                            <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg, application/pdf" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CustomTabPanel>
                            <CustomTabPanel className={styles.docs_container} style={{ backgroundColor: "#f7f7f7", borderRadius: '0 0 5px 5px', height: '180px', overflow: 'auto' }} value={tabsValue} index={3}>
                                <Grid container spacing={2} sx={{ p: 2 }}>
                                    {(Array.isArray(data.documentos?.beneficiaria) && data.documentos.beneficiaria.length > 0) ?
                                        data.documentos.beneficiaria.map((doc, ind) => (
                                            <Grid key={ind} item xs={12}>
                                                {doc.ref ?
                                                    <DocsItems>
                                                        <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                                                            <InsertDriveFileOutlinedIcon sx={{ fill: "#484848" }} />
                                                            <a className='link_black' href={doc.url} target='_blank' rel='noreferrer'>{doc.ref.split(`Clientes/${customer.id}/`)[1]}</a>
                                                        </Box>
                                                        <NewTooltip id="Clickable" title="Excluir" placement="top">
                                                            <IconButton className="clickable" disabled={userRef?.cargo !== 'Administrador' && userRef?.id !== customer.consultora_uid} color="error" onClick={() => deleteInvoice('beneficiaria', index, ind)}>
                                                                <DeleteRounded />
                                                            </IconButton>
                                                        </NewTooltip>
                                                    </DocsItems> :
                                                    <DocsItems color='#fbfbed'>
                                                        <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                                                            <ArticleIcon sx={{ fill: "#b3b2ae" }} />
                                                            <Typography color='#696969'>{doc.valor} kWh</Typography>
                                                        </Box>
                                                        <NewTooltip id="Clickable" title="Excluir" placement="top">
                                                            <IconButton className="clickable" disabled={userRef?.cargo !== 'Administrador' && userRef?.id !== customer.consultora_uid} color="error" onClick={() => deleteInvoice('beneficiaria', index, ind, 'doc')}>
                                                                <DeleteRounded />
                                                            </IconButton>
                                                        </NewTooltip>
                                                    </DocsItems>
                                                }
                                            </Grid>
                                        )) :
                                        <Grid item xs={12}>
                                            <Typography color='#a1a1a1' textAlign='center' variant='h5' margin='1rem 0'>Nenhum documento encontrado</Typography>
                                        </Grid>}
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', gap: '0.5rem' }}>
                                        <Button
                                            sx={{ textTransform: 'capitalize' }}
                                            variant='contained'
                                            color='info'
                                            onClick={() => addKwh('beneficiaria', index)}
                                            startIcon={<MdEditDocument />}
                                            disableElevation>Adicionar kWh
                                        </Button>
                                        <Button
                                            sx={{ textTransform: 'capitalize' }}
                                            variant='contained'
                                            component="label"
                                            onChange={(e) => sendPhoto(e, 'beneficiaria', index)}
                                            startIcon={<CloudDownloadIcon />}
                                            disableElevation>Adicionar Documento
                                            <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg, application/pdf" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CustomTabPanel>
                            <CustomTabPanel
                                className={styles.docs_container}
                                style={{ backgroundColor: "#f7f7f7", borderRadius: '0 0 5px 5px', height: '180px', overflow: 'auto' }}
                                value={tabsValue}
                                index={0}>
                                <Grid container spacing={2} sx={{ p: 2 }}>
                                    {(Array.isArray(data.documentos?.proposta) && data.documentos.proposta.length > 0) ?
                                        data.documentos.proposta.map((doc, ind) => (
                                            <Grid key={ind} item xs={12}>
                                                {doc.ref ?
                                                    <DocsItems>
                                                        <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                                                            <InsertDriveFileOutlinedIcon sx={{ fill: "#484848" }} />
                                                            <a className='link_black' href={doc.url} target='_blank' rel='noreferrer'>{doc.ref.split(`Clientes/${customer.id}/`)[1]}</a>
                                                        </Box>
                                                        <NewTooltip id="Clickable" title="Excluir" placement="top">
                                                            <IconButton className="clickable" disabled={userRef?.cargo !== 'Administrador' && userRef?.id !== customer.consultora_uid && userRef?.id !== customer.assistente} color="error" onClick={() => deleteInvoice('proposta', index, ind)}>
                                                                <DeleteRounded />
                                                            </IconButton>
                                                        </NewTooltip>
                                                    </DocsItems> :
                                                    <DocsItems color='#fbfbed'>
                                                        <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                                                            <ArticleIcon sx={{ fill: "#b3b2ae" }} />
                                                            <Typography color='#696969'>{doc.valor} kWh</Typography>
                                                        </Box>
                                                        <NewTooltip id="Clickable" title="Excluir" placement="top">
                                                            <IconButton className="clickable" disabled={userRef?.cargo !== 'Administrador' && userRef?.id !== customer.consultora_uid} color="error" onClick={() => deleteInvoice('proposta', index, ind, 'doc')}>
                                                                <DeleteRounded />
                                                            </IconButton>
                                                        </NewTooltip>
                                                    </DocsItems>
                                                }
                                            </Grid>
                                        )) :
                                        <Grid item xs={12}>
                                            <Typography color='#a1a1a1' textAlign='center' variant='h5' margin='1rem 0'>Nenhum documento encontrado</Typography>
                                        </Grid>}
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button
                                            sx={{ textTransform: 'capitalize' }}
                                            variant='contained'
                                            component="label"
                                            onChange={(e) => sendPhoto(e, 'proposta', index)}
                                            startIcon={<CloudDownloadIcon />}
                                            disableElevation>Adicionar Documento
                                            <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg, application/pdf" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CustomTabPanel>
                            <CustomTabPanel
                                className={styles.docs_container}
                                style={{ backgroundColor: "#f7f7f7", borderRadius: '0 0 5px 5px', height: '180px', overflow: 'auto' }}
                                value={tabsValue}
                                index={1}>
                                <Grid container spacing={2} sx={{ p: 2 }}>
                                    {(Array.isArray(data.documentos?.simulacoes) && data.documentos.simulacoes.length > 0) ?
                                        data.documentos.simulacoes.map((doc, ind) => (
                                            <Grid key={ind} item xs={12}>
                                                <DocsItems>
                                                    <Box sx={{ display: 'flex', gap: '0.5rem', flexDirection: 'column', alignItems: 'center' }}>
                                                        <Box sx={{ display: 'flex', gap: '0.5rem', 'img': { maxWidth: '35px', maxHeight: '35px' } }}>
                                                            {banksList[checkSimulation(doc?.simulation_id, data?.simulacoes).banco] ? (
                                                                <img
                                                                    src={banksList[checkSimulation(doc?.simulation_id, data?.simulacoes).banco]}
                                                                    alt="Imagem do banco"
                                                                />
                                                            ) : (
                                                                <InsertDriveFileOutlinedIcon sx={{ fill: "#484848", fontSize: '35px' }} />
                                                            )}  
                                                            <Box sx={{ display: 'flex', gap: '0.3rem', flexDirection: 'column' }}>
                                                                {checkSimulation(doc?.simulation_id, data?.simulacoes) ?
                                                                    <p>{checkSimulation(doc?.simulation_id, data?.simulacoes).banco}</p> :
                                                                    <a className='link_black' href={doc?.url} target='_blank' rel='noreferrer'>{doc?.ref?.split(`Clientes/${customer.id}/`)[1]}</a>
                                                                }
                                                                <Box sx={{ display: 'flex', gap: '0.3rem', flexWrap: 'wrap' }}>
                                                                    <ChipSimulation obj={checkSimulation(doc?.simulation_id, data?.simulacoes)} />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box>
                                                        {checkSimulation(doc?.simulation_id, data?.simulacoes).mensagemVoz ? 
                                                            <NewTooltip
                                                                id="Clickable"
                                                                title={!checkSheet(data) ? "⚠ Envie um Orçamento ao Cliente antes de enviar a Simulação ⚠" : checkSimulation(doc?.simulation_id, data?.simulacoes)?.status === 'Enviado' ? "A Simulação já foi enviada para o Cliente" : "Enviar Simulação para o Cliente"}
                                                                placement="top">
                                                                <span>
                                                                    <IconButton
                                                                        className="clickable"
                                                                        disabled={(userRef?.cargo !== 'Administrador' && userRef?.id !== customer.consultora_uid && userRef?.id !== customer.assistente) || !checkSheet(data)}
                                                                        color={checkSimulation(doc?.simulation_id, data?.simulacoes)?.status === 'Enviado' ? 'primary' : 'info'}
                                                                        onClick={() => openSimulation(index, ind, data)}>
                                                                        {checkSimulation(doc?.simulation_id, data?.simulacoes)?.status === 'Enviado' ? <TbDeviceMobileCheck color='green' /> : <TbDeviceMobileShare/>}
                                                                    </IconButton>
                                                                </span>
                                                            </NewTooltip> : 
                                                            <NewTooltip title='Visualizar Informações' placement='top'>
                                                                <IconButton
                                                                        className="clickable"
                                                                        disabled={(userRef?.cargo !== 'Administrador' && userRef?.id !== customer.consultora_uid && userRef?.id !== customer.assistente)}
                                                                        onClick={() => openSimulation(index, ind, data)}>
                                                                        <TbDeviceMobileSearch />
                                                                        </IconButton>
                                                            </NewTooltip>
                                                        }
                                                        <NewTooltip id="Clickable" title="Excluir" placement="top">
                                                            <IconButton className="clickable" disabled={userRef?.cargo !== 'Administrador'} color="error" onClick={() => deleteInvoice('simulacoes', index, ind)}>
                                                                <DeleteRounded />
                                                            </IconButton>
                                                        </NewTooltip>
                                                    </Box>
                                                </DocsItems>
                                            </Grid>
                                        )) :
                                        <Grid item xs={12}>
                                            <Typography color='#a1a1a1' textAlign='center' variant='h5' margin='1rem 0'>Nenhum documento encontrado</Typography>
                                        </Grid>}
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', gap: '0.5rem' }}>
                                        <Button
                                            sx={{ textTransform: 'capitalize' }}
                                            variant='contained'
                                            color='warning'
                                            onClick={() => handleDoc(index, 'simulacoes', data)}
                                            startIcon={<BsBuildingFillAdd />}
                                            disableElevation>Adicionar Simulação
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CustomTabPanel>
                            <CustomTabPanel
                                className={styles.docs_container}
                                style={{ backgroundColor: "#f7f7f7", borderRadius: '0 0 5px 5px', height: '180px', overflow: 'auto' }}
                                value={tabsValue}
                                index={4}>
                                <Grid container spacing={2} sx={{ p: 2 }}>
                                    {(Array.isArray(data.documentos?.documentos) && data.documentos.documentos.length > 0) ?
                                        data.documentos.documentos.map((doc, ind) => (
                                            <Grid key={ind} item xs={12}>
                                                {doc.ref ?
                                                    <DocsItems>
                                                        <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                                                            <InsertDriveFileOutlinedIcon sx={{ fill: "#484848" }} />
                                                            <a className='link_black' href={doc.url} target='_blank' rel='noreferrer'>{doc.ref.split(`Clientes/${customer.id}/`)[1]}</a>
                                                        </Box>
                                                        <NewTooltip id="Clickable" title="Excluir" placement="top">
                                                            <IconButton className="clickable" disabled={userRef?.cargo !== 'Administrador' && userRef?.id !== customer.consultora_uid} color="error" onClick={() => deleteInvoice('documentos', index, ind)}>
                                                                <DeleteRounded />
                                                            </IconButton>
                                                        </NewTooltip>
                                                    </DocsItems> :
                                                    <DocsItems color='#fbfbed'>
                                                        <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                                                            <ArticleIcon sx={{ fill: "#b3b2ae" }} />
                                                            <Typography color='#696969'>{doc.valor} kWh</Typography>
                                                        </Box>
                                                        <NewTooltip id="Clickable" title="Excluir" placement="top">
                                                            <IconButton className="clickable" disabled={userRef?.cargo !== 'Administrador' && userRef?.id !== customer.consultora_uid} color="error" onClick={() => deleteInvoice('documentos', index, ind, 'doc')}>
                                                                <DeleteRounded />
                                                            </IconButton>
                                                        </NewTooltip>
                                                    </DocsItems>
                                                }
                                            </Grid>
                                        )) :
                                        <Grid item xs={12}>
                                            <Typography color='#a1a1a1' textAlign='center' variant='h5' margin='1rem 0'>Nenhum documento encontrado</Typography>
                                        </Grid>}
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button
                                            sx={{ textTransform: 'capitalize' }}
                                            variant='contained'
                                            onClick={() => handleDoc(index, 'documentos')}
                                            startIcon={<CloudDownloadIcon />}
                                            disableElevation>Adicionar Documento
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CustomTabPanel>
                            <Box
                                sx={{
                                    overflow: 'hidden',
                                    borderRadius: '0 0 5px 5px',
                                    display: 'flex',
                                    alignItems: "center",
                                    background: "#F9FAFC"
                                }}>
                                <CustomAudioPlayerFixed url={data.mensagemVoz?.url} view={false} color="#0000008a" background='#F9FAFC' />
                                <NewTooltip title='Fazer Download' placement='top'>
                                    <IconButton sx={{ marginRight: '1rem' }} onClick={() => handleDownload({ fileUrl: data.mensagemVoz?.url, fileName: 'teste' })}>
                                        <DownloadRoundedIcon />
                                    </IconButton>
                                </NewTooltip>
                            </Box>
                        </Collapse>
                        <Loading view={loading} height='100%' fixed={false} backgroundColor='#fff' iconSize={40} />
                    </Box>
                )) : <Typography color='#a1a1a1' textAlign='center' variant='h5' margin='1rem 0'>Nenhum orçamento encontrado</Typography>}
            </Box>
            <SendDoc userRef={userRef} view={view} customer={customer} indexProposal={indexProposal} close={() => setView(false)} type={type} simulationRef={simulation} setSimulationRef={setSimulation} sheetRef={sheetRef} />
            {/* <Box>
                <ButtonCB background="#000">Apresentar Proposta Pessoalmente</ButtonCB>
                <ButtonCB>Enviar Orçamento</ButtonCB>
            </Box> */}
        </ThemeProvider>
    );
};

export default Proposal;
