import { useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss';

import Rating from '@mui/material/Rating';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import PowerRoundedIcon from '@mui/icons-material/PowerRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import NossaHistoria from '../../images/NossaHistoria.jpg';
// import Mapa from '../../images/Mapa.webp';
import LogoMulher from '../../images/LogoMulher.png';

import LogoFacilit from '../../images/LogoFacilit.png';
import { ReactComponent as GoogleWhite } from '../../images/Google.svg';
import { ReactComponent as GrupoCorpBrasil } from '../../images/Logo_Grupo.svg';
import { ReactComponent as LogoCorpBrasil } from '../../images/Logo_CorpBrasil.svg';
import { ReactComponent as GrupoCorpBrasilWhite } from '../../images/Logo_Grupo_Branco.svg';

import { Box, Divider, Grid, IconButton, styled } from '@mui/material';
import SlickSlider from "react-slick";
import moment from 'moment';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactPlayer from 'react-player';

import { imagesProject, imagesPartners } from '../../utils/importImages';

import { Depoimentos } from '../../data/Testimony';
import NewTooltip from '../../components/Tooltip';
import queryString from 'query-string';

const History = () => {
  const sliderProject = useRef();
  const sliderPartners = useRef();
  const sliderAssessments = useRef();
  const [name, setName] = useState(null);
  const settingsProjeto = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };
  const settingsPartners = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };
  const settingsAssessments = settingsProjeto;
  const NewBox = styled('div')(({ padding, margin }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '15px',
    width: '100%',
    backgroundColor: '#fff',
    margin: margin || 0,
    padding: padding || '1rem',
    boxSizing: 'border-box',
    boxShadow: '0px 10px 24px 0px #0000001A'
  }));

  // useEffect(() => {
  //     const getAccessToken = async () => {
  //       if (!accessToken) {
  //         const newAccessToken = await refreshAccessToken();
  //         setAccessToken(newAccessToken);
  //       }
  //     };

  //     getAccessToken();
  //   }, [accessToken]);

  // const { reviews, loading, error } = useGoogleReviews('ChIJJ6B1twQNxpQRLvrUQYY3WMY', '16044202400792665571', accessToken);

  useEffect(() => {

    const parsed = queryString.parse(window.location.search);
    setName(parsed.nome);

  }, [])

  console.log(name)

  const calculateDifference = (startDate) => {
    const now = moment();
    const diffInDays = now.diff(startDate, 'days');

    if (diffInDays === 0) {
      return ('Hoje');
    } else if (diffInDays === 1) {
      return ('Ontem');
    } else if (diffInDays > 7) {
      const weeks = Math.floor(diffInDays / 7);
      return (`Há ${weeks} semana(s)`);
    } else {
      return (`Há ${diffInDays} dia(s)`);
    }
  };

  console.log(imagesProject)

  return (
    <div className={styles.assessments_container}>
      <HelmetProvider>
        <Helmet>
          <title>Nossa História - CorpBrasil Energia Renovável</title>
          <meta name="title" content="Nossa História - CorpBrasil Energia Renovável" />
          <meta name="description" content="A Experiência dos Nossos Clientes com Energia Solar" />
        </Helmet>
      </HelmetProvider>
      {/* {name ?  
        null : 
        <div className="fab">
            <div>
            <Badge anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }} badgeContent={'1'} color="error">
            <button data-cooltipz-size="large" className={"btn-cotacao whats tooltip"} aria-label={'Está com dúvidas? Entre em contato conosco'} data-cooltipz-dir="left">
              <a id="Botão-Flutuante_Entrar_em_Contato" href="https://bit.ly/CORPBRASIL-WhatsApp"><WhatsAppLogo /></a>
            </button>
          </Badge>
            </div>
        </div>
      } */}
      <div className={styles.assessments_content}>
        <Grid container spacing={2} sx={{ maxWidth: '800px' }}>
          <Grid item xs={12} sx={{ 'img': { width: '100%' } }}>
            <img src={NossaHistoria} alt='' />
            <Box sx={{ backgroundColor: "#f5f5f5", padding: '1rem 1rem 0 1rem', top: '-20px', position: 'relative', borderRadius: '15px' }}>
                <Box marginBottom={'1rem'}>
                  <NewBox padding="1.3rem">
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                        <p>Nossa sede administrativa está em <b>Tietê-SP</b> e atendemos todo o estado de São Paulo.</p>
                        <p>Experimente e sinta nosso compromisso em garantir o melhor atendimento, até mesmo depois da instalação do sistema de energia solar!</p>
                        <b>Não gostou do atendimento? Cancele mesmo com o contrato assinado!</b>
                      </Box>
                      {/* <LocationCityRoundedIcon sx={{ fill: "#FFC700", fontSize: '50px', paddingLeft: '1rem' }} /> */}
                    </Box>
                    {/* <Box sx={{ 'img': { width: '100%', borderRadius: '15px' }, paddingTop: '1rem' }}>
                      <img src={Mapa} alt='' />
                    </Box> */}
                  </NewBox>
                </Box>
              <NewBox>
                <Box margin="1rem 0">
                  <GrupoCorpBrasil />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                  <p>Na CorpBrasil, transformamos desafios em oportunidades, impulsionados pela necessidade de soluções energéticas sustentáveis.</p>
                  <p>Criamos a <b>CorpBrasil Energia Renovável</b> para fornecer sistemas fotovoltaicos de alta qualidade e a <b>CorpBrasil Facilit</b> para oferecer serviços de manutenção e limpeza contínuos.</p>
                </Box>
                <Box sx={{ 'img': { maxWidth: '190px' }, display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', maxWidth: '400px', marginTop: '1rem' }}>
                  <LogoCorpBrasil />
                  <img src={LogoFacilit} alt='' />
                </Box>
              </NewBox>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} paddingTop={'0!important'}>
                <Box padding={'0 1rem 1.5rem 1rem'}>
                  <Box className={styles.aspect_169}>
                    <ReactPlayer
                      width='100%'
                      height='100%'
                      id="video-promocional"
                      url='https://streamable.com/ablnqq'
                      controls />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} paddingTop={'0!important'}>
                <Box padding={'0 1rem'}>
                  <NewBox padding="1.3rem">
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '0.5rem' }}>
                        <p><b>Potência</b> Instaladas</p>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '0.7rem' }}>
                          <h1>+2358</h1>
                          <p style={{ marginBottom: '0.35rem' }}>mWp</p>
                        </Box>
                      </Box>
                      <PowerRoundedIcon sx={{ fill: "#FFC700", fontSize: '50px' }} />
                    </Box>
                  </NewBox>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box padding={'0 1rem'}>
                  <NewBox padding="1.3rem 1.3rem 1rem 1.3rem">
                    <Box sx={{ 'img': { maxWidth: '105px' }, display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center' }}>
                      <img src={LogoMulher} alt='' />
                      <h2>Algumas Instalações</h2>
                    </Box>
                    <SlickSlider {...settingsProjeto} ref={sliderProject} className={styles.slider}>
                      {imagesProject.map((projeto, index) => (
                        <div key={index} className={`${styles.project}`}>
                          <img className={styles.project_item} rel="preconnect" src={projeto.src} alt="" />
                          <div className={styles.project_name}>
                            <Box sx={{ backgroundColor: "#fff", padding: '0.5rem', boxShadow: '1px 2px 11px 0px #0000001A', borderRadius: '16px', display: 'flex', alignItems: 'center', gap: '0.4rem' }}>
                              <LocationOnRoundedIcon />
                              <b>{projeto.name.replace(" SP", "")}</b>
                              {projeto?.name.includes(" SP") &&
                                <p>/ SP</p>
                              }
                            </Box>
                          </div>
                        </div>
                      ))}
                    </SlickSlider>
                    <Box>
                      <IconButton onClick={() => sliderProject?.current?.slickPrev()}><KeyboardArrowLeftIcon /></IconButton>
                      <IconButton onClick={() => sliderProject?.current?.slickNext()}><KeyboardArrowRightIcon /></IconButton>
                    </Box>
                  </NewBox>
                </Box>
              </Grid>
              <Grid item xs={12} marginTop='3rem'>
                <Box sx={{ padding: '3rem 1rem 8rem 1rem', backgroundColor: "#282828", display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
                  <GoogleWhite />
                  <Box
                    sx={{
                      width: 200,
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.5rem',
                      justifyContent: 'center',
                      color: "#fff"
                    }}
                  >
                    <Rating
                      name="google"
                      value={5}
                      readOnly
                      precision={0.5}
                      emptyIcon={<StarRoundedIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                    />
                    <Box><h3>4,9</h3></Box>
                  </Box>
                  <Box sx={{ color: '#fff', textAlign: 'center' }}>
                    <p>Avaliações no Google</p>
                    <h3>EXCELENTE</h3>
                  </Box>
                </Box>
                <Box className={styles.slider_testimony}>
                  <SlickSlider id='Slider-Google' {...settingsAssessments} ref={sliderAssessments} className={`${styles.slider}`}>
                    {Depoimentos.map((data, index) => (
                      <div key={index} className={styles.google_assessment}>
                        <div className={styles.google_assessment_item}>
                          <div className={styles.google_assessment_item_header}>
                            {data.foto ?
                              <img src={data.foto} alt='' /> :
                              <div className={styles.profile_photo} style={{ backgroundColor: data.cor }}>
                                <b>{data.nome.charAt(0).toUpperCase()}</b>
                              </div>
                            }
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                              <NewTooltip title='Visualizar comentário no Google' placement='top'>
                                <a href={data.link} target='_blank' rel='noreferrer' className={styles.name}>{data.nome}</a>
                              </NewTooltip>
                              <span style={{ fontSize: '0.8rem' }}>{data.avaliacoes === '1' ? `${data.avaliacoes} avaliação` : `${data.avaliacoes} avaliações`} </span>
                            </Box>
                          </div>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.3rem', marginBottom: '0.5rem' }}>
                            <Rating
                              name="google"
                              value={5}
                              readOnly
                              precision={0.5}
                              emptyIcon={<StarRoundedIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                            />
                            <p>{calculateDifference(data.data)}</p>
                          </Box>
                          <Divider />
                          <div className={styles.google_assessment_item_text}>
                            <p>{data.texto}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </SlickSlider>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <IconButton onClick={() => sliderAssessments?.current?.slickPrev()}><KeyboardArrowLeftIcon /></IconButton>
                  <IconButton onClick={() => sliderAssessments?.current?.slickNext()}><KeyboardArrowRightIcon /></IconButton>
                </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem', position: 'relative', top: '-4rem' }}>
                  <h2>Clientes e Parceiros</h2>
                  <SlickSlider {...settingsPartners} ref={sliderPartners} className={styles.slider}>
                    {imagesPartners.map((projeto, index) => (
                      <div key={index} className={styles.partners}>
                        <img className={styles.partners_item} rel="preconnect" src={projeto.src} alt="" />
                      </div>
                    ))}
                  </SlickSlider>
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ backgroundColor: "#282828" }}>
                <Box sx={{ color: "#fff", display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem', padding: '1.5rem 1rem 2rem 1rem' }}>
                  <GrupoCorpBrasilWhite />
                  <Box sx={{ '.path': { fill: "#fff" }, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <b>CorpBrasil Energia Renovável LTDA</b>
                    <b>34.691.677/0001-35</b>
                  </Box>
                  <Box sx={{ marginTop: '1rem', textAlign: 'center' }}>
                    <p>Copyright © 2024 CorpBrasil Energia Renovável.</p>
                    <p>Todos os direitos reservados.</p>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default History;