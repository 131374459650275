// AutocompleteMaps.js
import { InputAdornment, TextField } from "@mui/material";
import { useEffect, useRef } from "react";
import { usePlacesWidget } from "react-google-autocomplete";

import { IoCheckmarkDone } from "react-icons/io5";
import { FcGoogle } from "react-icons/fc";

const AutocompleteMapsV2 = ({ KeyMaps, onPlaceSelected, defaultValue, newValue, label, disabled }) => {
  const inputRef = useRef(null);
  const isNewValue = defaultValue !== newValue && newValue;

  const { ref } = usePlacesWidget({
    apiKey: KeyMaps,
    onPlaceSelected: (place) => {
      const address = place.formatted_address;
      const cityRef = place.address_components.find(ref => ref.types.includes('administrative_area_level_2'));
      const postalCode = place.address_components.find(ref => ref.types.includes('postal_code'));

      // Passa os valores selecionados para a função `onPlaceSelected` que será usada pelo `Controller`
      onPlaceSelected({
        endereco: address,
        cidade: cityRef ? cityRef.long_name : '',
        cep: postalCode ? postalCode.long_name : '',
        lat: place.geometry?.location?.lat(),
        lng: place.geometry?.location?.lng()
      });
    },
    options: {
      types: ["geocode", "establishment"],
      componentRestrictions: { country: "br" },
      fields: ["formatted_address", "address_components", "geometry.location"],
    },
  });

  useEffect(() => {
    if (inputRef.current && newValue) {
      inputRef.current.value = newValue;
    }
  }, [newValue]);

  return (
    <TextField
      margin="dense"
      label={label}
      fullWidth
      required
      disabled={disabled}
      variant="outlined"
      sx={{
        '& .MuiInputLabel-root': { color: isNewValue ? 'green' : 'grey' }, // Cor do label
        '& .MuiInputLabel-root.Mui-focused': { color: isNewValue ? 'green' : 'grey' }, // Cor do label quando focado
        '& .MuiOutlinedInput-root': {
          '& > fieldset': { borderColor: isNewValue ? 'green' : 'grey' }, // Cor da borda padrão
          '&:hover fieldset': { borderColor: isNewValue ? 'green' : 'grey' }, // Cor da borda ao passar o mouse
          '&.Mui-focused fieldset': { borderColor: isNewValue ? 'green' : 'grey' }, // Cor da borda quando focado
        },
      }}
      inputProps={{
        autoComplete: 'new-password',
        form: {
          autoComplete: 'off',
        }
      }}
      InputProps={{
        endAdornment: ( isNewValue &&
          <InputAdornment position="end">
            <IoCheckmarkDone size='1.6rem' color="green"/>
          </InputAdornment>
        ),
        startAdornment: (
          <InputAdornment position="start">
            <FcGoogle size='1.6rem'/>
          </InputAdornment>
        ),
      }}
      InputLabelProps={{ shrink: true }}
      inputRef={(el) => {
        ref.current = el;
        inputRef.current = el;
      }}
    />
  );
};

export default AutocompleteMapsV2;
