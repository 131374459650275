import { Avatar, AvatarGroup, Badge, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
// import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import { MdQueuePlayNext } from "react-icons/md";
import { FaStar } from "react-icons/fa";
import { SlClock } from "react-icons/sl";

import NewTooltip from '../../components/Tooltip';
import { Link } from 'react-router-dom';
import useGoPage from '../../hooks/useGoPage';
import { useFirebaseData } from '../../contexts/QueueFirebaseContext';
import { useActivity } from '../../contexts/ActivityContext';
import Swal from 'sweetalert2';
import NewTableRow from '../../components/NewTableRow';

const Activitys = ({ userRef, members }) => {
  const goPage = useGoPage();
  const { setActivityData, updateActivity } = useActivity();
  const { dataActivitys, dataCustomers } = useFirebaseData();
  const [activitys, setActivitys] = useState([]);

  useEffect(() => {
    if (dataActivitys) {
      if (userRef?.cargo !== 'Administrador') {
        setActivitys(dataActivitys.filter((data) => data.responsavel === userRef?.id))
      } else {
        setActivitys(dataActivitys)
      }
    }
  }, [dataActivitys, userRef])

  const getCustomerInfo = useCallback((id) => {
    return dataCustomers.find((data) => data.id === id) || {};
  }, [dataCustomers]);

  const linkCliente = useCallback(
    (activity, type) => {
      if (!activity) return null;

      const { cliente_id, orcamento_id, proposta_id } = activity;
      const info = getCustomerInfo(cliente_id);
      const name = info?.nome || 'Não Encontrado';
      const truncatedName = name.length > 22 ? `${name.substring(0, 22)}...` : name;

      let linkRef = `/clientes/${cliente_id}`;
      if (type === 'planilha' && orcamento_id) {
        const [idCustomer, index] = orcamento_id.split('-');
        linkRef = `/credito/${idCustomer}/${index}`;
      }

      const estimate = info?.planilha?.find((data) => data.orcamento_id === proposta_id);

      // Retorna o nome do cliente truncado se não houver orcamento_id ou planilha

      return (
        <NewTooltip title={type === 'planilha' ? 'Visualizar Orçamento' : 'Visualizar Cliente'} placement='top'>
          <Link
            className="link_black clickable"
            to={linkRef}
            onClick={() => goPage(null, 'top')}
          >
            {type === 'planilha' ? estimate?.nome || 'Não Encontrado' : truncatedName}
          </Link>
        </NewTooltip>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getCustomerInfo]
  );

  const getMember = useCallback((idMember) => {
    const member = members.find((member) => member.id === idMember);
    return member || {}
  }, [members])

  const getUniqueList = (data, dataItem) => {
    const result = [];
    const seenPhotos = new Set();

    for (const key in data) {
      if (data[key]?.photo?.url) {
        const { photo, nome, telefone, cargo, time, nome_completo } = data[key];

        // Verifica se o URL da foto já existe
        if (!seenPhotos.has(photo.url)) {
          result.push({
            photo: photo.url,
            name: nome_completo,
            phone: telefone,
            title: key === 'responsible' ? dataItem.categoria : key,
            job: time && cargo === 'Vendedor(a)' ? "BackOffice" : nome === 'Pós-Venda' ? "Analista de Projetos" : cargo
          });
          seenPhotos.add(photo.url); // Marca o URL da foto como visto
        }
      }
    }

    return result;
  };

  const getResponsibles = useCallback((data) => {
    const responsible = getMember(data.responsavel);
    const customer = getCustomerInfo(data?.cliente_id);
    const assistant = getMember(customer?.assistente);
    const sheetRef = customer.planilha.find((ref) => ref.orcamento_id === data.proposta_id);
    const proposalRef = customer.proposta.find((ref) => ref.id === data.proposta_id);
    console.log('PROPOSALREF', proposalRef);
    const analyst = getMember(sheetRef?.visita_tecnica?.responsavel_id);
    const financing = getMember(proposalRef?.simulacao_responsavel?.id);
  
    // Define lista de responsáveis com chave específica
    const responsiblesListRef = {
      "Orçamento": assistant,
      "Projetos": analyst,
      "Financeiro": financing
    };
  
    // Encontre a chave onde o valor é igual a "responsible"
    const responsibleKey = Object.keys(responsiblesListRef).find(
      (key) => responsiblesListRef[key] === responsible
    );
  
    const responsiblesListRefWithKey = {
      ...(responsibleKey ? { [responsibleKey]: responsible } : { responsible }), // Adiciona `responsible` com chave específica ou genérica
      ...responsiblesListRef
    };
  
    const uniqueList = getUniqueList(responsiblesListRefWithKey, data);
    
    return (
      <AvatarGroup max={4}>
        {uniqueList.map((item, index) => (
          <NewTooltip
            key={index}
            title={
              <Box>
                <Typography component="h3">{item?.title}</Typography>
                <p>{`${item?.name} (${item?.job})`}</p>
                <p>Contato: {item?.phone}</p>
              </Box>
            }
            placement="top"
          >
            <Badge
              badgeContent={index === 0 ? <FaStar /> : 0}
              color='warning'
              overlap='circular'
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
              <Avatar
                src={item.photo}
                className="clickable"
                sx={index === 0 && { border: '1px solid #ccc' }}
              />
            </Badge>
          </NewTooltip>
        ))}
      </AvatarGroup>
    );
  }, [getMember, getCustomerInfo]);

  const formatDate = useCallback((date, format = 'DD/MM/YYYY') => {
    const timestampMillis = date?.toMillis();
    const formattedDate = moment(timestampMillis).format('DD/MM/YYYY');
    return formattedDate;
  },[])

  const getStatus = useCallback((data) => {
    const completed = data?.concluido;
    const canceled = data?.cancelado;
    const late = !isTodayBefore(data?.prazo);
    const start = data?.iniciado

    let changeStatus = "Aguardando";

    switch (true) {

      case completed:
        changeStatus = "Concluido";
        break;
      case canceled:
        changeStatus = "Cancelado";
        break;
      case late:
        changeStatus = "Atrasado";
        break;
      case start:
        changeStatus = "Iniciado";
        break;
      default:
        break;
    }

    const status = {
      'Aguardando': {
        color: '#000',
        background: "#ccc"
      },
      "Concluido": {
        color: '#1e4620',
        background: "#edf7ed"
      },
      "Cancelado": {
        color: '#5f2120',
        background: "#fdeded"
      },
      "Atrasado": {
        color: '#663c00',
        background: "#fff4e5"
      },
      "Iniciado": {
        color: '#00425d',
        background: "#c1e5ff"
      }
    }

    const currentStatus = status[changeStatus] || { color: '#000', background: '#fff' }; // Define um padrão se o status não for encontrado

    return (
        <NewTooltip title={<Box><p>Atividade criada em {formatDate(data?.createAt)}</p><p>Concluir até {formatDate(data?.prazo)}</p></Box>} placement={'top'}>
          <Box className="clickable" sx={{ display: 'flex', gap: '0.4rem', cursor: 'help' }}>
            <SlClock size="25px" color={currentStatus.color} />
            <Typography sx={{ backgroundColor: currentStatus.background, color: currentStatus.color, padding: '0.1rem 0.4rem', borderRadius: '5px' }} variant='p'>{changeStatus}</Typography>
          </Box>
        </NewTooltip>
    )
  }, [formatDate])

  const isTodayBefore = (timestamp) => {
    // Converte o Timestamp do Firebase para um objeto Date
    const date = timestamp?.toDate();

    // Obtém a data de hoje sem as horas, minutos, segundos e milissegundos
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Retorna true se a data de hoje for anterior à data informada
    return today <= date;
  };

  const startActivity = async (data) => {

    if(data.iniciado) {
      setActivityData(data);
    } else {
      const result = await Swal.fire({
        title: 'Atenção',
        html: `Você deseja iniciar essa atividade?`,
        icon: "warning",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
    });

    if (result.isConfirmed) {
        await updateActivity(data.id, {iniciado: true, desbloquear: moment().add(10, 'minutes').toDate() });
        setActivityData({...data, iniciado: true, desbloquear: moment().add(10, 'minutes').toDate()});
      }
    }

    goPage(`/atividade/${data.id}?mode=Atual-0`, 'top');

  }

  return (
    <div className="container-schedule">
      <div className="title-panel">
        <MdQueuePlayNext size='62px' />
        <h2>Atividades</h2>
      </div>
      <div className="box-schedule">
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <TableContainer className="table-visit" component={Paper} sx={{ margin: '2rem 0 1rem', maxWidth: '1400px' }}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#333", color: "#fff", ".MuiTableCell-root": { color: "#fff" } }}>
                  <TableCell align='center'>Status</TableCell>
                  <TableCell align='center'>Etapa</TableCell>
                  <TableCell align='center'>Cliente</TableCell>
                  <TableCell align='center'>Cidade</TableCell>
                  <TableCell align='center'>Telefone</TableCell>
                  <TableCell align='center'>Responsáveis</TableCell>
                  <TableCell align='center'>Orçamento</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activitys.length > 0 ? activitys.map((data, index) => (
                  <NewTableRow key={index} sx={{ '.MuiTableCell-root': { padding: '0.3rem 0.6rem!important' } }} onClick={() => startActivity(data)} text={`Clique para ${data.concluido ? "ver" : "iniciar"} a atividade`}>
                    <TableCell sx={{ width: '100px', padding: '0.3rem 0.5rem' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem', paddingLeft: '1rem' }}>
                        {getStatus(data)}
                      </Box>
                    </TableCell>
                    <TableCell align='center'>
                      {data.etapa}
                    </TableCell>
                    <TableCell align='center'>{linkCliente(data, 'cliente')}</TableCell>
                    <TableCell align='center'>{getCustomerInfo(data.cliente_id)?.cidade}</TableCell>
                    <TableCell align='center'>{getCustomerInfo(data.cliente_id)?.telefone}</TableCell>
                    <TableCell align='center'>{getResponsibles(data)}</TableCell>
                    <TableCell align="center" >
                      {linkCliente(data, 'planilha')}
                    </TableCell>
                  </NewTableRow>
                )) :
                  <TableRow>
                    <TableCell align='center' colSpan={10}>Nenhum orçamento encontrado</TableCell>
                  </TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
    </div>
  )
}

export default Activitys