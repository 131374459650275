import { Alert, AlertTitle, Box, Checkbox, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, IconButton, Link, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'

import CloseIcon from "@mui/icons-material/Close";
import useAuth from '../../../hooks/useAuth';
import { useActivity } from '../../../contexts/ActivityContext';
import parse from 'html-react-parser';
import { getFirstName } from '../../../functions/getFirstName';
import ButtonCB from '../../../components/Button';

import { FaWhatsapp } from "react-icons/fa";
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import { FaMicrophone } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import ButtonSection from '../../System/components/ButtonSection';
import Swal from 'sweetalert2';

import { formatPhoneBR } from '../../../functions/FormatPhoneBR';
import { createRoot } from 'react-dom/client';

const MessageBox = ({ open, close, customer, type }) => {
    const [openMessages, setOpenMessages] = useState([]);
    const [messagesSend, setMessagesSend] = useState([]);
    const { user } = useAuth();
    const [message, setMessage] = useState(null);
    const { activityData, updateActivity } = useActivity();
    const step = "Check-In";

    useEffect(() => {
        if(activityData && messagesSend.length === 0) {
            setMessagesSend(activityData.mensagens_id || []);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activityData, updateActivity])

    console.log('MENSAGENS ID', messagesSend)

    const formatTextForWhatsApp = (text) => {
        // Substitui quebras de linha por %0A para enviar via WhatsApp
        return text.replace(/\n/g, '%0A')
            .replace(/<b>/g, '')
            .replace(/<\/b>/g, '');
    };

    const handleMessage = async (text, type) => {

        setMessage((prev) => ({ ...prev, clicado: true }));

        if(!messagesSend.includes(message?.id) && message?.id) {
            saveMessage();
        }

        console.log(message)

        if(type === 'send') {
            const whatsappMessage = formatTextForWhatsApp(text);
            const whatsappUrl = `https://wa.me/55${customer?.telefone}?text=${whatsappMessage}`
            return window.open(whatsappUrl, "_blank");
        } else {
            await Swal.fire({
                title: message.titulo,
                html: '<div id="swal-react-container"></div>',
                didOpen: () => {
                    const container = document.getElementById('swal-react-container');
                    const root = createRoot(container);
                    root.render(
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                            <Typography>{message?.texto}</Typography>
                            <Alert severity='info' icon={<WhatsAppIcon sx={{ fill: "#51cd5f" }} />}>
                                <AlertTitle>Telefone do Cliente</AlertTitle>
                                <Link sx={{ cursor: 'pointer' }} onClick={() => window.open(`https://wa.me/55${customer?.telefone}`, "_blank")}>{formatPhoneBR(customer?.telefone)}</Link>
                            </Alert>
                        </Box>
                    )
                },
                icon: "info",
                showCloseButton: true,
                confirmButtonColor: "#000",
                confirmButtonText: "Fechar",
            })
        }
        
    }

    const saveMessage = useCallback(async () => {
        if (!message?.id || messagesSend.includes(message.id)) return;

        setMessagesSend(prevMessagesSend => [...prevMessagesSend, message.id]);
        
        const historyRef = activityData?.historico || [];
        const messagesRef = [...messagesSend, message.id];
        
        await updateActivity(activityData.id, { 
            mensagens_id: messagesRef,
            historico: [...historyRef, {
                data: new Date(),
                ...message,
                usuario_id: user?.id,
                usuario_nome: user?.nome,
                etapa: activityData?.titulo
            }]
        });
    }, [message, messagesSend, activityData, user, updateActivity]);

    const msg = {
        "Check-In": [
            {
                titulo: "Mensagem 1:",
                para: "Orçamento",
                id: "check-in_orcamento01",
                texto: `Olá, ${getFirstName(customer?.nome)}! Espero que você e a família estejam bem.\n\nSou ${user?.nome} da CorpBrasil, vou iniciar a preparação do seu orçamento, quero lembrar que estou à disposição para esclarecer qualquer dúvida.\n\nSinta-se à vontade para me chamar sempre que precisar!`,
            },
            {
                titulo: "Mensagem 2:",
                para: "Orçamento",
                id: "check-in_orcamento02",
                texto: `Olá, ${getFirstName(customer?.nome)}! Espero que você e a família estejam bem.\n\nQue bom ter você aqui novamente!\n\nSou ${user?.nome} da CorpBrasil, vou iniciar a preparação do seu orçamento, sinta-se à vontade para me chamar sempre que precisar!`,
                
            },
            {
                titulo: "Mensagem 1:",
                para: "Simulação",
                id: "check-in_simulação01",
                texto: `Olá, ${getFirstName(customer?.nome)}, sou ${user?.nome} da Corpbrasil, analista de facilitação financeira, espero que você e a família estejam bem!\n\nVou iniciar a simulação de crédito para o seu orçamento.\n\nQuero lembrar que estou à disposição para esclarecer qualquer dúvidas.`,
            },
            {
                titulo: "Mensagem 2:",
                para: "Simulação",
                id: "check-in_simulação02",
                texto: `Olá, ${getFirstName(customer?.nome)},espero que você e a família estejam bem! Sou ${user?.nome} da CorpBrasil, que bom ter você aqui novamente!\n\nVou iniciar a simulação de crédito para o seu orçamento.\n\nSinta-se à vontade para me chamar sempre que precisar!`,
            },
            {
                titulo: "Mensagem 1:",
                para: "Projetos",
                id: "check-in_projetos01",
                texto: `Olá, ${getFirstName(customer?.nome)}! Espero que você e a família estejam bem.\n\nSou ${user?.nome} da CorpBrasil, analista de projetos vou iniciar a preparação do relatório da visita técnica do seu imóvel.\n\nQuero lembrar que estou à disposição para esclarecer qualquer dúvida.\n\nSinta-se à vontade para me chamar sempre que precisar!`,
            },
            {
                titulo: "Mensagem 2:",
                para: "Projetos",
                id: "check-in_projetos02",
                texto: `Olá, ${getFirstName(customer?.nome)}! Espero que você e a família estejam bem.\n\nQue bom ter você aqui novamente!\n\nSou ${user?.nome} da CorpBrasil, vou iniciar a preparação do relatório da visita técnica do seu imóvel.\nSinta-se à vontade para me chamar sempre que precisar!`
            },
            {
                titulo: "Mensagem 1: Status da Preparação",
                para: "Contrato",
                id: "check-in_contrato01",
                texto: `Olá, ${getFirstName(customer?.nome)}, espero que você e a família estejam bem.\n\nSou ${user?.nome} da CorpBrasil, analista de contratos.\nPassando para avisar que seu contrato está sendo preparado.\n\nEstou organizando tudo para que fique de acordo com o que planejamos. Assim que estiver pronto, você será o primeiro a saber e enviarei em seu e-mail para revisão!\n\nQuero lembrar que se precisar de alguma informação adicional enquanto isso, estou aqui para ajudar. Sinta-se à vontade para me chamar!\n\nA condição de pagamento será:\n<b>[Escolhida pelo Cliente]</b> ?`,
            },
            {
                titulo: "Mensagem 2: Próximos Passos",
                para: "Contrato",
                id: "check-in_contrato02",
                texto: `Olá, ${getFirstName(customer?.nome)}, espero que você e a família estejam bem.\n\nSou ${user?.nome} da CorpBrasil, analista de contratos.\n\nEstamos quase com o contrato pronto! Assim que finalizarmos, enviaremos em seu e-mail para análise.\n\nSe precisar de alguma informação adicional enquanto isso, estou aqui para ajudar. Sinta-se à vontade para me chamar!\n\nA condição de pagamento será: <b>[Escolhida pelo Cliente]</b> ?`
,
            },
            {
                titulo: "Mensagem 3: Confirmação do Andamento",
                para: "Contrato",
                id: "check-in_contrato03",
                texto: `Olá, ${getFirstName(customer?.nome)}, espero que você e a família estejam bem.\n\nSou ${user?.nome} da CorpBrasil, analista de contratos.\n\nEstamos na etapa de preparação do seu contrato e cuidando dos últimos detalhes. Logo enviaremos em seu e-mail para que você possa revisar tudo.\n\nQualquer dúvida enquanto aguarda, sinta-se à vontade para me chamar!\n\nA condição de pagamento será: <b>[Escolhida pelo Cliente]</b> ?`
,
            },
            {
                titulo: "Mensagem 4: Atualização Rápida",
                para: "Contrato",
                id: "check-in_contrato04",
                texto: `Olá, ${getFirstName(customer?.nome)}, espero que você e a família estejam bem.\n\nSou ${user?.nome} da CorpBrasil, analista de contratos.\n\nEstamos cuidando dos detalhes finais do seu contrato para garantir que tudo esteja perfeito.\n\nEm breve, enviaremos em seu e-mail para revisão. Qualquer dúvida nesse meio-tempo, estou por aqui, sinta-se à vontade para me chamar!\n\nA condição de pagamento será: <b>[Escolhida pelo Cliente]</b> ?`
            },
        ],
        "Contato 1": [ // Orçamento, Simulação e Relatório de Visita Técnica
            {
                titulo: "Variação 1: Proposta Personalizada",
                para: "Orçamento",
                texto: `Oi, ${getFirstName(customer?.nome)}!\nAqui é a ${user?.nome} espero que você e a família estejam bem!\n\n${user?.nome}, estou aqui para garantir que seu orçamento atenda exatamente o que você precisa.\n\nExiste algum detalhe que gostaria de ajustar ou esclarecer, em seu orçamento?`,
            },
            {
                titulo: "Variação 2: Reforço de Disponibilidade",
                para: "Orçamento",
                texto: `Olá, ${getFirstName(customer?.nome)}!\nAqui é a ${user?.nome}, espero que você e a família estejam bem!\n\n${getFirstName(customer?.nome)}, você deve estar revisando o seu orçamento.\n\nPrecisa de alguma ajuda para entender melhor os benefícios ou quer discutir algum ponto específico?`,
            },
            {
                titulo: "Variação 3: Suporte Completo",
                para: "Orçamento",
                texto: `Oi, ${getFirstName(customer?.nome)}!\nAqui é a ${user?.nome}, espero que você e a família estejam bem!\n\n*${getFirstName(customer?.nome)}, nossa prioridade é que você se sinta seguro com a decisão sobre seu orçamento.*\n\nExiste algum ponto em seu orçamento que precise de mais clareza ou ajuste?`,
            },
            {
                titulo: "Variação 4: Ajustes Personalizados",
                para: "Orçamento",
                texto: `Olá, ${getFirstName(customer?.nome)}!\nAqui é a ${user?.nome}, espero que você e a família estejam bem!\n\n${getFirstName(customer?.nome)}, quero garantir que tudo em seu orçamento esteja alinhado com suas expectativas.\n\nDeseja ajustar algum detalhe no orçamento?`,
            },
            {
                titulo: "Variação 5: Foco no Interesse do Cliente",
                para: "Orçamento",
                texto: `Oi, ${getFirstName(customer?.nome)}!\nAqui é a ${user?.nome}, espero que você e a família estejam bem!\n\n${getFirstName(customer?.nome)}, eu sei que cada detalhe deve ser importante para você!\n\nExiste alguma dúvida ou ajuste que deseja que eu faça, para que seu orçamento fique ideal?`,
            },
            {
                titulo: "Variação 6: Flexibilidade e Atendimento Personalizado",
                para: "Orçamento",
                texto: `Olá, ${getFirstName(customer?.nome)}!\nAqui é a ${user?.nome}, espero que você e a família estejam bem!\n\n${getFirstName(customer?.nome)}, para que o seu orçamento fique exatamente ao que você espera. Eu gostaria de acompanhar a sua análise.\n\nTem algo no orçamento que gostaria de ajuda ou ajuste?`,
            },
            {
                titulo: "Variação 1:",
                para: "Simulação",
                texto: `Olá, ${getFirstName(customer?.nome)}, espero que você e a família estejam bem!\nSou ${user?.nome} da CorpBrasil, estou aqui para ajudar você a ter a melhor opção de crédito.\n\nEnviamos o seu orçamento junto com a simulação de financiamento.\n\nTem alguma dúvida sobre a carência ou precisa que eu veja com os bancos algum ajuste nos valores de parcelas?`,
            },
            {
                titulo: "Variação 2:",
                para: "Simulação",
                texto: `Oi, ${getFirstName(customer?.nome)}, espero que você e a família estejam bem!\nSou ${user?.nome} da CorpBrasil.\n\nVi que você está analisando o orçamento e a simulação de financiamento que enviamos.\n\nPosso ajudar com algum detalhe específico para encontrar a melhor opção de carência ou valor das parcelas?`,
            },
            {
                titulo: "Variação 3:",
                para: "Simulação",
                texto: `Olá, ${getFirstName(customer?.nome)} espero que você e a família estejam bem!\nSou ${user?.nome} da CorpBrasil.\n\nMeu objetivo é que se sinta totalmente seguro na escolha do financiamento.\n\nExiste alguma dúvida ou ajuste que deseja que eu faça, para que seu orçamento fique ideal para você?`,
            },
            {
                titulo: "Variação 4:",
                para: "Simulação",
                texto: `Oi, ${getFirstName(customer?.nome)} espero que você e a família estejam bem!\nSou ${user?.nome} da CorpBrasil.\n\nEnviamos o seu orçamento com a simulação de financiamento.\n\nVocê quer ajustar algum detalhe importante ou deseja alguma negociação específica com o banco no valor de parcelas?`,
            },
            {
                titulo: "Variação 5:",
                para: "Simulação",
                texto: `Olá, ${getFirstName(customer?.nome)} espero que você e a família estejam bem!\nSou ${user?.nome} da CorpBrasil.\n\nEnviamos o orçamento com a simulação de financiamento que você solicitou.\n\nTem algum ponto que gostaria de entender melhor ou ajustar para que se adapte ao que você busca?`,
            },
            {
                titulo: "Variação 6:",
                para: "Simulação",
                texto: `Oi, ${getFirstName(customer?.nome)} espero que você e a família estejam bem!\nSou ${user?.nome} da CorpBrasil.\n\nEnviamos o seu orçamento com a simulação de crédito, sabemos que o financiamento é uma decisão importante.\n\nExiste alguma dúvida ou desejar que eu negocie com o banco algum valor de parcela que você busca?`,
            },
            {
                titulo: "Variação 7:",
                para: "Simulação",
                texto: `Olá, ${getFirstName(customer?.nome)} espero que você e a família estejam bem!\nSou ${user?.nome} da CorpBrasil.\n\nEnviamos o orçamento com a simulação de financiamento para sua análise.\n\nExiste alguma dúvida, ou quer que eu negocie o valor de parcelas com o banco para que fiquem ideais para você?`
            },
            {
                titulo: "Variação 1: Relatório Personalizado",
                para: "Projetos",
                texto: `Oi, ${getFirstName(customer?.nome)}!\nAqui é a ${user?.nome}, espero que você e a família estejam bem!\n\n${getFirstName(customer?.nome)}, estou aqui para garantir que o seu projeto esteja de acordo com as normas da concessionária, e atender exatamente ao que você precisa.\n\nExiste algum detalhe no seu projeto que gostaria de ajustar ou esclarecer?`,
            },
            {
                titulo: "Variação 2: Reforço de Disponibilidade",
                para: "Projetos",
                texto: `Olá, ${getFirstName(customer?.nome)}!\nAqui é a ${user?.nome}, espero que você e a família estejam bem!\n\nVocê deve estar revisando o relatório da visita técnica que enviei.\n\nPrecisa de ajuda ou esclarecimentos em algum ponto?`,
            },
            {
                titulo: "Variação 3: Suporte Completo",
                para: "Projetos",
                texto: `Oi, ${getFirstName(customer?.nome)}!\nAqui é a ${user?.nome}, espero que você e a família estejam bem!\n\nNossa prioridade é que você se sinta seguro com a decisão sobre o seu projeto.\n\nExiste algum ponto que precise de mais detalhes ou ajustes?`,
            },
            {
                titulo: "Variação 4: Ajustes Personalizados",
                para: "Projetos",
                texto: `Olá, ${getFirstName(customer?.nome)}!\nAqui é a ${user?.nome}, espero que você e a família estejam bem!\n\nQuero garantir que tudo em seu projeto esteja alinhado com as normas da concessionária e também as suas expectativas.\n\nDeseja ajustar ou esclarecer algum detalhe em seu projeto?`,
            },
            {
                titulo: "Variação 5: Foco no Interesse do Cliente",
                para: "Projetos",
                texto: `Oi, ${getFirstName(customer?.nome)}!\nAqui é a ${user?.nome}, espero que você e a família estejam bem!\n\nSei que cada detalhe é muito importante em seu projeto!\n\nHá algum ponto sobre o qual você gostaria de receber mais esclarecimentos para que tudo fique exatamente como deseja?`,
            },
            {
                titulo: "Variação 6: Flexibilidade e Atendimento Personalizado",
                para: "Projetos",
                texto: `Olá, ${getFirstName(customer?.nome)}!\nAqui é a ${user?.nome}, espero que você e a família estejam bem!\n\n${getFirstName(customer?.nome)}, você deve estar analisando o relatório do projeto que enviei.\n\nPara que fique dentro das normas da concessionária e exatamente ao que você espera.\n\nTem algo que gostaria de ajuste ou esclarecimentos?`,
            }
        ],
        "Firmar Compromisso": [
            {
                "Quando o cliente quer analisar com calma e tomar a decisão sozinho:": [
                    {
                        titulo: "Variação 1:",
                        para: "Orçamento",
                        texto: `Combinado, vou te procurar no tempo que você precisar. Quando seria ideal para conversarmos de novo?`,
                    },
                    {
                        titulo: "Variação 2:",
                        para: "Orçamento",
                        texto: `Entendo, fique à vontade para analisar. Posso te procurar novamente em uma data que você escolher?`,
                    },
                    {
                        titulo: "Variação 3:",
                        para: "Orçamento",
                        texto: `Perfeito, assim você pode pensar com calma. Qual é um bom momento para eu entrar em contato?`,
                    },
                    {
                        titulo: "Variação 4:",
                        para: "Orçamento",
                        texto: `Com certeza, fique à vontade. Vou te procurar no momento que preferir para ver como está sua decisão.`,
                    },
                    {
                        titulo: "Variação 5:",
                        para: "Orçamento",
                        texto: `Entendo, a decisão é importante. Qual é um bom prazo para que eu possa te procurar e darmos sequência?`,
                    },
                    {
                        titulo: "Variação 6:",
                        para: "Orçamento",
                        texto: `Perfeito, assim você pode refletir com tranquilidade. Posso te procurar em alguns dias, qual melhor data?`,
                    },

                ],
                "Quando o cliente menciona que quer comparar com outros orçamentos:": [
                    {
                        titulo: "Variação 1:",
                        para: "Orçamento",
                        texto: `Claro, é sempre bom comparar. Quando posso te procurar para ver como ficou sua análise?`,
                    },
                    {
                        titulo: "Variação 2:",
                        para: "Orçamento",
                        texto: `Com certeza, é válido avaliar outras opções. Quando seria bom para eu te procurar e saber sua decisão?`,
                    },
                    {
                        titulo: "Variação 3:",
                        para: "Orçamento",
                        texto: `Perfeito, comparar é importante. Qual o melhor dia para eu te procurar novamente e falarmos mais sobre isso?`,
                    },
                    {
                        titulo: "Variação 4:",
                        para: "Orçamento",
                        texto: `Claro, comparar é importante. Vou te procurar em alguns dias para ver como ficou sua comparação. Qual é a melhor data para conversarmos novamente?`,
                    },
                    {
                        titulo: "Variação 5:",
                        para: "Orçamento",
                        texto: `Ótimo, avaliar outras opções é sempre válido. Me diga o melhor prazo para que eu te procure?`,
                    },
                    {
                        titulo: "Variação 6:",
                        para: "Orçamento",
                        texto: `Perfeito, é sempre bom ter outras perspectivas. Qual a data que você acha ideal para conversarmos novamente?`,
                    },
                ],
                "Quando o cliente quer pensar e pode precisar de um contato futuro para tirar dúvidas:": [
                    {
                        titulo: "Variação 1:",
                        para: "Orçamento",
                        texto: `Combinado, vou deixar você pensar à vontade. Quando posso te procurar para tirar dúvidas ou saber como está sua decisão?`,
                    },
                    {
                        titulo: "Variação 2:",
                        para: "Orçamento",
                        texto: `Perfeito, o tempo é seu. Em alguns dias posso te procurar para saber se você precisa de mais alguma informação?`,
                    },
                    {
                        titulo: "Variação 3:",
                        para: "Orçamento",
                        texto: `Claro, estarei à disposição. Qual o melhor momento para eu te procurar e continuarmos?`,
                    },
                    {
                        titulo: "Variação 4:",
                        para: "Orçamento",
                        texto: `Combinado, vou te dar o tempo que precisar. Em quanto tempo posso te procurar para saber como ficou sua decisão e tirar dúvidas?`,
                    },
                    {
                        titulo: "Variação 5:",
                        para: "Orçamento",
                        texto: `Claro, toda decisão precisa de tempo. Vou te procurar no prazo que escolher para retomar a conversa. Quanto tempo você precisa?`,
                    },
                    {
                        titulo: "Variação 6:",
                        para: "Orçamento",
                        texto: `Com certeza, é bom refletir. Vou te procurar quando for conveniente para você e tirar qualquer dúvida que tenha. Quanto tempo você precisa?`,
                    },
                ],
                "Quando o cliente quer tempo, mas você deseja que ele saiba que está à disposição:": [
                    {
                        titulo: "Variação 1:",
                        para: "Orçamento",
                        texto: `Combinado! Vou te procurar no momento ideal para você. Em qual data é conveniente para você?`,
                    },
                    {
                        titulo: "Variação 2:",
                        para: "Orçamento",
                        texto: `Ótimo, vou te dar o tempo que precisar. Qual o melhor momento para que eu te procure?`,
                    },
                    {
                        titulo: "Variação 3:",
                        para: "Orçamento",
                        texto: `Tranquilo, estarei à disposição. Qual a data que você acha melhor?`,
                    },
                    {
                        titulo: "Variação 4:",
                        para: "Orçamento",
                        texto: `Tudo bem, vou te dar o tempo necessário. Quando for o momento, te procurarei para seguir com o atendimento. Quanto tempo você precisa?`,
                    },
                    {
                        titulo: "Variação 5:",
                        para: "Orçamento",
                        texto: `Sem problemas, estarei à disposição. Vou te procurar no prazo que escolher para ver como está a sua avaliação. Qual a melhor data para você?`,
                    },
                    {
                        titulo: "Variação 6:",
                        para: "Orçamento",
                        texto: `Ótimo, fique à vontade. Vou te procurar no melhor momento e continuamos. Qual a melhor data para você?`,
                    },
                ]
            }
        ],
        "Reagendamento": [
            {
                titulo: "Variação 1:",
                para: "Orçamento",
                texto: `Oi, ${getFirstName(customer?.nome)} espero que esteja bem!\nNós combinamos de conversar hoje sobre seu orçamento de energia solar!\n\nVocê estava avaliando algumas propostas para tomar uma decisão.\n\nConseguiu identificar a que melhor atende às suas expectativas?`,
            },
            {
                titulo: "Variação 2:",
                para: "Orçamento",
                texto: `Oi, ${getFirstName(customer?.nome)}! Espero que esteja bem.\nNós combinamos de conversar hoje sobre seu orçamento de energia solar!\n\nVocê estava avaliando algumas propostas para tomar uma decisão.\n\nHá algum ponto que gostaria de revisar juntos para termos certeza de que está confortável para avançar?`,
            },
            {
                titulo: "Variação 3:",
                para: "Orçamento",
                texto: `Oi, ${getFirstName(customer?.nome)}! Espero que esteja bem.\nNós combinamos de conversar hoje sobre seu orçamento de energia solar!\n\nVocê estava avaliando algumas propostas para tomar uma decisão.\n\nQue tal identificarmos juntos o que precisa constar no orçamento para atender melhor às suas necessidades?`,
            },
            {
                titulo: "Variação 4:",
                para: "Orçamento",
                texto: `Oi, ${getFirstName(customer?.nome)}! Espero que esteja bem.\nNós combinamos de conversar hoje sobre seu orçamento de energia solar!\n\nVocê estava avaliando algumas propostas para tomar uma decisão.\n\nO que acha de revisarmos juntos o orçamento para garantir que ele atenda exatamente ao que você precisa?`,
            },
            {
                titulo: "Variação 5:",
                para: "Orçamento",
                texto: `Oi, ${getFirstName(customer?.nome)}! Espero que esteja bem.\nNós combinamos de conversar hoje sobre seu orçamento de energia solar!\n\nVocê estava avaliando algumas propostas para tomar uma decisão.\n\nPodemos revisar juntos o que o orçamento precisa incluir para atender completamente às suas expectativas?`,
            },
            {
                titulo: "Variação 6:",
                para: "Orçamento",
                texto: `Oi, ${getFirstName(customer?.nome)}! Espero que esteja bem.\nNós combinamos de conversar hoje sobre seu orçamento de energia solar!\n\nVocê estava avaliando algumas propostas para tomar uma decisão.\n\nGostaria de revisar o orçamento comigo para garantir que ele contemple tudo o que você precisa?`,
            },
            {
                titulo: "Variação 1:",
                para: "Simulação",
                texto: `Oi, ${getFirstName(customer?.nome)}, como vai?\n\nNós combinamos de conversar hoje sobre sua solicitação de financiamento!\n\nVocê iria analisar as condições das simulações enviadas.\n\nExiste dúvidas, ou quer que eu negocie o valor de parcelas com o banco para que fiquem ideais para você?`,
            },
            {
                titulo: "Variação 2:",
                para: "Simulação",
                texto: `Oi, ${getFirstName(customer?.nome)}, como vai?\n\nNós combinamos de conversar hoje sobre sua solicitação de financiamento!\n\nVocê iria analisar as condições das simulações enviadas.\n\nPara que seja a escolha perfeita para você, existe algum ponto que você queira esclarecer ou ajustar no valor da parcela?`,
            },
            {
                titulo: "Variação 3:",
                para: "Simulação",
                texto: `Oi, ${getFirstName(customer?.nome)}, como vai?\n\nNós combinamos de conversar hoje sobre sua solicitação de financiamento!\n\nVocê iria analisar as condições das simulações enviadas.\n\nMinha prioridade é garantir que ele se encaixe em seu orçamento. Existe algum ajuste que queria que negociasse com o banco para você?`,
            },
            {
                titulo: "Variação 4:",
                para: "Simulação",
                texto: `Oi, ${getFirstName(customer?.nome)}, como vai?\n\nNós combinamos de conversar hoje sobre sua solicitação de financiamento!\n\nVocê iria analisar as condições das simulações enviadas.\n\nExiste alguma dúvida ou desejar que eu negocie com o banco algum valor de parcela que você busca?`,
            },
            {
                titulo: "Variação 5:",
                para: "Simulação",
                texto: `Oi, ${getFirstName(customer?.nome)}, como vai?\n\nNós combinamos de conversar hoje sobre sua solicitação de financiamento!\n\nVocê iria analisar as condições das simulações enviadas.\n\nTem algum ponto que gostaria de entender melhor ou ajustar para que se adapte ao que você busca?`,
            },
            {
                titulo: "Variação 6:",
                para: "Simulação",
                texto: `Oi, ${getFirstName(customer?.nome)}, como vai?\n\nNós combinamos de conversar hoje sobre sua solicitação de financiamento!\n\nVocê iria analisar as condições das simulações enviadas.\n\nQuer ajustar algum detalhe importante ou deseja alguma negociação específica com o banco no valor de parcelas?`,
            },
            {
                titulo: "Variação 7:",
                para: "Simulação",
                texto: `Oi, ${getFirstName(customer?.nome)}, como vai?\n\nNós combinamos de conversar hoje sobre sua solicitação de financiamento!\n\nVocê iria analisar as condições das simulações enviadas.\n\nTem alguma dúvida sobre a carência ou precisa que eu veja com os bancos algum ajuste nos valores de parcelas?`,
            },
            {
                titulo: "Variação 1:",
                para: "Projetos",
                texto: `Oi, ${getFirstName(customer?.nome)}! Espero que esteja bem!\n\nNós combinamos de conversar hoje sobre seu projeto de energia solar!\n\nVocê estava avaliando o projeto para tomar uma decisão.\n\nConseguiu identificar algum ajuste que melhor atenderá sua necessidade?`,
            },
            {
                titulo: "Variação 2:",
                para: "Projetos",
                texto: `Oi, ${getFirstName(customer?.nome)}! Espero que esteja bem.\n\nNós combinamos de conversar hoje sobre seu projeto de energia solar!\n\nVocê estava avaliando o projeto para tomar uma decisão.\n\nHá algum ponto que gostaria de revisar juntos para esclarecer dúvidas?`,
            },
            {
                titulo: "Variação 3:",
                para: "Projetos",
                texto: `Oi, ${getFirstName(customer?.nome)}! Espero que esteja bem.\n\nNós combinamos de conversar hoje sobre seu projeto de energia solar!\n\nVocê estava avaliando algumas possibilidades para tomar uma decisão.\n\nQue tal identificarmos juntos se há algo para atender melhor às suas necessidades?`,
            },
            {
                titulo: "Variação 4:",
                para: "Projetos",
                texto: `Oi, ${getFirstName(customer?.nome)}! Espero que esteja bem.\n\nNós combinamos de conversar hoje sobre seu projeto de energia solar!\n\nVocê estava avaliando algumas possibilidades para tomar uma decisão.\n\nO que acha de revisarmos juntos seu projeto para garantir que ele atenda exatamente o que você precisa?`,
            },
            {
                titulo: "Variação 5:",
                para: "Projetos",
                texto: `Oi, ${getFirstName(customer?.nome)}! Espero que esteja bem.\n\nNós combinamos de conversar hoje sobre seu projeto de energia solar!\n\nVocê estava avaliando algumas possibilidades para tomar uma decisão.\n\nPodemos revisar juntos para atender se há algo para ajustar e atender às suas expectativas?`,
            },
            {
                titulo: "Variação 6:",
                para: "Projetos",
                texto: `Oi, ${getFirstName(customer?.nome)}! Espero que esteja bem.\n\nNós combinamos de conversar hoje sobre seu projeto de energia solar!\n\nVocê estava avaliando algumas possibilidades para tomar uma decisão.\n\nGostaria de revisar o seu projeto comigo para garantir que ele contemple tudo o que você precisa?`,
            },
            {
                titulo: "Variação 7:",
                para: "Projetos",
                texto: `Oi, ${getFirstName(customer?.nome)}! Espero que esteja bem.\n\nNós combinamos de conversar hoje sobre seu projeto de energia solar!\n\nVocê estava avaliando algumas possibilidades para tomar uma decisão.\n\nVamos juntos para analisar se todos os pontos do projeto estão alinhados às suas necessidades?`,
            },
        ],
        "Agendar Visita Técnica": [
            {
                titulo: "Variação 1:",
                para: "Orçamento",
                texto: `${getFirstName(customer?.nome)}, o que vai garantir que seu projeto atenda perfeitamente às suas necessidades, é a visita técnica.\n\n*Com ela, poderemos avaliar todos os detalhes do imóvel onde o sistema será instalado.*\n\nNesta semana temos <b>[sugira 3 dias da semana]</b> qual horário fica bom para você?`,
            },
            {
                titulo: "Variação 2:",
                para: "Orçamento",
                texto: `${getFirstName(customer?.nome)}, para avançarmos no seu projeto de energia solar, precisamos fazer a visita técnica que é gratuita.\n\n*Assim, conseguimos avaliar o espaço para instalação e tirar todas as dúvidas diretamente no local.*\n\nNesta semana temos <b>[sugira 3 dias da semana]</b> qual horário fica bom para você?`,
            },
            {
                titulo: "Variação 3:",
                para: "Orçamento",
                texto: `${getFirstName(customer?.nome)}, esclarecemos as dúvidas, então estamos prontos para a próxima etapa: a visita técnica.\n\n*Ela é fundamental para coletarmos todas as informações onde o sistema será instalado e, assim, planejarmos a instalação.*\n\nNesta semana temos <b>[sugira 3 dias da semana]</b>, qual horário fica bom para você?`,
            },
            {
                titulo: "Variação 4:",
                para: "Orçamento",
                texto: `${getFirstName(customer?.nome)}, para garantir segurança e eficiência no seu projeto, realizamos uma visita técnica grátis, onde avaliamos o espaço e dados elétricos do imóvel.\n\n*É rápido e facilita bastante para ajustar tudo ao seu gosto!*\n\nNesta semana temos <b>[sugira 3 dias da semana]</b>, qual horário fica bom para você?`,
            },
            {
                titulo: "Variação 5:",
                para: "Orçamento",
                texto: `${getFirstName(customer?.nome)}, agora que estamos alinhados sobre os pontos importantes do seu orçamento, o próximo passo é a visita técnica gratuita.\n\n*Dessa forma, podemos verificar os detalhes do local e garantir uma instalação adequada ao seu imóvel.*\n\nNesta semana temos <b>[sugira 3 dias da semana]</b>, qual horário fica bom para você?`,
            },
            {
                titulo: "Variação 6:",
                para: "Orçamento",
                texto: `${customer?.nome}, a visita técnica gratuita é a próxima etapa no nosso processo, e ela nos ajuda a planejar tudo com precisão para a instalação.\n\n*Assim, deixamos tudo pronto para que sua compra seja segura.*\n\nNesta semana temos <b> [sugira 3 dias da semana] </b>. Qual horário fica bom para você?`,
            },
        ],
        "Solicitar Contrato": [
            {
                titulo: "Mensagem 1: Preparação para Formalização",
                para: "Projetos",
                texto: `${getFirstName(customer?.nome)}, o próximo passo é solicitar o contrato para formalizarmos tudo que ajustamos e garantir que seu projeto siga conforme o planejado.\n\n*Podemos seguir com a solicitação do seu contrato?*`,
            },
            {
                titulo: "Mensagem 2: Confirmação da Escolha",
                para: "Projetos",
                texto: `${getFirstName(customer?.nome)}, agora para oficializar as condições que ajustamos para você.\n\n *Posso solicitar o contrato e enviar no seu e-mail para você revisar antes de confirmarmos tudo?*`,
            },
            {
                titulo: "Mensagem 3: Passo Decisivo para o Projeto",
                para: "Projetos",
                texto: `${getFirstName(customer?.nome)}, agora que já está tudo ajustado conforme sua necessidade.\n\n*Vamos oficializar no contrato e enviar no seu e-mail para você revisar antes de confirmarmos tudo?*`,
            },
            {
                titulo: "Mensagem 4: Segurança e Agilidade",
                para: "Projetos",
                texto: `${getFirstName(customer?.nome)}, para assegurar todos os ajustes e benefícios do seu projeto.\n\n*Podemos prosseguir com a solicitação do contrato e enviar no seu e-mail para você revisar antes de confirmarmos tudo?*`,
            },
            {
                titulo: "Mensagem 5: Formalizando a Proposta",
                para: "Projetos",
                texto: `${getFirstName(customer?.nome)}, agora para garantir todos os detalhes acordados e ajustados conforme sua necessidade.\n\n*Vamos solicitar o contrato e enviar no seu e-mail para você revisar antes de confirmarmos tudo?*`,
            },
            {
                titulo: "Mensagem 6: Alinhamento Final",
                para: "Projetos",
                texto: `${getFirstName(customer?.nome)}, para que possamos avançar com o projeto do jeito que ajustamos para te atender.\n\n*Vamos solicitar o contrato e enviar no seu e-mail para você revisar antes de confirmarmos tudo?*`,
            }
        ],
        "Avisar o Cliente e Confirmar o Envio": [
            {
                titulo: "Mensagem 1: Confirmação de Envio",
                para: "Contrato",
                texto: `Olá, ${getFirstName(customer?.nome)}!\nEnviamos o contrato para seu e-mail.\n\n*Caso tudo esteja conforme planejado, é só prosseguir com a assinatura.*\n\nRevise com calma, e se precisar de ajuda ou houver algo que queira esclarecimentos. Sinta-se à vontade para me chamar!`,
            },
            {
                titulo: "Mensagem 2: Contrato Enviado para Revisão",
                para: "Contrato",
                texto: `Oi, ${getFirstName(customer?.nome)}!\nO contrato foi enviado para seu e-mail.\n\n*Se estiver tudo certo, você pode assinar.*\n\nDê uma olhada e, se precisar de algum esclarecimento, estou aqui para ajudar. Sinta-se à vontade para me chamar!`,
            },
            {
                titulo: "Mensagem 3: Passo Final",
                para: "Contrato",
                texto: `Olá, ${getFirstName(customer?.nome)}!\nO contrato já está no seu e-mail para revisão.\n\n*Caso tudo esteja conforme planejado, é só assinar!*\n\nSe precisar de qualquer esclarecimento e orientação, sinta-se à vontade para me chamar!`,
            },
            {
                titulo: "Mensagem 4: Contrato Pronto para Assinatura",
                para: "Contrato",
                texto: `Oi, ${getFirstName(customer?.nome)}!\nO contrato foi enviado para seu e-mail.\n\n*Se estiver tudo conforme combinamos, é só assinar.*\n\nRevise com calma, e qualquer dúvida ou esclarecimentos, sinta-se à vontade para me chamar!`,
            },
            {
                titulo: "Mensagem 5: Revisão e Assinatura",
                para: "Contrato",
                texto: `Olá, ${getFirstName(customer?.nome)}!\nAcabei de enviar o contrato para seu e-mail.\n\n*Se tudo estiver certo, você pode assinar!*\n\nFique à vontade para revisar e, se precisar de ajuda ou esclarecimentos, sinta-se à vontade para me chamar!`,
            }
        ],
        "Execução de Recebíveis": [
            {
                titulo: "Mensagem 1: Financiamento",
                para: "Contrato",
                texto: `Oi, ${getFirstName(customer?.nome)}, sou ${user?.nome} da CorpBrasil, como está você e a família? Tudo bem?\n\n*Parabéns, seu projeto foi aprovado pela concessionária de energia.*\n\nAgora vamos avançar para a etapa de pagamento e faturamento do sistema fotovoltaico.\n\nComo o método de pagamento escolhido em seu contrato foi financiamento, daremos início à execução conforme o contrato.\n\nEstou aqui para orientar, e qualquer dúvida é só me chamar!`
,
            },
            {
                titulo: "Mensagem 2: Cartão de Crédito",
                para: "Contrato",
                texto: `Oi, ${getFirstName(customer?.nome)}, sou ${getFirstName(user?.nome)} da CorpBrasil, como está você e a família Tudo bem?\n\n*Parabéns, seu projeto foi aprovado pela concessionária de energia.*\n\nAgora vamos para a etapa de pagamento e faturamento do sistema fotovoltaico.\n\nComo o método de pagamento será via cartão de crédito, enviarei um link de pagamento em até 24x sem juros, para que você possa concluir essa etapa com segurança.\n\nEstou aqui para orientar, e qualquer dúvida é só me chamar!`,
            },
            {
                titulo: "Mensagem 3: Pix",
                para: "Contrato",
                texto: `Oi, ${getFirstName(customer?.nome)}, sou ${getFirstName(user?.nome)} da CorpBrasil, como está você e a família? Tudo bem?\n\n*Parabéns, a aprovação do seu projeto pela concessionária de energia foi concluída.*\n\nAgora seguimos para o pagamento e faturamento do sistema fotovoltaico.\n\nComo o pagamento será via Pix, enviaremos os dados bancários para que você realize a transferência do valor conforme o contrato.\n\nEstou aqui para orientar, e qualquer dúvida é só me chamar!`,
            },
            {
                titulo: "Mensagem 4: Entrada de 80% + 20% na Entrega dos Materiais",
                para: "Contrato",
                texto: `Oi, ${getFirstName(customer?.nome)}, sou ${getFirstName(user?.nome)} da CorpBrasil, como está você e a família? Tudo bem?\n\n*Parabéns, seu projeto foi aprovado pela concessionária de energia, e passaremos agora para a etapa de pagamento e faturamento do sistema fotovoltaico.*\n\nNeste caso, será necessário o pagamento de 80% como entrada no valor de <b>[ R$ ]</b> e 20% no valor de <b>[ R$ ]</b> no dia da entrega dos materiais.\n\nComo o pagamento da entrada será via Pix, enviaremos os dados bancários para que você realize a transferência do valor conforme o contrato, e os boletos serão emitidos após a entrega dos materiais.\n\nIrei te orientar e qualquer dúvida, só me chamar!`,
            },
            {
                titulo: "Mensagem 5: Pagamento em 70% + 15% na Entrega dos Materiais e 15% após 30 Dias do 1° Boleto",
                para: "Contrato",
                texto: `Oi, ${getFirstName(customer?.nome)}, sou ${getFirstName(user?.nome)} da CorpBrasil, como está você e a família? Tudo bem?\n\n*Parabéns, seu projeto foi aprovado pela concessionária de energia. Agora vamos para a etapa de pagamento e faturamento do sistema fotovoltaico.*\n\nEm seu contrato, o pagamento será realizado em 70% de entrada no valor de <b>[R$ ]</b> via Pix, + 15% no valor <b>[R$ ]</b> na entrega dos materiais via boleto, e os últimos 15% após 30 dias do primeiro boleto.\n\nEnviaremos os dados bancários para o pagamento via Pix, e os boletos serão emitidos após a entrega dos materiais.\n\nIrei te orientar e qualquer dúvida, só me chamar!`,
            }
        ],    
    }

    const selectMessage = (message, isSelected) => {
        setMessage(isSelected ? null : message);
    };

    const optionMessage = useCallback((messageData, index) => {
        const parsedMessageText = messageData.texto || "";
        const isChecked = message?.texto === parsedMessageText;
        const isSend = messagesSend.includes(messageData?.id)
        return (
            <FormControlLabel
                key={index}
                control={
                    <Checkbox
                        checked={isChecked}
                        onChange={() => selectMessage(messageData, isChecked)}
                        name="Mensagem"
                        color="success"
                    />
                }
                sx={{
                    width: '100%',
                    maxWidth: '340px',
                    display: 'flex',
                    padding: '1rem',
                    boxSizing: 'border-box',
                    backgroundColor: isSend ? "#edf7ed" : "#fff",
                    color: isSend ? "#1e4620" : "#000",
                    boxShadow: "0px 4px 24px 0px #0000001A",
                    alignItems: 'flex-start', // Alinha o conteúdo no topo
                    '& .MuiFormControlLabel-label': {
                        width: '100%'
                    },
                    'span': { paddingTop: 0 }
                }}
                label={
                    <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', gap: '0.8rem', whiteSpace: 'pre-line', "p": { lineHeight: '20px', fontSize: '15px' } }}>
                        {isSend && (
                            <Box sx={{ position: 'absolute', right: '-30px', backgroundColor: "#b2e3b2", padding: '0 0.5rem', borderRadius: '19px', top: '-20px', fontSize: '10.5px', fontWeight: 'bold' }}>ENVIADO</Box>
                        )}
                        <b>{`${messageData.titulo} (${messageData?.para}):`}</b>
                        <p>{parse(parsedMessageText)}</p>
                    </Box>
                }
            />
        );
    }, [message, messagesSend]);

    console.log('DATA', customer, activityData);

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="lg"
        >
            <IconButton
                aria-label="close"
                onClick={close}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon sx={{ fill: '#000' }} />
            </IconButton>
            <DialogTitle align='center'>Mensagens</DialogTitle>
            <DialogContent>
                <Box sx={{ width: '100%', gap: '1rem', display: 'flex', flexDirection: 'column', margin: '0.5rem 0 1rem 0', 'h2': { textAlign: 'center' } }}>
                    <h2>{step}</h2>
                    <FormGroup
                        sx={{
                            display: "flex",
                            gap: "1rem",
                            justifyContent: "center"
                        }}
                        row
                    >
                    {msg[step].map((data, index) => (
                            optionMessage(data, index)
                        ))}
                        </FormGroup>
                </Box>
                {msg['Firmar Compromisso'].map((item, index) => (
                    <Box key={index} sx={{ margin: '1rem 0', boxSizing: 'border-box' }}>
                        <ButtonSection
                            icon={false}
                            size="large"
                            align="center"
                            color='#6e6e6e'
                            open={openMessages[index]}
                            text="Firmar Compromisso"
                            onClick={() => setOpenMessages(prevState => {
                                const updatedMessages = [...prevState];
                                updatedMessages[index] = !openMessages[index];
                                return updatedMessages;
                            })}
                        />
                        <Collapse
                            sx={{ width: "100%" }}
                            in={openMessages[index]}
                            timeout="auto"
                            unmountOnExit
                        >
                            {Object.entries(item).map(([title, variations]) => (
                                <Box key={title} sx={{ width: '100%', gap: '1rem', display: 'flex', flexDirection: 'column', margin: '0.5rem 0 1rem 0', 'h2': { textAlign: 'center' } }}>
                                    <h2>{title}</h2>
                                    <FormGroup
                                        sx={{
                                            display: "flex",
                                            gap: "1rem",
                                            justifyContent: "center",
                                            boxSizing: 'border-box'
                                        }}
                                        row
                                    >
                                        {variations.map((variation, idx) => (
                                            optionMessage(variation, idx)
                                        ))}
                                    </FormGroup>
                                </Box>
                            ))}
                        </Collapse>
                    </Box>
                ))}
            </DialogContent>
            <DialogActions sx={{ margin: '0.3rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'end', gap: '0.7rem' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        {message?.clicado && (
                            <Typography sx={{ alignItems: 'center', display: 'flex', gap: '0.3rem', color: "#939393", fontSize: '14px', justifyContent: 'center' }}>Enviado com sucesso <IoCheckmarkDoneOutline size="1rem" /></Typography>
                        )}
                        <ButtonCB size='small' disabled={!message || activityData.titulo !== 'Contato 1'} onClick={() => handleMessage(message?.texto, 'send')} startIcon={<FaWhatsapp size={'1.3rem'} />} background="#6ABE59">Enviar via WhatsApp</ButtonCB>
                    </Box>
                    <ButtonCB size='small' onClick={() => handleMessage(message?.texto, 'audio')} disabled={!message || activityData.titulo !== 'Contato 2'} startIcon={<FaMicrophone size={'1.3rem'} />} background="#3163EB">Enviar Áudio</ButtonCB>
                    <ButtonCB size='small' onClick={() => handleMessage(message?.texto, 'phone')} disabled={!message || activityData.titulo !== 'Contato 3'} startIcon={<FaPhoneAlt size={'1.3rem'} />} background="#AD1717">Ligar</ButtonCB>
                </Box>
            </DialogActions>
        </Dialog >
    )
}

export default MessageBox