
const removePrefixPhone = (phone) => {
  
  if (phone.startsWith('55')) {
    return phone.slice(2);
    }

    return phone;
}

export default removePrefixPhone;