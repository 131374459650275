import React, { createContext, useContext, useState } from 'react';

// Criando o contexto
const LoadingContext = createContext();

// Componente provedor que envolve toda a aplicação e fornece os dados do cadastro
export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

// Hook personalizado para acessar o contexto
export const useLoading = () => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading deve ser usado dentro de um LoadingProvider');
  }
  return context;
};
