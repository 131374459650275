import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

import { PatternFormat } from "react-number-format";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { theme, themeInput } from "../../../data/theme";
import {
  ThemeProvider,
  DialogContentText,
  InputAdornment,
  Alert,
  ListItemIcon,
  ListItemText,
  Grid,
  Typography,
  AlertTitle,
  FormControl,
  useMediaQuery,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
// import LockIcon from "@mui/icons-material/Lock";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import CloseIcon from "@mui/icons-material/Close";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import RefreshIcon from '@mui/icons-material/Refresh';

import style from "../styles.module.scss";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { dataBase } from "../../../firebase/database";
import moment from "moment";
import { Link } from "react-router-dom";
import { KeyMaps, idBruna, idPaulo } from "../../../data/Data";
// import AutocompleteMaps from "../../../components/AutoCompleteMaps";
import NewTooltip from "../../../components/Tooltip";
import { formatPhoneBR } from "../../../functions/FormatPhoneBR";
import axios from "axios";
import { AutoSizer, List } from "react-virtualized";
import Loading from "../../../components/Loading";
import { toast } from "../../../components/Toast";
import Geocode from "react-geocode";

Geocode.setLanguage("pt-BR");
Geocode.setRegion("br");
Geocode.setApiKey(KeyMaps);
Geocode.setLocationType("ROOFTOP");

const RegisterVisit = ({ leads, members, view, customerRef, close, userRef, type = 'Cliente' }) => {
  // const navigate = useNavigate();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [responsible, setResponsible] = useState({
    nome: null,
    id: "",
  });
  const [responsibles, setResponsibles] = useState([]);
  // const [leadsSheet, setLeadsSheet] = useState([]);
  // const [question, setQuestion] = useState([
  //   {
  //     pergunta: 'aaaa',
  //     resposta: ''
  //   },
  //   {
  //     pergunta: 'bbbb',
  //     resposta: ''
  //   }
  // ]); 
  const [feedback, setFeedback] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [representative, setRepresentative] = useState({
    nome: "Nenhum",
    id: "0",
    codigo: "Nenhum",
  });
  const filter = createFilterOptions();
  // const [representatives, setRepresentatives] = useState([]);
  const [location, setLocation] = useState(null);
  const [customer, setCustomer] = useState({
    nome: "",
    doc: "",
    email: "",
    empresa: "",
    telefone: "",
    cep: "",
    cidade: "",
    numero: "",
    complemento: "",
    bairro: "",
    estado: "",
  });
  const loadingLeads = open && leads.length === 0;
  const feedbackList = [
    {
      titulo: 'Energia Solar - Atende',
      descricao: 'Cliente com energia solar, atendimento realizado.'
    },
    {
      titulo: 'Energia Solar - Não Atende',
      descricao: 'Cliente com energia solar, não atendeu.'
    },
    {
      titulo: 'Negociando',
      descricao: 'Cliente em negociação.'
    },
    {
      titulo: 'Não tem Interesse',
      descricao: 'Cliente não tem interesse.'
    },
    {
      titulo: 'Investimento',
      descricao: 'Cliente interessado em investimento.'
    },
    {
      titulo: 'Responsável Ausente',
      descricao: 'Responsável ausente, retornar posteriormente.'
    },
    {
      titulo: 'Nova Visita- Cliente Potêncial',
      descricao: 'Cliente potencial, agendar nova visita.'
    },

  ]
  const ListboxComponent = React.forwardRef(function ListboxComponent(
    props,
    ref
  ) {
    const { children, role, ...other } = props;
    const itemCount = Array.isArray(children) ? children.length : 0;
    const itemSize = 55;

    return (
      <div ref={ref}>
        <div {...other} style={{ flex: "1 1 auto" }}>
          <AutoSizer disableHeight>
            {({ width }) => (
              <List
                height={200}
                width={width}
                rowHeight={itemSize}
                overscanCount={5}
                rowCount={itemCount}
                rowRenderer={(props) => {
                  return React.cloneElement(children[props.index], {
                    style: props.style,
                  });
                }}
                role={role}
              />
            )}
          </AutoSizer>
        </div>
      </div>
    );
  });

  useEffect(() => {
    const updateResponsible = () => {
      if (userRef) {
        setResponsible({
          nome: userRef.nome === "Pós-Venda" ? userRef.nome_ref : userRef.nome,
          id: userRef.id,
          img: userRef.photo?.url,
        });
      }
    };

    const updateLocation = async () => {
      if (!view) return;

      try {
        const { coords } = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        // Use the latitude and longitude obtained from coords to get the address
        const geocodeResult = await Geocode.fromLatLng(coords.latitude, coords.longitude);
        const cityRef = geocodeResult.results[0].address_components;
        const address = geocodeResult.results[0].formatted_address;
        const city = cityRef.find((ref) => ref.types.includes('administrative_area_level_2'));

        setLocation({
          endereco: address,
          lat: coords.latitude,
          lng: coords.longitude,
        });

        setCustomer((prevCustomer) => ({
          ...prevCustomer,
          endereco: {
            endereco: address,
            lat: coords.latitude,
            lng: coords.longitude,
          },
          historico: customerRef?.historico || [],
          visitas: customerRef?.visitas || [],
          id: customerRef?.id || '',
          cidade: city ? city.long_name : "",
        }));
      } catch (error) {
        // return showAlert(
        //       "Atenção!", `Localização não encontrada. Verifique se o <b>GPS está ativado</b> no aparelho.`,
        //       "error"
        //     );
        // console.error("Erro ao obter localização:", error);
      }
    };

    updateResponsible();
    updateLocation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRef, view, type]);

  const updateLocation = async () => {

    try {
      const { coords } = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      // Use the latitude and longitude obtained from coords to get the address
      const geocodeResult = await Geocode.fromLatLng(coords.latitude, coords.longitude);
      const cityRef = geocodeResult.results[0].address_components;
      const address = geocodeResult.results[0].formatted_address;
      const city = cityRef.find((ref) => ref.types.includes('administrative_area_level_2'));

      setLocation({
        endereco: address,
        lat: coords.latitude,
        lng: coords.longitude,
      });

      setCustomer((prevCustomer) => ({
        ...prevCustomer,
        endereco: {
          endereco: address,
          lat: coords.latitude,
          lng: coords.longitude,
        },
        historico: customerRef?.historico || [],
        visitas: customerRef?.visitas || [],
        id: customerRef?.id || '',
        cidade: city ? city.long_name : "",
      }));
      toast({ icon: 'success', text: 'Localização atualizada com sucesso!', position: 'top-start' });
    } catch (error) {
      return toast({ icon: 'error', text: 'Localização não encontrada. Verifique se o <b>GPS está ativado</b> no aparelho.', position: 'top-start' });
    }
  };



  const closeBox = () => {
    close();
    setTimeout(() => {
      setCustomer({
        nome: "",
        doc: "",
        email: "",
        empresa: "",
        telefone: "",
        cep: "",
        endereco: {
          endereco: "",
          lat: -23.109715809172528,
          lng: -47.71509284910918,
        },
        cidade: "",
        numero: "",
        complemento: "",
        bairro: "",
        estado: "SP",
      });
      setResponsible({
        nome: userRef?.nome,
        id: userRef?.id,
        img: "",
      });
      setLocation(null);
      setFeedback('');
      // setQuestion([
      //   {
      //     pergunta: 'aaaa',
      //     resposta: ''
      //   },
      //   {
      //     pergunta: 'bbbb',
      //     resposta: ''
      //   }
      // ]);
      setRepresentative({
        nome: "Nenhum",
        id: "0",
        codigo: "Nenhum",
      });
    }, 300);
  };

  const showAlert = (onConfirmCallback) => {
    return Swal.fire({
      title: "Atenção",
      text: "Não foi possível encontrar a localização. Verifique o GPS e clique em Atualizar.",
      icon: "error",
      confirmButtonText: "Atualizar",
      confirmButtonColor: "#3085d6",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (onConfirmCallback) {
          onConfirmCallback();
        }
      }
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {

      if(!location && userRef?.nome === "Paulo Moraes") return showAlert(updateLocation); // Caso não encontre a localização

      console.log(customer)
      const result = await Swal.fire({
        title: "Atenção",
        text: `Você deseja registar a Visita?`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#05a937",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });

      if (!result.isConfirmed) return;

      setLoading(true);
      const filteredData = Object.fromEntries(
        Object.entries(customer)
          .map(([key, value]) => {
            if (key === "telefone") {
              // Remove todos os caracteres não numéricos do telefone
              value = value.replace(/\D/g, "");
            }
            return [key, value];
          })
          .filter(([_, value]) => value)
      );
      const filterRepresentative =
        representative.nome !== null ? representative : null;

      // const assistantRef =
      //   checkSellerMaster() && responsible.id !== userRef?.id
      //     ? userRef?.id
      //     : customer.assistente?.length > 0
      //       ? members.find((data) => data.nome === customer.assistente)
      //       : { id: '' };
          
      const visitsRef = customer.visitas || [];

      if(checkLead(customer?.telefone || customerRef?.telefone)) {
        setLoading(false);
        console.log(customer);
        const updateCustomerData = {
          feedback_vendedor: feedback,
          visitas: [...visitsRef, location ? 
            {
            data: moment().format('DD/MM/YYYY - HH:mm'),
            createAt: new Date(),
            ...location,
            endereco: location?.endereco || '',
            cidade: customer.cidade || '',
            usuario_id: userRef?.id,
            usuario_nome: userRef?.nome,
            feedback: feedback
          } : {
            data: moment().format('DD/MM/YYYY - HH:mm'),
            createAt: new Date(),
            usuario_id: userRef?.id,
            usuario_nome: userRef?.nome,
            feedback: feedback
          }
          ],
          historico: [...customer.historico, {
            data: moment().format('DD/MM/YYYY - HH:mm'),
            evento: 'Visita Registrada',
            text: `Foi registrado uma visita em <b>${location?.endereco || '<b>Não Localizado</b>'}</b>.`,
            usuario_id: userRef?.id,
            usuario_nome: userRef?.nome
          }]
        };
        await updateDoc(doc(dataBase, 'Clientes', customer.id), updateCustomerData);
        await sendN8NVisit(customer.id, type === 'Cliente' ? customer : customerRef);
      } else {
        const addCustomerData = {
          ...filteredData,
          consultora: responsible.nome,
          feedback_vendedor: feedback,
          consultora_uid: responsible.id,
          telefone: unmask(customer.telefone),
          representante: filterRepresentative,
          data: moment().format("DD MMM YYYY - HH:mm"),
          dataRef: moment().format("DD MMM YYYY - HH:mm"),
          createAt: new Date(),
          assistente: '',
          historico: [
            {
              data: moment().format("DD/MM/YYYY - HH:mm"),
              evento: "Cliente Criado",
              text: `O Cliente foi criado pelo metódo <b>Registrar Visita</b>.`,
              usuario_id: userRef?.id,
              usuario_nome: userRef?.nome,
            },
            {
              data: moment().format('DD/MM/YYYY - HH:mm'),
              evento: 'Visita Registrada',
              text: `Foi registrado uma visita em <b>${location?.endereco || '<b>Não Localizado</b>'}</b>.`,
              usuario_id: userRef?.id,
              usuario_nome: userRef?.nome,
            }
          ],
          visitas: [...visitsRef, location ? 
            {
            data: moment().format('DD/MM/YYYY - HH:mm'),
            createAt: new Date(),
            ...location,
            endereco: location?.endereco || '',
            cidade: customer.cidade || '',
            usuario_id: userRef?.id,
            usuario_nome: userRef?.nome,
            feedback: feedback
          } : {
            data: moment().format('DD/MM/YYYY - HH:mm'),
            createAt: new Date(),
            usuario_id: userRef?.id,
            usuario_nome: userRef?.nome,
            feedback: feedback
          }]
        }

        const resultCustomer = await addDoc(collection(dataBase, "Clientes"), addCustomerData);
        await sendN8N(resultCustomer.id);
      }

      setCustomer({
        nome: "",
        doc: "",
        email: "",
        empresa: "",
        telefone: "",
        cep: "",
        endereco: {
          endereco: "",
          lat: -23.109715809172528,
          lng: -47.71509284910918,
        },
        cidade: "",
        numero: "",
        complemento: "",
        bairro: "",
        estado: "SP",
      });

      setLoading(false);

      await Swal.fire({
        title: "Clientes",
        html: `A visita ao cliente <b>${customer.nome}</b> foi registrada com sucesso.`,
        icon: "success",
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: "#111",
      });

      // navigate(`/clientes/${resultCustomer.id}`);
      closeBox();

    } catch (error) {
      setLoading(false);
      console.error(error);
      toast({ icon: 'error', text: 'Ocorreu um erro ao criar o cliente.', error: error })
      // console.log(error);
    }
  };

  const sendN8N = async (id) => {
    return await axios.post("https://n8n.corpbrasil.cloud/webhook/41485ed1-bb2f-42e9-8a8b-735af046b818", {
      id: id,
      status: 'Aberto',
      cliente: customer.nome,
      telefone: customer.telefone,
      cidade: customer.cidade,
      responsavel: userRef?.nome,
      analista: customer.assistente_nome || 'Nenhum',
      representante: customer.representante?.nome || 'Nenhum',
      indicador: 'Nenhum'
    })
  }

  const sendN8NVisit = async (id, data) => {
    return await axios.post("https://n8n.corpbrasil.cloud/webhook/dc166bdc-afb7-49dc-882d-cad82a4c5327", {
      id: id,
      status: 'Aberto',
      cliente: data.nome,
      telefone: data.telefone,
      cidade: data.cidade,
      responsavel: userRef?.nome,
      analista: data.assistente_nome || 'Nenhum',
      representante: data.representante?.nome || 'Nenhum',
      indicador: 'Nenhum',
      localizacao: location,
      cidade_localizada: customer.cidade,
      feedback: feedback
    })
  }

  // const showAlert = async (title, message, icon) => {
  //   await Swal.fire({
  //     title: title,
  //     html: message,
  //     icon: icon,
  //     showConfirmButton: true,
  //     showCloseButton: true,
  //     confirmButtonColor: "#111",
  //   });
  //   return closeBox();
  // };

  useEffect(() => {
    if (members) {
      const filterResponsibles = members.filter(
        (member) =>
          (member.cargo === "Vendedor(a)" && !member.desativado) ||
          member.nome === "CORPBRASIL"
      );
      // const filterRepresentatives = members.filter(
      //   (member) => member.cargo === "Representante"
      // );

      const responsiblesRef = filterResponsibles
        .map((member) => {
          return {
            nome: member.nome === "Pós-Venda" ? member.nome_ref : member.nome,
            id: member.id,
            img: member.photo?.url,
          };
        })
        .sort((a, b) => {
          if (a.nome < b.nome) {
            return -1;
          }
          if (a.nome > b.nome) {
            return 1;
          }
          return 0;
        });

      const responsibleSellerMaster = responsiblesRef.filter(
        (data) =>
          data.id === userRef?.id || data.id === idBruna || data.id === idPaulo
      );

      setResponsibles(
        checkSellerMaster() ? responsibleSellerMaster : responsiblesRef
      );

      // const representativesRef = filterRepresentatives
      //   .map((member) => {
      //     return {
      //       nome: member.nome,
      //       id: member.id,
      //       codigo: member.id_representante,
      //     };
      //   })
      //   .sort((a, b) => {
      //     if (a.nome < b.nome) {
      //       return -1;
      //     }
      //     if (a.nome > b.nome) {
      //       return 1;
      //     }
      //     return 0;
      //   });

      // const defaultRepresentative = {
      //   nome: "Nenhum",
      //   id: "0",
      //   codigo: "Nenhum",
      // };

      // if (userRef && userRef.cargo === "Vendedor(a)" && userRef.time) {
      //   setRepresentatives([
      //     defaultRepresentative,
      //     ...representativesRef.filter((data) =>
      //       data.codigo.includes(userRef.time)
      //     ),
      //   ]);
      // } else {
      //   setRepresentatives([defaultRepresentative, ...representativesRef]);
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members]);

  const checkSellerMaster = () => {
    if (userRef && userRef.cargo === "Vendedor(a)" && userRef.time) {
      return true;
    } else {
      return false;
    }
  };

  // const mask = (v) => {
  //   v = v.replace(/\D/g, "");

  //   if (v.length <= 11) {
  //     v = v.replace(/(\d{3})(\d)/, "$1.$2");
  //     v = v.replace(/(\d{3})(\d)/, "$1.$2");
  //     v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  //   } else {
  //     v = v.substring(0, 14); // limita em 14 números
  //     v = v.replace(/^(\d{2})(\d)/, "$1.$2");
  //     v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
  //     v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
  //     v = v.replace(/(\d{4})(\d)/, "$1-$2");
  //   }

  //   return v;
  // };

  console.log(type);

  const unmask = (v) => {
    if (!v) return "";
    return v.replace(/\D/g, "");
  };

  const checkInputs = () => {
    const {
      nome,
      telefone,
      //endereco: { endereco },
    } = customer;
    const isNomeValid = type === 'Cliente' ? nome?.length > 0 : true;
    const isTelefoneValid = type === 'Cliente' ? telefone?.length > 0 && !telefone.includes("_") : true;
    // const isEnderecoValid = endereco?.length > 0;
    const isQuestionsValid = feedback?.length > 0;

    const areAllInputsValid = isNomeValid && isTelefoneValid && isQuestionsValid

    if (areAllInputsValid) {
      return true;
    }

    return false;
  };

  const checkLead = (phone) => {
    if (phone === "15000000000") return false;
    return leads.find((data) => data.telefone === phone);
  };

  // const handleQuestion = (value, index) => {
  //   setQuestion((prevQuestions) => {
  //     const updatedQuestions = [...prevQuestions];
  //     updatedQuestions[index] = {
  //       ...updatedQuestions[index],
  //       resposta: value,
  //     };
  //     return updatedQuestions;
  //   });
  // };

  // console.log(question)

  const detailsLead = async (leadRef) => {
    return Swal.fire({
      title: "Detalhes do Cliente",
      html:
        `Cliente: <b>${leadRef.nome}</b></br>` +
        `Telefone: <b>${formatPhoneBR(leadRef.telefone)}</b></br>` +
        `Consultor(a): <b>${leadRef.consultora}</b></br>` +
        `Cidade: <b>${leadRef.cidade}</b></br></br>`,
      icon: "info",
      showCloseButton: true,
      confirmButtonColor: "#000",
      confirmButtonText: "Fechar",
    });
  };

  console.log(leads);

  return (
    <Dialog
      className={style.dialog}
      open={view}
      fullScreen={fullScreen}
      maxWidth="md"
      sx={{
        padding: (theme) => theme.spacing(2),
        position: "absolute",
        zIndex: "999 !important",
        right: "0px",
        bottom: "0px",
        top: "0px",
        left: "0px",
      }}
    >
      <IconButton
        aria-label="close"
        onClick={closeBox}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle align="center">Registrar Visita</DialogTitle>
      <DialogContent>
        <Loading view={loading} />
        <DialogContentText sx={{ textAlign: "center", marginBottom: "0.3rem" }}>
          Preencha os campos obrigatórios <b>(*)</b> abaixo para registrar a visita ao <b>Cliente</b>.
        </DialogContentText>
        {type === 'Cliente' ? 
          <Alert severity="info">
            <b>Atenção:</b> Por favor, verifique se o <b>GPS está ativado</b> para que o sistema possa capturar a localização correta da visita.
          </Alert> : null
        }
        <form onSubmit={onSubmit} autoComplete="off">
          <Grid container spacing={1} sx={{ marginTop: "1rem" }}>
            {userRef?.cargo === 'Administrador' && type === 'Cliente' ? 
              <Grid item xs={12}>
                <Autocomplete
                  value={responsible}
                  disabled={
                    userRef && userRef.cargo === "Vendedor(a)" && !userRef.time
                      ? true
                      : false
                  }
                  onChange={(event, newValue) => {
                    if (newValue != null) {
                      setResponsible({
                        nome: newValue.nome,
                        id: newValue.id,
                        img: newValue.img,
                      });
                    } else {
                      // Caso seja necessário lidar com a limpeza do campo
                      setResponsible({});
                    }
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  fullWidth
                  disableClearable={true}
                  id="free-solo-with-text-demo"
                  options={responsibles}
                  getOptionLabel={(option) => option.nome}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <ListItemIcon>
                        <img
                          src={option.img}
                          alt={option.nome}
                          style={{ width: 24, height: 24, borderRadius: "50%" }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={option.nome} />
                    </li>
                  )}
                  noOptionsText="Responsável não encontrado"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Responsável"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: responsible.img && (
                          <InputAdornment position="start">
                            <img
                              src={responsible.img}
                              alt={responsible.nome}
                              style={{
                                width: 24,
                                height: 24,
                                borderRadius: "50%",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                />
              </Grid> : null
            }
            <ThemeProvider theme={themeInput}>
              {type === 'Cliente' ?
                <>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      value={customer}
                      // sx={{ marginTop: "0.55rem" }}
                      disableListWrap
                      ListboxComponent={ListboxComponent}
                      open={open}
                      onOpen={() => {
                        setOpen(true);
                      }}
                      onClose={() => {
                        setOpen(false);
                      }}
                      // ListboxComponent={ListboxComponent}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          setCustomer((prev) => ({ ...prev, nome: newValue }));
                        } else if (newValue && newValue.inputValue) {
                          setCustomer({
                            doc: "",
                            email: "",
                            empresa: "",
                            telefone: "",
                            cep: "",
                            cidade: customer.cidade,
                            endereco: customer.endereco || {},
                            numero: "",
                            complemento: "",
                            bairro: "",
                            estado: "",
                            nome: newValue.inputValue,
                          });
                        } else {
                          setCustomer((prev) => ({ ...prev, ...newValue }));
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some(
                          (option) => inputValue === option.nome
                        );

                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            nome: `Adicionar "${inputValue}"`,
                          });
                        }
                        return filtered;
                      }}
                      clearOnBlur
                      handleHomeEndKeys
                      fullWidth
                      loading={loadingLeads}
                      loadingText="Nenhum Cliente"
                      disableClearable={true}
                      id="free-solo-with-text-demo"
                      options={leads}
                      noOptionsText="Lead não encontrado"
                      getOptionLabel={(option) =>
                        option.nome ? option.nome : ""
                      }
                      renderOption={(props, option) => (
                        <li {...props}>
                          {option.telefone
                            ? `${option.nome} (${option.cidade
                            }) - ${formatPhoneBR(option.telefone)}`
                            : option.nome}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Nome Completo"
                          type="text"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "no-password",
                          }}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: checkLead(customer?.telefone) && (
                              <InputAdornment position="start">
                                <InfoRoundedIcon sx={{ fill: "#ccc" }} />
                              </InputAdornment>
                            ),
                          }}
                          AutoComplete="no-password"
                          // error={checkLead(customer?.telefone)}
                          helperText={
                            checkLead(customer?.telefone) ? (
                              <Typography
                                fontSize={"12px"}
                                sx={{ overflowWrap: "break-word" }}
                              >
                                <strong>Cliente já cadastrado.</strong> Veja mais
                                detalhes{" "}
                                <b>
                                  <Link
                                    style={{ color: "#000" }}
                                    to=""
                                    onClick={() =>
                                      detailsLead(checkLead(customer?.telefone))
                                    }
                                  >
                                    aqui
                                  </Link>
                                </b>
                              </Typography>
                            ) : (
                              ""
                            )
                          }
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.nome === value.nome
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <PatternFormat
                      value={customer.telefone ? customer.telefone : ''}
                      onChange={(e) => setCustomer({ ...customer, telefone: e.target.value })}
                      format="(##) ##### ####"
                      mask="_"
                      fullWidth
                      customInput={TextField}
                      placeholder="(00) 00000 0000"
                      label="Telefone"
                      minLength={9}
                      variant="outlined"
                      required
                    />
                  </Grid>
                </> : null
              }
            </ThemeProvider>
            <Grid item xs={12}>
              <Alert icon={<LocationOnIcon />} severity="success">
                <AlertTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  Localização
                  <NewTooltip title='Atualizar' placement='right'>
                    <IconButton onClick={updateLocation}>
                      <RefreshIcon />
                    </IconButton>
                  </NewTooltip>
                </AlertTitle>
                <p><b>Endereço:</b> {location?.endereco}</p>
                <p><b>Latitude:</b> {location?.lat} - <b>Longitude:</b> {location?.lng}</p>
              </Alert>
            </Grid>
            {/* {question?.map((data, index) => (
              <Grid item xs={12}>
                <FormControl fullWidth required>
                  <FormLabel id="radio-buttons-group-label4">
                   {data.pergunta}
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="radio-buttons-group-label4"
                    // defaultValue={data.resposta}
                    name="radio-buttons-group4"
                    value={data.resposta}
                    onChange={(e) => handleQuestion(e.target.value, index)}
                  >
                    <FormControlLabel value="Sim" control={<Radio required />} label="Sim" />
                    <FormControlLabel value="Não" control={<Radio required />} label="Não" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            ))} */}
            <Grid item xs={12}>
            <FormControl fullWidth sx={{  margin: '1rem 0'  }}>
                            <InputLabel id="select-label">Feedback</InputLabel>
                            <Select
                                labelId="select-label"
                                id="select"
                                sx={{ backgroundColor: "#fff"}}
                                value={feedback}
                                label="Feedback"
                                onChange={(e) => setFeedback(e.target.value)}
                            >
                                {feedbackList?.map((data, index) => (
                                    <MenuItem key={index} value={data.descricao}> 
                                        <ListItemText primary={data.titulo} secondary={data.descricao} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
            </Grid>
            {/* <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="dense"
                label="CNPJ/CPF"
                type="text"
                inputProps={{
                  minLength: 14,
                }}
                value={customer.doc ? customer.doc : ""}
                onChange={(e) =>
                  setCustomer({ ...customer, doc: mask(e.target.value) })
                }
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="dense"
                label="Email"
                type="email"
                name="email"
                value={customer.email ? customer.email : ""}
                onChange={(e) =>
                  setCustomer({ ...customer, email: e.target.value })
                }
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                margin="dense"
                label="Empresa"
                name="Empresa"
                type="text"
                value={customer.empresa ? customer.empresa : ""}
                onChange={(e) =>
                  setCustomer({ ...customer, empresa: e.target.value })
                }
                fullWidth
                variant="outlined"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PatternFormat
                value={customer.cep ? customer.cep : ""}
                onChange={(e) =>
                  setCustomer({ ...customer, cep: e.target.value })
                }
                sx={{ marginTop: '0.5rem' }}
                format="#####-###"
                mask="_"
                fullWidth
                customInput={TextField}
                placeholder="00000-000"
                label="CEP"
                minLength={9}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label="Complemento"
                name="Complemento"
                type="text"
                value={customer.complemento ? customer.complemento : ""}
                onChange={(e) =>
                  setCustomer({ ...customer, complemento: e.target.value })
                }
                fullWidth
                variant="outlined"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                margin="dense"
                label="Bairro"
                name="Bairro"
                type="text"
                value={customer.bairro ? customer.bairro : ""}
                onChange={(e) =>
                  setCustomer({ ...customer, bairro: e.target.value })
                }
                fullWidth
                variant="outlined"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                margin="dense"
                label="Estado"
                name="Estado"
                type="text"
                value={customer.estado ? customer.estado : ""}
                fullWidth
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                margin="dense"
                label="Cidade"
                name="Cidade"
                type="text"
                value={customer.cidade ? customer.cidade : ""}
                onChange={(e) =>
                  setCustomer({ ...customer, cidade: e.target.value })
                }
                fullWidth
                disabled
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
                autoComplete="new-password"
              />
            </Grid> */}
          </Grid>
          <DialogActions sx={{ justifyContent: "center" }}>
            <ThemeProvider theme={theme}>
              <NewTooltip
                title={
                  !checkInputs() &&
                  "Preencha os campos obrigatório para registrar a visita"
                }
                placement="top"
              >
                <span>
                  <Button
                    disableElevation
                    variant="contained"
                    color="success"
                    disabled={!checkInputs()}
                    sx={{ textTransform: "capitalize" }}
                    type="submit"
                  >
                    Registrar
                  </Button>
                </span>
              </NewTooltip>
              <Button
                disableElevation
                variant="contained"
                sx={{ textTransform: "capitalize" }}
                onClick={closeBox}
              >
                Cancelar
              </Button>
            </ThemeProvider>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default RegisterVisit;
