import React, { createContext, useContext, useState } from 'react';

// Criando o contexto
const RegisterInfoContext = createContext();

// Componente provedor que envolve toda a aplicação e fornece os dados do cadastro
export const RegisterInfoProvider = ({ children }) => {
  const [address, setAddress] = useState(null);
  const [documents, setDocuments] = useState([]);

  return (
    <RegisterInfoContext.Provider value={{ address, setAddress, documents, setDocuments }}>
      {children}
    </RegisterInfoContext.Provider>
  );
};

// Hook personalizado para acessar o contexto
export const useRegisterInfo = () => {
  const context = useContext(RegisterInfoContext);
  if (!context) {
    throw new Error('useRegisterInfo deve ser usado dentro de um AcceptProvider');
  }
  return context;
};
