import { useCallback } from "react";
import useUploadPhotos from "../hooks/useUploadPhotos";

const useUploadDocuments = () => {
  const { uploadImages } = useUploadPhotos();

  const uploadDocuments = useCallback(
    async (id, documents, folder, locale) => {
      if (!documents || documents.length === 0) return [];

      // Separar objetos que possuem 'file' e os que já possuem 'url'
      const filesToUpload = documents.filter((doc) => doc.file);
      const existingFiles = documents.filter((doc) => doc.url);

      if (filesToUpload.length === 0) return existingFiles;

      const uploadedFiles = await uploadImages(filesToUpload, folder, 0, id, locale);
      const mappedUploadedFiles = uploadedFiles.map(mapDocument);

      // Combinar arquivos que já possuem 'url' com os carregados
      return [...existingFiles, ...mappedUploadedFiles];
    },
    [uploadImages]
  );

  const mapDocument = (object) => ({
    url: object.downloadURL,
    ref: object.fullPath,
  });

  return { uploadDocuments };
};

export default useUploadDocuments;
