import { useState, useEffect, memo, useLayoutEffect, useRef } from 'react'
import styles from './styles.module.scss';
import { Button, IconButton, TextField, FormControl, Select, InputLabel, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, InputAdornment } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import moment from 'moment';

import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'; // Visita Comercial
import PeopleIcon from '@mui/icons-material/People'; // Tecnica + Comercial
import RestaurantIcon from '@mui/icons-material/Restaurant'; // Almoço
import EngineeringIcon from '@mui/icons-material/Engineering'; // Pós Venda
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { ReactComponent as CheckIcon } from "../../../images/icons/Check.svg";
import { IoCheckmarkDone } from "react-icons/io5";
import { FcGoogle } from "react-icons/fc";

import { theme } from '../../../data/theme';
import Swal from "sweetalert2";
import "cooltipz-css";
import { deleteDoc, doc, getDoc, onSnapshot, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';
import { dataBase } from '../../../firebase/database';
import axios from 'axios';

const EditVisit = ({ isLoaded, userRef, visitsAll, sellers, tecs, representativesRef }) => {
  const [info, setInfo] = useState({
    cliente: '',
    dataTexto: '',
    horarioTexto: '00:00',
    visitaNumero: 1800,
    consultoraTexto: '',
    representante: 'Nenhum',
    tecnicoTexto: 'Lucas',
    concessionaria: '',
    observacao: '',
    cidade: '',
  });
  const hoursRef = useRef(null);
  const [visit, setVisit] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [dataRef, setDataRef] = useState();
  const [visitsFindCount, setVisitsFindCount] = useState();
  const [visitsFind, setVisitsFind] = useState();
  const [saidaCliente, setSaidaCliente] = useState(undefined);
  const [propostas, setPropostas] = useState([{
    kit: '',
    ativo: false,
    valor_inicial: 0,
    valor_final: 0,
    comissao: 0
  }]);
  // const [ki, setki] = useState([]);
  // const [valorInicial, setValorInicial] = useState([]);
  const [saidaTexto, setSaidaTexto] = useState(undefined);
  const [chegadaTexto, setChegadaTexto] = useState(undefined);
  const [visits, setVisits] = useState([]);
  const [analista, setAnalista] = useState({ id: '', nome: 'Nenhuma' });
  const [analistas, setAnalistas] = useState([]);
  const [rotaTempo, setRotaTempo] = useState(undefined);
  // const [tempoTexto, setTempoTexto] = useState(undefined);
  // const [tempoTexto2, setTempoTexto2] = useState(undefined);
  const [tecRefUID, setTecRefUID] = useState(tecs[0]);
  const [sellerRef, setSellerRef] = useState(sellers[0]);
  // const [createVisitGroup, setCreateVisitGroup] = useState({ 
  //   check: false,
  //   info: {
  //     tempo: '0 minutos'
  //   }
  //  });
  const [monthNumber, setMonthNumber] = useState(String(new Date().getMonth() + 1).padStart(2, "0"));
  // eslint-disable-next-line no-unused-vars
  const [consultoras, setConsultoras] = useState([]);
  const [driver, setDriver] = useState(); // Para escolher o motorista/tecnico de acordo com o tipo de visita
  const navigate = useNavigate();
  const { idVisit } = useParams();
  const [position, setPosition] = useState({
    lat: -23.109655741119823,
    lng: -47.71509175415125
  });
  const [representatives, setRepresentatives] = useState([]);

  useEffect(() => {
    if (sellers) {
      setAnalistas(sellers.filter(data => data.nome_ref));
    }
  }, [sellers])

  useEffect(() => {
    const getVisit = async () => {
      onSnapshot(doc(dataBase, "Visitas", idVisit), (doc) => {
        setVisit(doc.data());
      });
    };
    getVisit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (representativesRef) {
      if (userRef && userRef.time && userRef.cargo === 'Vendedor(a)') {
        setRepresentatives(representativesRef.filter(data => data.grupo.time === userRef.time))
      } else {
        setRepresentatives(representativesRef);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [representativesRef])

  useLayoutEffect(() => {
    const fetchCustomerData = async () => {
      if (visit && visit.cliente_id && !customer) {
          try {
              const customerDoc = await getDoc(doc(dataBase, 'Clientes', visit.cliente_id));
              if (customerDoc.exists()) {
                  const customerRef = { id: customerDoc.id, ...customerDoc.data() };
                  setCustomer(customerRef);
              }
          } catch (error) {
              console.error("Error fetching customer data:", error);
          }
      }
  };

    // faz a solicitação do servidor assíncrono e preenche o formulário
    if (visit) {
      setTimeout(() => {
        setInfo({
          ...info,
          consultoraTexto: visit.consultora,
          cliente: visit.cliente,
          observacao: visit.observacao,
          visitaNumero: visit.visitaNumero,
          tecnicoTexto: visit.tecnico,
          dataTexto: moment(new Date(visit.dia)).format("YYYY-MM-DD"),
          representante: visit.indicador?.ref_indicador ?? 'Nenhum',
          veiculo: visit.veiculo,
          address: visit.endereco,
          concessionaria: visit.concessionaria ? visit.concessionaria : 'CPFL',
          cidade: visit.cidade,
          // numero: visit.numero,
          horarioTexto: visit.consultora === "lunch" ? visit.saidaEmpresa : visit.chegadaCliente
        })
        setSaidaTexto(visit.saidaEmpresa);
        setChegadaTexto(visit.chegadaEmpresa);
        setSaidaCliente(visit.chegadaCliente);
        if (visit.propostas) {
          setPropostas(Object.values(visit.propostas));
        }
        setPosition({
          lat: visit.lat,
          lng: visit.lng
        })
        if (visit.representante) {
          setAnalista({ id: visit.representante?.id, nome: visit.representante?.nome });
        }
        if (visit.tecnico !== 'Nenhum') {
          setTecRefUID(tecs.find((tec) => tec.nome === visit.tecnicoTexto));
        } else {
          setTecRefUID({ nome: 'Nenhum', uid: '000', veiculo: visit.veiculo });
        }
      }, 0);
      fetchCustomerData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visit]);

  const calculateCommission = (discount) => {
    if (discount >= 5) return 2;
    if (discount >= 4.5) return 2.5;
    if (discount >= 4) return 3;
    if (discount >= 3.5) return 3.5;
    if (discount >= 3) return 4;
    if (discount >= 2.5) return 4.5;
    if (discount >= 2) return 5;
    if (discount >= 1.5) return 5.5;
    if (discount >= 1) return 6;
    if (discount >= 0.5) return 6.5;
    return null; // Caso o desconto não se encaixe em nenhuma faixa, retorna nulo.
  };

  const handleCommissionValue = (valueInitial, valueEnd) => {
    const initial = parseFloat(valueInitial);
    const end = parseFloat(valueEnd);
    const discountPercentage = ((initial - end) / initial) * 100;
    // console.log(`Desconto concedido: ${discountPercentage}%`);

    const commissionPercentage = calculateCommission(discountPercentage);
    // setPercentage(commissionPercentage);
    let commissionValue = 0;

    if (commissionPercentage !== null) {
      commissionValue = (end * commissionPercentage) / 100;
    }

    // console.log(`Comissão devida: ${commissionValue}`);
    return { commissionValue, commissionPercentage };
  };

  console.log(propostas)

  function visitaNome(valor) {
    switch (valor) {
      case 'comercial':
        return 'Comercial';
      case 'comercial_tecnica':
        return 'Comercial + Técnica';
      case 'pos_venda':
        return 'Pós-Venda';
      default:
        return valor;
    }
  }

  // const { ref } = usePlacesWidget({
  //   apiKey: KeyMaps,
  //   onPlaceSelected: (place) => {
  //     const address = place.formatted_address;
  //     const cityRef = place.address_components.find(ref => ref.types[0] === 'administrative_area_level_2');
  //     // if (place.address_components[0].types[0] === "street_number") {
  //     //   const numberRef = place.address_components.find(ref => ref.types[0] === "street_number");
  //     //   setNumberAddress(numberRef.long_name);
  //     // } else {
  //     //   setNumberAddress(undefined);
  //     // }
  //     setCity(cityRef.long_name);
  //     setAddressComplete(address.substring(0, address.length - 19));
  //     setPosition({
  //       lat: place.geometry?.location?.lat(),
  //       lng: place.geometry?.location?.lng()
  //     })
  //     // setCheck(true); // Habilita o serviço de calculo de distancia do google
  //   },
  //   options: {
  //     types: ["geocode", "establishment"],
  //     componentRestrictions: { country: "br" },
  //     fields: ["formatted_address", "address_components", "geometry.location"],
  //   },
  // });



  useEffect(() => {
    if (info.tecnicoTexto) {
      setTecRefUID(tecs.find((tec) => tec.nome === info.tecnicoTexto));
    }
    if (info.consultoraTexto) {
      setSellerRef(sellers.find((sel) => sel.nome === info.consultoraTexto));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.dataTexto, info.tecnicoTexto, info.consultoraTexto]);

  useEffect(() => { // Seleciona a visita de acordo com o tipo escolhido
    const findTecs = async () => {
      let tecRef = '';
      let consultoraTexto = null;
      switch (visit && visit.categoria) {
        case 'lunch':
          setDriver(tecs.filter((ref) => ref.nome === userRef.nome))
          tecRef = userRef.nome;
          //driverRef.current = 'Bruna';
          break
        case 'comercial':
          setDriver(tecs.filter((ref) => ref.nome === "Bruna"))
          tecRef = 'Bruna';
          // consultoraTexto = 'Bruna';
          //driverRef.current = 'Bruna';
          break
        case 'comercial_tecnica':
          setDriver(tecs.filter((ref) => ref.nome === "Neto" || ref.nome === "Bruna" || ref.nome === "Lucas"))
          tecRef = 'Lucas';
          // consultoraTexto = 'Bruna';
          //driverRef.current = 'Lucas';
          break
        case 'pos_venda':
          setDriver(tecs.filter((ref) => ref.nome === "Neto"))
          tecRef = 'Lucas';
          consultoraTexto = 'Pós-Venda';
          break
        case 'tecnica':
          setDriver(tecs.filter((ref) => ref.nome === "Neto"))
          tecRef = 'Lucas';
          break
        default:
          setDriver(tecs)
          setVisits(visitsAll)
      }
      setInfo({
        ...info,
        tecnicoTexto: tecRef,
        consultoraTexto: consultoraTexto
      })
    };
    findTecs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visit]);

  console.log(info)

  useEffect(() => {
    if (visitsAll) {

      let visitsData;
      let visitsType;

      const filterByDateAndTechnician = (visit) => {
        const isLucasOrNeto = visit.tecnico === "Lucas" || visit.tecnico === "Neto" || visit.tecnico === "Bruna";
        const isTargetMonthYear = moment(visit.data).month() + 1 === parseInt(monthNumber) && moment(visit.data).year() === parseInt('2024');
        return isTargetMonthYear && isLucasOrNeto;
      };

      const filterByDateAndSeller = (visit) => {
        const isBruna = visit.tecnico === "Bruna";
        const isTargetMonthYear = moment(visit.data).month() + 1 === parseInt(monthNumber) && moment(visit.data).year() === parseInt('2024');
        return isTargetMonthYear && isBruna;
      };

      switch (visit && visit.categoria) {
        case 'comercial':
          visitsData = visitsAll.filter((visitR) => visitR.data === info.dataTexto && (visitR.tecnico !== "Lucas" && visitR.tecnico !== "Neto") && visitR.chegadaCliente !== visit.chegadaCliente);
          visitsType = visitsAll.filter(filterByDateAndSeller);
          break;
        case 'lunch':
          visitsData = visitsAll.filter((visitR) => visitR.data === info.dataTexto && visitR.chegadaCliente !== visit.chegadaCliente);
          visitsType = visitsAll.filter((visit) => moment(visit.data).month() + 1 === parseInt(monthNumber) && moment(visit.data).year() === parseInt('2024'));
          break;
        default:
          visitsData = visitsAll.filter((visitR) => visitR.data === info.dataTexto && (visitR.tecnico === "Lucas" || visitR.tecnico === "Neto" || visitR.categoria === 'lunch') && visitR.chegadaCliente !== visit.chegadaCliente);
          visitsType = visitsAll.filter(filterByDateAndTechnician);
      }

      if (info.dataTexto && info.dataTexto.substring(8, 10) !== "00") {
        setVisits(visitsData);
      } else {
        setVisits(visitsType);
      }
    }
  }, [info.dataTexto, visitsAll, monthNumber, visit]);

  useEffect(() => {
    if (sellers) {
      setConsultoras(sellers.filter(obj => obj.nome_ref !== 'Kauana' && obj.nome !== 'Ana' && obj.nome !== 'Leticia'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellers])

  // useEffect(() => {
  //     setTimeout(() => {
  //       let horarioTextRef = info.horarioTexto;

  //       setSaidaTexto(createVisitGroup.info.saidaDoCliente);
  //       setChegadaTexto(createVisitGroup.info.chegadaEmpresa);
  //       setInfo({
  //         ...info,
  //         dataTexto: moment(new Date(createVisitGroup.info.dia)).format('YYYY-MM-DD'),
  //         tecnicoTexto: createVisitGroup.info.tecnico
  //       })
  //       //setCity(visitRef.cidade);
  //       setPositionRef({
  //         lat: createVisitGroup.info.lat,
  //         lng: createVisitGroup.info.lng
  //       })
  //       if(createVisitGroup.type === 'depois') {
  //         const saida = moment(createVisitGroup.info.saidaDoCliente, "hh:mm")
  //         horarioTextRef = saida.add(rotaTempo2, 'seconds').format('kk:mm'); 
  //       } else if(createVisitGroup.type === 'antes') {
  //         const chegada = moment(createVisitGroup.info.chegadaCliente, "hh:mm").subtract(rotaTempo2, 'seconds') 
  //         horarioTextRef = chegada.subtract(info.visitaNumero, 'seconds').format('kk:mm');
  //       }

  //       setInfo({
  //         ...info,
  //         horarioTexto: horarioTextRef,
  //         dataTexto: moment(new Date(createVisitGroup.info.dia)).format('YYYY-MM-DD'),
  //         tecnicoTexto: createVisitGroup.info.tecnico
  //       })
  //     }, 0);
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [createVisitGroup, rotaTempo, info.visitaNumero]);

  useEffect(() => {
    // if (dataTexto) {
    //   filterSchedule(dataTexto, tecnicoTexto);
    // } else {
    //   filterSchedule();
    // }
    if (info.tecnicoTexto && info.tecnicoTexto !== 'Nenhum') setTecRefUID(tecs.find((tec) => tec.nome === info.tecnicoTexto));
    if (info.consultoraTexto) {
      setSellerRef(sellers.find((sel) => sel.nome === info.consultoraTexto));
    }
    let veiculo = '';
    if (tecRefUID && tecRefUID.nome !== 'Nenhum') {
      if (tecRefUID.nome !== visit.tecnico) {
        veiculo = tecRefUID.veiculo;
      } else {
        veiculo = visit.veiculo;
      }
      setInfo({
        ...info,
        veiculo: veiculo
      })
    }

    // Muda o filtro de busca das visitas de acordo com o dia escolhido
    if (info.dataTexto === visit.data) {
      setDataRef(
        visitsAll && visitsAll.filter(
          (dia) =>
            dia.data === info.dataTexto &&
            dia.tecnico === info.tecnicoTexto &&
            dia.chegadaCliente !== visit.chegadaCliente
        )
      );
    } else {
      setDataRef(
        visitsAll && visitsAll.filter(
          (dia) => dia.data === info.dataTexto && dia.tecnico === info.tecnicoTexto
        )
      );
    }

    // Atualiza o tempo de rota de acordo com o tipo de visita
    if (
      (info.dataTexto === visit.data && visit.visitaConjunta) ||
      (info.dataTexto === visit.data && visit.group)
    ) {
      if (
        (info.dataTexto === visit.data &&
          !visit.group &&
          visit.groupRef === "antes") ||
        (info.dataTexto === visit.data && visit.group === "depois")
      ) {
        // setTempoTexto(visit.tempoConjunta);
        setRotaTempo(visit.tempoRotaConjunta);
        // console.log("2");
      }
      if (
        (info.dataTexto === visit.data &&
          !visit.visitaConjunta &&
          !visit.group) ||
        (info.dataTexto === visit.data &&
          visit.visitaConjunta &&
          visit.groupRef === "depois" &&
          !visit.group) ||
        (info.dataTexto === visit.data &&
          visit.group === "antes" &&
          !visit.visitaConjunta)
      ) {
        // Primeira visita do 'antes'
        // setTempoTexto(visit.tempo);
        setRotaTempo(visit.tempoRota);
        // console.log("33333");
      }
    } else {
      if (
        (info.dataTexto !== visit.data &&
          !visit.group &&
          visit.groupRef === "antes") ||
        (info.dataTexto !== visit.data && visit.group === "depois") ||
        (info.dataTexto === visit.data && !visit.visitaConjunta)
      ) {
        // setTempoTexto(visit.tempo);
        setRotaTempo(visit.tempoRota);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.dataTexto, info.tecnicoTexto, info.horarioTexto, tecRefUID, info.consultoraTexto]);

  console.log(rotaTempo)

  // useEffect(() => {
  //   if (!createVisitGroup) return;

  //   let horarioTextRef = info.horarioTexto;
  //   let newData = createVisitGroup.info.dia ? moment(new Date(createVisitGroup.info.dia)).format('YYYY-MM-DD') : info.dataTexto;
  //   let newTecnicoTexto = createVisitGroup.info.tecnico ? createVisitGroup.info.tecnico : 'Lucas';
  //   let newPositionRef = {
  //     lat: createVisitGroup.info.lat,
  //     lng: createVisitGroup.info.lng
  //   };

  //   if (createVisitGroup.type === 'depois') {
  //     const saida = moment(createVisitGroup.info.saidaDoCliente, "hh:mm").add(rotaTempo2, 'seconds');
  //     horarioTextRef = saida.format('kk:mm');
  //   } else if (createVisitGroup.type === 'antes') {
  //     const chegada = moment(createVisitGroup.info.chegadaCliente, "hh:mm").subtract(rotaTempo2, 'seconds');
  //     horarioTextRef = chegada.subtract(info.visitaNumero, 'seconds').format('kk:mm');
  //   } else {
  //     horarioTextRef = info.horarioTexto;
  //   }

  //   setSaidaTexto(createVisitGroup.info.saidaDoCliente);
  //   setChegadaTexto(createVisitGroup.info.chegadaEmpresa);
  //   setInfo({
  //     ...info,
  //     dataTexto: newData,
  //     tecnicoTexto: newTecnicoTexto,
  //     horarioTexto: horarioTextRef
  //   });
  //   setPositionRef(newPositionRef);
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [createVisitGroup, rotaTempo2, info.visitaNumero]);

  useEffect(() => {
    if (visit) {
      moment.locale("pt-br");
      console.log(visit)
      const saidaEmpresa = moment(info.horarioTexto, "hh:mm"); //Horario de chegada
      const chegadaCliente = moment(info.horarioTexto, "hh:mm"); //Horario de chegada
      if ((visit.groupRef === 'depois' || visit.group === 'antes') && visit.data === info.dataTexto) {
        saidaEmpresa.subtract((visit.tempoRota), "seconds").format("hh:mm"); // Pega o tempo que o tecnico vai precisar sair da empresa
        setSaidaTexto(saidaEmpresa.format("kk:mm"));
        chegadaCliente.add(Number(info.visitaNumero), "seconds").format("hh:mm"); //Adiciona tempo de viagem volta
        setSaidaCliente(chegadaCliente.format("kk:mm"));
        setInfo({ ...info, horarioTexto: visit.chegadaCliente });
        // chegadaCliente.add((visit.tempoRotaConjunta + 60), "seconds").format("hh:mm"); //Adiciona tempo de viagem volta
        setChegadaTexto(visit.chegadaEmpresa);
      } else if ((visit.groupRef === 'antes' || visit.group === 'depois') && visit.data === info.dataTexto) {
        // saidaEmpresa.subtract((rotaTempo2 - 60), "seconds").format("hh:mm"); // Pega o tempo que o tecnico vai precisar sair da empresa
        setSaidaTexto(visit.saidaEmpresa);
        chegadaCliente.add(Number(info.visitaNumero), "seconds").format("hh:mm"); //Adiciona tempo de viagem volta
        setSaidaCliente(chegadaCliente.format("kk:mm"));
        setInfo({ ...info, horarioTexto: visit.chegadaCliente });
        // chegadaCliente.add(visit.tempoRotaConjunta, "seconds").format("hh:mm"); //Adiciona tempo de viagem volta
        setChegadaTexto(visit.chegadaEmpresa);
      } else {
        saidaEmpresa.subtract(rotaTempo, "seconds").format("hh:mm"); // Pega o tempo que o tecnico vai precisar sair da empresa
        setSaidaTexto(saidaEmpresa.format("kk:mm"));
        chegadaCliente.add(info.visitaNumero, "seconds").format("hh:mm"); //Adiciona tempo de viagem volta
        setSaidaCliente(chegadaCliente.format("kk:mm"));
        chegadaCliente.add(rotaTempo, "seconds").format("hh:mm"); //Adiciona tempo de viagem volta
        setChegadaTexto(chegadaCliente.format("kk:mm"));
      }

      let saidaEmpresaRef, ChegadaEmpresaRef;
      moment.locale("pt-br");
      saidaEmpresaRef = saidaTexto;
      ChegadaEmpresaRef = chegadaTexto;

      const saidaFormatada = moment(saidaEmpresaRef, "hh:mm");
      const chegadaFormatada = moment(ChegadaEmpresaRef, "hh:mm");
      console.log(saidaFormatada)
      const check = [];
      let visitsFindData = [];

      // const dataRefVisit = visitsAll && visitsAll.filter(
      //     (dia) => dia.data === info && info.dataTexto &&
      //     (dia.tecnicoUID === (tecRefUID && tecRefUID.uid) || (dia.categoria === 'lunch' && dia.tecnico === (info && info.consultoraTexto)))
      //     );

      console.log(dataRef);


      dataRef && dataRef.map((ref) => {
        if (
          saidaFormatada <= moment(ref.saidaEmpresa, "hh:mm") &&
          chegadaFormatada <= moment(ref.saidaEmpresa, "hh:mm")
        ) {
          check.push(ref);
        } else {
          if (saidaFormatada >= moment(ref.chegadaEmpresa, "hh:mm"))
            check.push(ref);
        }
        return dataRef;
      });

      dataRef && dataRef.map((a) => {
        //Percorre todos os arrays de 'dataRef' e compara se os arrays são iguais
        if (check.includes(a) === false) {
          visitsFindData.push(a);
        }
        return visitsFindData;
      });
      console.log(check);
      setVisitsFindCount(dataRef && dataRef.length - check.length);
      setVisitsFind(visitsFindData);

      console.log(visitsFind);
      console.log(visitsFindCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.horarioTexto, info.visitaNumero, chegadaTexto, saidaTexto, rotaTempo, info.dataTexto, dataRef]);

  // const onSubmit = async (e) => {
  //   try {
  //     e.preventDefault();
  //     console.log(createVisitGroup.info.chegadaCliente);
  //     let c = 1;
  //     if (visitsFindCount < 0 || visitsFindCount > 0) {
  //       const visits = visitsFind.map(
  //         (e) =>
  //           `Visita <b>` +
  //           c++ +
  //           "</b> - Saida: <b>" +
  //           e.saidaEmpresa +
  //           "</b> Chegada: <b>" +
  //           e.chegadaEmpresa +
  //           "</b></br>"
  //       );
  //       Swal.fire({
  //         title: 'Visitas',
  //         html:
  //           `Foram encontrado(s) <b>${visitsFindCount}</b> visita(s) marcada(s) nesse periodo.</br></br>` +
  //           visits,
  //         icon: "error",
  //         showConfirmButton: true,
  //         confirmButtonColor: "#111",
  //       });
  //     } else {
  //       Swal.fire({
  //         title: 'Visitas',
  //         html: `Você deseja cadastrar uma nova <b>Visita?</b>`,
  //         icon: "question",
  //         showCancelButton: true,
  //         showCloseButton: true,
  //         confirmButtonColor: "#0eb05f",
  //         cancelButtonColor: "#d33",
  //         confirmButtonText: "Sim",
  //         cancelButtonText: "Não",
  //       }).then(async (result) => {
  //           if (result.isConfirmed) {
  //             const changePropostas = propostas.map(data => (
  //               {
  //                 ativo: data.ativo,
  //                 kit: data.kit,
  //                 valor_inicial: data.valor_inicial,
  //                 valor_final: data.valor_final,
  //                 comissao: handleComissionValue(data.valor_inicial, data.valor_final)
  //               }
  //             ))

  //             // if(createVisitGroup.check === true) {
  //             //   visitGroup('antes', changePropostas);
  //             // } else {
  //             //   visitOnly(changePropostas);
  //             // }
  //           }
  //         })
  //     };
  //   } catch (error) {
  //   }
  // };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      if(isAfterPermittedHours(info.horarioTexto)) {
        return Swal.fire({
           title: 'Atenção!',
           html: `O horário permitido para marcar uma <b>Visita Comercial + Técnica</b> é entre ás <b>06:00 da manhã até 18:00 da tarde</b>.`,
           icon: "warning",
           showConfirmButton: true,
           showCloseButton: true,
           confirmButtonColor: "#111",
         }).then((result) => {
           if(result.isConfirmed) {
             setTimeout(() => {
               hoursRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
               hoursRef.current.focus();
             }, 500);
           }
         });
       }

      let c = 1;
      if (visitsFindCount < 0 || visitsFindCount > 0) {
        const visits = visitsFind.map(
          (e) =>
            `Visita <b>` +
            c++ +
            "</b> - Saida: <b>" +
            e.saidaEmpresa +
            "</b> Chegada: <b>" +
            e.chegadaEmpresa +
            "</b></br>"
        );
        Swal.fire({
          title: 'Visita',
          html:
            `Foram encontrado(s) <b>${visitsFindCount}</b> visita(s) marcada(s) nesse periodo.</br></br>` +
            visits,
          icon: "warning",
          showConfirmButton: true,
          showCloseButton: true,
          confirmButtonColor: "#111",
        });
      } else {
        let msg1 = '';
        let findPropostaAtiveVisit = null;
        if (visit.propostas) {
          findPropostaAtiveVisit = visit.propostas.find(data => data.ativo === true); // Procura alguma proposta já ativa na visita
        }
        console.log(findPropostaAtiveVisit)
        const findPropostaAtive = propostas.find(data => data.ativo === true);
        console.log(findPropostaAtive)
        if (findPropostaAtiveVisit && !findPropostaAtive) {
          msg1 = '<br /><br /><b>Atenção:</b> A visita contém <b>1 proposta ativa com comissão</b>, porém você desativou a proposta.' +
            'Caso salve a visita <b>sem nenhuma proposta ativa</b>, a comissão do representante será <b>excluida!</b>'
        }
        Swal.fire({
          title: 'Visita',
          html: `Você deseja alterar essa <b>Visita?</b> ${msg1}`,
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: "#0eb05f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const changePropostas = propostas.map(data => {
              const { commissionPercentage, commissionValue } = handleCommissionValue(data.valor_inicial, data.valor_final);
              return {
                ativo: data.ativo,
                kit: data.kit,
                valor_inicial: (typeof data.valor_inicial === 'number' ? data.valor_inicial : Number(data.valor_inicial.replace(',', '.'))),
                valor_final: (typeof data.valor_final === 'number' ? data.valor_final : Number(data.valor_final.replace(',', '.'))),
                comissao: {
                  valor: commissionValue,
                  porcentagem: commissionPercentage,
                  status: ''
                },
              }
            })
            let propsRepresentative = {};
            const indicadorRef = representatives.find((data) => data.id_representante === info.representante);
            if (visit.indicador?.nome === 'Nenhum' && info.representante !== 'Nenhum') {
              propsRepresentative = {
                id: indicadorRef.id,
                nome: indicadorRef.nome,
                ref_indicador: indicadorRef.id_representante,
                telefone: indicadorRef.telefone
              }
            } else {
              propsRepresentative = {
                ...visit.indicador
              }
            }

            const propostaAtive = changePropostas.find(data => data.ativo === true);
            const docComission = await getDoc(doc(dataBase, 'Comissoes', idVisit));
            const consultoraRef = await sellers.find(data => data.nome === info.consultoraTexto);
            if (docComission.exists()) {
              console.log(docComission.data())
              if (findPropostaAtiveVisit && !findPropostaAtive) {
                await deleteDoc(doc(dataBase, 'Comissoes', idVisit));
              } else {
                await updateDoc(doc(dataBase, 'Comissoes', idVisit), {
                  ...propostaAtive,
                  backoffice_id: consultoraRef.id,
                  visita_data: info.dataTexto,
                  nome: info.cliente,
                  data: moment().format('DD/MM/YYYY'),
                })
              }
            }
            else if (propostaAtive) {
              await setDoc(doc(dataBase, "Comissoes", idVisit), {
                ...propostaAtive,
                representante: {
                  ...propsRepresentative
                },
                backoffice_id: consultoraRef.id,
                nome: visit.cliente,
                visita_data: visit.dia,
                etapa: 'Faturamento Pendente',
                data: moment().format('DD/MM/YYYY'),
                createAt: serverTimestamp()
              })
            }
            const scheduleRefUID = doc(dataBase, 'Visitas', idVisit);
            if (visit.data !== info.dataTexto) {
              const visitsAntes = visitsAll.filter(
                (ref) =>
                  ref.data === visit.data &&
                  ref.chegadaEmpresa === visit.saidaEmpresa &&
                  ref.tipo !== "Almoço" &&
                  !ref.visitaAlmoco

              );
              const visitsDepois = visitsAll.filter(
                (ref) =>
                  ref.data === visit.data &&
                  ref.saidaEmpresa === visit.chegadaEmpresa &&
                  ref.tipo !== "Almoço" &&
                  !ref.visitaAlmoco
              );

              if (visitsAntes.length > 0) {
                visitsAntes.map(async (ref) => {
                  const visitBefore = visitsAll.filter(before => (before.data === ref.data && before.chegadaEmpresa === ref.saidaEmpresa && ref.categoria !== 'lunch' && before.tipo === "Visita Conjunta" && !before.visitaAlmoco));
                  if (ref.cidade === visit.cidade) {
                    if (visitBefore) {
                      visitBefore.map(async (ref) => {
                        await updateDoc(doc(dataBase, "Visitas", ref.id),
                          {
                            chegadaEmpresa: moment(ref.saidaDoCliente, "hh:mm").add(ref.tempoRota, 'seconds').format('kk:mm'),
                            groupRef: "",
                            group: "",
                            visitaConjunta: false,
                            tipo: "Visita"
                          })
                      })
                    }
                    await deleteDoc(
                      doc(dataBase, "Visitas", ref.id)
                    );
                  } else {
                    await updateDoc(doc(dataBase, "Visitas", ref.id),
                      {
                        chegadaEmpresa: moment(ref.saidaDoCliente, "hh:mm").add(ref.tempoRota, 'seconds').format('kk:mm'),
                        groupRef: "",
                        group: "",
                        visitaConjunta: false,
                        tipo: "Visita"
                      })
                  }

                })
              }
              if (visitsDepois.length > 0) {
                visitsDepois.map(async (ref) => {
                  const visitNext = visitsAll.filter(next => (next.data === ref.data && next.saidaEmpresa === ref.chegadaEmpresa && ref.categoria !== 'lunch' && next.tipo === "Visita Conjunta" && !next.visitaAlmoco));
                  if (ref.cidade === visit.cidade) {
                    if (visitNext) {
                      visitNext.map(async (ref) => {
                        await updateDoc(doc(dataBase, "Visitas", ref.id),
                          {
                            saidaEmpresa: moment(ref.chegadaCliente, "hh:mm").subtract(ref.tempoRota, 'seconds').format('kk:mm'),
                            groupRef: "",
                            group: "",
                            visitaConjunta: false,
                            tipo: "Visita"
                          })
                      })
                    }
                    await deleteDoc(
                      doc(dataBase, "Visitas", ref.id)
                    );
                  } else {
                    await updateDoc(doc(dataBase, "Visitas", ref.id),
                      {
                        saidaEmpresa: moment(ref.chegadaCliente, "hh:mm").subtract(ref.tempoRota, 'seconds').format('kk:mm'),
                        groupRef: "",
                        group: "",
                        visitaConjunta: false,
                        tipo: "Visita"
                      })
                  }

                })
              }
            }
            if (visit.categoria === "lunch") {
              await updateDoc(scheduleRefUID, {
                dia: moment(info.dataTexto).format("YYYY MM DD"),
                saidaEmpresa: saidaTexto,
                chegadaCliente: saidaTexto,
                visita: "01:00",
                visitaNumero: 3600,
                saidaDoCliente: chegadaTexto,
                chegadaEmpresa: chegadaTexto,
                consultora: tecRefUID.nome,
                tecnico: tecRefUID.nome,
                tecnicoUID: tecRefUID.uid,
                cidade: "",
                tempoRota: "",
                tempo: "",
                cliente: "",
                observacao: info.observacao,
                data: info.dataTexto,
                cor: tecRefUID.cor,
                confirmar: false,
                categoria: 'lunch',
                corTec: tecRefUID.cor,
                updateVisit: new Date(),
                dataRef: new Date(`${info.dataTexto}T${saidaTexto}`)
              });
            }
            if (
              visit.data !== info.dataTexto && // Visita editada para outro dia
              visit.categoria !== "lunch"
            ) {
              let props = {
                dia: moment(info.dataTexto).format("YYYY MM DD"),
                data: info.dataTexto,
                saidaEmpresa: saidaTexto,
                chegadaCliente: info.horarioTexto,
                visita: moment("00:00", "HH:mm").add(info.visitaNumero, "seconds").format("HH:mm"),
                visitaNumero: info.visitaNumero,
                saidaDoCliente: saidaCliente,
                chegadaEmpresa: chegadaTexto,
                tecnico: tecRefUID.nome,
                tecnicoUID: tecRefUID.uid,
                veiculo: info.veiculo,
                cidade: visit.cidade,
                cliente: info.cliente,
                observacao: info.observacao,
                consultora: info.consultoraTexto,
                uid: sellerRef.id,
                cor: sellerRef.cor,
                visitaConjunta: false,
                groupRef: "",
                group: "",
                tipo: "Visita",
                categoria: visit.categoria,
                corTec: tecRefUID.cor,
                updateVisit: new Date(),
                dataRef: new Date(`${info.dataTexto}T${info.horarioTexto}`),
                concessionaria: info.concessionaria,
                endereco: info.address,
                indicador: {
                  ...propsRepresentative
                }
              }
              if (analista.nome !== 'Nenhuma') {
                props.representante = analista
              }
              if (info.representante !== 'Nenhum') {
                props.propostas = [
                  ...propostas
                ]
              }
              console.log(props)
              await updateDoc(
                doc(dataBase, "Visitas", idVisit),
                { ...props }
              );
            } else if (
              visit.data === info.dataTexto && // Visita editada para o mesmo dia
              visit.categoria !== "lunch"
            ) {
              let props = {
                dia: moment(info.dataTexto).format("YYYY MM DD"),
                data: info.dataTexto,
                saidaEmpresa: saidaTexto,
                chegadaCliente: info.horarioTexto,
                visita: moment("00:00", "HH:mm").add(info.visitaNumero, "seconds").format("HH:mm"),
                visitaNumero: info.visitaNumero,
                saidaDoCliente: saidaCliente,
                chegadaEmpresa: chegadaTexto,
                tecnico: tecRefUID.nome,
                tecnicoUID: tecRefUID.uid,
                veiculo: info.veiculo,
                cidade: visit.cidade,
                cliente: info.cliente,
                observacao: info.observacao,
                consultora: info.consultoraTexto,
                uid: sellerRef.id,
                cor: sellerRef.cor,
                categoria: visit.categoria, //Alterar
                corTec: tecRefUID.cor,
                updateVisit: new Date(),
                dataRef: new Date(`${info.dataTexto}T${info.horarioTexto}`),
                concessionaria: info.concessionaria,
                endereco: info.address,
                indicador: {
                  ...propsRepresentative
                }
              }

              if (analista.nome !== 'Nenhuma') {
                props.representante = analista
              }

              if (info.representante !== 'Nenhum') {
                props.propostas = [
                  ...propostas
                ]
              }

              await updateDoc(
                doc(dataBase, "Visitas", idVisit),
                { ...props }
              );
            }
            if (visit.categoria !== "lunch") {
              const date = new Date(visit.data);
              axios.post('https://n8n.corpbrasil.cloud/webhook/63b48297-3e22-4eba-8b21-45e87f52f3fb', {
                ID: idVisit,
                data: moment(visit.data).format("DD/MM/YYYY"),
                nome: tecRefUID.nome,
                cliente: info.cliente,
                marcado: info.horarioTexto,
                consultora: info.consultoraTexto,
                city: visit.cidade,
                semana: getMonthlyWeekNumber(date),
                mes: moment(visit.data).format("M"),
                ende: visit.endereco,
                confirmada: 'Não',
                categoria: visit.categoria,
                extra: visit.preData,
              })
            }
            Swal.fire({
              title: 'Visita',
              html: `A visita em <b>${visit.cidade}</b> foi alterada com sucesso.`,
              icon: "success",
              showConfirmButton: true,
              showCloseButton: true,
              confirmButtonColor: "#111",
            }).then(() => {
              navigate(`/visitas/${idVisit}`);
              window.scrollTo(0, 0);
            });
          }
        });
      }

    } catch (error) {
      // console.log(error);
    }
  };

  console.log(visitsFind)
  console.log(visitsFindCount)

  function getMonthlyWeekNumber(dt) {
    // como função interna, permite reuso
    var getmonweek = function (myDate) {
      var today = new Date(myDate.getFullYear(), myDate.getMonth(), myDate.getDate(), 0, 0, 0);
      var first_of_month = new Date(myDate.getFullYear(), myDate.getMonth(), 1, 0, 0, 0);
      var p = Math.floor((today.getTime() - first_of_month.getTime()) / 1000 / 60 / 60 / 24 / 7);
      // ajuste de contagem
      if (today.getDay() < first_of_month.getDay()) ++p;
      // ISO 8601.
      if (first_of_month.getDay() <= 3) p++;
      return p;
    }
    // último dia do mês
    var udm = (new Date(dt.getFullYear(), dt.getMonth() + 1, 0, 0, 0, 0)).getDate();
    /*  Nos seis primeiros dias de um mês: verifica se estamos antes do primeiro Domingo.
     *  Caso positivo, usa o último dia do mês anterior para o cálculo.
     */
    if ((dt.getDate() < 7) && ((dt.getDate() - dt.getDay()) < -2))
      return getmonweek(new Date(dt.getFullYear(), dt.getMonth(), 0));
    /*  Nos seis últimos dias de um mês: verifica se estamos dentro ou depois do último Domingo.
     *  Caso positivo, retorna 1 "de pronto".
     */
    else if ((dt.getDate() > (udm - 6)) && ((dt.getDate() - dt.getDay()) > (udm - 3)))
      return 1;
    else
      return getmonweek(dt);
  }

  // const visitGroup = async (direction, proposta) => {
  //   try {
  //     let SaidaClienteRef2;
  //     const visitRef = createVisitGroup.info;
  //     const scheduleVisitRef = createVisitGroup.ref;
  //     if(direction === 'antes') { // Verifica se existe uma referencia de visita abaixo da visita a ser criada
  //       if(visitRef.categoria === "lunch") {
  //         SaidaClienteRef2 = visitRef.saidaEmpresa;
  //       } else {
  //         await updateDoc(scheduleVisitRef, {
  //           saidaEmpresa: visitRef.chegadaCliente,
  //           tempoRotaConjunta: rotaTempo2,
  //           tempoConjunta: tempoTexto2,
  //           groupRef: 'antes',
  //           visitaConjunta: true,
  //           tipo: "Visita Conjunta"
  //          })
  //          SaidaClienteRef2 = visitRef.chegadaCliente;
  //       }
  //       const visita = {
  //         ...{preData},
  //         dia: moment(info.dataTexto).format("YYYY MM DD"),
  //         saidaEmpresa: saidaTexto,
  //         chegadaCliente: info.horarioTexto,
  //         visita: moment('00:00', "HH:mm").add(info.visitaNumero, 'seconds').format('HH:mm'),
  //         visitaNumero: info.visitaNumero,
  //         saidaDoCliente: saidaCliente,
  //         chegadaEmpresa: SaidaClienteRef2,
  //         chegadaEmpresaRef: visitRef.chegadaEmpresa,
  //         saidaEmpresaRef: visitRef.saidaEmpresa,
  //         consultora: info.consultoraTexto,
  //         uid: sellerRef.id,
  //         cor: sellerRef.cor,
  //         tecnico: tecRefUID.nome,
  //         tecnicoUID: tecRefUID.uid,
  //         veiculo: tecRefUID.veiculo,
  //         cidade: city,
  //         endereco: addressComplete,
  //         cliente: info.cliente,
  //         observacao: info.observacao,
  //         tempoRota: rotaTempo,
  //         tempo: tempoTexto,
  //         tempoRotaConjunta: rotaTempo2,
  //         tempoConjunta: tempoTexto2,
  //         lng: position.lng,
  //         lat: position.lat,
  //         data: info.dataTexto,
  //         idRef: visitRef.id,
  //         group: 'antes',
  //         confirmar: false,
  //         tipo: "Visita Conjunta",
  //         categoria: type,
  //         corTec: tecRefUID.cor,
  //         createVisit: new Date(),
  //         dataRef: new Date(`${info.dataTexto}T${info.horarioTexto}`),
  //         concessionaria: info.concessionaria
  //       };
  //       createVisit(visita, proposta)
  //     } else {
  //       if(visitRef.categoria !== "lunch") {
  //         await updateDoc(scheduleVisitRef, {
  //           chegadaEmpresa: createVisitGroup.info.saidaDoCliente,
  //           groupRef: 'depois',
  //           visitaConjunta: true,
  //           tipo: "Visita Conjunta"
  //          })
  //       } 
  //         //====================================== DEPOIS
  //         const visita = {
  //           ...{preData},
  //           dia: moment(info.dataTexto).format("YYYY MM DD"),
  //           saidaEmpresa: visitRef.tipo === "Almoço" ? visitRef.saidaDoCliente : saidaTexto,
  //           chegadaCliente: info.horarioTexto,
  //           visita: moment('00:00', "HH:mm").add(info.visitaNumero, 'seconds').format('HH:mm'),
  //           visitaNumero: info.visitaNumero,
  //           saidaDoCliente: saidaCliente,
  //           chegadaEmpresa: chegadaTexto,
  //           consultora: info.consultoraTexto,
  //           uid: sellerRef.id,
  //           cor: sellerRef.cor,
  //           tecnico: tecRefUID.nome,
  //           tecnicoUID: tecRefUID.uid,
  //           cidade: city,
  //           endereco: addressComplete,
  //           veiculo: tecRefUID.veiculo,
  //           lat: position.lat,
  //           lng: position.lng,
  //           cliente: info.cliente,
  //           observacao: info.observacao,
  //           tempoRota: rotaTempo2,
  //           tempo: tempoTexto2,
  //           tempoRotaConjunta: rotaTempo,
  //           tempoConjunta: tempoTexto,
  //           lngRef: position.lng,
  //           latRef: position.lat,
  //           data: info.dataTexto,
  //           confirmar: false,
  //           group: 'depois',
  //           visitaConjunta: true,
  //           tipo: 'Visita Conjunta',
  //           categoria: type,
  //           corTec: tecRefUID.cor,
  //           createVisit: new Date(),
  //           dataRef: new Date(`${info.dataTexto}T${info.horarioTexto}`),
  //           concessionaria: info.concessionaria
  //         };

  //         createVisit(visita, proposta)
  //     }
  //   } catch (error) {

  //   }
  // }

  // const visitOnly = (proposta) => {  

  //     const indicadorRef = representatives.find((data) => data.id_representante === info.representante);
  //     const visita = {
  //       ...{preData},
  //       dia: moment(info.dataTexto).format("YYYY MM DD"),
  //       saidaEmpresa: saidaTexto,
  //       chegadaCliente: info.horarioTexto,
  //       visita: moment('00:00', "HH:mm").add(info.visitaNumero, 'seconds').format('HH:mm'),
  //       visitaNumero: info.visitaNumero,
  //       saidaDoCliente: saidaCliente,
  //       chegadaEmpresa: chegadaTexto,
  //       consultora: info.consultoraTexto,
  //       uid: sellerRef.id,
  //       cor: sellerRef.cor,
  //       tecnico: tecRefUID.nome,
  //       tecnicoUID: tecRefUID.uid,
  //       cidade: city,
  //       endereco: addressComplete,
  //       veiculo: tecRefUID.veiculo,
  //       lat: position.lat,
  //       lng: position.lng,
  //       cliente: info.cliente,
  //       observacao: info.observacao,
  //       tempoRota: rotaTempo,
  //       tempo: tempoTexto,
  //       data: info.dataTexto,
  //       confirmar: false,
  //       tipo: 'Visita',
  //       categoria: type,
  //       corTec: tecRefUID.cor,
  //       createVisit: new Date(),
  //       dataRef: new Date(`${info.dataTexto}T${info.horarioTexto}`),
  //       concessionaria: info.concessionaria,
  //       indicador: indicadorRef ? {
  //         nome: indicadorRef.nome,
  //         id: indicadorRef.uid,
  //         ref_indicador: indicadorRef.id_representante,
  //         cidade: indicadorRef.cidade,
  //         telefone: indicadorRef.telefone
  //       } : {
  //         nome: 'Nenhum',
  //         id: '0',
  //         ref_indicador: 'Nenhum'
  //       }
  //     };
  //     createVisit(visita, proposta);
  // }

  // const addVisit = (ref) => {
  //   Swal.fire({
  //     title: 'Visitas',
  //     html: `Você deseja que a Visita seja conectada <b>antes</b> ou <b>depois</b> da Visita escolhida? </br></br>
  //     <b>Atenção: Verifique se já existe alguma Visita criada proxima a Visita escolhida.</b>`,
  //     icon: "question",
  //     showDenyButton: true,
  //     showCloseButton: true,
  //     confirmButtonColor: "#0eb05f",
  //     confirmButtonText: "Antes",
  //     denyButtonText: `Depois`,
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       let msg;
  //       const antes = findVisits("antes", ref);
  //       if (antes.length > 0) {
  //         if (antes[0].tipo === "Almoço") {
  //           msg = "um <b>Almoço</b> criado";
  //         } else {
  //           msg = "uma <b>Visita</b> criada";
  //         }
  //         Swal.fire({
  //           title: 'Visitas',
  //           html:
  //             `Já existe ${msg} acima dessa visita.<br/>` +
  //             `<b>Exclua</b> ou <b>Altere</b> ${
  //               antes[0].tipo === "Almoço"
  //                 ? "o <b>Almoço</b>"
  //                 : "a <b>Visita</b>"
  //             } para poder criar uma <b>Visita Conjunta</b>`,
  //           icon: "warning",
  //           showConfirmButton: true,
  //           showCloseButton: true,
  //           confirmButtonColor: "#111",
  //         });
  //       } else {
  //         if(userRef && userRef.nome === 'Pós-Venda') {
  //           setTimeout(() => {
  //             setCreateVisitGroup({
  //               check: true,
  //               type: "antes",
  //               info: ref,
  //               typeRef: 'pos_venda',
  //               ref: doc(dataBase, "Visitas", ref.id)
  //             });
  //           }, 400);
  //         } else {
  //           setTimeout(() => {
  //             setCreateVisitGroup({
  //               check: true,
  //               type: "antes",
  //               info: ref,
  //               typeRef: type,
  //               ref: doc(dataBase, "Visitas", ref.id),
  //             });
  //           }, 400);
  //         }
  //       }
  //     } else if (result.isDenied) {
  //       let msg;
  //       const depois = findVisits("depois", ref);
  //       if (depois.length > 0) {
  //         if (depois[0].tipo === "Almoço") {
  //           msg = "um <b>Almoço</b> criado";
  //         } else {
  //           msg = "uma <b>Visita</b> criada";
  //         }
  //         Swal.fire({
  //           title: 'Visitas',
  //           html:
  //             `Já existe ${msg} abaixo dessa visita.<br/>` +
  //             `<b>Exclua</b> ou <b>Altere</b> ${
  //               depois[0].tipo === "Almoço"
  //                 ? "o <b>Almoço</b>" 
  //                 : "a <b>Visita</b>"
  //             } para poder criar uma <b>Visita Conjunta</b>`,
  //           icon: "warning",
  //           showConfirmButton: true,
  //           showCloseButton: true,
  //           confirmButtonColor: "#111",
  //         });
  //       } else {
  //         setTimeout(() => {
  //           setCreateVisitGroup({
  //             check: true,
  //             type: "depois",
  //             info: ref,
  //             typeRef: type,
  //             ref: doc(dataBase, "Visitas", ref.id)
  //           });
  //         }, 400);
  //       }
  //     }
  //   })
  // }

  // const removeVisit = (ref) => {
  //   Swal.fire({
  //     title: 'Visitas',
  //     html: `Você deseja desconectar essa <b>Visita?</b>`,
  //     icon: "question",
  //     showCancelButton: true,
  //     showCloseButton: true,
  //     confirmButtonColor: "#0eb05f",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Sim",
  //     cancelButtonText: "Não",
  //   }).then(async (result) => {
  //     if(result.isConfirmed) {
  //       setCreateVisitGroup({ 
  //         check: false,
  //         info: {
  //           tempo: '0 minutos'
  //         }
  //        });
  //       Swal.fire({
  //         title: 'Visitas',
  //         html: `A Visita foi <b>desconectada</b> com sucesso.`,
  //         icon: "success",
  //         showConfirmButton: true,
  //         showCloseButton: true,
  //         confirmButtonColor: "#111",
  //       })
  //     }
  //   })
  // }

  // const findVisits = (type, visit) => {
  //   if (type === "antes")
  //     return visitsAll && visitsAll.filter(
  //       (ref) =>
  //         ref.data === visit.data && ref.chegadaEmpresa === visit.saidaEmpresa &&
  //         ref.tecnico === visit.tecnico && ref.tipo !== "Almoço" && !ref.visitaAlmoco
  //     );
  //   if (type === "depois")
  //     return visitsAll && visitsAll.filter(
  //       (ref) =>
  //         ref.data === visit.data && ref.saidaEmpresa === visit.chegadaEmpresa &&
  //         ref.tecnico === visit.tecnico && ref.tipo !== "Almoço" && !ref.visitaAlmoco
  //     );
  // };

  // const handlePropostaChange = (index, field, value) => {
  //   setPropostas(currentPropostas => {
  //     const newPropostas = [...currentPropostas];
  //     const newProposta = { ...newPropostas[index], [field]: value };
  //     newPropostas[index] = newProposta;
  //     return newPropostas;
  //   });
  // };

  // const addNewProposta = () => {
  //   // Define o objeto com os valores iniciais para a nova proposta
  //   const newProposta = {
  //     kit: '',
  //     ativo: false,
  //     valor_inicial: '',
  //     valor_final: '',
  //     comissao: 0
  //   };

  //   // Atualiza o estado para incluir a nova proposta no array de propostas
  //   setPropostas(currentPropostas => [...currentPropostas, newProposta]);
  // };

  // const removeProposta = (index) => {
  //   Swal.fire({
  //     title: 'Proposta',
  //     html: `Você deseja remover a <b>Proposta?</b>`,
  //     icon: "question",
  //     showCancelButton: true,
  //     showCloseButton: true,
  //     confirmButtonColor: "#0eb05f",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Sim",
  //     cancelButtonText: "Não",
  //   }).then(async (result) => {
  //       if (result.isConfirmed) {
  //         setPropostas(currentPropostas => currentPropostas.filter((_, i) => i !== index));
  //       }})
  // };

  // const ativeProposta = (index) => {
  //   if(propostas[index].ativo === true) {
  //     Swal.fire({
  //       title: 'Proposta',
  //       html: `Você deseja <b>desmarcar</b> a Proposta como <b>Aceita</b>?`,
  //       icon: "question",
  //       showCancelButton: true,
  //       showCloseButton: true,
  //       confirmButtonColor: "#0eb05f",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Sim",
  //       cancelButtonText: "Não",
  //     }).then(async (result) => {
  //         if (result.isConfirmed) {
  //           setPropostas(setInativo(propostas, index));
  //         }})
  //   } else {
  //     Swal.fire({
  //       title: 'Proposta',
  //       html: `Você deseja <b>marcar</b> a Proposta como <b>Aceita</b>?`,
  //       icon: "question",
  //       showCancelButton: true,
  //       showCloseButton: true,
  //       confirmButtonColor: "#0eb05f",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Sim",
  //       cancelButtonText: "Não",
  //     }).then(async (result) => {
  //         if (result.isConfirmed) {
  //           setPropostas(setAtivo(propostas, index));
  //           Swal.fire({
  //             title: 'Proposta',
  //             html: `A Proposta foi marcada com sucesso como <b>Aceita</b>.`,
  //             icon: "success",
  //             showConfirmButton: true,
  //             showCloseButton: true,
  //             confirmButtonColor: "#111",
  //           })
  //         }})
  //   }
  // };

  // const setAtivo = (propostasRef, indexAtivo) => {
  //   return propostasRef.map((proposta, index) => ({
  //     ...proposta,
  //     ativo: index === indexAtivo,
  //   }));
  // };

  // const setInativo = (propostasRef, indexAtivo) => {
  //   return propostasRef.map((proposta, index) => ({
  //     ...proposta,
  //     ativo: index === indexAtivo ? !proposta.ativo : proposta.ativo,
  //   }));
  // };

  const handleAnalista = (value) => {
    console.log(value)
    if (value === 'Nenhuma') {
      setAnalista({ id: '', nome: 'Nenhuma' })
    } else {
      const analistaRef = analistas.find(data => data.nome_ref === value);
      setAnalista({ id: analistaRef.id, nome: analistaRef.nome_ref })
    }
  }

  const returnPage = () => {
    navigate(`/visitas/${idVisit}`);
    window.scrollTo(0, 0);
  }

  const isAfterPermittedHours = (time) => {
    const inputTime = moment(time, "HH:mm");
    const sixPM = moment("18:00", "HH:mm");
    const sixAM = moment("06:00", "HH:mm");
  
    if(visit.categoria === 'comercial_tecnica' || visit.categoria === 'tecnica') {
      return inputTime.isBefore(sixAM) || inputTime.isAfter(sixPM);
    } else return false
  
  }

  return (
    <div className={styles.panel}>
      <div className={styles.panel_header}>
        <IconButton className={styles.back_button} onClick={returnPage}><ArrowCircleLeftOutlinedIcon sx={{ fontSize: '50px' }} /></IconButton>
        <div className={styles.panel_header_title}>
          {visit && visit.categoria === 'comercial' ? <RequestQuoteIcon sx={{ fontSize: '45px', fill: "#0eb05f" }} /> : null}
          {visit && visit.categoria === 'comercial_tecnica' ? <PeopleIcon sx={{ fontSize: '45px', fill: "#576af5" }} /> : null}
          {visit && visit.categoria === 'pos_venda' ? <EngineeringIcon sx={{ fontSize: '45px', fill: "#e24725" }} /> : null}
          {visit && visit.categoria === 'tecnica' ? <EngineeringIcon sx={{ fontSize: '45px', fill: "#1c256e" }} /> : null}
          <h1>Editar {visit && `${visit.tipo} ${visitaNome(visit.categoria)}`}</h1>
        </div>
      </div>
      <form onSubmit={onSubmit} className={styles.panel_content}>
        <h2>Dados do Cliente</h2>
        <div className={styles.panel_content_box}>
          <div className={styles.panel_content_title}>
            <h3>Endereço</h3>
          </div>
          <div className={styles.panel_content_title}>
            {isLoaded && position ?
              <GoogleMap mapContainerStyle={{ width: '100%', height: '300px' }} zoom={18} center={{ lat: position.lat, lng: position.lng }}>
                {position && <MarkerF position={{ lat: position.lat, lng: position.lng }} />}
              </GoogleMap> : null
            }
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <TextField
                margin="dense"
                label="Rua / Avenida"
                value={visit && `${visit.endereco} - ${visit.cidade}`}
                helperText={visit && visit.tempo}
                fullWidth
                required
                disabled
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IoCheckmarkDone size='1.6rem' color="green"/>
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start">
                      <FcGoogle size='1.6rem'/>
                    </InputAdornment>
                  ),
                }}
                autoComplete='off'
              />
            </Grid>
            <Grid item xs={12} md={2.33}>
              <TextField
                margin="dense"
                type='date'
                label="Data"
                value={info.dataTexto || '0000-00-00'}
                onChange={(e) => setInfo({ ...info, dataTexto: e.target.value })}
                fullWidth
                required
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={2.33}>
              <TextField
                margin="dense"
                type='time'
                inputRef={hoursRef}
                label="Horário Marcado"
                disabled={visit.data === info.dataTexto && visit.tipo === 'Visita Conjunta'}
                value={info.horarioTexto ?? ''}
                onChange={(e) => setInfo({ ...info, horarioTexto: e.target.value })}
                fullWidth
                required
                helperText={isAfterPermittedHours(info.horarioTexto) ? 'Horário permitido: das 06:00 até 18:00hrs.' : null}
                error={isAfterPermittedHours(info.horarioTexto)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={2.32}>
              <FormControl fullWidth margin='dense'>
                <InputLabel id="disjuntor">Tempo de Visita</InputLabel>
                <Select
                  labelId='disjuntor'
                  label="Tempo de Visita"
                  value={info.visitaNumero ?? ''}
                  disabled={visit.data === info.dataTexto && visit.tipo === 'Visita Conjunta'}
                  onChange={(e) => setInfo({ ...info, visitaNumero: e.target.value })}
                  required>
                  <MenuItem value={1800}>00:30</MenuItem>
                  <MenuItem value={3600}>01:00</MenuItem>
                  <MenuItem value={5400}>01:30</MenuItem>
                  <MenuItem value={7200}>02:00</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {visits && visits.length > 0 ?
            <><div className={styles.panel_content_title}>
              <h3>Visitas de</h3>
              {info.dataTexto ?
                <h3 style={{ marginLeft: '0.3rem' }}> {moment(info.dataTexto).format('DD/MM/YYYY')}</h3> :
                <select
                  value={monthNumber}
                  name="month"
                  className={styles.month_visits}
                  onChange={(e) => setMonthNumber(e.target.value)}
                >
                  <option value="01">Janeiro</option>
                  <option value="02">Fevereiro</option>
                  <option value="03">Março</option>
                  <option value="04">Abril</option>
                  <option value="05">Maio</option>
                  <option value="06">Junho</option>
                  <option value="07">Julho</option>
                  <option value="08">Agosto</option>
                  <option value="09">Setembro</option>
                  <option value="10">Outubro</option>
                  <option value="11">Novembro</option>
                  <option value="12">Dezembro</option>
                </select>
              }
            </div>
              <div className={styles.panel_content_title}>
                <TableContainer className="table-visits" component={Paper} sx={{ maxHeight: 240 }}>
                  <Table size="small" stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow className="table-visits_header">
                        <TableCell align="center"></TableCell>
                        <TableCell align="center" padding="none">Visita</TableCell>
                        {/* {type !== 'lunch' && 
                      <TableCell align="center">Ação</TableCell>
                    } */}
                        <TableCell align="center">Dia</TableCell>
                        <TableCell align="center">Saida</TableCell>
                        <TableCell align="center">Chegada</TableCell>
                        <TableCell align="center">Motorista</TableCell>
                        <TableCell align="center">Cidade</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {visits.map((visita) => (
                        <TableRow
                          key={visita.id}
                          sx={visita.data === info.dataTexto && (visita.chegadaEmpresa === visit.saidaEmpresa || visita.saidaEmpresa === visit.chegadaEmpresa) ? { backgroundColor: "#ffffd5" } : null}
                        // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell
                            aria-label={visita.consultora}
                            data-cooltipz-dir="right"
                            sx={{ backgroundColor: `${visita.cor}`, color: '#fff', width: 30 }}
                            align="center" component="th" scope="row">
                            {visita.consultora.substring(0, 1)}
                          </TableCell>
                          {visita.categoria === "lunch" && <TableCell style={{ filter: 'contrast' }} className="type-icon lunch" aria-label="Almoço" data-cooltipz-dir="right"><RestaurantIcon /></TableCell>}
                          {visita.categoria === "comercial" && <TableCell className="type-icon comercial" aria-label="Visita Comercial" data-cooltipz-dir="right"><RequestQuoteIcon /></TableCell>}
                          {visita.categoria === "comercial_tecnica" && <TableCell className="type-icon comercial_tec" aria-label="Comercial + Técnica" data-cooltipz-dir="right"><PeopleIcon /></TableCell>}
                          {visita.categoria === "pos_venda" && <TableCell className="type-icon pos_venda" aria-label="Pós-Venda" data-cooltipz-dir="right"><EngineeringIcon /></TableCell>}
                          {visita.categoria === "tecnica" && <TableCell className="type-icon tec" aria-label="Técnica" data-cooltipz-dir="right"><EngineeringIcon /></TableCell>}
                          <TableCell sx={{ width: 30 }} align="center" scope="row">
                            {visita.dia.substring(8, 10)}
                          </TableCell>
                          <TableCell align="center">{visita.saidaEmpresa}</TableCell>
                          <TableCell align="center">{visita.chegadaEmpresa}</TableCell>
                          {/* <TableCell align="center">{visita.saidaEmpresa}</TableCell>
                      <TableCell align="center">{visita.chegadaEmpresa}</TableCell> */}
                          <TableCell align="center">{visita.tecnico}</TableCell>
                          {visita.categoria !== "lunch" &&
                            <TableCell align="center">{visita.cidade && visita.cidade}</TableCell>
                          }
                          {visita.categoria === 'lunch' &&
                            <TableCell />
                          }
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div></> :
            <div style={{ display: 'none!important' }} className="visit-aviso">
              <h1>Nenhuma Visita Encontrada</h1>
            </div>
          }
          <div className={styles.panel_content_title}>
            <div className={visitsFindCount < 0 || visitsFindCount > 0 ? "box-visit__info prev error-aviso" : "box-visit__info prev check"}>
              <span className="">Previsão de Visita
                {(visitsFindCount < 0 || visitsFindCount > 0) ?
                  <div aria-label="Essa Visita ultrapassa o horário de uma Visita já existente. Verifique os horários disponiveis"
                    data-cooltipz-dir="top" data-cooltipz-size="large" ><ErrorOutlineIcon sx={{ fill: 'red' }} /></div>
                  :
                  <div aria-label="A Visita pode ser criada"
                    data-cooltipz-dir="top" >
                    <CheckIcon className="check-icon" />
                  </div>
                }
              </span>
              <p className="notice">
                <ArrowCircleRightIcon className="saida" />Saindo às <b>{saidaTexto}</b>
              </p>
              <p className="notice">
                <ArrowCircleLeftIcon className="saida" />Chegando às <b>{chegadaTexto}</b>
              </p>
            </div>
          </div>
          <div className={styles.panel_content_input}>
            <TextField
              margin="dense"
              id="name"
              label="Cliente"
              type="text"
              value={info.cliente ?? ''}
              onChange={(e) => setInfo({ ...info, cliente: e.target.value })}
              fullWidth
              required
              variant="outlined"
            />
            <FormControl fullWidth margin='dense'>
              <InputLabel id="disjuntor">Consultora</InputLabel>
              <Select
                labelId='disjuntor'
                label="Consultora"
                value={info.consultoraTexto ?? ''}
                disabled={userRef && userRef.cargo === "Vendedor(a)" ? true : false}
                onChange={(e) => setInfo({ ...info, consultoraTexto: e.target.value })}
                required>
                {consultoras && consultoras.map((data, index) => (
                  <MenuItem key={index} value={data.nome}>{data.nome}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin='dense'>
              <InputLabel id="tecnico">Técnico</InputLabel>
              <Select
                labelId='tecnico'
                label="tecnico"
                value={info.tecnicoTexto || ''}
                onChange={(e) => setInfo({ ...info, tecnicoTexto: e.target.value })}
                required>
                {driver && driver.map((data, index) => (
                  <MenuItem key={index} value={data.nome}>{data.nome}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              id="name"
              label="Veiculo"
              type="text"
              value={(tecRefUID && tecRefUID.veiculo) || ''}
              fullWidth
              disabled
              variant="outlined"
            />
          </div>
          <div className={styles.panel_content_input}>
            <FormControl fullWidth margin='dense'>
              <InputLabel id="disjuntor">Concessionária</InputLabel>
              <Select
                labelId='disjuntor'
                label="Concessionária"
                value={info.concessionaria ?? ''}
                onChange={(e) => setInfo({ ...info, concessionaria: e.target.value })}
                required>
                <MenuItem value='CPFL'>CPFL</MenuItem>
                <MenuItem value='Elektro'>Elektro</MenuItem>
                <MenuItem value='Enel'>Enel</MenuItem>
                <MenuItem value='Cerim'>Cerim</MenuItem>
                <MenuItem value='Ceripa'>Ceripa</MenuItem>
                <MenuItem value='EDP São Paulo'>EDP São Paulo</MenuItem>
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              id="name"
              label="Observação"
              type="text"
              value={info.observacao ?? ''}
              onChange={(e) => setInfo({ ...info, observacao: e.target.value })}
              fullWidth
              variant="outlined"
            />
            <FormControl fullWidth margin='dense'>
              <InputLabel id="analista">Analista</InputLabel>
              <Select
                labelId='analista'
                label="Analista"
                value={analista.nome ?? ''}
                onChange={(e) => handleAnalista(e.target.value)}
                disabled={userRef && userRef.cargo !== 'Administrador'}
                required>
                <MenuItem value='Nenhuma'>Nenhuma</MenuItem>
                {analistas && analistas.map((seller, index) => (
                  <MenuItem key={index} value={seller.nome_ref}>{seller.nome_ref}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {visit && visit.categoria === 'comercial_tecnica' && (userRef && (userRef.cargo === 'Administrador' || userRef.time)) ?
              <FormControl fullWidth margin='dense'>
                <InputLabel id="disjuntor">Representante</InputLabel>
                <Select
                  labelId='disjuntor'
                  label="Representante"
                  value={info.representante ?? ''}
                  onChange={(e) => setInfo({ ...info, representante: e.target.value })}
                  disabled={visit && visit.indicador?.nome !== 'Nenhum'}
                  required>
                  <MenuItem value='Nenhum'>Nenhum</MenuItem>
                  {representativesRef && representativesRef.map((data, index) => (
                    <MenuItem key={index} value={data.id_representante}>{data.nome} <b>({data.id_representante})</b></MenuItem>
                  ))}
                </Select>
              </FormControl> : null
            }
          </div>
        </div>

        {userRef && (userRef.cargo === 'Administrador' || userRef.uid === visit.uid || userRef.id === visit.responsavel?.id || userRef?.id === customer?.assistente) ?
          <div className={styles.button_footer}>
            <ThemeProvider theme={theme}>
              <Button
                disableElevation
                color='secondary'
                variant={'contained'}
                type='submit'
                style={{ textTransform: 'capitalize' }}>
                Alterar
              </Button>
                <Button
                  disableElevation
                  variant={'outlined'}
                  onClick={returnPage}
                  style={{ textTransform: 'capitalize' }}>
                  Cancelar
                </Button>
            </ThemeProvider>
          </div>
          : null}
      </form>
    </div>
  )
}

export default memo(EditVisit)