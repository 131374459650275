import { TextField } from "@mui/material";
import { useEffect, useRef } from "react";
import { usePlacesWidget } from "react-google-autocomplete";

const AutocompleteMaps = ({ KeyMaps, setCustomer, setValue, trigger, check = false, address, title, hookForm = false }) => {
    const inputRef = useRef(null);

    const { ref } = usePlacesWidget({
      apiKey: KeyMaps,
      onPlaceSelected: async (place) => {
        const address = place.formatted_address;
        const cityRef = place.address_components.find(ref => ref.types.includes('administrative_area_level_2'));
        const postalCode = place.address_components.find(ref => ref.types.includes('postal_code'));

        console.log(setCustomer);
        console.log(setValue, hookForm)

        if(hookForm) {
          setValue("endereco", {
            lat: place.geometry?.location?.lat(),
            lng: place.geometry?.location?.lng(),
            endereco: address.substring(0, address.length - 19),
          }, { shouldDirty: true, shouldValidate: true });
          
          setValue("cidade", cityRef ? cityRef.long_name : '', { shouldDirty: true, shouldValidate: true });
          setValue("cep", postalCode ? postalCode.long_name : '', { shouldDirty: true, shouldValidate: true });
          
          await trigger(["endereco", "cidade", "cep"]);
          console.log ('aaaa2')
        } else {
          setCustomer((prevCustomer) => ({
            ...prevCustomer,
            endereco: {
              lat: place.geometry?.location?.lat(),
              lng: place.geometry?.location?.lng(),
              endereco: address.substring(0, address.length - 19),
            },
            cidade: cityRef ? cityRef.long_name : '',
            cep: postalCode ? postalCode.long_name : '',
          }));
        }
      
      },
      options: {
        types: ["geocode", "establishment"],
        componentRestrictions: { country: "br" },
        fields: ["formatted_address", "address_components", "geometry.location"],
      },
    });

    useEffect(() => {
        if (inputRef.current && address) {
          inputRef.current.value = address; // Define o valor inicial do endereço
        }
      }, [address]);

    return (
      <TextField
        margin="dense"
        label={title}
        fullWidth
        required
        disabled={check}
        variant="outlined"
        inputProps={{
            autoComplete: 'new-password',
            form: {
              autoComplete: 'off',
            }
          }}
        InputLabelProps={{ shrink: true }}
        inputRef={(el) => {
          ref.current = el; // Conecta a ref do autocomplete à input
          inputRef.current = el; // Mantém a ref da input localmente
        }}
      />
    );
  };

  export default AutocompleteMaps;
