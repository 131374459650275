import { styled } from "@mui/material";

export const NewBox = styled("div")(({ padding, margin }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "15px",
    width: "100%",
    backgroundColor: "#fff",
    margin: margin || 0,
    padding: padding || "1rem",
    boxSizing: "border-box",
    boxShadow: "0px 10px 24px 0px #0000001A",
  }));