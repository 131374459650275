import Swal from "sweetalert2";

export const showBoxMessage = ({ title, text, icon }) => {
    return Swal.fire({
        title: title,
        html: text,
        icon: icon,
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: "#111",
      });
}